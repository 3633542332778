import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { InputDataUnit } from '@app/Global/Models/ClientModels';
import { ControlData } from '@app/Global/Models/EntityModels';

@Component({
  selector: 'FormInputDataUnit',
  templateUrl: './FormInputDataUnit.html',
  styleUrls: ['./FormInputDataUnit.scss']
})

export class FormInputDataUnit implements OnInit {

  //Parent component
  @Input() ParentComponent;

  //Account ID
  @Input() AccountID;

  //Autocomplete LinkType for additional filtering
  @Input() AutoCompleteLinkType;

  //ID of the input
  @Input() ID;

  //If any dropdown options are relevant, the caller can provide (usually for type=dropdown)
  @Input() DropdownOptions;

  //Max date for the primeng calendar picker, allow caller to provide
  @Input() public DTPMaxDate;

  //Max date for the primeng calendar picker, allow caller to provide
  @Input() public DTPMinDate;

  //This is the control type name for the mat-autocomplete input
  @Input() public AutoCompleteControlTypeName;

  //Allowed options in the multiselect input
  @Input() public MultiselectSingleOptions;

  //Selected options in the multiselect input
  @Input() public SelectedMultiselectSingleOptions;

  //Do we want to show the clear button?
  @Input() public ShowClearButton = true;

  //Multiselect single Input
  @ViewChild('INP_MultiselectSingle') INPMultiselectSingle;

  //A local ModelData that captures it inside an array, which let's us use a generic function to retrieve and update. We need to delay filling this, due to an Angular initial renedering bug (see https://angular.io/errors/NG0100)
  public ModelData: [InputDataUnit];

  //Input Helper text
  public InputHelperText;

  //A copy of the matching Data unit, this can be used by this template to render things that are static and don't change (e.g. placeholder, styles, etc). Needs to be initialized with a default, or angular will give null reference errors.
  public LookupDataItem = new InputDataUnit();

  //A local copy to store the Primeng calendar picker
  public DTPDate = { JSDate: null, ISODate: null };

  //The current date format for the Prime DTP
  public DTPDateFormat = "dd/M/yy";

  //A data entry format that supports integer month values, so users can enter using keypad
  public DTPDateDataEntryFormat = "dd/mm/yy";

  //A display format that shows the month name as text.
  public DTPDateDisplayFormat = "dd/M/yy";

  //UI Yes/No Selector options
  public YesNoOptions = [
    { name: 'Select', value: "0" },
    { name: 'Yes', value: "1" },
    { name: 'No', value: "2" }
  ];

  //File Upload Data
  public FileUploadData;

  //Autocomplete request/response model data
  public AutoCompleteData = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: '', Client_AutoCompleteControlData: []
  };

  //Unique GUID to track the last autocomplete request
  private LastAutoCompleteRequestGUID: string;

  constructor(
    private apiService: ApiService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    public globalFunctions: GlobalFunctions) {
  }

  //Angular constructor
  ngOnInit() {

    //Map the autocomplete type name
    this.AutoCompleteData.Client_AutoCompleteControlTypeName = this.AutoCompleteControlTypeName;

    //Need to add a small delay here due to this error: Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    this.globalFunctions.delay(50).then(() => {
      this.ModelData = this.ParentComponent.ModelData;

      //Lookup and cache to optimize rendering for static things
      this.LookupDataItem = this.ModelData.filter(x => x.Name === this.ID)[0];

      //Check if any defaults need to be calculated. e.g. the clear button should be hidden for yesno questions
      if (this.LookupDataItem.HTMLInputType === "yesno") {
        this.ShowClearButton = false;

        //Set the matching default value for the model for yesno questions ("0" = "Choose an answer"). But we should only set it the first time. Otherwise we reset the state everytime this gets re-rendered in via ngIf (e.g. user changing prior questions and hiding/showing content multiple times).
        const currentValue = this.ModelDataUnit_Get({ id: this.ID }).Value;
        if (currentValue === "") {
          this.ModelDataUnit_Update({ id: this.ID }, { value: "0" });
        }
      }
      else if (this.LookupDataItem.HTMLInputType === "date") {

        //If the default date value is passed, set the date object
        if (!this.globalFunctions.isEmpty(this.LookupDataItem.ValueDate)) {

          //Set ISODate
          this.DTPDate.ISODate = this.LookupDataItem.ValueDate;

          //Set JSDate
          this.DTPDate.JSDate = new Date(this.DTPDate.ISODate);
        }
      }
      //Hide clear button for file upload
      else if (this.LookupDataItem.HTMLInputType === "p-fileupload") {
        this.ShowClearButton = false;
      }
      //Hide clear button for multiselect single option
      else if (this.LookupDataItem.HTMLInputType === "p-multiselect-single") {
        this.ShowClearButton = false;
      }
      //Hide clear button for checkbox and set the label display
      else if (this.LookupDataItem.HTMLInputType === "checkbox") {
        this.ShowClearButton = false;

        if (this.LookupDataItem.IsChecked) {
          this.LookupDataItem.LabelDisplay = this.LookupDataItem.LabelChecked;
        }
        else {
          this.LookupDataItem.LabelDisplay = this.LookupDataItem.LabelUnchecked;
        }
      }
    });
  }

  public DTP_OnClose(input) {

    //Convert DTP display format back to display format whenever calendar is closed
    this.DTPDateFormat = this.DTPDateDisplayFormat;

    //Redeclare the JS Date
    this.DTP_SyncJSDate(this.DTPDate);

    //Convert to ISO for API later
    this.globalFunctions.Date_ToISO(this.DTPDate);

    //Hide the calendar if supplied (needed on the OK button click for primeng)
    if (!this.globalFunctions.isEmpty(input)) {
      input.hideOverlay();
    }

    //We need to save this to the Model on the parent. Since we have a calendar input, dummy the matching ID in
    this.ModelDataUnit_Update({ id: this.ID }, this.DTPDate.ISODate);

    //Resync Inputs
    this.ParentComponent.InputView_Sync();
  }

  public DTP_Focus() {

    //Convert DTP display format to a version that the user can input using keypad. This is easier for data entry.
    this.DTPDateFormat = this.DTPDateDataEntryFormat;

    //Redeclare the JS Date
    this.DTP_SyncJSDate(this.DTPDate);
  }

  //Redeclare the JS Date so that PrimeNG re-renders its content again
  public DTP_SyncJSDate(date): void {

    if (!this.globalFunctions.isEmpty(date.JSDate)) {
      date.JSDate = new Date(date.JSDate.getFullYear(), date.JSDate.getMonth(), date.JSDate.getDate());
    }
  }

  //Get input styles
  public Input_Style() {
    let styles = "";

    //Check if it is resizable
    if (this.LookupDataItem.IsResizable === false) {
      styles += "glb_resizeNone ";
    }

    //Check if there is a custom css height to apply
    if (!this.globalFunctions.isEmpty(this.LookupDataItem.CSSHeight)) {
      styles += this.LookupDataItem.CSSHeight + " ";
    }

    //If input is disabled, add disabled background color
    if (this.LookupDataItem.Disabled) {
      styles += "glb_BGColorDisabledInput ";
    }

    return styles;
  }

  //Get the matching model data input
  public ModelDataUnit_Get(input) {

    //Use a default for initial display, or there will be null ref template errors
    const defaultDataItem = new InputDataUnit({ DisplayName: "", Value: "" });

    //Check if we have a Model array to work with
    if (this.globalFunctions.isEmpty(this.ModelData)) {
      return defaultDataItem;
    }

    //Now try to lookup the matching Data Unit
    const lookupDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    //Did we find it?
    if (this.globalFunctions.isEmpty(lookupDataItem)) {
      //If not found, return the default
      return defaultDataItem;
    }

    //Return the matching item
    return lookupDataItem;
  }

  //Sync text area on input change
  public TextArea_Sync(input, overlay, event = null) {

    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    //Validate the input and update the model data if valid
    this.TextArea_Validate(modelDataItem, event);

    //To display input text helper
    this.InputHelperView_Sync(input, overlay, event);
  }

  //Update the matching model data input value
  public ModelDataUnit_Update(input, event) {

    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelDataItem)) {

      //Dropdown event is a little different. Value is inside a nested "MatSelectChange"
      if (this.LookupDataItem.HTMLInputType === "dropdown") {
        modelDataItem.Value = event.value;

        //Also sync input view for dropdowns
        this.ParentComponent.InputView_Sync();
      }
      //Autocomplete event is a little different. Value is inside a nested property "ControlGUID"
      else if (this.LookupDataItem.HTMLInputType === "autocomplete") {
        modelDataItem.Value = event.ControlGUID;
        modelDataItem.AutoCompleteControlData = event;

        //Also sync input view for autocomplete
        this.ParentComponent.InputView_Sync();
      }
      //File upload
      else if (this.LookupDataItem.HTMLInputType === "p-fileupload") {

        if (event.FileUpload_IsValid === false) {

          modelDataItem.Value = "";
          modelDataItem.FileName = "";
        }
        else {

          modelDataItem.Value = event.FileUpload_FileBase64String;
          modelDataItem.FileName = event.FileUpload_FileName

        }

        //Also sync input view for file upload
        this.ParentComponent.InputView_Sync();
      }
      else if (this.LookupDataItem.HTMLInputType === "yesno") {
        modelDataItem.Value = event.value;
      }
      //Multiselect single
      else if (this.LookupDataItem.HTMLInputType === "p-multiselect-single") {

        //Set the model value with the selection option GUID
        if (!this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions) && !this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions[0])) {
          modelDataItem.Value = this.SelectedMultiselectSingleOptions[0].GUID;
        }

        //Also sync input view after the option is selected
        this.ParentComponent.InputView_Sync();
      }
      //Toggle checkbox item value
      else if (this.LookupDataItem.HTMLInputType === "checkbox") {

        if (modelDataItem.IsChecked) {
          modelDataItem.IsChecked = false;
          modelDataItem.Value = "false";
          modelDataItem.LabelDisplay = modelDataItem.LabelUnchecked;
        }
        else {
          modelDataItem.IsChecked = true;
          modelDataItem.Value = "true";
          modelDataItem.LabelDisplay = modelDataItem.LabelChecked;
        }

        //Also sync input view for file upload
        this.ParentComponent.InputView_Sync();
      }
      else {

        //For all other HTMLInput type, set the input value
        modelDataItem.Value = event;

        //Update the display value
        this.DataInput_Format(modelDataItem);
      }
    }
  }

  //Action when a yes/no option is selected
  public OptionSelection_Click(input, overlay, event): void {

    //Update the model data first
    this.ModelDataUnit_Update(input, event);

    //Now call the input sync
    this.InputView_Sync(overlay);
  }

  //Action when a checkbox is clicked
  public Checkbox_Toggle(input): void {

    //Update the model data first
    this.ModelDataUnit_Update(input, "");
  }

  //Process/Validate/Prep file for upload
  public FileSelected_Process(event: Event, fileUpload) {

    //Model update (ModelDataUnit_Update) will be done via callback
    this.FileUploadData = this.globalFunctions.FileSelected_Process(event, fileUpload, this.notifyService, this);
  }

  //Unselect a file
  public File_Unselect() {

    //Reset the file content
    this.FileUploadData.FileUpload_FileBase64String = null;
    this.FileUploadData.FileUpload_FileName = null;
    this.FileUploadData.FileUpload_IsValid = false;
    this.FileUploadData.FileUpload_IsChosen = false;

    this.ModelDataUnit_Update({ id: this.ID }, this.FileUploadData);
  }

  //Display the unformatted value when the text area is focused/active
  public TextArea_OnFocus(input) {

    //Lookup the matching model entry
    const modelData = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelData)) {

      //Set the value display with the unformatted value
      modelData.ValueDisplay = modelData.Value;
    }
  }

  //Display the formatted value when the text area is unfocused
  public TextArea_OnBlur(input, overlay) {

    //Lookup the matching model entry
    const modelData = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelData)) {

      //Format the display value
      this.DataInput_Format(modelData);
    }

    //Now call the inputview sync method
    this.InputView_Sync(overlay);
  }

  //Format the input data
  private DataInput_Format(inputDataUnit: InputDataUnit): void {
    if (!this.globalFunctions.isEmpty(inputDataUnit.Value)) {
      inputDataUnit.ValueDisplay = this.globalFunctions.customDataTypeParser(inputDataUnit.Value, inputDataUnit.Type);
    }
    else {
      inputDataUnit.ValueDisplay = inputDataUnit.Value;
    }
  }

  //Check if the input helper should be displayed, and update its content
  public InputHelperView_Sync(input, overlay, event = null): void {

    //A small delay so that other UI reflows don't put this in an awkward position
    this.globalFunctions.delay(200).then(() => {

      //Lookup the matching model entry
      const modelData = this.ModelData.filter(x => x.Name === input.id)[0];

      if (!this.globalFunctions.isEmpty(modelData)) {

        //Input helper messaging for textareas
        if (modelData.HTMLInputType === "textarea") {

          if (modelData.Type === "string") {
            //Check if the length is valid and adjust the text
            if (modelData.Value.length > modelData.MinLength) {
              //This input is ready, indicate via its text
              this.InputHelperText = "Please press TAB or click away to continue...";
            }
            else {
              //This input is NOT ready
              this.InputHelperText = "This input requires at least " + modelData.MinLength + " characters";
            }
          }
          else {

            //This input is ready, indicate via its text
            this.InputHelperText = "Valid between " + modelData.MinValue + " and " + this.globalFunctions.customDataTypeParser(modelData.MaxValue, "decimal.0") + ". Press TAB or click away to continue...";

          }

          //Show the input overlaypanel if we are showing the Input Helper
          if (!this.globalFunctions.isEmpty(event) && !this.globalFunctions.isEmpty(overlay)) {
            overlay.show(event);
          }
        }
      }
    });
  }

  //Clear button click, removes the relevant model data, and triggers an InputView sync
  public InputDataClear_Click(input): void {

    //Adding a confirmer flow for Result, this is a large text field and we don't want the user to accidently lose it
    if (this.LookupDataItem.ClearConfirm === true && !this.globalFunctions.isEmpty(this.ModelDataUnit_Get(input).Value)) {

      const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());
      dialogRef.componentInstance.htmlContent = "Are you sure you want to clear " + this.LookupDataItem.DisplayName + "?"

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Update it to blank
          this.InputData_Clear(input);
        }
      });
    }
    else {

      //Update it to blank
      this.InputData_Clear(input);
    }
  }

  //Clears input data
  public InputData_Clear(input): void {

    //Clear DTP data too
    this.DTPDate.JSDate = null;
    this.DTPDate.ISODate = null;

    //Mat Autocomplete resetting
    this.LastAutoCompleteRequestGUID = "";

    if (this.LookupDataItem.HTMLInputType === "autocomplete") {

      const autoCompleteControlDataDefault = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

      //Reset mat autocomplete model data
      this.ModelDataUnit_Update(input, autoCompleteControlDataDefault);
    }
    else {
      //Update it to blank
      this.ModelDataUnit_Update(input, "");
    }

    //Clear linked field data
    this.LinkedData_Clear();

    //Sync the input view
    this.ParentComponent.InputView_Sync()
  }

  //Clears all the linked field data. E.g. Security valuations
  public LinkedData_Clear(): void {

    //Find the matching field to extract GUID
    const matchingField = this.ModelData.filter(x => x.Name === this.ID)[0];
    if (!this.globalFunctions.isEmpty(matchingField)) {

      //Get all the linked fields based on matching parent guid
      const linkedFields = this.ModelData.filter(x => x.ParentGUID === matchingField.GUID);
      if (!this.globalFunctions.isEmpty(linkedFields)) {
        linkedFields.forEach(linkedProperty => {
          linkedProperty.Value = "";
          linkedProperty.AutoCompleteControlData = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" }
        });
      }
    }
  }

  //Inputview sync to trigger other input changes
  public InputView_Sync(overlay) {

    //Hide the text helper overlay
    if (!this.globalFunctions.isEmpty(overlay)) {
      overlay.hide();
    }

    //Now call the Input view sync on the parent. This will help trigger any new inputs to appear (or disappear)
    this.ParentComponent.InputView_Sync();
  }

  //Toggle when we an item is selected from the list. Allowed = Single only
  public MultiSelectSingle_Toggle(e) {

    //Empty the array if there is any selection option already
    if (!this.globalFunctions.isEmpty(this.SelectedMultiselectSingleOptions)) {
      this.SelectedMultiselectSingleOptions.length = 0;
    }

    //Set the new value in the selection option
    this.SelectedMultiselectSingleOptions.push(e.itemValue);

    this.ModelDataUnit_Update({ id: this.ID }, e);

    //Hide the panel on selection
    if (!this.globalFunctions.isEmpty(this.INPMultiselectSingle)) {
      this.INPMultiselectSingle.hide();
    }
  }

  //Regex and Min/Max validations
  public TextArea_Validate(inputDataUnit, event) {

    //Where was this character inserted? at event.target.selectionStart
    const characterIndexInserted = event.target.selectionStart;

    //Check the data type, and perform the necessary removal of invalid characters using regex. except string, that has no regex
    if (inputDataUnit.Type.includes('string') === false) {

      //Get the regex expression for the input data type
      const regExType = this.globalFunctions.RegExp_Get(inputDataUnit.Type);

      //Now perform the regex test
      if (!this.globalFunctions.isEmpty(regExType) && regExType.test(event.target.value) === false) {

        //Didn't pass regex, remove the last character
        this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

        //Let's parse it into a pretty text that we can display to the user
        let prettyType;
        prettyType = JSON.parse(JSON.stringify(inputDataUnit.Type));
        let precision = '';
        let prettyTypeDescription = '';

        if (prettyType.includes('.') === true) {
          const splitted = prettyType.split('.');
          prettyType = splitted[0];
          precision = splitted[1];
          prettyTypeDescription = prettyType + " with a maximum of " + precision + " decimal places";
        }
        else {
          prettyTypeDescription = prettyType;
        }

        //What if it was pasted in? and has more issues than just the last entered character? regex it once more, if it doesn't pass, remove the entire thing
        if (regExType.test(event.target.value) === false) {
          event.target.value = "";
        }

        //Now give the user this error message
        this.notifyService.Error_Show("An invalid value was entered. Please review and try again", "Not a valid " + prettyTypeDescription)
      }
      else {
        //We passed regex. now lets do max and min processing for numeric values

        //Check if its too big
        if (parseFloat(event.target.value) > parseFloat(inputDataUnit.MaxValue)) {

          //Don't update it, its too large. now give the user this error message
          this.notifyService.Error_Show("This value is larger than the maximum of " + this.globalFunctions.customDataTypeParser(inputDataUnit.MaxValue, "decimal.0") + ". Please make it smaller and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

          //If its still too large, set the value to 0
          if (parseFloat(event.target.value) > parseFloat(inputDataUnit.MaxValue)) {
            event.target.value = 0;
          }

          //Otherwise put the last value in there.
          else {
            event.target.value = inputDataUnit.Value;
          }
        }

        //Check if its too small
        else if (parseFloat(event.target.value) < parseFloat(inputDataUnit.MinValue)) {

          //Don't update it, its too small. now give the user this error message
          this.notifyService.Error_Show("This value is smaller than the minimum of " + inputDataUnit.MinValue + ". Please make it larger and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

          //If its still too small, put the last value in there.
          if (parseFloat(event.target.value) < parseFloat(inputDataUnit.MinValue)) {
            event.target.value = inputDataUnit.Value;
          }
        }
        else {

          //Number has passed min and max validation. lets put this value into the dataunit value, so that it can be saved later
          inputDataUnit.Value = event.target.value;
        }
      }
    }
    else {

      //String has no regex, but we stil need to check max. don't worry about min.
      if (event.target.value.length > inputDataUnit.MaxLength) {

        //Its too long. now give the user this error message
        this.notifyService.Error_Show("This value is longer than the maximum of " + inputDataUnit.MaxLength + ". Please make it shorter and try again", "Invalid length")

        //Didn't pass, remove the last character
        this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted);

        //If its still too long, just truncate it (deal with copy and paste)
        if (event.target.value.length > inputDataUnit.MaxLength) {
          event.target.value = event.target.value.substr(0, inputDataUnit.MaxLength);
        }

        //And also set the dataunit value to this value. if you don't do this, there is a chance that the user clicks save very quickly, and a blank value is saved on the server. we don't want that
        inputDataUnit.Value = event.target.value;
      }

      //Dont really need to do min length processing for strings.
      else {

        //Length validation is fine, update the dataUnits value  
        inputDataUnit.Value = event.target.value;
      }

    }
  }

  //Removes a character from the event target string, and resets the location of the selection index
  private LastCharacter_ResetAndRemove(target, characterIndexInserted) {

    //Didn't pass, remove the last character
    target.value = this.globalFunctions.removeStringByIndex(target.value, characterIndexInserted);

    //Try to set the cursor back to where it was
    target.selectionStart = characterIndexInserted - 1;
    target.selectionEnd = characterIndexInserted - 1;
  }

  //When user leaves the autocomplete input without clicking from the list, lets try to assist in selecting for the exact match
  public AutoComplete_OnLeave() {

    //Get the current autocomplete data unit
    const currentDUValue = this.ModelDataUnit_Get({ id: this.ID });
    if (this.globalFunctions.isEmpty(currentDUValue)) {
      return;
    }

    //If the DU has the autocomplete data set correctly (i.e. in correct format by checking the property exists), we don't need to do anything further. This mean either the user has clicked the item from the list or the item has been created (no value selected)
    if (typeof (currentDUValue.AutoCompleteControlData["ControlGUID"]) === "string") {
      return;
    }

    //Check if we have any matching results. If no matching result or more than 1 matching result, return. We can't determine the target item
    if (this.AutoCompleteData.Client_AutoCompleteControlData.length === 0 || this.AutoCompleteData.Client_AutoCompleteControlData.length > 1) {
      return;
    }

    //See if the search item matches the last item on the autocomplete available list
    const searchedAutocompleteData = this.AutoCompleteData.Client_AutoCompleteControlData.filter(x => x.ControlValue.toUpperCase() === JSON.parse(JSON.stringify(currentDUValue.AutoCompleteControlData).toUpperCase()))[0];

    //If no matching result, return
    if (this.globalFunctions.isEmpty(searchedAutocompleteData)) {
      return;
    }

    //Now, update the current data unit with the target item
    this.ModelDataUnit_Update({ id: this.ID }, searchedAutocompleteData);
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue). This is currently specific to Client type Autocomplete. Will look to make it more generic, where possible (TODO).
  public AutoComplete_Client_ApplyFilter(autocompleteRecord, requestType: string, controlValue: string, fieldType: string, seed = false): ControlData[] {

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Client_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && autocompleteRecord.Client_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      autocompleteRecord.Client_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteLastSearchedValue)) {
      if ((autocompleteRecord.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {

        //Request new data and update the current value so we don't request it again.
        autocompleteRecord.Client_AutoCompleteLastSearchedValue = controlValue;

        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed.
        this.globalFunctions.delay(350).then(() => {

          //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
          this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
          //Make a copy of it for sending to the update request
          const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

          //Request a new set of values from the server
          this.AutoComplete_RequestData(requestType, autocompleteRecord.Client_AutoCompleteControlTypeName, controlValue, autocompleteRecord.Client_AutoCompleteControlData, fieldType, copyOfGUID);
        });
      }
    }
    //Don't place an else here, or seeding will always ask server for new data.
    //The only minor issue is that you need to press or click twice when backspacing to a blank string from the last character. not sure why it behaves this way. If you don't, it won't trigger the seed

    //Check if the control data is non null
    if (!this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteControlData)) {

      //Just return the array, even though its not synchronous. the update will come later and update it.
      return autocompleteRecord.Client_AutoCompleteControlData;
    }
  }

  //For Material to access global functions, we need to use a get and arrow syntax to bring context along.
  public get AutoComplete_GetPrettyName() {
    return (value) => this.globalFunctions.AutoComplete_GetPrettyName(value);
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string): void {

    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest(requestType, controlType, requestValue, fieldType, Number(this.AccountID), this.AutoCompleteLinkType ?? "");

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //This helps with flow, so we only resolve the last request.
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {

              this.globalFunctions.AutoComplete_ProcessResponse(apiResponse, controlArray);

              //Filter out the return value for already chosen security
              if (controlType === "Lookup_AccountSecurity") {
                if (!this.globalFunctions.isEmpty(controlArray)) {

                  //Get all the input securities based on the name
                  const inputSecurities = this.ModelData.filter(x => x.Name.startsWith("INP_Security_") === true && x.Value !== "");

                  //Loop through all the chosen securities and remove it from the autocomplete result: control array
                  inputSecurities.forEach(inputSecurity => {
                    const matchingSecurity = controlArray.findIndex(x => x.ControlGUID === inputSecurity.Value);
                    if (matchingSecurity >= 0) {
                      controlArray.splice(matchingSecurity, 1);
                    }
                  });
                }
              }
            }
          }
        });
    }
  }
}