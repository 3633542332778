import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { LoanIndex } from '../LoanIndex/LoanIndex';
import { ApiService } from '@app/Services/APIService';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';

@Component({
  selector: 'TaskQualityReview',
  templateUrl: './TaskQualityReview.html',
  styleUrls: ['./TaskQualityReview.scss']
})

export class TaskQualityReview implements OnInit {

  //The DataRow that we contains all the dataunits on the client side that we are targeting, including the TaskGUID
  public DataRow;

  //Store the QC feedback from the textarea/input on html
  public Feedback: "";

  //Indicates if we have feedback to provide or not. We use this to switch between the buttons, and also decide what happens to the QC_Passed flag on the task
  public HasFeedback = false;

  //Are we in a "in progress' state of saving changes onto the server
  public IsUpdatingFeedback = false;

  //We need a copy of LoanIndex to help construct the request to the server
  public LoanIndex: LoanIndex;

  constructor(
    public dialogRef: MatDialogRef<TaskQualityReview>,
    private apiService: ApiService,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    private globalFunctions: GlobalFunctions) { }

  //Angular constructor
  ngOnInit() {
    //We need to initialize the Feedback UI element on the Angular constructor, otherwise we get null reference errors the first time we call the HasFeedback_Sync method - it can't read its length property (e.g. TypeError: Cannot read properties of undefined (reading 'length'))
    //Note that this is only relevant for the keydown method. keyup doesn't need this.
    this.Feedback = "";
  }

  //Close this dialog
  public TaskQuality_Close() {
    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //Checks the length of the Feedback input to decide whether or not we have feedback
  public HasFeedback_Sync(): void {
    //Default to false
    this.HasFeedback = false;

    if (this.Feedback.length > 0) {
      //Flip the bool if there is some text
      this.HasFeedback = true;
    }
  }

  //Clear the feedback input/textarea
  public Feedback_ClearData(): void {
    this.Feedback = "";
    this.HasFeedback_Sync();
  }

  //Construct the feedback request and send it to the server
  public AddFeedback_Click(): void {
    const apiRequest = { AccountID: this.LoanIndex.AccountID, UserTaskGUID: this.DataRow.GUID, Feedback: this.Feedback };

    //Turn on full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);
    this.IsUpdatingFeedback = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.AddTaskQualityFeedback], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error. Remove any loading screens and return
          this.IsUpdatingFeedback = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Update the client side based on the response

          //Try to lookup the Task Status
          const taskStatus = this.DataRow.DataUnits.filter(x => x.Name === "Task Status")[0];

          //If found, let's update it
          if (!this.globalFunctions.isEmpty(taskStatus)) {
            taskStatus.Value = response.StatusGUID;
            taskStatus.ValueDisplay = response.StatusDisplay;

            taskStatus.ControlGUID = taskStatus.Value;
            taskStatus.ControlDisplay = taskStatus.ValueDisplay;
          }

          //Try to lookup the QC Passed Flag
          const taskQCFlag = this.DataRow.DataUnits.filter(x => x.Name === "QC Passed")[0];

          //QC Passed doesn't exist for CS type task
          if (!this.globalFunctions.isEmpty(taskQCFlag)) {

            //Update the Value and Display
            if (response.QCPassed === true) {
              taskQCFlag.Value = "True";
            }
            else {
              taskQCFlag.Value = "False";
            }

            taskQCFlag.ValueDisplay = taskQCFlag.Value;
          }

          //Insert the new Feedback note to the User Task
          //Use Entity_UpdateLocal to attach the feedback note to the parent user task
          this.LoanIndex.Entity_UpdateLocal(response.NoteEntityResponse);

          //In addition, if we are launched via the "Headless" mode, there might be some dashboard components visible. We should trigger a refresh on all of those, as a Task status change will mot likely mean that it needs to now be displayed in a different status column.
          this.LoanIndex.ParentComponent_RefreshTaskData();

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsUpdatingFeedback = false;

          //We can close this screen now
          this.TaskQuality_Close();
        }
      });
  }
}