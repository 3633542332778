import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { ApiService } from '@app/Services/APIService';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';
import { CreateEmailData } from '@app/Global/Models/ClientModels';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { Editor } from 'primeng/editor';
import Quill from 'quill';

@Component({
  selector: 'CreateEmail',
  templateUrl: './CreateEmail.html',
  styleUrls: ['./CreateEmail.scss']
})

export class CreateEmail implements AfterViewInit {

  @ViewChild('QuillEditor_CreateEmail') quillEditor_CreateEmail: Editor;

  //The DataRow that we contains all the dataunits on the client side that we are targeting, including the TaskGUID
  public DataRow;

  //To store the unique identifier of the target prefill, e.g. User Task GUID
  public LookupGUID;

  //Create Email Data init
  public CreateEmailData = new CreateEmailData();

  //Are we in a "in progress' state of saving changes onto the server
  public IsCreatingEmail = false;

  //We need a copy of LoanIndex to help construct the request to the server
  public LoanIndex: LoanIndex;

  public EmailToolTipLength = 27;

  public RecipientEmails;

  //Add delay for showing mat tool tip (in ms)
  public ToolTipDelay = 300;

  public PageHeader = "Create Reminder Email";
  public EmailTemplateName;

  public Quill: any;

  constructor(
    public dialogRef: MatDialogRef<CreateEmail>,
    private apiService: ApiService,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog,
    private globalFunctions: GlobalFunctions) { }

  //Initialise
  Page_Init() {

    //Add client side display attributes
    this.RecipientEmails.forEach(recipientEmail => {
      recipientEmail.IsSelected = false;

      recipientEmail.DisplayName_ShowToolTip = false;
      if (recipientEmail.DisplayName.length > this.EmailToolTipLength) {
        recipientEmail.DisplayName_ShowToolTip = true;
      }

      recipientEmail.Email_ShowToolTip = false;
      if (recipientEmail.Email.length > this.EmailToolTipLength) {
        recipientEmail.Email_ShowToolTip = true;
      }

      recipientEmail.LinkType_ShowToolTip = false;
      if (recipientEmail.LinkType.length > this.EmailToolTipLength) {
        recipientEmail.LinkType_ShowToolTip = true;
      }

      recipientEmail.ToolTipText = "Click to add";
    });
  }

  //This will be called after the view has been loaded
  ngAfterViewInit() {

    this.Quill = new Quill('#quill-editor', {
      modules: {
        //toolbar: toolbarOptions
        toolbar: '#toolbar'
      },
      placeholder: 'Compose an epic...',
      theme: 'snow', // or 'bubble'
    });

    //Set quill content with the value being passed, if exists
    if (!this.globalFunctions.isEmpty(this.CreateEmailData.Body)) {
      this.Quill.setContents(this.Quill.clipboard.convert({ html: this.CreateEmailData.Body }));
    }

    //Validate uploaded image size in quill editor
    this.globalFunctions.QuillImage_Validate(this.Quill, this.notifyService);

  }

  //Close this dialog
  public CreateEmail_Close() {

    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //Clear the input textarea
  public Input_ClearData(inputName: string): void {
    this.CreateEmailData[inputName] = "";

    //Clear IsSelected flag on recipient email list
    if (inputName === "EmailTo") {
      this.RecipientEmails.forEach(re => {
        re.IsSelected = false;
      });
    }
  }

  //Limit text size on any input
  public TextSize_Limit(input, length: number): number {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //Get the CSS based on whether the recipient is selected or not
  public SelectedClientBG_GetCSS(recipientEmail): string {
    if (recipientEmail.IsSelected === true) {
      return "color-bg-active";
    }
    return "color-bg-disabled";
  }

  //Add recipient email to the EmailTo List when a suggested client is clicked
  public RecipientEmail_Click(recipientEmail): void {

    //Switch the selected flag
    if (recipientEmail.IsSelected === true) {
      recipientEmail.IsSelected = false;
    }
    else {
      recipientEmail.IsSelected = true;
    }

    //If selected
    if (recipientEmail.IsSelected === true) {

      //Check if the target client email is already added
      if (!this.CreateEmailData.EmailTo.includes(recipientEmail.Email)) {

        //Add email delimiter before adding a new email to the list
        if (!this.CreateEmailData.EmailTo.trimRight().endsWith(";") && this.CreateEmailData.EmailTo.length > 0) {
          this.CreateEmailData.EmailTo = this.CreateEmailData.EmailTo + ";";
        }

        //Add target client email to the list
        this.CreateEmailData.EmailTo = this.CreateEmailData.EmailTo + recipientEmail.Email + ";";
      }

      recipientEmail.ToolTipText = "Click to remove";
    }
    else {

      //Remove the target client email from the list
      this.CreateEmailData.EmailTo = this.CreateEmailData.EmailTo.replaceAll(recipientEmail.Email + ";", "");

      recipientEmail.ToolTipText = "Click to add";
    }
  }

  //Construct the request to send email and send it to the server
  public SendEmail_Click(): void {

    //Inputs validation
    if (this.globalFunctions.isEmpty(this.CreateEmailData.EmailTo)) {
      this.notifyService.Error_Show("Recipient email is missing", "Error");
      return;
    }

    if (this.globalFunctions.isEmpty(this.CreateEmailData.Subject)) {
      this.notifyService.Error_Show("Email subject is missing", "Error");
      return;
    }

    const emailBody = this.Quill.getSemanticHTML();

    if (this.globalFunctions.isEmpty(emailBody)) {
      this.notifyService.Error_Show("Email body is missing", "Error");
      return;
    }

    //Add confirmation before sending an email
    const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());

    //Use html content so that we can style it
    dialogRef.componentInstance.htmlContent = "Are you sure that you want to send the email?"

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        const apiRequest = { AccountID: this.LoanIndex.AccountID, UserTaskGUID: this.DataRow.GUID, ToEmail: this.CreateEmailData.EmailTo, ReplyTo: this.CreateEmailData.ReplyTo, Subject: this.CreateEmailData.Subject, Body: emailBody, CCEmail: this.CreateEmailData.CCTo, EmailTemplate: this.EmailTemplateName };

        //Turn on full screen loading
        this.clientDataStore.SetShowFullscreenLoading(true);
        this.IsCreatingEmail = true;

        this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.SendTaskEmail], apiRequest)
          .subscribe(apiResponse => {
            if (this.globalFunctions.isEmpty(apiResponse)) {

              //There was no response, or an error. Remove any loading screens and return
              this.IsCreatingEmail = false;
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {

              //Get the response
              const response = JSON.parse(JSON.stringify(apiResponse));

              //Update the client side based on the response

              //Try to lookup the Task Status
              const taskStatus = this.DataRow.DataUnits.filter(x => x.Name === "Task Status")[0];

              //If found, let's update it
              if (!this.globalFunctions.isEmpty(taskStatus)) {
                taskStatus.Value = response.StatusGUID;
                taskStatus.ValueDisplay = response.StatusDisplay;

                taskStatus.ControlGUID = taskStatus.Value;
                taskStatus.ControlDisplay = taskStatus.ValueDisplay;
              }

              //Use Entity_UpdateLocal to attach the task note and document to the parent user task
              this.LoanIndex.Entity_UpdateLocal(response.NoteEntityResponse, false);
              this.LoanIndex.Entity_UpdateLocal(response.TaskDocEntityResponse, false);

              //In addition, if we are launched via the "Headless" mode, there might be some dashboard components visible. We should trigger a refresh on all of those, as a Task status change will mot likely mean that it needs to now be displayed in a different status column.
              this.LoanIndex.ParentComponent_RefreshTaskData();

              //Success message
              this.notifyService.Success_Show(response.ResponseMessage, "Success");

              //Turn off the fullscreen loading
              this.clientDataStore.SetShowFullscreenLoading(false);
              this.IsCreatingEmail = false;

              //Launch a modal to input the followup note
              this.LoanIndex.TaskFollowup_Launch(this.DataRow);

              //We can close this screen now
              this.CreateEmail_Close();
            }
          });
      }
    });
  }
}