<div id="PG_SLAReportingDrillthrough" class="glb_formBGColor glb_boxedPadding glb_Font text-white glb_Font glb_paddingZeroOffset">
  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
      <button id="BTN_CloseSLADrillthrough" tabindex="3"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="SLADrillthrough_Close()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
      </button>
    </div>
  </div>
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="glb_customFlexRow col-11 glb_paddingZeroOffset">
      <div class="col-8 glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
          SLA Drillthrough - {{SLACategory}}
        </div>
      </div>
    </div>
    <div class="col-1 justify-content-end align-items-center glb_customFlexRow">
      <span>
        <button id="BTN_DownloadSLADrillthrough"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right"
          (click)="Tasks_DownloadCSV()">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
          </fa-icon>
        </button>
      </span>
    </div>
    <br>
    <br>
    <div class="glb_boxedPaddingNarrow col-12">
      <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM">
          <div class="glb_thinBottomWhiteBorder glb_customFlexRow col-12 glb_paddingZeroOffset row-cols-12">
            <div *ngFor="let headerItem of ShowColumns" class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{headerItem.HeaderDisplay}}</div>
              <fa-icon class="fa-fw" [icon]="['fal', 'sort']" matTooltip="Click to Sort" matTooltipPosition="right"
                (click)="Click_Sort(headerItem.HeaderName)">
              </fa-icon>
            </div>
          </div>
        </div>
        <div *ngFor="let item of SLADrillthroughData" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
          <div class="glb_customFlexRow glb_paddingZeroOffset glb_paddingBottomXS col-12 row-cols-12">

            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.LoanID}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <a id="LNK_LaunchTask" class="hyperlinkID glb_hoverHand" (click)="TaskView_Launch(item)">{{item.TaskID}}</a>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow" matTooltip="{{item.LenderName}}" matTooltipPosition="right">
              <div>{{item.LenderInternalName}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow" matTooltip="{{item.TaskType}}" matTooltipPosition="right"
              [matTooltipDisabled]="item.DisablePBToolTip">
              <div>{{TextSize_Limit(item.TaskType,TextDisplayLimit)}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.TaskStatus}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.SLACategory}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.Source}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.DueDate_FMT}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.OverrideDueDate_FMT}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow">
              <div>{{item.CompletedDate_FMT}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow" matTooltip="{{item.AssignedToName}}" matTooltipPosition="right"
              [matTooltipDisabled]="item.DisablePBToolTip">
              <div>{{TextSize_Limit(item.AssignedToName,TextDisplayLimit)}}</div>
            </div>
            <div class="glb_customFlexRow align-items-center glb_rightPaddingNarrow" matTooltip="{{item.TaskNote}}" matTooltipPosition="right"
              [matTooltipDisabled]="item.DisablePBToolTip">
              <div>{{TextSize_Limit(item.TaskNote,TextDisplayLimit)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>