import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { LoanIndex } from '../LoanIndex/LoanIndex';
import { ApiService } from '@app/Services/APIService';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'TaskFollowup',
  templateUrl: './TaskFollowup.html',
  styleUrls: ['./TaskFollowup.scss'],
  animations: [

    //Using a longer fade in here for the child elements that get rendered into the page when a new section is added
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class TaskFollowup {

  //The DataRow that we contains all the dataunits on the client side that we are targeting, including the TaskGUID
  public DataRow;

  //Store the follow up note from the textarea/input on html
  public FollowUpNote: "";

  //Indicates if the follow up date has been added or not
  public AllowSubmit = false;

  //Are we in a "in progress' state of saving changes onto the server
  public IsUpdatingFollowup = false;

  //We need a copy of LoanIndex to help construct the request to the server
  public LoanIndex: LoanIndex;

  //A local copy to store the Primeng calendar picker
  public DTPDate = { JSDate: null, ISODate: null };

  //The current date format for the Prime DTP
  public DTPDateFormat = "dd/M/yy";

  //A data entry format that supports integer month values, so users can enter using keypad
  public DTPDateDataEntryFormat = "dd/mm/yy";

  //A display format that shows the month name as text.
  public DTPDateDisplayFormat = "dd/M/yy";

  //Max date for the primeng calendar picker
  public DTPMaxDate = new Date(2050, 1, 1);

  //Min date for the primeng calendar picker (today)
  public DTPMinDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<TaskFollowup>,
    private apiService: ApiService,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    private globalFunctions: GlobalFunctions) { }

  public DTP_OnClose(input) {

    //Convert DTP display format back to display format whenever calendar is closed
    this.DTPDateFormat = this.DTPDateDisplayFormat;

    //Redeclare the JS Date
    this.DTP_SyncJSDate(this.DTPDate);

    //Convert to ISO for API later
    this.globalFunctions.Date_ToISO(this.DTPDate);

    //Hide the calendar if supplied (needed on the OK button click for primeng)
    if (!this.globalFunctions.isEmpty(input)) {
      input.hideOverlay();
    }

    //Check if the date is selected to allow submitting the form
    this.AllowSubmit = false;
    if (!this.globalFunctions.isEmpty(this.DTPDate.JSDate)) {
      this.AllowSubmit = true;
    }
  }

  //When the calendar input is in focus, change the date format
  public DTP_Focus() {

    //Convert DTP display format to a version that the user can input using keypad. This is easier for data entry.
    this.DTPDateFormat = this.DTPDateDataEntryFormat;

    //Redeclare the JS Date
    this.DTP_SyncJSDate(this.DTPDate);
  }

  //Redeclare the JS Date so that PrimeNG re-renders its content again
  public DTP_SyncJSDate(date): void {

    if (!this.globalFunctions.isEmpty(date.JSDate)) {
      date.JSDate = new Date(date.JSDate.getFullYear(), date.JSDate.getMonth(), date.JSDate.getDate());
    }
  }

  //Close this dialog
  public TaskFollowUp_Close() {
    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //Clear the note input/textarea
  public Notes_ClearData(): void {
    this.FollowUpNote = "";
  }

  //Construct the followup request and send it to the server
  public SubmitFollowup_Click(): void {

    //Input validation
    if (this.globalFunctions.isEmpty(this.DTPDate.JSDate) || this.globalFunctions.isEmpty(this.DTPDate.ISODate)) {
      this.notifyService.Error_Show("Please select the Follow Up date", "Error");
      return;
    }

    //Construct api request
    const apiRequest = { AccountID: this.LoanIndex.AccountID, UserTaskGUID: this.DataRow.GUID, FollowupDate: this.DTPDate.ISODate, Note: this.FollowUpNote };


    //Turn on full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);
    this.IsUpdatingFollowup = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.AddTaskFollowup], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error. Remove any loading screens and return
          this.IsUpdatingFollowup = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Update the client side based on the response

          //Update Completed Date Data Unit
          const taskFollowupDate = this.DataRow.DataUnits.filter(x => x.Name === "Follow Up Date")[0];

          if (!this.globalFunctions.isEmpty(taskFollowupDate)) {

            //Update only if we receive the value in the response
            if (!this.globalFunctions.isEmpty(response.FollowupDate) && response.FollowupDate != this.globalFunctions.getServerNullString()) {

              //Set Value on this dataunit with the date returned from the server
              taskFollowupDate.Value = response.FollowupDate;
              taskFollowupDate.ValueDisplay = response.FollowupDate;

              //Format the data types, which also handles unescaping.
              taskFollowupDate.ValueDisplay = this.globalFunctions.customDataTypeParser(taskFollowupDate.ValueDisplay, taskFollowupDate.Type);

              //Force remove the CSS if it was empty, just in case
              taskFollowupDate.CSSClass = "";
            }
          }

          //Insert the new Feedback note to the User Task
          //Use Entity_UpdateLocal to attach the feedback note to the parent user task
          this.LoanIndex.Entity_UpdateLocal(response.NoteEntityResponse, false);

          //In addition, if we are launched via the "Headless" mode, there might be some dashboard components visible. We should trigger a refresh on all of those, as a Task status change will mot likely mean that it needs to now be displayed in a different status column.
          this.LoanIndex.ParentComponent_RefreshTaskData();

          //Success message
          this.notifyService.Success_Show(response.ResponseMessage, "Success");

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsUpdatingFollowup = false;

          //We can close this screen now
          this.TaskFollowUp_Close();
        }
      });
  }
}