<div id="PG_CreateEmail" class="glb_formBGColor glb_boxedPadding">

    <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
            <button id="BTN_CloseCreateEmail" [disabled]="IsCreatingEmail" tabindex="15"
                class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                (click)="CreateEmail_Close()">
                <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
            </button>
        </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="col-8 glb_paddingZeroOffset">
                <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
                    {{PageHeader}}
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 glb_customFlexRow">
        <!-- From Email Display name-->
        <div class="glb_customFlexRow justify-content-center align-items-center col-6 ta-parent">
            <div class="col-12 ta-firstchild-col12">
                <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">From Email Display Name</div>
                <textarea id="INP_FromEmailDisplay" class="textAreaCustom glb_textAreaHeightRegular glb_Font glb_inactiveInputBox ta-content"
                    [placeholder]="CreateEmailData.FromEmailDisplay" [disabled]="true" [(ngModel)]="CreateEmailData.FromEmailDisplay">
                          </textarea>
            </div>
        </div>

        <!-- Reply To -->
        <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center col-6 ta-parent">
            <div class="col-10 ta-firstchild">
                <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">Reply To</div>
                <textarea id="INP_ReplyTo" class="textAreaCustom glb_textAreaHeightRegular glb_Font ta-content"
                    [placeholder]="CreateEmailData.ReplyToPlaceholder" [(ngModel)]="CreateEmailData.ReplyTo">
                </textarea>
            </div>

            <!-- Button to clear content inside the input box, spans a div of its own -->
            <div class="col-2 justify-content-start align-self-end ta-clear">
                <span>
                    <button tabindex="-1"
                        class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                        matTooltip="Click to Clear" (click)="Input_ClearData('ReplyTo')">
                        <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>

    <!-- Email To -->
    <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center ta-parent">
        <div class="col-11 ta-firstchild">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">To</div>
            <textarea id="INP_EmailTo" class="textAreaCustom glb_textAreaHeightRegular glb_Font ta-content"
                [placeholder]="CreateEmailData.EmailToPlaceholder" [(ngModel)]="CreateEmailData.EmailTo">
                      </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end ta-clear">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('EmailTo')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div>

    <!-- Suggested Recipients -->
    <div class="col-lg-10 col-md-12 col-sm-12 col-12">
        <div class="glb_inputFieldLabel glb_customFlexRow text-white">Suggested Recipients:</div>
        <div class="glb_customFlexRow justify-content-start row-cols-6">
            <div class="glb_customFlexRow justify-content-left align-items-left text-white col-3" *ngFor="let recipientEmail of RecipientEmails"
                style="padding: 3px;">
                <div class="glb_boxedPaddingNarrow glb_roundedBorderSM glb_customFlexRow glb_hoverHand align-items-center col-12 chosen-items-border"
                    [ngClass]="SelectedClientBG_GetCSS(recipientEmail)" (click)="RecipientEmail_Click(recipientEmail)"
                    [matTooltip]="recipientEmail.ToolTipText" matTooltipPosition="below">
                    <div class="col-12" matTooltip="{{recipientEmail.LinkType}}" matTooltipPosition="below"
                        [matTooltipDisabled]="!recipientEmail.LinkType_ShowToolTip" [matTooltipShowDelay]="ToolTipDelay">
                        {{TextSize_Limit(recipientEmail.LinkType,EmailToolTipLength)}}
                    </div>
                    <div class="col-12" matTooltip="{{recipientEmail.DisplayName}}" matTooltipPosition="below"
                        [matTooltipDisabled]="!recipientEmail.DisplayName_ShowToolTip" [matTooltipShowDelay]="ToolTipDelay">
                        {{TextSize_Limit(recipientEmail.DisplayName,EmailToolTipLength)}}
                    </div>
                    <div class="col-12 glb_customFlexRow justify-content-start">
                        <div class="col-11 glb_breakLongWords" matTooltip="{{recipientEmail.Email}}" matTooltipPosition="below"
                            [matTooltipDisabled]="!recipientEmail.Email_ShowToolTip" [matTooltipShowDelay]="ToolTipDelay">
                            {{TextSize_Limit(recipientEmail.Email,EmailToolTipLength)}}
                        </div>
                        <div class="col-1 glb_customFlexRow justify-content-left align-items-center" matTooltip="Email Consent"
                            matTooltipPosition="below" [matTooltipShowDelay]="ToolTipDelay">
                            <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="recipientEmail.EmailConsent" [disabled]="true">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- CC To -->
    <div *ngIf="CreateEmailData.ShowCC" class="glb_customFlexRow row-cols-2 justify-content-center align-items-center ta-parent">
        <div class="col-11 ta-firstchild">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">CC</div>
            <textarea id="INP_CCTo" class="textAreaCustom glb_textAreaHeightRegular glb_Font ta-content"
                [placeholder]="CreateEmailData.CCToPlaceholder" [(ngModel)]="CreateEmailData.CCTo">
                </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end ta-clear">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('CCTo')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div>

    <!-- BCC To -->
    <div *ngIf="CreateEmailData.ShowBCC" class="glb_customFlexRow row-cols-2 justify-content-center align-items-center ta-parent">
        <div class="col-11 ta-firstchild">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">BCC</div>
            <textarea id="INP_BCCTo" class="textAreaCustom glb_textAreaHeightRegular glb_Font ta-content"
                [placeholder]="CreateEmailData.BCCToPlaceholder" [(ngModel)]="CreateEmailData.BCCTo">
                </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end ta-clear">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('BCCTo')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div>

    <!-- Subject -->
    <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center ta-parent">
        <div class="col-11 ta-firstchild">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">Subject</div>
            <textarea id="INP_Subject" class="textAreaCustom glb_textAreaHeightRegular glb_Font ta-content"
                [placeholder]="CreateEmailData.SubjectToPlaceholder" [(ngModel)]="CreateEmailData.Subject">
                    </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end ta-clear">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('Subject')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div>

    <!-- Email Body -->
    <!-- <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center" style="padding-bottom: 6px;">
        <div class="col-11 " style="padding-left:0px; padding-right:0px;">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white" style="font-size: .80em;">Body</div>
            <textarea id="INP_Body" class="textAreaCustom glb_Font textAreaHeightLarge" style="width: 100%; white-space: pre-wrap;"
                [placeholder]="CreateEmailData.BodyPlaceholder" [(ngModel)]="CreateEmailData.Body">
                  </textarea>
        </div>

        <div class="col-1 justify-content-start align-self-end" style="padding-bottom: 4px; padding-left: 8px;">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('Body')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div> -->
    <!-- Quill Rich Text Editor-->
    <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center ta-parent">
        <div class="col-11 ta-firstchild">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white ta-label">Body</div>
            <div id="toolbar" class="glb_quillInputBackgroundColor">
                <span class="ql-formats">
                    <select class="ql-header"></select>
                    <select class="ql-size"></select>
                    <select class="ql-font"></select>
                </span>
                <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color ql-picker ql-color-picker"></select>
                    <select class="ql-background ql-picker ql-color-picker"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                        <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                        <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                        <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                        <button class="ql-align ql-align-justify q1-box" title="justify" value="justify"></button>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Link" title="Link"></button>
                    <button class="ql-image" aria-label="Insert Image" title="Insert Image"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" title="Remove Styles"></button>
                </span>
            </div>
            <div #RichTextEditor_CreateEmail class="glb_customFlexRow glb_quillInputBackgroundColor glb_inputFieldLabel glb_quillContent"
                id="quill-editor">
            </div>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end ta-clear">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Input_ClearData('Body')">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>
    </div>

    <br>

    <!-- Send Email Button -->
    <div class="glb_paddingTopSM glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
            <button id="BTN_SendEmail" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="SendEmail_Click()">
                <span class="glb_Font glb_smallerText col-12 glb_customFlexRow align-items-center justify-content-center">Send Email
                </span>
            </button>
        </div>
    </div>
</div>