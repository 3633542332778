import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { NavigationUrls, UsersControllerMethods } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import moment from 'moment';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';

@Component({
  selector: 'LoginModal',
  templateUrl: './LoginModal.html',
  styleUrls: ['./LoginModal.scss']
})

export class LoginModal implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LoginModal>,
    private globalFunctions: GlobalFunctions,
    private clientDataStore: ClientDataStore,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public message: string) {

    //Init the class variables in the object shape that the server expects
    this.LoginData = {
      LoginID: "", UserPassword: "", UserDisplayName: "", UserEmail: "", UserLoggedInStatus: false, UserMobile: "", AccessToken: "", TwoFactorCode: "", LoggedInUserGUID: "", LoggedInClientGUID: ""
    }

    //Init the class variable
    this.UserOptionData = {};
  }

  //Input that the LoginModalWrapper may provide
  @Input() SuppliedhtmlContent: string;
  @Input() SuppliedAllowClose: boolean;

  //The other child components
  @ViewChild('ForgotPassword') ForgotPassword;
  @ViewChild('GoogleVerification') GoogleVerification;

  //This is my first field, the login id
  @ViewChild('LoginInput') LoginInput;

  //This is my second field, the password
  @ViewChild('PasswordInput') PasswordInput;

  //This is the html content inside the modal
  public HtmlContent = "";
  public LoginData;
  public UserOptionData;
  public IsLoggingIn = false;
  public AllowClose = false;
  public ResetUserNumber = "";
  public ResetEmailAddress = "";
  public IsPasswordResetLink = false;

  //For hiding/revealing the password
  public InputTypePassword = "password";
  public HidePassword = true;
  public ShowGoogleAuthenticationVerification = false;
  public TwoFactorCode = "";
  public ShowForgotPassword = false;

  ngOnInit(): void {

    //In case the Global Function calls us via the LoginModalWrapper, we may be supplied the content via the Input. use that instead, if that is the case.
    if (!this.globalFunctions.isEmpty(this.SuppliedhtmlContent)) {
      this.HtmlContent = this.SuppliedhtmlContent;
    }

    if (!this.globalFunctions.isEmpty(this.SuppliedAllowClose)) {
      this.AllowClose = this.SuppliedAllowClose;
    }

    //Always check the build version whenever this LoginModal is constructed
    this.globalFunctions.CheckBuildVersion();
  }

  //Clears data in input boxes
  public TextArea_ClearDataPassword(): void {
    this.LoginData.LoginID = "";
    this.LoginData.UserPassword = "";
  }

  //Clears data in input boxes
  public TextArea_ClearDataResetPassword(): void {
    this.ResetUserNumber = "";
    this.ResetEmailAddress = "";
  }

  //Validate GA code and login
  public GoogleAuthenticationCode_ValidateAndLogin() {
    if (this.globalFunctions.isEmpty(this.TwoFactorCode)) {
      this.notifyService.Error_Show("Please enter a valid Two Factor Authentication code", "No Two Factor Authentication Code");
      return;
    }

    //Prepare api request with google code
    this.LoginData.TwoFactorCode = this.TwoFactorCode;
    this.Login_Perform();
  }

  //Attempts to perform a login
  public Login_Perform(): void {

    if (this.globalFunctions.isEmpty(this.LoginData.LoginID)) {
      this.notifyService.Error_Show("Please enter a user name above", "No User Name");

      //Test all the toasts and their icons
      //this.notifyService.showInfo("Please enter a user name above", "No User Name");
      //this.notifyService.showWarning("Please enter a user name above", "No User Name");
      //this.notifyService.showSuccess("Please enter a user name above", "No User Name");
      this.globalFunctions.delay(1).then(() => { this.LoginInput.nativeElement.focus(); });
      return;
    }
    if (this.globalFunctions.isEmpty(this.LoginData.UserPassword)) {
      this.notifyService.Error_Show("Please enter a password above", "No Password");
      this.globalFunctions.delay(1).then(() => { this.PasswordInput.nativeElement.focus(); });
      return;
    }

    //Clear session storage, so we don't show anything from prior sessions
    sessionStorage.clear();

    //Use this to throw a error from the client, and see how the browser test deals with it
    //throw Error("This is a test error thrown on the client, error details would appear here.");

    const apiRequest = this.LoginData;
    this.IsLoggingIn = true;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.Login], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsLoggingIn = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          if (response.ResponseMessage === "Initiate Two Factor Authentication Flow") {
            this.ShowGoogleAuthenticationVerification = true;
            this.notifyService.Success_Show("Two Factor Authentication verification initiated. Please enter code from your Two Factor Authenticator App.", "User Validation Successful");

            //We aren't logging in anymore. reset the local variable so that the spinners disappear
            this.IsLoggingIn = false;

            //Focus the input field
            this.globalFunctions.delay(1).then(() => { this.GoogleVerification.focusField.nativeElement.focus(); });
          }

          else if (response.ResponseMessage === "Password Verification Successful") {

            //Put the access token data into the LoginData, and put it into the Client Data Store
            this.LoginData.AccessToken = response.AccessToken;
            this.LoginData.UserDisplayName = this.globalFunctions.HTMLUnescape(response.UserDisplayName);
            this.LoginData.LoggedInUserGUID = response.LoggedInUserGUID;
            this.LoginData.LoggedInClientGUID = response.LoggedInClientGUID;
            this.LoginData.UserLoggedInStatus = true;

            //Put the user option data into the userOptionData and put it into the Client Data Store
            this.UserOptionData.HideDischargedLoansInSearch = response.HideDischargedLoansInSearch;

            //Remove the user password.
            this.LoginData.UserPassword = "";

            //Set the Is Read Only Mode
            this.clientDataStore.Update_IsReadOnlyMode(response.IsReadOnlyMode);

            //Let's store claims too
            //console.log("claims: ", loginResponse.UserClaims);
            this.clientDataStore.ClientClaims = response.UserClaims;

            //Store the entity types that are to be displayed for the nav bar into the client data store, so that it can be utilized by the loan nav bar later
            this.clientDataStore.EntityTypesDisplay = response.NavTabEntityTypes;
            this.clientDataStore.EntityTypes = response.EntityTypes;
            this.clientDataStore.ClaimNames = response.ClaimNames;
            //console.log('loginResponse.ClaimNames', loginResponse.ClaimNames);

            //Store the email variables
            this.clientDataStore.EmailVariables = response.EmailVariables;

            //Store the pivoted entities
            this.clientDataStore.PivotedEntities = response.PivotedEntities;

            //Fill the account enquiry task type guids
            this.clientDataStore.AccountEnquiryControlGUIDArray = response.FallbackTaskTypeGUIDs;

            //And indicate in the client that the user is now logged in
            this.LoginData.UserLoggedInStatus = true;

            //this.clientDataStore.Update_LoginData(this.loginData);
            //Show the search bar
            this.clientDataStore.Update_InvisibleSearchBar(false);

            //Why don't we just stick it into the global client store? jeezus.
            this.clientDataStore.loginDataDirect = this.LoginData;
            this.clientDataStore.UpdateLoginData(this.LoginData);
            this.clientDataStore.UpdateUserOptionData(this.UserOptionData);

            //Who was last logged in?
            //if (localStorage.getItem(StoreItemTypes[StoreItemTypes.LastLoggedInUser] === this.loginData.LoginID) {
            //}
            //else {
            //}

            //Lets now store who is logging in now. i dont think this is needed any more. TODO
            //console.log(this.loginData);
            localStorage.setItem(StoreItemTypes[StoreItemTypes.LastLoggedInUser], this.LoginData.LoginID);

            //Let's pop the refresh token into session storage
            sessionStorage.setItem(StoreItemTypes[StoreItemTypes.RefreshToken], response.RefreshToken);

            //Remove last search results. this usually would be cleared on logoff, but let's clear it here anyway, in case there was a crash and it wasn't removed properly. also helps when debugging, as when you hit save and browser recompiles, these are not discarded. and they cause issues when reused.
            sessionStorage.removeItem(StoreItemTypes[StoreItemTypes.PreviousSearchResults]);

            //Now close this dialog, pass a true result so that the GlobalAppData method will reload pages if necessary
            this.dialogRef.close(true);

            //A notification might be a nice touch here
            this.notifyService.Success_Show("Welcome to " + this.globalFunctions.GetApplicationName(), "Login Success");
            this.IsLoggingIn = false;

            //Indicate the the login modal has completed its work, and is now closed
            this.clientDataStore.LoginModalLaunched = false;

            //Get the static data needed for the entity model. checks GUID and caching, fills it appropriately
            this.ControlData_CheckAndGet();

            //Flag to enable/skip password expiry
            let checkPasswordExpiry = true;

            //Check for IgnorePasswordExpiry claim
            if (this.clientDataStore.ClientClaims.filter(x => x.Name == "IgnorePasswordExpiry").length > 0) {

              //Claim exists! let's grab it and inspect!
              const ignorePasswordExpiryClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "IgnorePasswordExpiry")[0];

              //Check if the READ property of this claim is NOT ticked.
              if (ignorePasswordExpiryClaim.Read == true) {

                checkPasswordExpiry = false;
              }
            }

            //Check the days to password expiry and launch a dialog for user to change the password
            if (response.DaysToPasswordExpiry <= 5 && checkPasswordExpiry == true) {

              let passwordExpiryDaysText = "in " + response.DaysToPasswordExpiry.toString() + " days";
              let passwordChangeConfirmText = "Would you like to change it now?"

              //Set height and text for the confirm modal if password is expiring within 3 days
              if (response.DaysToPasswordExpiry <= 3) {
                passwordChangeConfirmText = "Please change it now."
              }

              //Change confirm modal text if it is expiring today
              if (response.DaysToPasswordExpiry <= 0) {
                passwordExpiryDaysText = "today";
              }

              //Change confirm modal text if it is expiring tomorrow
              if (response.DaysToPasswordExpiry == 1) {
                passwordExpiryDaysText = "in " + response.DaysToPasswordExpiry.toString() + " day";
              }

              const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());

              //Convert date from YYYY-MM-DD to long date aus
              const PasswordExpiryDate_Display = moment(response.PasswordExpiryDate).format('dddd, Do MMMM YYYY hh:mm:ss A');

              //If the password is going to expire in less than or in 3 days, force users to change their password
              if (response.DaysToPasswordExpiry <= 3) {
                dialogRef.componentInstance.ShowNoButton = false;
                dialogRef.componentInstance.YesButtonText = "Change Password";
              }

              //Use html content so that we can style it
              dialogRef.componentInstance.htmlContent = "Your password is going to expire " + passwordExpiryDaysText + " on: </br></br><b>" + PasswordExpiryDate_Display + "</b>.</br></br>" + passwordChangeConfirmText;

              dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                  //Visit profile and launch password change modal
                  this.URLWithState_Navigate(NavigationUrls.UserProfile, "LaunchPasswordChange")
                }
              }
              );
            }
          }
        }
      });
  }

  //Navigate to a url, retaining state. Used for returning to pages
  public URLWithState_Navigate(link: string, incomingState = '') {
    this.router.navigate([link], { state: { Link: incomingState } });
  }

  public TwoFactorVerification_Cancel() {
    this.ShowForgotPassword = false;
    this.LoginData.TwoFactorCode = null;
    this.IsLoggingIn = false;
    this.ShowGoogleAuthenticationVerification = false;
    this.TwoFactorCode = null;

    //This allows the field we want to be focused to be set.
    this.globalFunctions.delay(1).then(() => { this.LoginInput.nativeElement.focus(); });
  }

  public ForgotPassword_Cancel() {
    this.ShowForgotPassword = false;
    this.IsLoggingIn = false;

    //This allows the field we want to be focused to be set.
    this.globalFunctions.delay(1).then(() => { this.LoginInput.nativeElement.focus(); });
  }

  //Launch forgot password form
  public ForgotPasswordForm_Launch() {
    this.ShowForgotPassword = true;

    //Focus the first input element the reset password form 
    this.globalFunctions.delay(1).then(() => { this.ForgotPassword.focusField.nativeElement.focus(); });
  }

  //Attempts a password reset
  public PasswordResetLink_Get(): void {
    if (this.globalFunctions.isEmpty(this.ResetUserNumber)) {
      this.notifyService.Error_Show("Please enter a Login ID", "No Login ID");

      //Focus the first input element on the reset password form 
      this.globalFunctions.delay(1).then(() => { this.ForgotPassword.focusField.nativeElement.focus(); });
      return;
    }
    if (this.globalFunctions.isEmpty(this.ResetEmailAddress)) {
      this.notifyService.Error_Show("Please enter an email address", "No email address");

      //Focus the second input element on the reset password form 
      this.globalFunctions.delay(1).then(() => { this.ForgotPassword.focusField2.nativeElement.focus(); });
      return;
    }

    //Does the email address smell ok?
    if (!this.ResetEmailAddress.includes("@") || this.ResetEmailAddress.startsWith("@") || this.ResetEmailAddress.length <= 2) {
      this.notifyService.Error_Show("Please enter a valid email address", "Invalid email address");

      //Focus the second input element on the reset password form 
      this.globalFunctions.delay(1).then(() => { this.ForgotPassword.focusField2.nativeElement.focus(); });
      return;
    }

    const apiRequest = { UserNumber: this.ResetUserNumber, EmailAddress: this.ResetEmailAddress };
    this.IsPasswordResetLink = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.PasswordResetLink], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsPasswordResetLink = false;
          this.ShowForgotPassword = true;

          //Focus the first input element on the reset password form 
          this.globalFunctions.delay(1).then(() => { this.ForgotPassword.focusField.nativeElement.focus(); });
          return;
        }
        else {

          //I dont care about the response here
          //var passwordResetLinkResponse: LoginResponse = JSON.parse(JSON.stringify(apiResponse));

          //a notification might be a nice touch here
          this.notifyService.Success_Show("If the provided details match to an existing account, a password reset link will be sent - please check your email", "Password Reset");
          this.IsPasswordResetLink = false;
          this.ShowForgotPassword = false;

          //Focus on the first input element of the login form
          this.globalFunctions.delay(1).then(() => { this.LoginInput.nativeElement.focus(); });
        }
      });
  }

  //Removes this login dialog
  public Dialog_Close() {
    if (this.AllowClose) {

      //Don't forget to reset the state of the login modal, without this it won't be launchable again!
      this.clientDataStore.LoginModalLaunched = false;

      //Now close this dialog, but with a false result (dont tell GlobalFunctions to refresh pages, or we'll land at an empty dashboard!)
      this.dialogRef.close(false);
    }
  }

  //Reaveal/Hide the password on click and hold
  public PasswordReveal_Toggle(hidePassword: boolean): void {
    if (hidePassword == true) {
      this.InputTypePassword = "password";
      this.HidePassword = true;
    }
    else {
      this.InputTypePassword = "text";
      this.HidePassword = false;
    }
  }

  //Checks local cache of control data, to see if it needs refreshing
  private ControlData_CheckAndGet() {

    //console.log('Checking Static Control and View Model Data');
    //Get and store the cache guid from cache. needed for the check routine inside GetControlDataCacheGUID.
    this.clientDataStore.ControlDataCacheGUID = JSON.parse(localStorage.getItem(this.clientDataStore.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID]));
    //console.log('this.clientDataStore.ControlDataCacheGUID', this.clientDataStore.ControlDataCacheGUID);

    //Now see if we need to retrieve them again.
    this.ControlDataCacheGUID_Get();
  }

  //Checks the control data cache GUID from the server, refresh the contents if needed
  private ControlDataCacheGUID_Get() {
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID])
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('ControlDataCacheGUID from server', response);
          //console.log('this.clientDataStore.loginDataDirect.LoginID', this.clientDataStore.loginDataDirect.LoginID);

          //Refresh ClientViewModel on each login. This is required when we modify the user entity property claims on server and force it to apply on next login
          //Client view model. by adding a dummy body property, it will send the access token. as we now want to evaluate role level entity property mods to the client view model.
          this.ControlDataFromServer_Get(StoreItemTypes.GetClientViewModel, this.clientDataStore.ClientViewModelData);

          //Are they different? if so, request the data from the server again.
          if (this.clientDataStore.ControlDataCacheGUID !== this.clientDataStore.loginDataDirect.LoginID + response) {
            //console.log('Control Data is different, refresh it from the server');
            //get the static data needed for the entity model
            this.ControlDataWrapper_Get(true);

            //Let's make it specific for each user, inject user number here
            const combinedCacheGUID = this.LoginData.LoginID + response

            //And store this new GUID into the data store
            this.clientDataStore.ControlDataCacheGUID = combinedCacheGUID;

            //Dont forget to store the actual ControlCacheGUID for use next time            
            localStorage.setItem(this.LoginData.LoginID + StoreItemTypes[StoreItemTypes.GetControlDataCacheGUID], JSON.stringify(combinedCacheGUID));
          }
          else {
            //console.log('Control Data is the same, grab it from cache');

            //Data is identical. we must have the same control data. just reuse it. remember to use JSON.parse when pulling from the cache
            //Control data is common, no need to use loginID in its name
            const controlData = JSON.parse(localStorage.getItem(StoreItemTypes[StoreItemTypes.GetControlData]));
            this.clientDataStore.ControlData = controlData;
          }
        }
      });
  }

  //Wrapper for calling all control related data
  private ControlDataWrapper_Get(skipClientViewModelUpdate = false) {
    //console.log('Refreshing Static Control and View Model Data from the server');

    //Let's grab the control data
    this.ControlDataFromServer_Get(StoreItemTypes.GetControlData, this.clientDataStore.ControlData);

    if (skipClientViewModelUpdate !== true) {
      //Client view model. by adding a dummy body property, it will send the access token. as we now want to evaluate role level entity property mods to the client view model.
      this.ControlDataFromServer_Get(StoreItemTypes.GetClientViewModel, this.clientDataStore.ClientViewModelData);
    }

    //Entity Types was already retrieved earlier, during login itself
  }

  //Generic method to get some data from an endpoint, and push it into the supplied array. in this case, static data oriented endpoints. and puts it into local storage (cache)
  private ControlDataFromServer_Get(endpointType: StoreItemTypes, dataToStore, requestData = null): void {

    //Convert enum to string, expect that the enum also describes the route exactly
    const endpoint = StoreItemTypes[endpointType];
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, endpoint, requestData)
      .subscribe(apiResponse => {

        //Parse the response. should also check for error
        if (!this.globalFunctions.isEmpty(apiResponse)) {
          dataToStore.length = 0;

          //Loop through the responding apiResponse array and push the values in
          apiResponse.forEach(element => {

            //We need to unescape some values here
            const itemControlValue = element['ControlValue'];
            const itemControlDisplay = element['ControlDisplay'];

            //console.log('itemControlValue', itemControlValue);
            //console.log('itemControlDisplay', itemControlDisplay);

            //Unescape the ControlValue and ControlDisplay elements, if they exist.
            if (!this.globalFunctions.isEmpty(itemControlValue)) {
              element['ControlValue'] = this.globalFunctions.HTMLUnescape(element['ControlValue']);
            }

            if (!this.globalFunctions.isEmpty(itemControlDisplay)) {
              element['ControlDisplay'] = this.globalFunctions.HTMLUnescape(element['ControlDisplay']);
            }

            dataToStore.push(element)
          });

          //Save it into local storage, JSON stringify it as its an array of objects
          if (endpointType === StoreItemTypes.GetControlData) {

            //Don't store the control data per user, as its identical.
            localStorage.setItem(endpoint, JSON.stringify(dataToStore));
          }
          else {
            localStorage.setItem(this.clientDataStore.loginDataDirect.LoginID + endpoint, JSON.stringify(dataToStore));
          }
        }
      });
  }
}