<div class="glb_paddingTopLG glb_paddingBottomLG glb_formBGColor text-white glb_Font">
  <div class="glb_paddingOffset col-md-12 col-lg-12">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">User Management</p>
    <div
      class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 justify-content-center align-items-center glb_paddingBottomMD">
      <div class="glb_customFlexRow">
        <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
        <input class="glb_formInputBoxDark glb_skinLightOutline glb_Font text-white" (keyup)='filterSearchValueKeyUp.next($event)'
          [(ngModel)]='filterSearchValue' cdkFocusInitial tabindex="1" type="name" placeholder="Filter User">
      </div>
      <div class="glb_customFlexRow justify-content-start align-items-center">
        <!-- This is the hide disabled users checkbox -->
        <span class="noLeftPadding hasPadding input-group-text glb_paddingZeroOffsetRight glb_RemoveBGandBorder text-white justify-content-start">
          <mat-checkbox class="glb_Font mat-checkbox-dark-custom" [checked]="HideDisabledUsers" (change)="DisabledUsers_Hide($event)"> Hide Disabled
          </mat-checkbox>
        </span>
      </div>
      <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
        <span style="padding-left:0.375em;">
          <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="UserFilter_Clear()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eraser']" matTooltip="Clear search filter">
            </fa-icon>
          </button>
        </span>
        <span *ngIf="!ReadOnlyMode" style="padding-left:0.375em;">
          <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="User_CreateModal(CreateUserTemplate)">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'user-plus']" matTooltip="Add new user">
            </fa-icon>
          </button>
        </span>
        <span style="padding-left:0.375em;">
          <button id="DownloadUsersID"
            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="Users_DownloadCSV()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
            </fa-icon>
          </button>
        </span>
      </div>
    </div>

    <!-- Pop up dialog for adding new user-->
    <ng-template #CreateUserTemplate>
      <div class="glb_boxedPadding glb_formBGColor glb_Font">
        <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
        <div class="glb_customFlexRow justify-content-end">
          <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
            <button [disabled]="isCreatingUser" tabindex="30"
              class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
              (click)="Dialog_Close()">
              <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
            </button>
          </div>
        </div>

        <div class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin" style="padding-top:20px">Add New
          User</div>

        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">First Name</div>
            <input cdkFocusInitial tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
              placeholder="First Name" [(ngModel)]="createUser_FirstName">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Last Name</div>
            <input tabindex="12" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Last Name"
              [(ngModel)]="createUser_LastName">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Email Address</div>
            <input tabindex="13" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Email Address"
              [(ngModel)]="createUser_Email">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Mobile Number</div>
            <input tabindex="14" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Mobile Number"
              [(ngModel)]="createUser_Mobile">
          </div>
        </div>

        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
          <div *ngIf="!isCreatingUser" class="glb_paddingBottomSM d-grid">
            <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_Create()">
              <span class="glb_Font glb_smallerText">Add User</span></button>
          </div>
          <div *ngIf="isCreatingUser" class="glb_paddingBottomSM d-grid">
            <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
              <span class="glb_Font glb_hiddenText glb_smallerText">Add User</span>
              <div class="spinner-border glb_floatingSpinner"></div>
            </button>
          </div>
        </div>
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
          <div *ngIf="!isCreatingUser" class="glb_paddingBottomSM d-grid">
            <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="Dialog_Close()">
              <span class="glb_Font glb_smallerText">Cancel</span></button>
          </div>
          <div *ngIf="isCreatingUser" class="glb_paddingBottomSM d-grid">
            <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
              <span class="glb_Font glb_hiddenText glb_smallerText">Cancel</span>
              <div class="spinner-border glb_floatingSpinner"></div>
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- this is the loading spinner, lets show it while the entity is loading -->
    <div class="glb_customFlexRow" *ngIf="showSpinner">
      <div class="col">
        <div class="glb_customFlexRow justify-content-center glb_paddingBottomSM">
          <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
        </div>
        <div class="glb_customFlexRow justify-content-center">Loading</div>
      </div>
    </div>

    <!-- user results are displayed here -->
    <div *ngIf="UserList_IsEmpty() && !showSpinner">
      <div class="itemFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
        <div class="itemBorders" *ngFor="let item of userList | searchfilter | paginate: {id: 'search', itemsPerPage: 9, currentPage:
        currentPage}" @fadeIn style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
          <a (click)="User_View(ViewUserTemplate, item)" style="cursor: pointer;">
            <div class="glb_customFlexRow">
              <div class="glb_paddingZeroOffset col-lg-10 text-white">
                <div class="itemTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
                  <div> {{item.UserName}} ({{item.UserNumber}}) </div>
                </div>
                <div class="itemEmail glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-4 row-cols-lg-4">
                  <div class="itemEmail"> {{item.EmailAddress}}
                  </div>
                  <div class="itemEmail"> Created By: {{item.CreaterName}}
                  </div>
                  <div class="itemField"> Is Disabled: {{item.IsDisabled}}
                  </div>
                  <div class="itemEmail"> Role: {{item.RoleName}}
                  </div>
                </div>
              </div>

              <div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
                <!-- show a chevron, only on lg screens -->
                <div class="d-none d-lg-block">
                  <fa-icon [icon]="['fal', 'chevron-right']" size="lg" class="btn align-self-right" style="color:grey;" matTooltip="Click to Launch">
                  </fa-icon>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="glb_customFlexRow">
          <div class="col">
            <div class="glb_Font glb_customFlexRow justify-content-center">
              <pagination-controls class="glb_paginatorDark" id='search' (pageChange)="Page_OnChange($event)" [maxSize]="paging.maxSize"
                [responsive]="true" [previousLabel]="paging.previousLabel" [nextLabel]="paging.nextLabel"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ViewUserTemplate>
  <div class="glb_boxedPadding glb_formBGColor glb_Font">
    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button [disabled]="isUpdatingUser || isCreatingUser" tabindex="30"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button" (click)="Dialog_Close()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>

    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="header-padding glb_customFlexRow col-12 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset">{{chosenUser.FirstName}}
            {{chosenUser.LastName}}
            ({{chosenUser.UserNumber}})
          </div>
        </div>
        <!--Empty div to retain the padding as we are hiding all other buttons-->
        <div *ngIf="IsCloning" class="glb_customFlexRow col-4 justify-content-end">
          <div class="buttonPadding glb_clickableIconButtonColorLight glb_clickableIconButtonMD">
            <span style="padding-left:0.375em;">
            </span>
          </div>
        </div>
        <div *ngIf="!IsCloning && !ReadOnlyMode" class="glb_customFlexRow col-4 justify-content-end">
          <div class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="UserEdit_Clone()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone this User">
                </fa-icon>
              </button>
            </span>
          </div>
          <div *ngIf="!userSaveEnabled && !IsCloning && !ReadOnlyMode" class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="UserEdit_Enable()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this User">
                </fa-icon>
              </button>
            </span>
          </div>
          <div *ngIf="userSaveEnabled && !ReadOnlyMode" class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="User_Update()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save your changes">
                </fa-icon>
              </button>
            </span>
          </div>
          <!--Delete button-->
          <div *ngIf="showDeleteUserButton && !IsCloning && !ReadOnlyMode" class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="User_Delete(currentChosenUser)">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete this User">
                </fa-icon>
              </button>
            </span>
          </div>
        </div>

        <!-- input boxes for updating the users fields -->
        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Login ID</div>
            <input tabindex="10" class="glb_formInputBox glb_skinLightOutline text-black glb_Font glb_inactiveInputBox" type="text"
              placeholder="Login ID" [(ngModel)]="currentChosenUser.UserNumber" [disabled]=true>
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">First Name</div>
            <input tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="First Name"
              [(ngModel)]="currentChosenUser.FirstName" [disabled]="!userSaveEnabled" [ngClass]="{ 'glb_inactiveInputBox': !userSaveEnabled }">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Last Name</div>
            <input tabindex="12" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Last Name"
              [(ngModel)]="currentChosenUser.LastName" [disabled]="!userSaveEnabled" [ngClass]="{ 'glb_inactiveInputBox': !userSaveEnabled }">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Email Address</div>
            <input tabindex="13" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Email Address"
              [(ngModel)]="currentChosenUser.EmailAddress" [disabled]="!userSaveEnabled" [ngClass]="{ 'glb_inactiveInputBox': !userSaveEnabled }">
          </div>
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Mobile Number</div>
            <input tabindex="14" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Mobile Number"
              [(ngModel)]="currentChosenUser.MobileNumber" [disabled]="!userSaveEnabled" [ngClass]="{ 'glb_inactiveInputBox': !userSaveEnabled }">
          </div>
          <div *ngIf="!IsCloning" class="glb_paddingBottomSM userManagement">
            <mat-checkbox tabindex="17" [(ngModel)]="currentChosenUser.IsDisabled" class="glb_checkBoxMargin mat-checkbox-dark-custom"
              [disabled]="!userSaveEnabled">Account Disabled
            </mat-checkbox>
          </div>
          <div *ngIf="this.showSampleUserCheckBox" class="glb_paddingBottomSM userManagement">
            <mat-checkbox tabindex="18" [(ngModel)]="currentChosenUser.IsSampleUser" class="glb_checkBoxMargin mat-checkbox-dark-custom"
              [disabled]="!userSaveEnabled">Is Sample User
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-body modalBodyCustom" style="padding-top: 0px;">
      <!-- This div is for buttons only-->
      <div>
        <div *ngIf="IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isCreatingUser" class="glb_paddingBottomSM d-grid">
              <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_Clone()">
                <span class="glb_Font glb_smallerText">Create User</span></button>
            </div>
            <div *ngIf="isCreatingUser" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Create User</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isCreatingUser" class="glb_paddingBottomSM d-grid">
              <button tabindex="20" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_DiscardChanges()">
                <span class="glb_Font glb_smallerText">Cancel</span></button>
            </div>
            <div *ngIf="isCreatingUser" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Cancel</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="userSaveEnabled && !IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isUpdatingUser" class="glb_paddingBottomSM d-grid">
              <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_Update()">
                <span class="glb_Font glb_smallerText">Save Changes</span></button>
            </div>
            <div *ngIf="isUpdatingUser" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Save Changes</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="userSaveEnabled && !IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isDiscardingUserDetails" class="glb_paddingBottomSM d-grid">
              <button tabindex="20" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_DiscardChanges()">
                <span class="glb_Font glb_smallerText">Discard Changes</span></button>
            </div>
            <div *ngIf="isDiscardingUserDetails" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Discard Changes</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!chosenUser.IsRegistered && !IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomMD">
            <div *ngIf="!isUserInviteRequestClicked" class="glb_paddingBottomSM d-grid">
              <button tabindex="21" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="User_Invite()">
                <span class="glb_Font glb_smallerText">Invite User</span></button>
            </div>
            <div *ngIf="isUserInviteRequestClicked" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Invite User</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="chosenUser.IsRegistered && showGoogleAuthSetupButton && !IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isGoogleSetupClicked" class="glb_paddingBottomSM d-grid">
              <button tabindex="22" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="UserTwoFactorSetupReset_Click()">
                <span class="glb_Font glb_smallerText">Resend Two Factor Authentication Setup</span></button>
            </div>
            <div *ngIf="isGoogleSetupClicked" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Resend Two Factor Authentication Setup</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="chosenUser.IsRegistered && !IsCloning && !ReadOnlyMode">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!isSendPasswordResetLinkClicked" class="glb_paddingBottomSM d-grid">
              <button tabindex="23" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PasswordResetLink_Click()">
                <span class="glb_Font glb_smallerText">Reset Password</span></button>
            </div>
            <div *ngIf="isSendPasswordResetLinkClicked" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Reset Password</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="glb_sectionTitle1 glb_paddingTopLG glb_fontColorSkin">User Roles</div>
      <!-- Adding Roles - a chevron with sliding row. -->
      <div *ngIf="!IsCloning && !ReadOnlyMode" class="glb_customFlexRow justify-content-end glb_paddingBottomSM"
        (click)="TemplateID_Toggle('insertRole')">
        <a #assignRoleFocus class="justify-content-end glb_customFlexRow col-2 glb_hoverHand">
          <fa-icon [icon]="['fal', 'plus']" [ngClass]="TemplateID_GetCSS('insertRole',true)" style="color: white;" (click)="AssignRoleForm_Expand()"
            matTooltip="Expand">
          </fa-icon>
          <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('insertRole',false)" style="color: white;" matTooltip="Hide">
          </fa-icon>
        </a>
      </div>

      <!--Empty div to retain the padding as we are hiding plus icon to assign role-->
      <div *ngIf="IsCloning" class="glb_customFlexRow justify-content-end glb_paddingBottomSM">
      </div>

      <div id="insertRole" #assignRoleForm style="padding-bottom: 15px;" [ngClass]="TemplateID_GetCSS('insertRole')">
        <!-- sliding content - to insert a new role -->
        <!-- Use headers to define columns -->
        <div [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInTab === 1
          ,'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2' : ColumnsInTab === 2 }">
          <div class="card">
            <div class="card-body-lesspadding glb_customFlexRow" [ngClass]="CssClass_Get('DataPair', ColumnsInBody_Role, WrapOddRows)">
              <div *ngFor="let tableheaderitem of TableHeaders_Role; odd as isOdd; even as isEven; index as itemIndex" [ngClass]="{'glb_customFlexRow
                justify-content-end align-items-center' : tableheaderitem === ' ' }">
                <div class="text-style">
                  <div [ngClass]="CssClass_Get('TableHeaderValues', ColumnsInBody_Role, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                    TableHeaders_Role.length)">
                    <!-- {{tableheaderitem}} -->
                    <div *ngIf="tableheaderitem === 'Role'">
                      <!-- needs a slight padding so that it aligns/sits properly -->
                      <div class="glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                        <div>
                          <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom"
                            appearance="outline">
                            <input #focusNewRoleName (click)="AutoComplete_Role_ApplyFilter(roleInsert_AutoComplete, 'Search',
                            insertRole.value, 'Role', true)" (keydown)="AutoComplete_Role_ApplyFilter(roleInsert_AutoComplete, 'Search',
                            insertRole.value, 'Role', true)" #insertRole type="text" matInput [matAutocomplete]="insertRole_autoComplete"
                              placeholder="Role Name" [(ngModel)]="insertRole_RoleBind">
                            <mat-autocomplete #insertRole_autoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                              (optionSelected)="AutoComplete_SaveSelectedRole_ForInsert($event.option)" [panelWidth]="500">
                              <div *ngFor="let control of AutoComplete_Role_ApplyFilter(roleInsert_AutoComplete, 'Search',
                                insertRole.value, 'Role')">
                                <mat-option class="glb_Font" [value]=control>
                                  {{control.ControlValue}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="tableheaderitem === ' '" class="glb_customFlexRow justify-content-end align-items-center">
                      <!-- add a save/insert button -->
                      <div class="glb_customFlexRow justify-content-end align-items-center">
                        <span>
                          <button *ngIf="!isPerformingServerRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="UserRole_Assign()">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Assign the Role">
                            </fa-icon>
                          </button>
                        </span>
                        <!-- lets stick a little spinner here, and switch it out when the button is pressed -->
                        <span>
                          <button *ngIf="isPerformingServerRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right">
                            <fa-icon class="fa-fw glb_fixedSizeIcons fastSpin" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                            </fa-icon>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- and now the role content -->
      <div class="itemFirstBorder glb_customFlexRow text-white glb_Font row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
        <!-- content loop here -->
        <div *ngFor="let item of chosenUserRoles; odd as isOdd; even as isEven; index as itemIndex" class="itemBorders">
          <div class="card-body-lesspadding card-body glb_customFlexRow" [ngClass]="CssClass_Get('DataPair', ColumnsInBody_Role, WrapOddRows)">
            <div class="glb_customFlexRow justify-content-left align-items-center">
              {{item.RoleName}}
            </div>
          </div>
        </div>
      </div>

      <!-- now the user link type filters -->
      <div class="glb_sectionTitle1 glb_paddingTopLG glb_fontColorSkin">User Link Type Filters</div>
      <!-- Adding user link type filters - a chevron with sliding row. -->
      <div *ngIf="!IsCloning && !ReadOnlyMode" class="glb_customFlexRow justify-content-end glb_paddingBottomSM"
        (click)="TemplateID_Toggle('addLinkTypeFilterForm')">
        <a #addLinkTypeFilterFocus class="justify-content-end glb_customFlexRow col-2 glb_hoverHand">
          <fa-icon [icon]="['fal', 'plus']" [ngClass]="TemplateID_GetCSS('addLinkTypeFilterForm',true)" style="color: white;" matTooltip="Expand"
            (click)="UserLinkTypeForm_Expand()">
          </fa-icon>
          <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('addLinkTypeFilterForm',false)" style="color: white;"
            matTooltip="Hide">
          </fa-icon>
        </a>
      </div>

      <!--Empty div to retain the padding as we are hiding the plus icon to add user link type filter-->
      <div *ngIf="IsCloning" class="glb_customFlexRow justify-content-end glb_paddingBottomSM">
      </div>

      <div id="addLinkTypeFilterForm" #addLinkTypeFilterForm style="padding-bottom: 15px;" [ngClass]="TemplateID_GetCSS('addLinkTypeFilterForm')">
        <!-- sliding content - to insert a new user link type filter -->
        <!-- Use headers to define columns -->
        <div [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInTab === 1
          ,'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2' : ColumnsInTab === 2 }">
          <div class="card">
            <div class="card-body-lesspadding glb_customFlexRow" [ngClass]="CssClass_Get('DataPair', ColumnsInBody_UserLinkTypeFilters, WrapOddRows)">
              <div *ngFor="let tableheaderitem of TableHeaders_UserLinkTypeFilters; odd as isOdd; even as isEven; index as itemIndex" [ngClass]="{'glb_customFlexRow
                justify-content-end align-items-center' : tableheaderitem === ' ' }">
                <div class="text-style">
                  <div [ngClass]="CssClass_Get('TableHeaderValues', ColumnsInBody_UserLinkTypeFilters, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                TableHeaders_UserLinkTypeFilters.length)">
                    <!-- {{tableheaderitem}} -->
                    <div *ngIf="tableheaderitem === 'UserLinkTypeFilter'">
                      <!-- needs a slight padding so that it aligns/sits properly -->
                      <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                        <div>
                          <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom"
                            appearance="outline">
                            <input #linkTypeInputVal type="text" matInput [matAutocomplete]="autoCompleteLinkType" placeholder="Link Type"
                              [(ngModel)]="createUserLinkTypeFilter_LinkTypeBind.LinkType_AutoCompleteControlTypeName"
                              (click)="AutoComplete_LinkType_ApplyFilter(createUserLinkTypeFilter_LinkTypeBind, 'Search', linkTypeInputVal.value, 'LinkType', true)"
                              (keydown)="AutoComplete_LinkType_ApplyFilter(createUserLinkTypeFilter_LinkTypeBind, 'Search', linkTypeInputVal.value, 'LinkType', true)">
                            <mat-autocomplete #autoCompleteLinkType="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                              (optionSelected)='AutoComplete_SaveSelectedLinkType_ForCreate($event.option)' [panelWidth]="500">
                              <div
                                *ngFor="let control of AutoComplete_LinkType_ApplyFilter(createUserLinkTypeFilter_LinkTypeBind, 'Search', linkTypeInputVal.value, 'LinkType')">
                                <mat-option class="glb_Font" [value]=control>
                                  {{control.ControlValue}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>

                      <!-- needs a slight padding so that it aligns/sits properly -->
                      <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                        <div>
                          <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom"
                            appearance="outline">
                            <input #clientInputVal type="text" matInput [matAutocomplete]="autoCompleteClient" placeholder="Target Client"
                              [(ngModel)]="createUserLinkTypeFilter_ClientBind.Client_AutoCompleteControlDisplayValue">
                            <mat-autocomplete #autoCompleteClient="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                              (optionSelected)='AutoComplete_SaveSelectedClient($event.option)' [panelWidth]="900">
                              <div
                                *ngFor="let control of AutoComplete_Client_ApplyFilter(createUserLinkTypeFilter_ClientBind, 'Search', clientInputVal.value, 'Client')">
                                <mat-option class="glb_Font" [value]=control>
                                  {{control.ControlValue}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>

                    <div *ngIf="tableheaderitem === ' '" class="glb_customFlexRow justify-content-end align-items-center">
                      <!-- add a save/insert button -->
                      <div class="glb_customFlexRow justify-content-end align-items-center">
                        <span>
                          <button *ngIf="!isPerformingServerRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="UserLinkTypeFilter_Create()">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Create the User Link Type Filter">
                            </fa-icon>
                          </button>
                        </span>
                        <!-- lets stick a little spinner here, and switch it out when the button is pressed -->
                        <span>
                          <button *ngIf="isPerformingServerRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right">
                            <fa-icon class="fa-fw glb_fixedSizeIcons fastSpin" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                            </fa-icon>
                          </button>
                        </span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- and now the user link type filter content -->
      <div class="itemFirstBorder glb_customFlexRow text-white glb_Font row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
        <!-- content loop here -->
        <div *ngFor="let item of chosenUserLinkTypeFilters; odd as isOdd; even as isEven; index as itemIndex" class="itemBorders">
          <div class="card-body-lesspadding card-body glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-3"
            [ngClass]="CssClass_Get('DataPair', ColumnsInBody_UserLinkTypeFilters, WrapOddRows)">
            <div class="glb_customFlexRow align-content-center justify-content-left align-items-left">{{item.LinkTypeName}}</div>
            <div class="glb_customFlexRow align-content-center justify-content-left align-items-left">{{item.TargetClientName}}</div>
            <!-- add a delete button -->
            <div *ngIf="!IsCloning && !ReadOnlyMode" class="glb_customFlexRow justify-content-end align-items-center">
              <span style="padding-left:0.375em;">
                <button *ngIf="!isPerformingServerRequest"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="UserLinkTypeFilter_Delete(item.LinkTypeFilterGUID)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
                  </fa-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-template>