import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ActivatedRoute } from '@angular/router';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'TwoFactorReset',
  templateUrl: './TwoFactorReset.html',
  styleUrls: ['./TwoFactorReset.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class TwoFactorReset implements OnInit {

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private store: ClientDataStore) {
  }

  //The child components
  @ViewChild('focusField1') FocusLoginID;
  @ViewChild('focusField2') FocusPassword;

  public IsGenerating2FACode = false
  public User2FADataRequest = { ResetToken: "", Password: "", UserNumber: "" };
  public TwoFactorSuccessfulModalRef;
  public ResponseMessageHTML;
  public AppName = this.globalFunctions.GetApplicationName();

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      //Write this into the Reset Token
      if (!this.globalFunctions.isEmpty(params.ResetToken)) {
        this.User2FADataRequest.ResetToken = params.ResetToken
      }
      else {
        this.apiService.Error_Go('Invalid or expired link', 'Invalid or expired link, please double check your email, or request a new 2FA link.', 'Invalid Token');
      }
    })
  }

  //Set up 2FA code
  public TwoFactor_Setup(twoFactorSetupTemplate: TemplateRef<any>): void {

    //Is it valid?
    if (this.globalFunctions.isEmpty(this.User2FADataRequest.UserNumber)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Login ID is missing");
      this.globalFunctions.delay(1).then(() => { this.FocusLoginID.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.User2FADataRequest.Password)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Password is missing");
      this.globalFunctions.delay(1).then(() => { this.FocusPassword.nativeElement.focus(); });
      return;
    }

    if (this.User2FADataRequest.Password.length < GlobalFunctions.PasswordMinLength) {
      this.notifyService.Error_Show("This password is too short, please try again.", "Too short");
      this.globalFunctions.delay(1).then(() => { this.FocusPassword.nativeElement.focus(); });
      return;
    }

    if (this.User2FADataRequest.Password.length > GlobalFunctions.PasswordMaxLength) {
      this.notifyService.Error_Show("This password is too long, please try again.", "Too long");
      this.globalFunctions.delay(1).then(() => { this.FocusPassword.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.User2FADataRequest.ResetToken) || this.User2FADataRequest.ResetToken.length < 35) {
      this.notifyService.Error_Show("Please check your email or request a new password reset.", "Invalid Token");
      return;
    }

    const apiRequest = this.User2FADataRequest;
    this.IsGenerating2FACode = true;
    this.store.SetShowFullscreenLoading(true);
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.TwoFactorSetup], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsGenerating2FACode = false;
          this.store.SetShowFullscreenLoading(false);

          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          if (response.ResponseMessage === "Two Factor Setup Successful") {

            this.ResponseMessageHTML = response.ResponseHTML;

            //We need to keep a ref to this registration modal, so that we can close it later
            this.TwoFactorSuccessfulModalRef = this.matDialog.open(twoFactorSetupTemplate, this.globalFunctions.GetFeatureModalConfig("50%"));

            //Not much to do here. just a message to let the user know that they can login now. a notification might be a nice touch here
            this.notifyService.Success_Show("Your new two factor code has been generated", "Successful");
            this.store.SetShowFullscreenLoading(false);
          }
          this.IsGenerating2FACode = false;
        }
      });
  }

  //Launch Login Modal
  public LoginModal_Launch() {
    if (!this.globalFunctions.isEmpty(this.TwoFactorSuccessfulModalRef)) {
      this.TwoFactorSuccessfulModalRef.close();
    }
    this.globalFunctions.LaunchLoginModal(true, "Two Factor Setup successful, please log in", true, true);
  }
}