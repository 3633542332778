<div id="PG_Documentation" class="glb_formBGColor glb_paddingBottomMD glb_Font text-white" [ngClass]="ModalIdentifier">
    <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow sticky-top">
        <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
                <button id="BTN_MinimizeDocumentation" tabindex="4"
                    class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                    (click)="Documentation_MinimizeModal()">
                    <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
                </button>
                <button id="BTN_CloseDocumentation" tabindex="3"
                    class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                    (click)="Documentation_CloseModal()">
                    <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="glb_customFlexRow glb_boxedPadding col-12">
        <br>
        <p class="glb_Font glb_Title glb_TitleMobile text-center text-white col-12">
            {{AppName}} User Guide
        </p>

        <div class="glb_Font text-white col-12">
            <div class="glb_customFlexRow subHeadingTitle">Welcome to {{AppName}} (XV8)!</div>
            <p>XCHANGE has been designed to allow AMAL, AMAL's lenders and their staff to view, access, download, interact with and administer
                accounts in the AMAL system.</p>
            <p>This application has been upgraded from a prior edition (version 6), and therefore this new major release is numbered version 8.</p>
            <p>XCHANGE may be referred to as 'XV8' or 'Lender XCHANGE' in this user guide and other reference materials.</p>
            <p>This page is intended as a 'quick start' guide, and has some basic information about using XV8, with the view to get you up and
                running fast.</p>
            <p>If you would like some further information, or stuck with a bug and would like to file a report, please contact the <span
                    class="glb_bold">AMAL XV8 support</span> team at <a class="customLink"
                    href="mailto:xchangev8support@amal.com.au">xchangev8support&#64;amal.com.au</a>.</p>
            <br>
            <br>

            <!-- MaintenanceWindow -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div (click)="TemplateID_Toggle('MaintenanceWindow')" class="collapsibleInnerBottomBorder">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Maintenance Window & Readonly Mode</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('MaintenanceWindow',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('MaintenanceWindow',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div id="MaintenanceWindow" [ngClass]="TemplateID_GetCSS('MaintenanceWindow')">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div class="subHeading2Padding">
                                <div class="subHeadingTitle">
                                    Read Only Mode
                                </div>

                                <ul class="listPadding">
                                    <li>The application will run as read only mode during End Of Day (EOD) processing.</li>
                                    <li>EOD Time: approx. 7:05 PM till 9 PM (daily)</li>
                                    <li>During this time, <span class="btn btn-warning glb_paddingBoxZeroOffset">Read Only</span> will be displayed on
                                        the
                                        top
                                        left.</li>
                                    <li>You will still be able to search and view account details as well as create account enquiries. However, you
                                        will
                                        not
                                        be able to edit, insert or generate any other content.</li>
                                    <li>This is important to maintain the integrity of the application during critical system processing.</li>
                                </ul>
                            </div>
                            <div class="subHeading2Padding">
                                <div class="subHeadingTitle">
                                    Maintenance Window
                                </div>

                                <ul class="listPadding">
                                    <li>The application will be offline during the scheduled weekly release/deployment.</li>
                                    <li>Scheduled Release Time: 1:30 AM till 3 AM (Every Saturday Morning)</li>
                                    <li>During this time, the application will be unavailable.</li>
                                    <li>This allows us to deploy new features, bug fixes, security patches or hot fixes.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ImportantKeywords -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div (click)="TemplateID_Toggle('ImportantKeywords')" class="collapsibleInnerBottomBorder">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Important Keywords</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('ImportantKeywords',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('ImportantKeywords',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('ImportantKeywords')" id="ImportantKeywords">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>Here is a summary of the important keywords that this page will introduce, which is crucial to your understanding
                                    of
                                    how
                                    the application
                                    works.</p>
                                <p>The will be reinforced in <mark class="customMarkStyle">highlighted text</mark>.</p>

                                <ul class="listPadding">
                                    <li>Entities</li>
                                    <li>Navigation Tabs</li>
                                    <li>Loan Index</li>
                                    <li>Tab Page</li>
                                    <li>Action Bar</li>
                                    <li>Entity Field Editor</li>
                                    <li>Field Types</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- EntityModel -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('EntityModel')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Entity Model</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('EntityModel',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('EntityModel',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('EntityModel')" id="EntityModel">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>All the data available in the application is mapped into a Entity Model, and encompasses the following
                                    <mark class="customMarkStyle">Entities</mark>, also referred to as <mark class="customMarkStyle">Navigation
                                        Tabs</mark>
                                </p>

                                <ul class="listPadding">
                                    <li>Account Details</li>
                                    <li>Account Sub Details</li>
                                    <li>Linked Accounts</li>
                                    <li>Associations</li>
                                    <li>System Tasks</li>
                                    <li>User Tasks</li>
                                    <li>Transactions</li>
                                    <li>Dates</li>
                                    <li>Contractual Payment Amounts</li>
                                    <li>Bank Accounts</li>
                                    <li>Scheduled Transfers</li>
                                    <li>Balances</li>
                                    <li>Arrears</li>
                                    <li>Rates</li>
                                    <li>Securities</li>
                                    <li>Notes</li>
                                    <li>Documents</li>
                                    <li>Custom Fields</li>
                                    <li>Accruals</li>
                                    <li>Fee Setup</li>
                                </ul>

                                <div class="glb_customFlexRow row-cols-1">
                                    <div class="tipsPadding">
                                        <button class="tipsConfig btn">
                                            <div style="font-size: x-small;">Tip</div>
                                        </button>
                                        &nbsp;&nbsp;&nbsp; Entities, and therefore Navigation Tabs, that you will be able to see are dependant on the
                                        level of
                                        access granted to you via your role.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Login -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('Login')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Login</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('Login',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('Login',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('Login')" id="Login">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>Please refer to your invitation email to register your account and create your own password to log in to
                                    {{AppName}}.
                                </p>
                                <p>You will be required to set up and use Two Factor Authentication on a mobile device to log in to your account -
                                    please
                                    follow the instructions in your welcome email to get up and running with Two Factor Authentication.</p>
                                <p>After successfully logging into {{AppName}}, you will be greeted with your dashboard.</p>
                            </div>
                        </div>
                        <div class="subHeading2Padding">
                            <div class="subHeadingTitle">
                                Password Change Policy
                            </div>

                            <ul class="listPadding">
                                <li>Passwords will expire every 42 days.</li>
                                <li>Email reminders will be sent out close to the expiry dates.</li>
                                <li>Upon Login, you will be reminded to change the password if close to expiry.</li>
                                <li>When updating your password, it cannot be same as your previous 10 passwords.</li>
                                <li>Once it is expired, you will need to contact your administrator to reset it.</li>
                                <li>Two Factor (2FA) Code is required for a password change.</li>
                                <li>Login ID is required to reset a password.</li>
                                <li>Invalid password reset attempts is limited to 5. After this, you will be
                                    required to contact your Administrator.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dashboard -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('Dashboard')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Dashboard</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('Dashboard',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('Dashboard',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('Dashboard')" id="Dashboard">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>On this page, you will see some charts showing a 6 month historical view of loan data across the following
                                    dimensions:
                                </p>
                                <ul class="listPadding">
                                    <li>Principal Balances</li>
                                    <li>Total Arrears Balances</li>
                                    <li>Settlements per month</li>
                                    <li>Maturities</li>
                                    <li>Loan Status</li>
                                    <li>Loan Status pie chart, this one contains data for only the latest period (data from the current month)</li>
                                </ul>
                                <br>
                                <p>Note that data points for the first 4 charts are all positional and based on principal balances, the last 2 charts
                                    in
                                    relation to Loan Status contain data points about the number, or count of loans contained in that status.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LoanSearchBar -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('LoanSearchBar')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Account Search Bar</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('LoanSearchBar',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('LoanSearchBar',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('LoanSearchBar')" id="LoanSearchBar">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>At the top of the page, you will see an Account Search Bar. Please click on this and start typing to search for an
                                    Account.</p>
                                <p>You can search on many keywords, including:</p>
                                <ul class="listPadding">
                                    <li>First Name</li>
                                    <li>Middle name</li>
                                    <li>Surname</li>
                                    <li>Loan Identifiers (Both Loan ID and Lender Foreign Identifiers)</li>
                                    <li>Addresses (Street name, suburb, postcode, etc)</li>
                                    <li>Contact Details (e.g. email addresses, mobile or phone numbers</li>
                                </ul>
                                <br>
                                <p>You can click on any result that appears, and you will be taken to the <mark class="customMarkStyle">Account
                                        Index</mark> page for that particular Loan.</p>
                                <p>From here, you can access any of the Loan <mark class="customMarkStyle">Navigation Tabs</mark> that are
                                    available to you in the top bar.</p>
                                <p>These are easily identifiable across the top of the page - see the sample below:</p>
                                <br>
                                <div class="navbar-collapse" id="navbarSupportedContent">
                                    <div class="nav navCustom align-items-center nav-tabs glb_customFlexRow row-cols-2 row-cols-sm-4 row-cols-md-6 row-cols-lg-9 col-lg-12"
                                        id="nav-tab" role="tablist">
                                        <ng-container *ngFor="let entity of EntityTypesDisplay; index as itemIndex">
                                            <!-- ngclass is needed just on entry, for show and active, added to the first nav element to highlight it on entry -->
                                            <a class="nav-item nav-link glb_hoverHand" id="nav-{{entity.Name}}-tab"
                                                [ngClass]="NavTab_GetClass(itemIndex)">
                                                <div class="align-items-center justify-content-center"
                                                    (click)="CurrentLoanSearch_SetItemIndex(itemIndex)" id="nav-{{entity.Name}}-tab">
                                                    <div class="glb_Font glb_TitleSmall glb_TitleSmallMobile" id="nav-{{entity.Name}}-tab">
                                                        {{entity.NavDisplayName}}
                                                    </div>
                                                </div>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                                <br>

                                <div class="glb_customFlexRow row-cols-1">
                                    <div class="tipsPadding">
                                        <button class="tipsConfig btn">
                                            <div style="font-size: x-small;">Tip</div>
                                        </button>
                                        &nbsp;&nbsp;&nbsp; Each Navigation Tab is linked to an Entity mentioned above. If you wish to return to the
                                        Search
                                        Screen and search
                                        for another Loan, please click the search
                                        <button
                                            class="glb_clickableIconButtonXL glb_clickableIconButtonStyle glb_clickableIconButtonColorDark align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'search']" matTooltip="Search">
                                            </fa-icon>
                                        </button>
                                        button at the top left of the screen (to the left of the Loan Search Bar input field).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Account Enquiry -->
            <div *ngIf="AccountEnquiryClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('AccountEnquiry')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Account Enquiry</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('AccountEnquiry',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('AccountEnquiry',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('AccountEnquiry')" id="AccountEnquiry">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>After navigating to an Account page, click the clipboard-question icon
                                    <span class="noLeftPadding hasPadding">
                                        <span class="glb_hoverHand sideBarButton" matTooltip="Click to Launch Account Enquiry screen"
                                            matTooltipClass="glb_tooltipSM" matTooltipPosition="right">
                                            <fa-icon class="sideBarButtonFontAwesome" [icon]="['fal', 'clipboard-question']"></fa-icon>
                                        </span>
                                    </span>
                                    on the left side bar to launch the Account Enquiry screen:
                                </p>
                                <!-- Create an enquiry -->
                                <p>
                                    There are multiple fields that must to be populated before you can submit an enquiry.
                                </p>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Task Type
                                    </div>

                                    <ul>
                                        <li>You must select a Task Type you wish to create the account enquiry for
                                        <li>When an Account Enquiry is submitted, the selected task is
                                            created and added to the user task tab</li>
                                    </ul>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Due Date
                                    </div>
                                    <ul>
                                        <li>Due Date gets populated automatically according to SLA configuration for a specific task
                                        <li>Due Date is readonly (cannot be modified), however it can be overridden</li>
                                    </ul>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Override Due Date
                                    </div>
                                    <ul>
                                        <li>You can override Due Date by ticking this checkbox
                                            <span class="noLeftPadding hasPadding">
                                                <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="true">
                                                </mat-checkbox>
                                            </span> and selecting a new Due Date/Time from the
                                            DateTime picker
                                        </li>
                                        <li>Use Override Due Date when you need it to be completed earlier or later than the SLA</li>
                                    </ul>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Instructions
                                    </div>
                                    <ul>
                                        <li>Any task specific instructions can be added here</li>
                                    </ul>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Choose a File
                                    </div>
                                    <ul>
                                        <li>You can optionally attach a document/file</li>
                                    </ul>
                                </div>
                                <br />
                                <p>
                                    Once you have filled out all mandatory fields, please click Submit button to send an enquiry. Submitting an
                                    enquiry
                                    creates a new user task and auto assigns to relevant assignee.
                                </p>
                                <ng-container
                                    *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('CreateAccountEnquiry',1)"></ng-container>
                                <p>
                                    The newly created task is added to User Task tab
                                </p>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        User Tasks Tab
                                    </div>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('UserTasksTab',1)"></ng-container>
                                </div>
                                <p>Alternatively it can also be accessed via Dashboard >> Lender
                                    Tasks</p>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Lender Tasks
                                    </div>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('LenderTasks',1)"></ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- TabPages -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('TabPages')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Tab Pages</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('TabPages',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('TabPages',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('TabPages')" id="TabPages">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>Each Navigation Tab will reveal its related Entity data into the <mark class="customMarkStyle">Tab
                                        Page</mark>
                                    below it when it is clicked.</p>

                                <p>On the top right of the Tab Page, you will notice up to 3 buttons and a Filter input box:</p>
                                <ul class="listPadding">
                                    <li>Refresh</li>
                                    <li>Download</li>
                                    <li>Add (for some Entities)</li>
                                    <li>Filter</li>
                                </ul>
                                <br>
                                <p>Each button is explained in some further detail in the section below.</p>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'sync-alt']" matTooltip="Click to Refresh">
                                            </fa-icon>
                                        </button>
                                        Refresh
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you to refresh the data that sits directly below this button.</li>
                                        <li>Sometimes, things have been changed on the system, and your browser may not know about it!</li>
                                        <li>While we do our best to keep you in sync regularly, sometimes you may find that pressing this button will
                                            get
                                            you
                                            the latest data
                                            that you need.</li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                                            </fa-icon>
                                        </button>
                                        Download
                                    </div>

                                    <ul class="listPadding">
                                        <li>This button allows you to download the data directly below it into a csv (comma separate values) file. All
                                            fields
                                            visible in
                                            the Tab Page below it will be copied.</li>
                                        <li>You can open the downloaded file in Microsoft Excel, and convert it to an Excel (.xlsx) file, if you wish.
                                        </li>
                                        <li>This allows you to download and do any calculations or formulas for any piece of data that you can see.
                                        </li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'plus-square']" matTooltip="Click to Add">
                                            </fa-icon>
                                        </button>
                                        Add
                                    </div>

                                    <ul class="listPadding">
                                        <li>Some data tables can have <b>new</b> entries inserted to them!</li>
                                        <li>If you would like to insert a new row of data to the existing ones below, you can press this button.</li>
                                        <li> A new pop up screen will appear showing you all the relevant fields that may be populated. Go ahead and
                                            fill
                                            out
                                            the data, and
                                            click Save.</li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        Filter
                                        <div class="input-group glb_clickableIconButtonPadding col-8 col-xs-8 col-sm-8 col-md-8 col-lg-6">
                                            <div class="input-group-prepend d-none d-md-block d-lg-block">
                                                <span class="input-group-text">Filter</span>
                                            </div>
                                            <input type="text" class="form-control form-control-custom" placeholder="Enter Search Term"
                                                aria-label="Filter" aria-describedby="filter">
                                        </div>

                                    </div>

                                    <ul class="listPadding">
                                        <li>There is a filter box that you can click in and type any text that you like. The content directly below it
                                            will be
                                            filtered, and
                                            only text that matches your typed in text will appear.</li>
                                        <li>Note that this filter applies accross all fields that are visible below it.</li>
                                        <li> For example, if there is a date field in the data below, you may type "October 2019", and only rows that
                                            contain
                                            October 2019
                                            data will appear.</li>
                                        <li>To remove the filter, simply remove your text from the input box.</li>
                                        <div class="subHeading2Title">
                                            Combining multiple search filter terms using AND and OR operators
                                        </div>
                                        <p>
                                            <li>This filter box support the AND and OR operators, using special characters.</li>
                                            <li>To combine multiple search terms using the OR operator, you can place a pipe (|) symbol in between
                                                them.
                                                For
                                                example, if you
                                                typed the value "October|Waterfall", it would filter and find all rows that contain either the text
                                                "October"
                                                or the text
                                                "Waterfall".</li>
                                            <li>To combine multiple search terms using the AND operator, you can place a double ampersand (&&) symbol
                                                in
                                                between them. For
                                                example, if you typed the value "October&&Waterfall", it would filter and find all rows that contain
                                                BOTH
                                                the
                                                text "October"
                                                AND the text "Waterfall".</li>
                                            <li>You can combine as many terms as you like, e.g. "Waterfall&&October&&Interest" is a valid filter term.
                                            </li>
                                            <li>Combining AND and OR operators together is currently not supported. e.g.
                                                "Waterfall||October&&Interest" is
                                                NOT
                                                a valid filter
                                                term.</li>
                                        </p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ActionBar -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('ActionBar')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Action Bar</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('ActionBar',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('ActionBar',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('ActionBar')" id="ActionBar">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>Data that already exists can also be modified. To do this, you can click on any piece of data that you see in a
                                    <mark class="customMarkStyle">Tab
                                        Page</mark>.
                                </p>
                                <p>The Field that you clicked on will become highlighted green, and its related Row data will become highlighted blue.
                                </p>
                                <p>Near the bottom of the screen, a new floating section will appear, called the <mark class="customMarkStyle">Action
                                        Bar</mark>.</p>
                                <p>This will contain buttons relevant to the Field (piece of data) that you selected. Here is a sample of what it
                                    looks
                                    like:
                                </p>

                                <div
                                    class="glb_ActionBarParentDocumentation glb_customFlexRow justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center align-items-end">

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clipboard']" matTooltip="Copy to Clipboard">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']"
                                                matTooltip="Click to Download Document">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eye']" matTooltip="Click to View Document">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Field">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']"
                                                matTooltip="Click to Edit Object/Record">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClone glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorDelete glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
                                            </fa-icon>
                                        </button>
                                    </span>

                                    <!-- a small separator/gap between other buttons and the close/cancel button -->
                                    <span style="padding-left:0.375em;">
                                        <div class="align-self-right">
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </span>

                                    <!-- a close/cancel button -->
                                    <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClear glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'times']" matTooltip="Click to Close">
                                            </fa-icon>
                                        </button>
                                    </span>

                                </div>

                                <br>
                                <p>In a typical scenario, you will be able to see the following options:</p>
                                <ul class="listPadding">
                                    <li>Copy to Clipboard</li>
                                    <li>Download Document</li>
                                    <li>View Document</li>
                                    <li>Edit Field</li>
                                    <li>Edit Object/Record</li>
                                    <li>Clone</li>
                                    <li>Delete</li>
                                    <li>Close</li>
                                </ul>

                                <div class="glb_customFlexRow row-cols-1">
                                    <div class="tipsPadding">
                                        <button class="tipsConfig btn">
                                            <div style="font-size: x-small;">Tip</div>
                                        </button>
                                        &nbsp;&nbsp;&nbsp; If you don't want to interact with this Field, or you selected the wrong Field, you can
                                        simply
                                        click on another one, hit the grey cancel button on the action bar, or even continue on to another Navigation
                                        Tab.
                                    </div>
                                </div>

                                <p>The below section explains what each of these buttons mean.</p>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clipboard']"
                                                    matTooltip="Copy to Clipboard">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Copy to Clipboard
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you to copy the selected field into your Clipboard (similar to pressing Ctrl+C). You
                                            may
                                            then
                                            paste this value
                                            using Ctrl+V into another application of your choice.</li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']"
                                                    matTooltip="Click to Download Document">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Download Document
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button will only appear when the Field you selected is linked to a Document. Clicking this will
                                            download
                                            the
                                            Document that is
                                            linked to this Field. The File will be downloaded by your browser - Please check your downloads folder to
                                            open
                                            it.
                                        </li>
                                        <li>Please note that while the Document can be downloaded, editing this copy of the document will not be saved
                                            back to
                                            the
                                            application.</li>
                                        <li>If you need to modify a Document, you will need to contact the AMAL Support team. Alternatively, you could
                                            upload
                                            a new version of
                                            the document using the Add button.</li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eye']" matTooltip="Click to View Document">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        View Document
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button will only appear when the Field you selected is linked to a Document. Clicking this will
                                            launch
                                            the
                                            document inside the browser tab in a popup, allowing you to view the file without having to download it
                                            onto
                                            your
                                            device. Currently supported file types are:
                                        </li>
                                        <li>Currently supported file types are:
                                        </li>
                                        <li>pdf</li>
                                        <li>docx
                                            <ul class="glb_paddingLeftMD">
                                                <li>Note: the .docx is rendered in the browser as html, and is meant as a quick preview. It may not
                                                    contain
                                                    all the original fonts, colours and formatting. If you need to review the content accurately, it
                                                    is
                                                    recommended that you download it using the Download button instead.</li>
                                                <li>The older .doc extension is not supported by this previewer.</li>
                                            </ul>
                                        </li>
                                        <li>jpg</li>
                                        <li>png</li>
                                        <li>txt</li>
                                        <li>xml
                                            <ul class="glb_paddingLeftMD">
                                                <li>Note: the xml file will be formatted and indented to make it more readable.</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Field">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Edit Field
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you edit the single Field that you clicked. A new popup screen will be launched, and
                                            you
                                            may
                                            update the value.
                                        </li>
                                        <li>The single field will correspond to the BLUE (not green) highlighted section of the Tab Page.</li>
                                        <li>Don't forget to press Save once completed, or Cancel the request by pressing the cross icon at the top
                                            right.
                                        </li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']"
                                                    matTooltip="Click to Edit Object/Record">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Edit Object/Record
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you edit ALL FIELDS related to the item that you clicked. A new popup screen will be
                                            launched,
                                            and you may
                                            update any values that exist.</li>
                                        <li>The Fields available will correspond to the GREEN highlighted section of the Tab Page.</li>
                                        <li>Don't forget to press Save once completed, or Cancel the request by pressing the cross icon at the top
                                            right.
                                        </li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClone glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Clone
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you COPY ALL FIELDS related to the item that you clicked, and generate an entirely new
                                            Row
                                            that
                                            is a Copy (or a
                                            Clone) of the one that was chosen. A new popup screen will be launched, and you will be given a chance to
                                            also
                                            update any values
                                            that exist before you press Save.</li>
                                        <li>The Fields that are copied correspond to the GREEN highlighted section of the Tab Page.</li>
                                        <li>A NEW record will be created, copying all the data from the Row that you clicked on (plus any edits that
                                            you
                                            made
                                            before you
                                            clicked Save).</li>
                                        <li>Don't forget to press Save once completed, or Cancel the request by pressing the cross icon at the top
                                            right.
                                        </li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorDelete glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Delete
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button allows you Delete ALL FIELDS and THE ROW (object) related to the item that you clicked.</li>
                                        <li>The Fields that will be deleted correspond to the GREEN highlighted section of the Tab Page.</li>
                                        <li>A warning popup will be provided, asking you to confirm the deletion.</li>
                                        <li>Deletions are audited, so if you do need some piece of data to be irrevocably removed, please contact AMAL
                                            Support.</li>
                                    </ul>

                                    <div class="subHeading2Title">
                                        <span style="padding-left:0.375em;">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClear glb_clickableIconButtonLG align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'times']" matTooltip="Click to Close">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        Close
                                    </div>
                                    <ul class="listPadding">
                                        <li>This button closes the Action Bar.</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- User Tasks -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('UserTasks')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">User Tasks</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('UserTasks',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('UserTasks',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('UserTasks')" id="UserTasks">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Viewing User Tasks for an Account
                                    </div>

                                    <p>After navigating to any Account page, if you are granted the "User Task" permission, you will be able to see
                                        and
                                        visit
                                        the tab named "User Tasks" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks1-min.JPG" class="glb_screenShotImg">
                                    </div>

                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Create a new User Task for an Account
                                    </div>

                                    <p>Click the plus icon
                                        <span class="noLeftPadding hasPadding">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'plus-square']" matTooltip="Click to Add">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        on the right to launch a popup to insert a new user task:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks2-min.JPG" class="glb_screenShotImg">
                                    </div>


                                    <p>On this popup you may enter the task details, such as:
                                    </p>

                                    <ul class="listPadding">
                                        <li>Task Type (e.g. Condition Subsequent)</li>
                                        <li>Start Date (When this task should commence)</li>
                                        <li>Due Date (When this task should be completed by)</li>
                                        <li>Task Status (Initially a task should start as 'Active')</li>
                                        <li>Assigned To (Type and choose the relevant AMAL Team - Lender Name, e.g. "AMAL team - Test")</li>
                                        <li>Task Note (Use this field to describe the details of the task, e.g. action items, and items that need to
                                            be
                                            addressed)
                                        </li>
                                    </ul>

                                    <p>Once you are happy with your data entry, click save to generate the User Task. It will appear in the grid as
                                        per
                                        screenshot
                                        below.
                                    </p>
                                    <p>On the top right of the User Task you will notice two icons. Hover over them to read the tooltip, and you will
                                        discover
                                        that the first one will allow you to attach notes to your User Task, and the other one will allow you to
                                        attach
                                        documents
                                        to it.
                                    </p>
                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks3-min.JPG" class="glb_screenShotImg">
                                    </div>

                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Create a new Note on a User Task
                                    </div>

                                    <p>Let's go ahead and add a note - Click on the first icon <span style="padding-left:0.375em;">
                                            <button class="btn glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'file-alt']">
                                                </fa-icon>
                                            </button>
                                        </span> A new popup will appear for note entry, and here you may
                                        enter a subject for this note, and then some text. Hit save once you have completed the data entry.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks4-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        The note will be added to the User Task under the "User Task Notes" section. On the note, you will notice a
                                        downward
                                        facing chevron(arrow). Click on this to expand the note and see its details:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks5-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks6-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        You may click the chevron to slide the details section closed again.
                                    </p>

                                    <p>
                                        If you have the permission to do so, you may click on any of the fields for a note, and inspect the context
                                        action
                                        bar
                                        at
                                        the bottom of the screen for an Edit button. You may utilize this to make updates to the note fields.
                                    </p>

                                    <p>
                                        Note that you may attach any number of notes to a User Task, and they will all appear under the "User Task
                                        Notes"
                                        tab
                                        of
                                        this User Task.
                                    </p>

                                    <p>
                                        Notes can be used to add any supporting additional information about the User Task. Some examples could be:

                                    </p>

                                    <ul class="listPadding">
                                        <li>You may have forgotten to add into the task detail itself.</li>
                                        <li>New information that surfaces later in the lifecycle of the request.</li>
                                        <li>Something about the request or data has changed, and the request needs amendment.</li>
                                        <li>Responding to a note added by another user, which includes the answer to the query or a clarification.
                                        </li>
                                        <li>Audit purposes.</li>
                                    </ul>

                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Attach a new Document to a User Task
                                    </div>
                                    <p>
                                        Now let's try attaching a document. Click on the second icon on the top right of the User Task
                                        <span style="padding-left:0.375em;">
                                            <button class="btn glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'folder-open']">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        which will open the following popup:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks7-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        Complete the data entry for the following fields:
                                    </p>

                                    <ul class="listPadding">
                                        <li>Document Category (e.g. Condition Subsequent) - this is the category of the document. Try to pick the
                                            category
                                            that
                                            suits the type of document that is being uploaded.</li>
                                        <li>Subject - this is the filename that the document will be given after it is uploaded. Try to keep it short
                                            yet
                                            descriptive.</li>
                                        <li>Choose a file - click on this to launch an explorer which will let you navigate and choose the file you
                                            would
                                            like
                                            to upload.</li>
                                    </ul>

                                    <p>
                                        Below is a list of the valid file extensions that XCHANGE will support:
                                    </p>

                                    <ul class="listPadding">
                                        <li>pdf</li>
                                        <li>txt</li>
                                        <li>doc</li>
                                        <li>docx</li>
                                        <li>xls</li>
                                        <li>xlsx</li>
                                        <li>jpg</li>
                                        <li>jpeg</li>
                                        <li>bmp</li>
                                        <li>text</li>
                                        <li>png</li>
                                        <li>eml</li>
                                        <li>msg</li>
                                        <li>xml</li>
                                    </ul>

                                    <p>
                                        Once you have chosen a valid file, you may press save.
                                    </p>

                                    <div class="glb_customFlexRow row-cols-1">
                                        <div class="tipsPadding">
                                            <button class="tipsConfig btn">
                                                <div style="font-size: x-small;">Tip</div>
                                            </button>
                                            &nbsp;&nbsp;&nbsp; If you have a lot of files to upload, you may zip and attach them as a single file.
                                            Otherwise
                                            you will have to manually upload each file separately. This may be preferred, if you would like each
                                            document
                                            to
                                            be attached using a respective document category that matches for each document.
                                        </div>
                                    </div>

                                    <p>
                                        Once the document has been uploaded, similar to notes, there will be a tab called "User Task Documents" that
                                        you
                                        can
                                        click on to view all documents attached to a User Task. Click the downwards facing chevron/arrow to expand the
                                        document information.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks8-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        Now click on the 'Document Category' field. The field will become highlighted, and at the bottom of the screen
                                        the
                                        context action bar will become visible. Click on the icon
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                                            </fa-icon>
                                        </button>
                                        to download the document. It will go to your "Downloads" folder that your browser has configured.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks9-min.JPG" class="glb_screenShotImg">
                                    </div>
                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Edit a User Task - change its Status to "Complete"
                                    </div>

                                    <p>
                                        To edit a User Task status, e.g. if the task is completed and you want to move its status to "Completed",
                                        first
                                        you
                                        must locate the User Task, and click on the Status field. On the Action Bar that appears at the bottom of the
                                        screen,
                                        click the Edit
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Field">
                                            </fa-icon>
                                        </button>
                                        button.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks10-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        On the popup, choose the status that you want, in this case "Complete", and press Save.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks11-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        You should see the updated status reflected immediately.
                                        Note that any of the User Task fields can be updated in a similar fashion, simply by clicking on the field and
                                        clicking the "Edit" option in the Context Action Bar.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks12-min.JPG" class="glb_screenShotImg">
                                    </div>
                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Viewing User Tasks on your Dashboard
                                    </div>
                                    <p>
                                        If granted the permission to do so, you will also see User Tasks on your Dashboard. Click on the downward
                                        facing
                                        chevron/arrow to expand the User Tasks assigned to that user.
                                    </p>

                                    <ul class="listPadding">
                                        <li>Note that only User Tasks that are within 3 days of its due date, or overdue, are displayed here (i.e.
                                            Upcoming
                                            User Tasks).</li>
                                        <li>In addition, once a User Task status has been changed to "Complete", and 3 or more days have elapsed from
                                            its
                                            Due
                                            Date, that User Task will automatically disappear from the Lender Task dashboard.</li>
                                    </ul>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks13-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        You may then click on a single User Task to bring up the context action bar. By clicking the View Task <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']" matTooltip="Click to View Task">
                                            </fa-icon>
                                        </button> option in the Context Action bar, you will bring up the User Task details for viewing:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks14-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <p>
                                        From here, you may interact with this User Task in the same manner as described earlier, e.g. attach notes,
                                        documents,
                                        or update the User Task fields.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UserTasks15-min.JPG" class="glb_screenShotImg">
                                    </div>

                                    <div class="glb_customFlexRow row-cols-1">
                                        <div class="tipsPadding">
                                            <button class="tipsConfig btn">
                                                <div style="font-size: x-small;">Tip</div>
                                            </button>
                                            &nbsp;&nbsp;&nbsp; The Lender Task section on the Dashboard will allow you to see all User Tasks created
                                            by
                                            all
                                            users, across all accounts. Its intention is to provide a quick view into all upcoming Tasks that need to
                                            be
                                            actioned in the next 3 days, and also highlight items that are past its Due Date and need urgent
                                            attention, or
                                            reprioritization.
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bank Details -->
            <div *ngIf="BankDetailsEditClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('BankDetails')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Bank Accounts</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('BankDetails',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('BankDetails',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('BankDetails')" id="BankDetails">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Viewing Bank Details for an Account
                                    </div>

                                    <p>
                                        After navigating to an Account page, visit the tab named "Bank Accounts" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('BankAccountTab',3)"></ng-container>
                                    </div>
                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Adding new Bank Details
                                    </div>

                                    <p>Click the plus icon
                                        <span class="noLeftPadding hasPadding">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'plus-square']" matTooltip="Click to Add">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        on the right to launch a popup to insert new Bank Details:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('BankAccountAdd',3)"></ng-container>
                                    </div>

                                    <p>Enter the "Account Name", "BSB No." and "Account Number" and click Save button to save Bank Details:
                                    </p>
                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('BankAccountNew',3)"></ng-container>
                                    </div>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Editing Bank Details
                                    </div>
                                    "Edit Object/Record" button <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']"
                                                matTooltip="Click to Edit Object/Record">
                                            </fa-icon>
                                        </button> allows you to edit Bank Details.
                                    </span>
                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('BankAccountEdit',3)"></ng-container>
                                    </div>
                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('BankAccountEditSave',3)"></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Statement Generation-->
            <div *ngIf="StatementGeneratorClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('StatementGeneration')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Statement Generation</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('StatementGeneration',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('StatementGeneration',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('StatementGeneration')" id="StatementGeneration">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Principal Borrower only
                                    </div>

                                    <p>Please note that statements are currently addressed to the principal borrower. A future release will allow you
                                        to
                                        choose another party (e.g. secondary borrower).
                                    </p>
                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generate Statement for an Account
                                    </div>

                                    <p>After navigating to any Account page, visit the tab named "Transactions" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/StatementGenerations1-min.JPG" class="glb_screenShotImg">
                                    </div>

                                </div>

                                <p>Click the book icon
                                    <span class="noLeftPadding hasPadding">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'book']"
                                                matTooltip="Click to Generate Statement">
                                            </fa-icon>
                                        </button>
                                    </span>
                                    on the right to launch a popup to input a date range to generate statement:
                                </p>

                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/StatementGenerations2-min.JPG" class="glb_screenShotImg">
                                </div>


                                <p>On this popup enter the "From Date" and "To Date":
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/StatementGenerations3-min.JPG" class="glb_screenShotImg">
                                </div>

                                <p>Once you are happy with your data entry, click "Generate". It will generate the statement, attach it to the
                                    documents
                                    tab
                                    and then will display the statement as per screenshot below.
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/StatementGenerations4-min.JPG" class="glb_screenShotImg">
                                </div>

                                <p>If the statement contains a large number of transactions, then a message will be displayed to refresh the document
                                    tab
                                    after a while as it will take few minutes for it to complete.
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/StatementGenerations5-min.JPG" class="glb_screenShotImg">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Payout Calculation Generation-->
            <div *ngIf="PayoutGeneratorClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('PayoutCalculator')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Residential Payout Calculator</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('PayoutCalculator',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('PayoutCalculator',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('PayoutCalculator')" id="PayoutCalculator">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Current Limitations
                                    </div>
                                    <p>This residential payout calculator will <mark class="customMarkStyle">NOT take into account any future rate
                                            reviews</mark>.
                                    </p>
                                </div>
                                <!-- How to navigate -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        How to navigate to Residential Payout Calculation
                                    </div>
                                    <p>After navigating to an Account page, click the sack-dollar icon
                                        <span class="noLeftPadding hasPadding">

                                            <fa-icon class="fa-fw" [icon]="['fal', 'sack-dollar']"
                                                matTooltip="Click to Launch Residential Payout Calculator">
                                            </fa-icon>
                                        </span>
                                        on the left side bar to launch the Residential Payout Calculator screen:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('NavBarButton',0)"></ng-container>
                                </div>
                                <!-- Generate Payout -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generate Payout Calculations for all linked Accounts
                                    </div>
                                    <p>The Residential Payout Calculator will list the payout amounts for all the linked loans within the facility
                                        (except
                                        offset, prepaid
                                        and
                                        retention loans) for the selected payout date:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('CurrentDate',0)"></ng-container>
                                    <p>Change the Payout Date to generate the payout amounts for any future date:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('Future',0)"></ng-container>
                                </div>
                                <!-- Optional fields -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Optional Fields
                                    </div>
                                    <p>
                                        There are two optional fields you can set prior to generating a payout letter.
                                        You can use checkbox
                                        <span class="noLeftPadding hasPadding">
                                            <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="true">
                                            </mat-checkbox>
                                        </span> to show and hide the optional fields:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('OptionalFields',0)"></ng-container>
                                </div>
                                <!-- Fees section -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Adding Account Fees
                                    </div>
                                    <p>The Residential Payout Calculator calculates the payout amount taking all applicable fees into consideration.
                                        To
                                        launch fees section please click on the magnifying-glass
                                        <span class="noLeftPadding hasPadding">
                                            <button id="BTN_ShowFeeDetails" class="glb_clickableIconButtonMD align-self-right BGColorGreyCircular">
                                                <fa-icon [icon]="['fal', 'magnifying-glass']" class="fa-fw glb_fixedSizeIcons IconColorDark"
                                                    matTooltip="View fee details" matTooltipPosition="right">
                                                </fa-icon>
                                            </button>
                                        </span> icon:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('FeesButton',0)"></ng-container>
                                    <p>
                                        To add a new fee please click on the plus
                                        <span class="noLeftPadding hasPadding">
                                            <button class="glb_clickableIconButtonMD align-self-right BGColorGreyCircular">
                                                <fa-icon [icon]="['fal', 'plus']" class="fa-fw glb_fixedSizeIcons IconColorDark" matTooltip="Add Fee">
                                                </fa-icon>
                                            </button>
                                        </span> icon. Clicking on the edit
                                        <span class="noLeftPadding hasPadding">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD justify-content-end">
                                                <fa-icon [icon]="['fal', 'edit']" class="fa-fw glb_fixedSizeIcons IconColorDark" matTooltip="Add Fee">
                                                </fa-icon>
                                            </button>
                                        </span> icon allows you to to edit a fee.
                                    </p>
                                    <p>
                                        Use the checkbox
                                        <span class="noLeftPadding hasPadding">
                                            <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="true">
                                            </mat-checkbox>
                                        </span> to waive/exclude the fee.
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('FeesModal',0)"></ng-container>
                                </div>
                                <!-- Saving a payout note -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Saving a Payout Note
                                    </div>
                                    <p>
                                        You must select Payout Date and Discharge Type to generate a Payout Letter or save a Payout Note
                                        successfully.<br />
                                        Click "Save Payout Note" to save the calculated payout amounts as audit notes on the Notes screen for future
                                        reference:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SavePayoutButton',0)"></ng-container>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SavePayoutNote',0)"></ng-container>
                                </div>
                                <!-- Generating the payout letter -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generating the Payout Letter (Indicative)
                                    </div>
                                    <p>
                                        Please note that Payout Date and Discharge Type must be selected to generate a payout letter.
                                        Please choose "Indicative" for Discharge Type and click "Generate Letter" to generate the payout letter:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('GeneratePayoutButton',0)"></ng-container>
                                    Select "Yes" to confirm you wish to generate the Payout Letter.
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('GeneratePayoutConfirmer',0)"></ng-container>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('PayoutLetter',0)"></ng-container>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generating the Payout Letter (Settlement Booked)
                                    </div>
                                    <p>
                                        Please choose "Settlement Booked" for Discharge Type and click "Generate Letter" to generate the payout
                                        letter:
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SettlementBookedSetup',0)"></ng-container>

                                        When the Discharge Type is set to "Settlement Booked", following business rules apply. If you agree, please
                                        select
                                        "Yes" to generate the Payout Letter:
                                        <ng-container
                                            *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SettlementBookedRules',0)"></ng-container>
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SettlementBookedPayoutLetter',0)"></ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lease Payout Calculation Generation-->
            <div *ngIf="LeasePayoutClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('LeasePayoutCalculator')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Lease Payout Calculator</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('LeasePayoutCalculator',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('LeasePayoutCalculator',false)"
                                    matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('LeasePayoutCalculator')" id="LeasePayoutCalculator">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generate Payout Calculation
                                    </div>

                                    <p>After navigating to any Account page, visit the tab named "Transactions" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/LeasePayout1.jpg" class="glb_screenShotImg">
                                    </div>

                                </div>

                                <p>Click the icon
                                    <span class="noLeftPadding hasPadding">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'garage-car']"
                                                matTooltip="Click to Launch Lease Payout Calculator">
                                            </fa-icon>
                                        </button>
                                    </span>
                                    on the right to launch the Lease Payout Calculator screen. It will generate the payout amount for the current
                                    date:
                                </p>

                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/LeasePayout2.jpg" class="glb_screenShotImg">
                                </div>


                                <p>Click "Save Payout" to save the calculated payout amount as an audit note on the Notes screen for future reference:
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/LeasePayout3.jpg" class="glb_screenShotImg">
                                </div>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/LeasePayout4.jpg" class="glb_screenShotImg">
                                </div>


                                <p>Click "Generate Letter" to generate the payout letter for the principal borrower and attach to the document screen
                                    for
                                    future reference:
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/LeasePayout5.jpg" class="glb_screenShotImg">
                                </div>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/LeasePayout6.jpg" class="glb_screenShotImg">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Resi Repayment Calculation Generation-->
            <div *ngIf="ResiRepaymentClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('ResiRepaymentCalculator')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Residential Repayment Calculator</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('ResiRepaymentCalculator',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('ResiRepaymentCalculator',false)"
                                    matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('ResiRepaymentCalculator')" id="ResiRepaymentCalculator">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <!-- How to navigate -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        How to navigate to Residential Repayment Calculator
                                    </div>
                                    <p>After navigating to an Account page, click the calculator icon
                                        <span class="noLeftPadding hasPadding">

                                            <fa-icon class="fa-fw" [icon]="['fal', 'calculator']"
                                                matTooltip="Click to Launch Residential Repayment Calculator">
                                            </fa-icon>
                                        </span>
                                        on the left side bar to launch the Residential Repayment Calculator screen:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('ResiRepaymentCalcButton',4)"></ng-container>
                                </div>
                                <!-- Prefills -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">

                                    </div>
                                    <p>This is prefilled with repayment amounts which are calculated using account data on
                                        launch.
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('Prefill',4)"></ng-container>
                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        P & I Repayments Calculation
                                    </div>
                                    <p> Repayments are calculated based on number of remaining periods.
                                        Inputs required:</p>
                                    <ul class="listPadding">
                                        <li>Balance</li>
                                        <li>Payment Type</li>
                                        <li>Effective Rate</li>
                                        <li>Effective Date</li>
                                        <li>Maturity Date</li>
                                    </ul>

                                </div>
                                <!-- P & I Calculation -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        How to calculate P & I repayments
                                    </div>
                                    <p>Please click on

                                        <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorClear text-white"
                                            matTooltip="Click to modify the inputs">
                                            <span class="glb_Font glb_smallerText">Modify</span>
                                        </button>
                                        button to enable all the inputs:
                                    </p>

                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('PAndIModify',4)"></ng-container>
                                    <p>Once you have entered all the inputs, you can use
                                        <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                                            matTooltip="Click to calculate repayment amount">
                                            <span class="glb_Font glb_smallerText">Calculate</span></button>
                                        button to calculate new indicative repayment amounts:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('PAndICalculate',4)"></ng-container>
                                    <p>
                                        New indicative repayments are refreshed on the screen:
                                    </p>
                                    <ng-container
                                        *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('PAndIRepayment',4)"></ng-container>
                                </div>
                                <!-- P & I Calculation -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        P & I Amortisation Schedule Chart
                                    </div>
                                    <p>
                                        P & I Amortisation Schedule chart depicts remaining principal balance and interest portion paid annually until
                                        maturity.
                                        You can use
                                        <span>
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                                                </fa-icon>
                                            </button>
                                        </span>
                                        button to download the Amortisation Schedule. <br />
                                        <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                                            matTooltip="Click to modify the inputs">
                                            <span class="glb_Font glb_smallerText">Update</span></button> button below it allows you to add extra
                                        repayments and
                                        compare against the original amortisation:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('AmortPrefill',4)"></ng-container>
                                    After clicking Update, the summary section will refresh to show the loan term reduction as a result of the
                                    extra repayments:
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('AmortExtra',4)"></ng-container>

                                </div>
                                <!-- IO Calculation -->
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Interest Only Repayment Calculation
                                    </div>
                                    <p> Indicative monthly repayments are based on a standard 31 day month. Inputs required: </p>
                                    <ul class="listPadding">
                                        <li>Balance</li>
                                        <li>Payment Type</li>
                                        <li>Effective Rate</li>
                                    </ul>
                                </div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        How to calculate IO repayments
                                    </div>
                                    <p>Please click on
                                        <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorClear text-white"
                                            matTooltip="Click to modify the inputs">
                                            <span class="glb_Font glb_smallerText">Modify</span>
                                        </button>
                                        button to enable all the inputs
                                    </p>
                                    <p>Once you have entered required inputs, you can use
                                        <button class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                                            matTooltip="Click to calculate repayment amount">
                                            <span class="glb_Font glb_smallerText">Calculate</span></button>
                                        button to calculate new indicative repayment amounts:
                                    </p>
                                    <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('IORepayment',4)"></ng-container>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Document Generation-->
            <div *ngIf="DocumentGeneratorClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('DocumentGeneration')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Document Generation</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('DocumentGeneration',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('DocumentGeneration',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('DocumentGeneration')" id="DocumentGeneration">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Generate Document from Template for an Account
                                    </div>

                                    <p>After navigating to any Account page, visit the tab named "Documents" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/DocumentGenerations1-min.JPG" class="glb_screenShotImg">
                                    </div>

                                </div>

                                <p>Click the file plus icon
                                    <span class="noLeftPadding hasPadding">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'file-plus']"
                                                matTooltip="Click to Generate Document">
                                            </fa-icon>
                                        </button>
                                    </span>
                                    on the right to launch a popup to input/select the document template:
                                </p>

                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/DocumentGenerations2-min.JPG" class="glb_screenShotImg">
                                </div>


                                <p>On this popup search the document template name inside the auto complete text box:
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/DocumentGenerations3-min.JPG" class="glb_screenShotImg">
                                </div>

                                <p>Select the target document template and click "Generate":
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/DocumentGenerations4-min.JPG" class="glb_screenShotImg">
                                </div>
                                <p>It will generate the document, attach it to the documents tab
                                    and then will display the document as per screenshot below:
                                </p>
                                <div
                                    class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                    <img src="/LenderClient/assets/screenshots/DocumentGenerations5-min.JPG" class="glb_screenShotImg">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- LoanEntityModify -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('LoanEntityModify')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Entity Field Editor</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('LoanEntityModify',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('LoanEntityModify',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('LoanEntityModify')" id="LoanEntityModify">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>After you click on any of the "Edit Field" <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Field">
                                            </fa-icon>
                                        </button>
                                    </span>, "Edit Object/Record" <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']"
                                                matTooltip="Click to Edit Object/Record">
                                            </fa-icon>
                                        </button>
                                    </span> or "Clone" <span style="padding-left:0.375em;">
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClone glb_clickableIconButtonLG align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone">
                                            </fa-icon>
                                        </button>
                                    </span> buttons on the <mark class="customMarkStyle">Action Bar</mark>, you will see the <mark
                                        class="customMarkStyle">Entity Field Editor</mark>
                                    popup window appear. This will also appear when you click on the "Add" <button
                                        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'plus-square']" matTooltip="Click to Add">
                                        </fa-icon>
                                    </button> button at the top right of an entity, for the scenario
                                    where you want to insert a new entity record.</p>

                                <p>In the <mark class="customMarkStyle">Entity Field Editor</mark> screen, fields that appear can be one of the
                                    following
                                    7
                                    types:</p>
                                <ul class="listPadding">
                                    <li>Free Form Plain Text (long or short form)</li>
                                    <li>Numeric</li>
                                    <li>Date</li>
                                    <li>Dropdown</li>
                                    <li>Lookup/Autocomplete</li>
                                    <li>Checkbox</li>
                                    <li>File picker</li>
                                </ul>
                                <br>
                                <p>Each <mark class="customMarkStyle">field type</mark> will have a "Clear"
                                    <button tabindex="-1"
                                        class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                                        type="button">
                                        <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                                    </button>
                                    button to its right. This can be used to remove any existing value from it. Please remember that for any changes
                                    to be
                                    persisted on the
                                    server, you must press the save button.
                                </p>
                                <p>For example, simply or updating or clearing some values across some fields will not immediately change them. By
                                    pressing
                                    save, you commit
                                    the changes to the server and only then will it be saved and visible by all others using the application.</p>
                                <p>Each <mark class="customMarkStyle">field type</mark> is explained in some further detail in the section below.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Update Borrower Contact Details -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('UpdateClientContactDetails')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Update Contact Details</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('UpdateClientContactDetails',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('UpdateClientContactDetails',false)"
                                    matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('UpdateClientContactDetails')" id="UpdateClientContactDetails">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Prerequisites, Login and Navigate to an Account
                                    </div>

                                    <p>Your must have the Edit permission on the Address and Contact Entities for the below to work. Please contact
                                        your
                                        administrator to check your role if you can't see the Edit buttons.
                                    </p>

                                    <p>Use the top search bar to find the Borrower you want to edit. The Search Bar section above describes what
                                        details
                                        you
                                        can search with.
                                    </p>

                                    <p>From the list of search results, click on the desired one and it will open the Account Details screen. Then,
                                        click on the 'Assocations' navigation tab. This tab will show all the parties associated with the account.
                                    </p>

                                    <div class="glb_customFlexRow row-cols-1">
                                        <div class="tipsPadding">
                                            <button class="tipsConfig btn">
                                                <div style="font-size: x-small;">Tip</div>
                                            </button>
                                            &nbsp;&nbsp;&nbsp; There may be multiple clients with same user name, so make sure to check the account
                                            number
                                            matches your expectation.
                                        </div>
                                    </div>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UpdateClientContactDetails/UpdateClientContactDetails1-min.jpg"
                                            class="glb_screenShotImg">
                                    </div>

                                </div>

                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Locate a client, and update the email address
                                    </div>

                                    <p>For the selected user, click on 'Contacts' Section. This section will show the available contact data.
                                    </p>

                                    <p>Click on the email address in the 'Data' field. The Action Bar will appear at the middle bottom of the screen.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UpdateClientContactDetails/UpdateClientContactDetails2-min.jpg"
                                            class="glb_screenShotImg">
                                    </div>

                                    <p>Click on the Edit icon in the Action Bar, which looks like this:
                                        <span class="noLeftPadding hasPadding">
                                            <button
                                                class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit">
                                                </fa-icon>
                                            </button>
                                        </span>
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UpdateClientContactDetails/UpdateClientContactDetails3-min.jpg"
                                            class="glb_screenShotImg">
                                    </div>

                                    <p>Update the email address and click Save. Wait a few moments for the request to be processed.</p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UpdateClientContactDetails/UpdateClientContactDetails4-min.jpg"
                                            class="glb_screenShotImg">
                                    </div>

                                    <p>A green toast message will appear at the bottom right hand side of the screen, confirming that the email
                                        address
                                        has
                                        been updated.</p>

                                    <p>Under the Associations tab, the email address for the client will now display the new/updated email address.
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/UpdateClientContactDetails/UpdateClientContactDetails5-min.jpg"
                                            class="glb_screenShotImg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Updating Security Insurance -->
            <div *ngIf="InsuranceEditClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('InsuranceEdit')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Update Security Insurance Policy</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('InsuranceEdit',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('InsuranceEdit',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('InsuranceEdit')" id="InsuranceEdit">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>After navigating to an Account page, visit the tab named "Securities" highlighted below:
                                </p>

                                <ng-container *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('SecuritiesTab',2)"></ng-container>

                                <!-- Select an Insurance Policy -->
                                <p>
                                    Click on the Insurance Policies tab and click the chevron to expand
                                </p>

                                <ng-container
                                    *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('InsurancePolicyExpand',2)"></ng-container>

                                <!-- Edit an Insurance Policy -->
                                <p>
                                    To edit the Insurance Policy Start Date, highlight the Start Date and click the edit button
                                    <button id="BTN_EditDataRow"
                                        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit">
                                        </fa-icon>
                                    </button>

                                </p>
                                <ng-container
                                    *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('InsurancePolicyStartDate',2)"></ng-container>
                                <p>
                                    You can also edit multiple insurance fields by using edit button
                                    <button id="BTN_EditDataRow"
                                        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonMD align-self-right">
                                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']"
                                            matTooltip="Click to Edit Object/Record">
                                        </fa-icon>
                                    </button>
                                </p>
                                <ng-container
                                    *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('InsurancePolicyEdit',2)"></ng-container>

                                <ng-container
                                    *ngTemplateOutlet="ImageTemplate;context:ImageTemplateContext_Get('InsurancePolicySave',2)"></ng-container>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- FieldDefinitions -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('FieldDefinitions')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Field Types</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('FieldDefinitions',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('FieldDefinitions',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('FieldDefinitions')" id="FieldDefinitions">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <div class="subHeading2Padding text-dark" style="background-color: white;">
                                    <div class="subHeading3Title">
                                        Free Form Plain Text
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field generally allows any combination of characters or symbols. Some fields will allow longer text
                                            to be
                                            entered. </li>
                                        <li>You may insert line breaks (otherwise known as carriage return/line feed or CR/LF) by using the "Enter"
                                            button
                                            on
                                            your keyboard.
                                        </li>
                                        <li>If the text you enter is too long, you will be greeted with an error message. Please shorten the text and
                                            try
                                            again.</li>
                                        <li>This type of field can be identified by the text (T) icon located the right of the input field.</li>
                                        <li>Below is a sample. You can drag the bottom right corner of the text area to increase its size, as needed.
                                        </li>
                                    </ul>

                                    <div class="glb_customFlexRow row-cols-2 justify-content-left align-items-center">
                                        <div class="col-6" style="padding-left:0px; padding-right:0px;">
                                            <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
                                            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Free Form Plain Text
                                                Field
                                            </div>
                                            <textarea class="glb_Font textAreaHeightRegular" style="width: 100%;"
                                                placeholder="Enter a value"></textarea>
                                            <fa-icon [icon]="['fal', 'text']"
                                                class="fa-fw fa-lg floatingIcons floatingIconsLeft floatingIconsLeftRegular"
                                                style="position:relative; top:-30px; width:30px; height:0px;display: block;">
                                            </fa-icon>
                                        </div>
                                    </div>

                                    <div class="subHeading3Title">
                                        Numeric
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field allows you to enter numeric data. The input field will dictate this for you, and you won't be
                                            able
                                            to
                                            enter anything
                                            except digits. </li>
                                        <li>Some fields will have a precision (e.g. 2 decimal places only). If you face an error during save, please
                                            shorten
                                            or remove decimal
                                            places and try again.</li>
                                        <li>This field type encompasses numeric, decimal, currency, integer (whole number) and percentage values.</li>
                                        <li>Otherwise this operates the same as the Free Form Plain text input field. You can drag the bottom right
                                            corner
                                            of
                                            the
                                            text area to increase its size, as needed.</li>
                                        <li>This type of field can be identified by the calculator icon located the right of the input field.</li>
                                        <li>Below is a sample that is hooked up to a 2 decimal place field. Try to enter a new value and test it out!
                                        </li>
                                        <li>If you enter an incorrect value, please note the error message that appears in the toast notification on
                                            the
                                            bottom right of your
                                            screen. This will warn you when you exceed the precision (number of decimal places allowed).</li>
                                    </ul>

                                    <div class="glb_customFlexRow row-cols-2 justify-content-left align-items-center">
                                        <div class="col-6" style="padding-left:0px; padding-right:0px;">
                                            <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
                                            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">2 Decimal Place
                                                Numeric
                                                Field</div>

                                            <textarea class="glb_Font textAreaHeightRegular" style="width: 100%;" placeholder="Enter a value"
                                                (keydown)="TextArea_OnKeyPress($event, 'keydown', DataUnitNumericSample)"
                                                (keyup)="TextArea_OnKeyPress($event, 'keyup', DataUnitNumericSample)"></textarea>
                                            <fa-icon [icon]="['fal', 'calculator']"
                                                class="fa-fw fa-lg floatingIcons floatingIconsLeft floatingIconsLeftRegular"
                                                style="position:relative; top:-30px; width:30px; height:0px;display: block;">
                                            </fa-icon>
                                        </div>
                                    </div>

                                    <div class="subHeading3Title">
                                        Date
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field allows you to enter a date directly, using the date format DD/MM/YYYY.</li>
                                        <li>If you enter an invalid date, it will not be saved.</li>
                                        <li>This field also contains a calendar picker, please click on the <mat-icon
                                                style="vertical-align:middle; font-size:21px; display: inline !important; color: grey;"
                                                fontIcon="today">
                                            </mat-icon> icon and a calendar popup will appear. In this popup, you can
                                            navigate in between months and even years (click on the month name to expand into years).</li>
                                        <li>This type of field can be identified by the calendar icon located the right of the input field.</li>
                                        <li>Below is a sample, feel free to test it out.</li>
                                    </ul>

                                    <div class="col-6" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
                                        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Date Field</div>
                                        <mat-form-field class="glb_Font" appearance="outline"
                                            style="display: grid; padding-right:0px; padding-left:0px; padding-bottom:0px;">
                                            <input matInput class="glb_Font" [matDatepicker]="picker" #databox placeholder="Enter or choose a date">
                                            <mat-datepicker-toggle class="mat-datepicker-light-custom" matSuffix
                                                [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="subHeading3Title">
                                        Date and Time
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field allows you to enter a date with a time component, using the datetime format DD/MMMM/YYYY HH:MM
                                            AM/PM.
                                        </li>
                                        <li>This field contains a calendar picker, please click on the icon and a calendar popup will appear.</li>
                                        <li>In this popup, you can navigate in between months and even years (click on the month name to expand into
                                            years).
                                        </li>
                                        <li>You can also choose a time component in 12 hour format (AM/PM): Hours and Minutes.</li>
                                        <li>This type of field can be identified by the calendar icon located the right of the input field.</li>
                                        <li>Below is a sample, feel free to test it out.</li>
                                    </ul>

                                    <!-- Primeng datetime with time support fields -->

                                    <!-- The input box or selectors should be in a row, and span one column -->
                                    <div class="glb_customFlexRow row-cols-2 justify-content-start align-items-center">
                                        <div class="col-6 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
                                            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Sample Date Time
                                            </div>
                                            <p-calendar #calendar class="DTPInputGrid" [(ngModel)]="DataUnit.ValueDate" [showTime]="true"
                                                [showSeconds]="false" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true"
                                                [inline]="false" [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false"
                                                [dateFormat]="DTPDateFormat" [hourFormat]="12" [appendTo]="'body'" baseZIndex="15000"
                                                inputStyleClass="DTPInputStyle glb_Font" placeholder="Choose a date and time">
                                                <ng-template pTemplate="footer">
                                                    <div class="glb_customFlexRow justify-content-center">
                                                        <button
                                                            class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white"
                                                            (click)="DTP_Close(calendar)">OK</button>
                                                    </div>
                                                </ng-template>
                                            </p-calendar>
                                            <div class="glb_floatTopRightCalendar">
                                                <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button"
                                                    (click)="calendar.toggle()">
                                                    <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="subHeading3Title">
                                        Dropdown
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field will reveal a dropdown list of available options. You must choose an entry from the list.</li>
                                        <li>To remove a value, please click the clear button on the right, and then press save.</li>
                                        <li>This type of field can be identified by the downward pointing arrow at the right of the input field.</li>
                                    </ul>

                                    <div class="col-6" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
                                        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Dropdown Field</div>
                                        <mat-form-field appearance="outline">
                                            <mat-select #matSelect class="glb_Font" placeholder="Choose a value from the dropdown list"
                                                (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                                                <div *ngFor="let control of ControlData">
                                                    <mat-option class="glb_Font" value={{control.ControlGUID}}>
                                                        {{control.ControlValue}}
                                                    </mat-option>
                                                </div>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="subHeading3Title">
                                        Lookup/Autocomplete
                                    </div>

                                    <ul class="listPadding">
                                        <li>Similar to dropdown, this field will reveal a list of available options. However, you will need to supply
                                            some
                                            search terms.</li>
                                        <li>Therefore, this time you must provide a search term to look for. You can type any text into the field, and
                                            matching items
                                            will appear in a popup list. You MUST select a matching item from this list for it to register as a
                                            change.
                                        </li>
                                        <li>NOTE: If you do not select an item from the list, the previous value will remain and the field will NOT be
                                            updated
                                            when you click
                                            save.</li>
                                        <li>To remove a value, please click the clear button on the right, and then press save.</li>
                                        <li>This type of field can be identified by the magnifying glass icon at the right of the input field.</li>
                                        <li>Below is a sample autcomplete hooked up to the Suburb field. Go ahead and test it out.</li>
                                        <li>You can try searching by the suburb name, or even the postcode!</li>
                                    </ul>

                                    <div class="col-6" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
                                        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Lookup/Autocomplete
                                            (Suburb)
                                            Field
                                        </div>
                                        <mat-form-field class="glb_Font mat-autocomplete-light-custom glb_paddingZeroOffset mat-autocomplete-custom"
                                            appearance="outline">
                                            <input class="glb_Font" #inputVal type="text" matInput [matAutocomplete]="auto"
                                                placeholder="Enter a value to search">
                                            <mat-icon matSuffix>search</mat-icon>
                                            <mat-autocomplete class="glb_Font glb_FontSize" #auto="matAutocomplete"
                                                [displayWith]="AutoComplete_GetPrettyName">
                                                <div *ngFor="let control of AutoComplete_ApplyFilter(DataUnit, 'Search', inputVal.value)">
                                                    <mat-option class="glb_Font" [value]=control>
                                                        {{control.ControlValue}}
                                                    </mat-option>
                                                </div>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>

                                    <div class="subHeading3Title">
                                        Checkbox
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field has only two options - true or false.</li>
                                        <li>Tick or untick the box to switch the value. Note that it will not be saved immediately - just like any
                                            other
                                            field, you will need
                                            to hit the save button for that to be persisted on the server.</li>
                                        <li>Below is a sample checkbox. Go ahead and test it out.</li>
                                    </ul>

                                    <div class="col-6" style="padding-left:0px; padding-right:0px;">
                                        <!-- for boolean this can span the entire row, so left align it. (there is no clear button) -->
                                        <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 0.6em;">
                                            <mat-checkbox class="glb_Font mat-checkbox-light-custom" #checkBox>
                                                Sample Checkbox Field</mat-checkbox>
                                        </div>
                                    </div>

                                    <div class="subHeading3Title">
                                        File Picker
                                    </div>

                                    <ul class="listPadding">
                                        <li>This field allows you to browse to and select a file to upload.</li>
                                        <li>Currently valid filetypes are: {{ValidFileTypes_Get()}}</li>
                                        <li>Invalid file types will be rejected.</li>
                                        <li>Below is a sample. Go ahead and test it out. (You won't be able to upload anything here in this test
                                            though!)
                                        </li>
                                    </ul>

                                    <div class="col-6" style="padding-left:0px; padding-right:0px; padding-bottom: 5px;">
                                        <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Choose a file</div>
                                        <p-fileUpload chooseIcon="pi pi-folder" [multiple]="false" maxFileSize="15728640" [showCancelButton]="false"
                                            [showUploadButton]="false" chooseLabel=" " class="p-customFileUpload">
                                            <ng-template pTemplate="content">
                                            </ng-template>
                                        </p-fileUpload>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Borrower XCHANGE User Management -->
            <div *ngIf="BorrowerUserManagementClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('BorrowerUserManagement')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Borrower User Management</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('BorrowerUserManagement',true)"
                                    matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('BorrowerUserManagement',false)"
                                    matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('BorrowerUserManagement')" id="BorrowerUserManagement">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <div class="subHeading2Padding">
                                    <div class="subHeading2Title">
                                        Applies to Individual Borrowers only
                                    </div>

                                    <p>Please note that the borrower xhange details will be available for the individual borrowers only. The company
                                        borrowers
                                        do not have these details set up on borrower xchange. If your role allows you access to Borrower XCHANGE User
                                        Management, these details are available under "Client Custom
                                        Fields" for individuals.
                                    </p>
                                </div>

                                <div class="subHeading2Padding">
                                    <p>After navigating to any Account page, visit the tab named "Associations" highlighted below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/BorrowerUserManagement0-min.jpg" class="glb_screenShotImg">
                                    </div>

                                    <p>Click the "Client Custom Fields" title. The borrower xchange relevent fields start with "xChangeV6" as
                                        highlighted
                                        below:
                                    </p>

                                    <div
                                        class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
                                        <img src="/LenderClient/assets/screenshots/BorrowerUserManagement1.jpg" class="glb_screenShotImg">
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Borrower
                                            LoginID</div>
                                        <p>Login ID/User Number for logging into Borrower XChange.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Login
                                            Active</div>
                                        <p>Indicates if the borrower's login is active or disabled. Please note that the customers borrower xChangev6
                                            access
                                            will automatically be disabled 30 days after all loans have been discharged.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Account
                                            Registered</div>
                                        <p>Indicates if the borrower has completed registration.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Terms
                                            and
                                            Conditions
                                            Accepted
                                        </div>
                                        <p>Indicates if the borrower has accepted the terms and conditions.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Security
                                            Questions Completed
                                        </div>
                                        <p>Indicates if the borrower has answered all three security questions.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">xChangev6
                                            Account
                                            Locked</div>
                                        <p>Indicates if the login is currently locked. It gets locked after 5 consecutive failed logins within 1
                                            hour. It automatically becomes active again afer 1 hour of the last failed attempt.
                                        </p>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">Minimum
                                            Requirements
                                            To Access Account</div>
                                        <div>
                                            <ul class="listPadding">
                                                <li>Register using the Login ID/User Number and the registration link provided on the welcome letter
                                                    pack
                                                </li>
                                                <li>Accept Terms and Conditions</li>
                                                <li>Answer all 3 security questions</li>
                                                <li>Account must not be locked</li>
                                                <li>Account must be active</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="glb_paddingBottomSM">
                                        <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile subHeading2Title">Reset
                                            Password
                                        </div>
                                        <p>To reset a borrower password, please click the "Reset Password" button next to the "xChangeV6 Borrower
                                            LoginID". The login must be active in order to reset the password.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Lender User Management -->
            <div *ngIf="LenderUserManagementClaim" class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('UserManagement')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">Lender User Management</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('UserManagement',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('UserManagement',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('UserManagement')" id="UserManagement">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <div class="glb_paddingBottomSM">
                                    <p>If your role allows you access to User Management, at the top right of the page you will see the text "User
                                        Management".
                                        <br>Click on this to navigate to it. At this page you can search for users, modify their properties, create
                                        new
                                        users
                                        and perform some simple User Management tasks.
                                    </p>

                                    <p>
                                        From the main page, you can search for users by using the input filter box. Click on the "Filter User" input
                                        box
                                        and
                                        start typing. You can search on First Name, Last Name, email address or Login ID. Clicking on a user will
                                        launch a
                                        screen showing further details, and some buttons that let you performs actions and modify it.
                                    </p>
                                </div>
                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Clone a User</div>
                                    <p>To clone a user, click the "Clone" button
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone">
                                            </fa-icon>
                                        </button>
                                        located at the top right. The input fields will now become unlocked, and you can input the First Name, Last
                                        Name,
                                        Email Address and Mobile Number of the new user.
                                        <br>
                                        The user role and user link type filters will be copied from the user being cloned.
                                        <br>
                                        Click the "Create User" button to complete the process. If you change your mind, please press "Cancel".
                                        <br>
                                        Please note that cloning from a user also sends the invitation to the new user automatically.
                                    </p>
                                </div>
                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Edit a User's Properties</div>
                                    <p>To edit the properties of a user, you must first click the "Edit" button
                                        <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit">
                                            </fa-icon>
                                        </button>
                                        located at the top right. The input fields will now become unlocked, and you can edit the First Name, Last
                                        Name,
                                        Email
                                        Address, two factor options (Google Authenticator), or disable the user.
                                        <br>
                                        To save any changes that you made, click on "Save Changes". If you don't want to change anything, please press
                                        "Discard changes".
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Disable a User</div>
                                    <p>An example use case where this would be necessary is when a staff member leaves the company. To disable their
                                        access,
                                        navigate to the user, Click Edit, tick the "Account Disabled" checkbox, and save the changes.
                                        <br>The user will no longer will be able to login, and any existing sessions will immediately expire and cease
                                        to
                                        operate.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Lost Mobile Device</div>
                                    <p>Sometimes a user will be unable to login, for example their mobile device has been lost, or stolen. To allow
                                        the
                                        user
                                        to access their account, you may click the "Resend Two Factor Authentication Setup" button.
                                        <br>This will send the user an email with new Two Factor Authentication Setup Instructions, and they can set
                                        this
                                        up
                                        on a new mobile device.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Reset User Password/Forgot LoginID
                                    </div>
                                    <p>While users can request a password reset on their own, in the case they forget their email address or LoginID
                                        and
                                        therefore are unable to reset their own password, you can do it for them.
                                        <br>Click the "Reset Password" button. This will send the user an email with a link that they can follow to
                                        update
                                        their password.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">User Roles - Assign/Reassign a
                                        different
                                        Role</div>
                                    <p>A User can be given a specific role, depending on what kind of access level and data you want them to see. In
                                        this
                                        section, you can review any existing role that the user has, and reassign another role.
                                        <br>You can assign a single role per user.
                                        <br>Click the plus icon to search for and attach a different role.
                                        <br>Note that the roles themselves are managed by AMAL, if you need to create or modify a role please contact
                                        AMAL
                                        support.
                                    </p>
                                    <p>There is a maximum limit to the number of users that can be assigned to the Lender Administrator role.
                                        <br>Once you reach the limit, please reassign an existing Lender Admin user to another role to allow you to
                                        assign
                                        the
                                        new admin.
                                        <br>If you require more admins than the current maximum, please contact the AMAL Servicing Team.
                                        <br>NOTE: If you disable a Lender Admin user, please assign that user to a different role (for example: Lender
                                        Standard Role) to free up the admin spots.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">User Link Type Filters</div>
                                    <p>In addition to a Role, each User MUST be given at least ONE link type filter. This is another layer of
                                        granularity
                                        that allows you to restrict the accounts that the user can see and have access to. <br>
                                        In general, if you want the user to have access to all accounts, you would utilize a Link Type of
                                        "Loan\Lender"
                                        and
                                        the Target Client would equal the value of your Lender, e.g. "Lender ABC Pty Ltd". This is a typical scenario
                                        that
                                        would be used in most cases.
                                        <br>NOTE: If a user has no Link Type Filters, it will not be able to see or access any accounts.
                                    </p>
                                    <p>
                                        In some cases, you may want to restrict the accounts that this user can see. For example, if the user was a
                                        Originator, and you only wanted the user to access accounts that they originated, you would add a link type
                                        with
                                        value
                                        "Loan\Originator", with the target client equalling the client that you want it restricted to, e.g.
                                        "Originator
                                        ABC
                                        Pty Ltd".
                                    </p>
                                    <p>
                                        Note that multiple link type filters are supported - so if you wanted a user to see a combination of 2
                                        Originators, or
                                        1 Originator and 1 Mortgage Manager, this is possible by stacking multiple filters in an additive fashion.

                                        For example, to see "Originator ABC" and "Mortgage Manager XYZ" you would create two link type filters, each
                                        one
                                        with
                                        the appropriate link type and target client.
                                    </p>
                                    <p>
                                        An example use case for this feature is with external users that are outside of your organization boundaries,
                                        and
                                        therefore you must limit the accounts that they have access to. Please contact AMAL support as you will likely
                                        need to have Custom Roles created for these users, in addition to Link Type Filters.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Adding a new User</div>
                                    <p>Back on the main screen, there is a "Add New User" <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'user-plus']" matTooltip="Add new user">
                                            </fa-icon>
                                        </button> button on the top right that allows you to create a new User.
                                        <br>
                                        Fill out the required fields. First Name, Last Name and Email Address are absolutely necessary. Two factor
                                        authentication is mandatory for all accounts to increase security.
                                        <br>
                                    </p>
                                    <p>
                                        Once you have created the user, please navigate to the user from the filter/search box.
                                        <br>From the User properties screen, you must attach a relevant role, and also add a link type filter. See the
                                        notes
                                        above to understand what would be relevant here.
                                    </p>
                                    <p>
                                        After you have attached a role and at least one link type filter, you are ready to invite the user. Click the
                                        "Invite
                                        User" Button.
                                        <br>
                                        The user will recieve at the email address instructions on how to register, create a password, accept terms
                                        and
                                        conditions, and instructions for Two Factor Authentication setup (if applicable).
                                        <br>For security reasons, the invitation will only be active for 2 days. If the user does not successfully
                                        register in
                                        this period, the invitation will lapse. You can resend the invitation if needed by clicking the "Invite User"
                                        button
                                        again.
                                    </p>
                                </div>

                                <div class="glb_paddingBottomSM">
                                    <div class="glb_paddingBottomSM glb_paddingTopSM glb_TitleMD glb_TitleMDMobile">Downloading a User Report</div>
                                    <p>Back on the main screen, there is a "Download" <button
                                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
                                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                                            </fa-icon>
                                        </button> button. Clicking this will download a csv file containing a list of the currently filtered users.
                                        <br>There are some fields here that can help you perform user administration.
                                    </p>
                                    <p>
                                    <p>
                                        The Disabled column represents whether or not the user can log in to the system.
                                        <br>The Last Password Change Date column represents the last time that user changed their password.
                                    </p>

                                    <p>
                                        The Password Expired column indicates if this users' password is currently expired. If true, this user cannot
                                        log in, and must reset their password (either requesting themselves via "Forgot Password", or via an Admin).
                                        <br>Passwords expire every 42 days.
                                    </p>

                                    <p>
                                        The Password Reset Locked column indicates if this users' password is expired for a long enough period (over
                                        84 days) such that resetting their own password is now disabled. The user can no longer use the "Forgot
                                        Password"
                                        feature, and must reset their password by requesting a Lender admin to do it for them.
                                        <br>This is to ensure that a login that has expired for a long period is not able to recover access themselves
                                        -
                                        they need to verify continued access by speaking to a Lender admin first.
                                    </p>

                                    <p>
                                        We recommend a periodic review of users that have Password Expired = True and Password Reset Locked = True.
                                        <br>These are unused for a long period of time, e.g. users that no longer need access or have left the
                                        company.
                                        While they cannot log in, we suggest that Lender admins disable these users for tracking and audit purposes.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- API Documentation (Swagger) -->
            <div class="glb_customFlexRow">
                <div class="collapsibleOuterBorder">
                    <div class="collapsibleInnerBottomBorder" (click)="TemplateID_Toggle('APIDocumentation')">
                        <div class="collapsibleHeading collapsibleOuterContentPadding glb_customFlexRow row-cols-2">
                            <div class="glb_TitleLG glb_TitleLGMobile glb_fontColorSkin">API Documentation (for developers)</div>
                            <div class="glb_customFlexRow justify-content-end align-items-center">
                                <fa-icon [icon]="['fal', 'chevron-down']" [ngClass]="TemplateID_GetCSS('APIDocumentation',true)" matTooltip="Expand">
                                </fa-icon>
                                <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('APIDocumentation',false)" matTooltip="Hide">
                                </fa-icon>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="TemplateID_GetCSS('APIDocumentation')" id="APIDocumentation">
                        <div class="collapsibleInnerContentPadding">
                            <!-- content goes here -->
                            <div>
                                <p>If you would like to connect your apps to XCHANGE, organize your own integration or create some custom flows,
                                    please
                                    contact the AMAL XV8 support team at <a class="customLink"
                                        href="mailto:xchangev8support@amal.com.au">xchangev8support&#64;amal.com.au</a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <br>
                <br>
                <br>
                {{AppName}} AMAL User Guide
                <br>
                <br>
            </div>
        </div>
    </div>
</div>
<!-- Template for screenshots section -->
<ng-template #ImageTemplate let-screenshotID="screenshotID" let-fileExtension="fileExtension">
    <div class="glb_customFlexRow justify-content-center glb_paddingLeft glb_paddingRight glb_paddingBottomLG glb_paddingTopMD">
        <img src="{{screenshotID}}.{{fileExtension}}" class="glb_screenShotImg">
    </div>
</ng-template>