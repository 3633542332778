<div id="PG_SearchBar" class="glb_customFlexRow row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2">
    <!-- app title -->
    <div *ngIf="!HitErrorPage" class="text-start xchangeTitle">
        <!-- nav allowed when not on error page -->
        <a [routerLink]="['/' + navigationUrls.Home]">{{appName}}</a> {{environmentName}}
        <span *ngIf="IsReadOnlyMode" class="btn btn-warning glb_paddingBoxZeroOffset">Read Only</span>
    </div>

    <!-- app title -->
    <div *ngIf="HitErrorPage" class="text-start xchangeTitle">
        <!-- dont allow nav from error page -->
        <a>{{appName}}</a> {{environmentName}}
    </div>

    <!-- this is the top right header navbar for account details -->
    <div *ngIf="!invisibleSearchBar">
        <nav class="navbar navbar-dark justify-content-end navbarPadding col-lg-12">
            <div *ngIf="ShowNavBarButton" class="glb_boxedPaddingNarrow col-12 glb_customFlexRow justify-content-end">
                <button class="navbar-toggler" type="button" (click)="NavBarVisibilty_Toggle()">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>

            <div *ngIf="ShowNavBar" id="NavBarContent" class="glb_Font justify-content-end text-end">
                <div class="glb_customFlexRow flex-nowrap">
                    <span class="nav-item">
                        <a id="LNK_UserProfile" class="nav-link text-white glb_boxedPaddingNarrow" [routerLink]="['/' + navigationUrls.UserProfile]"
                            [state]="{Link: 'UserProfile'}" routerLinkActive="active">Hello {{userDisplayName}}
                        </a>
                    </span>

                    <span class="nav-item">
                        <a class="glb_Font nav-link text-white glb_boxedPaddingNarrow glb_hoverHand" (click)="UserGuide_Launch()"
                            routerLinkActive="active">User
                            Guide
                        </a>
                    </span>

                    <span class="nav-item" *ngIf="enableUserManagement">
                        <a class="glb_Font nav-link text-white glb_boxedPaddingNarrow" [routerLink]="['/' + navigationUrls.UserManagement]"
                            [state]="{Link: 'UserManagement'}" routerLinkActive="active">User Management
                        </a>
                    </span>

                    <span class="nav-item" *ngIf="enableRoleManagement">
                        <a class="glb_Font nav-link text-white glb_boxedPaddingNarrow" [routerLink]="['/' + navigationUrls.RoleManagement]"
                            [state]="{Link: 'RoleManagement'}" routerLinkActive="active">Role Management
                        </a>
                    </span>

                    <span class="nav-item" *ngIf="enableLenderConfiguration">
                        <a class="glb_Font nav-link text-white glb_boxedPaddingNarrow" [routerLink]="['/' + navigationUrls.LenderConfigurations]"
                            [state]="{Link: 'LenderConfigurations'}" routerLinkActive="active">Lender Configuration
                        </a>
                    </span>

                    <span class="nav-item">
                        <a id="LNK_Logout" class="glb_Font nav-link text-white glb_boxedPaddingNarrow glb_hoverHand" (click)="Logout()">Logout
                        </a>
                    </span>
                </div>
            </div>
        </nav>
    </div>
</div>


<div class="glb_customFlexRow">
    <!-- this is the search form -->
    <div class="col-12 glb_paddingZeroOffset">
        <form class="navbar-form navbar-search-form active" role="search" [formGroup]="searchForm" (onsubmit)="SearchKeywords()">
            <div class="input-group input-group-lg">
                <span *ngIf="!HitErrorPage" class="align-self-center iconButtonPadding">
                    <button type="button"
                        class="glb_clickableIconButtonXL glb_clickableIconButtonStyle glb_clickableIconButtonColorDark align-self-right"
                        (click)="NavToURLWithState('/' + navigationUrls.Home, 'ReturnToHome')">
                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'home']" matTooltip="Home">
                        </fa-icon>
                    </button>
                </span>

                <span *ngIf="!invisibleSearchBar" class="align-self-center iconButtonPadding">
                    <button type="button"
                        class="glb_clickableIconButtonXL glb_clickableIconButtonStyle glb_clickableIconButtonColorDark align-self-right"
                        (click)="NavToURLWithState('/' + navigationUrls.Dashboard, 'ReturnToDashboard')">
                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'chart-bar']" matTooltip="Dashboard">
                        </fa-icon>
                    </button>
                </span>

                <span *ngIf="!invisibleSearchBar" class="align-self-center iconButtonPaddingLeftAndRight">
                    <button type="button"
                        class="glb_clickableIconButtonXL glb_clickableIconButtonStyle glb_clickableIconButtonColorDark align-self-right"
                        (click)="NavToURLWithState('/' + navigationUrls.AccountSearch, 'ReturnToSearch')">
                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'search']" matTooltip="Back to Search Results">
                        </fa-icon>
                    </button>
                </span>

                <!-- disable this after a loan is clicked (i.e. looking at a loan) -->
                <input id="INP_AccountSearch" *ngIf="!invisibleSearchBar" [attr.disabled]="hideSearchBar ? true : null" type="search"
                    class="form-control glb_Font" [ngClass]="{'glb_LightTextColor' : !invisibleSearchBar}" formControlName="search"
                    (keyup)="SearchKeywords()" (focus)="FocusKeywords()" placeholder="Search accounts..." />

                <div *ngIf="!invisibleSearchBar" class="input-group-text btn glb_standardButton btn-default ">
                    <span type="button" [attr.disabled]="hideSearchBar ? true : null" class="glb_Font" type="button" id="searchButton"
                        (click)="SearchKeywords()">Search</span>
                </div>
            </div>
        </form>
    </div>
</div>