<div #LenderConfigurationModal id="LenderConfigurationModal" class="glb_boxedPadding glb_formBGColor text-white glb_Font">

  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
      <button [disabled]="editEnabled || TaskNotifier.EditMode" tabindex="30"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="LenderConfig_CloseModal()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
      </button>
    </div>
  </div>
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="header-padding glb_customFlexRow col-12 glb_paddingZeroOffset">
      <div class="col-8 glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
          {{chosenLenderConfigClone.LenderName}}
        </div>
      </div>
      <div class="glb_customFlexRow col-4 justify-content-end">
        <div *ngIf="editEnabled" class="buttonPadding">
          <span style="padding-left:0.375em;">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="LenderConfig_Update()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save your changes">
              </fa-icon>
            </button>
          </span>
        </div>
        <!--A delete button -->
        <div class="buttonPadding">
          <span style="padding-left:0.375em;">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="LenderConfig_Delete()" [disabled]="editEnabled">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete this Lender Configuration">
              </fa-icon>
            </button>
          </span>
        </div>
        <div *ngIf="editEnabled" class="buttonPadding">
          <span style="padding-left:0.375em;">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="LenderConfig_DiscardChanges()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'ban']" matTooltip="Click to Discard your changes">
              </fa-icon>
            </button>
          </span>
        </div>
        <div *ngIf="!editEnabled" class="buttonPadding">
          <span style="padding-left:0.375em;">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
              (click)="LenderConfig_EnableEditing()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this Lender Configuration">
              </fa-icon>
            </button>
          </span>
        </div>
      </div>

      <!-- Nav Tabs for Lender configurations-->
      <div id="LBL_NavBarID" class="glb_Font col-12" style="padding-top: 10px;">
        <div class="nav nav-tabs glb_customFlexRow row-cols-2 row-cols-sm-4 row-cols-md-6 row-cols-lg-9 col-lg-12" style="padding-right:0px;">
          <button class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" (click)="NavBarItem_Clicked($event)"
            [ngClass]="CurrentNavBarItem_Get('BTN_StandardConfig')" id="BTN_StandardConfig">
            <div class="glb_customFlexRow justify-content-center align-self-center col-12" id="BTN_StandardConfig">
              <div class="glb_TitleSmall glb_TitleSmallMobile" id="BTN_StandardConfig">
                Standard
              </div>
            </div>
          </button>
          <button class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" (click)="NavBarItem_Clicked($event)"
            [ngClass]="CurrentNavBarItem_Get('BTN_TaskConfig')" id="BTN_TaskConfig">
            <div class="glb_customFlexRow justify-content-center align-self-center col-12" id="BTN_TaskConfig">
              <div class="glb_TitleSmall glb_TitleSmallMobile" id="BTN_TaskConfig">
                Task
              </div>
            </div>
          </button>
          <button class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" (click)="NavBarItem_Clicked($event)"
            [ngClass]="CurrentNavBarItem_Get('BTN_EmailConfig')" id="BTN_EmailConfig">
            <div class="glb_customFlexRow justify-content-center align-self-center col-12" id="BTN_EmailConfig">
              <div class="glb_TitleSmall glb_TitleSmallMobile" id="BTN_EmailConfig">
                Email
              </div>
            </div>
          </button>
          <button class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" (click)="NavBarItem_Clicked($event)"
            [ngClass]="CurrentNavBarItem_Get('BTN_TaskNotifierConfig')" id="BTN_TaskNotifierConfig">
            <div class="glb_customFlexRow justify-content-center align-self-center col-12" id="BTN_TaskNotifierConfig">
              <div class="glb_TitleSmall glb_TitleSmallMobile" id="BTN_TaskNotifierConfig">
                Task Notifier
              </div>
            </div>
          </button>
        </div>
      </div>

      <!-- Standard Config tab -->
      <div [ngClass]="CurrentNavItem_ForceRemove('BTN_StandardConfig')" class="col-12">
        <div [ngClass]="CurrentNavItem_Show('BTN_StandardConfig')" class="col-12">
          <div class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12">
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Max Number of Lender Administrators</div>
                <input #inputMaxLenderAdmins tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="Max Number of Lender Administrators" [(ngModel)]="chosenLenderConfigClone.MaxLenderAdminCount"
                  [disabled]="!editEnabled" [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div>
                <div class="text-white glb_inputFieldLabel glb_cTaskConfigustomFlexRow">Lender Industry Type</div>
                <mat-form-field tabindex="14" appearance="fill" class="glb_Font glb_paddingZeroOffset">
                  <mat-select #matSelect class="glb_Font glb_formInputBoxDropDown glb_skinLightOutline text-dark"
                    [(value)]="chosenLenderConfigClone.LenderIndustryType" placeholder="Choose a value from the dropdown list"
                    [disabled]="!editEnabled" [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }"
                    (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                    <!-- <div *ngFor="let control of ControlData"> -->
                    <div *ngFor="let control of ControlData_Filter('Lender Industry Type', '')">
                      <mat-option class="glb_Font" value={{control.ControlGUID}}>
                        {{control.ControlValue}}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Task Config tab -->
      <div [ngClass]="CurrentNavItem_ForceRemove('BTN_TaskConfig')" class="col-12">
        <div [ngClass]="CurrentNavItem_Show('BTN_TaskConfig')" class="col-12">
          <div class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12">

            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Default Assignee</div>
                <mat-form-field appearance="outline" class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                  <input #inputDefaultAssignee tabindex="11" class="glb_formInputBox glb_skinLightOutline text-dark glb_Font" type="text"
                    placeholder="Default Assignee" [(ngModel)]="defaultAssignee_AutoCompleteModel" [disabled]="!editEnabled"
                    [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }" (click)="AutoComplete_Client_ApplyFilter(defaultAssignee_AutoCompleteRequest, 'Search',
                          insertLenderName.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultAssignee_AutoCompleteRequest, 'Search',
                          insertLenderName.value, 'Employee', true)" #insertLenderName type="text" matInput
                    [matAutocomplete]="defaultAssignee_MatAutoComplete">

                  <mat-autocomplete class="" #defaultAssignee_MatAutoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                    (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultAssignee_AutoCompleteModel)" [panelWidth]="500">
                    <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultAssignee_AutoCompleteRequest, 'Search',
                            insertLenderName.value, 'Employee')">
                      <mat-option class="glb_Font" [value]=control>
                        {{control.ControlValue}}
                      </mat-option>
                    </div>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>

            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Default SLA Days</div>
                <input #inputDefaultSLA tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="Default SLA Days" [(ngModel)]="chosenLenderConfigClone.DefaultSLADays" [disabled]="!editEnabled"
                  [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <!-- Sample code to add a chevron with sliding row. this is the CREATE section -->
            <div class="glb_customFlexRow row-cols-2 col-12 justify-content-end align-items-center glb_paddingTopMD">
              <div class="glb_formBGColor glb_TitleMD glb_fontColorSkin justify-content-start text-start glb_Font glb_paddingBottomSM">
                Task Type Configurations
              </div>
              <div class="glb_customFlexRow justify-content-end">
                <a (click)="TemplateID_Toggle('LenderConfigTaskAssignee_CreateSection')" #LenderConfigTaskAssignee_CreateSection
                  class="glb_hoverHand justify-content-end glb_customFlexRow col-2">
                  <fa-icon [icon]="['fal', 'plus']" class="glb_fixedSizeIcons"
                    [ngClass]="TemplateID_GetCSS('LenderConfigTaskAssignee_CreateSection',true)" style="color: white;"
                    (click)="LenderConfigTaskAssignee_CreateSection_Expand()" matTooltip="Expand">
                  </fa-icon>
                  <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('LenderConfigTaskAssignee_CreateSection',false)"
                    class="glb_fixedSizeIcons" style="color: white;" matTooltip="Hide">
                  </fa-icon>
                </a>
              </div>
            </div>

            <!-- Linked create section -->
            <div id="LenderConfigTaskAssignee_CreateSection" [ngClass]="TemplateID_GetCSS('LenderConfigTaskAssignee_CreateSection')" class="col-12"
              #LenderConfigTaskAssignee_CreateForm style="padding-bottom: 15px;">
              <!-- Sliding content - to create a new record -->
              <div class="card-body-bg card-body-lesspadding">
                <div class="row-cols-1 glb_customFlexRow align-items-center">

                  <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
                    <div class="">
                      <div class="text-white glb_inputFieldLabel glb_customFlexRow">Task Type</div>
                      <mat-form-field appearance="outline"
                        class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                        <input #insertLenderConfigTaskType tabindex="11" class="glb_formInputBox glb_skinLightOutline text-dark glb_Font" type="text"
                          placeholder="Search for a new task type" [(ngModel)]="defaultTaskType_AutoCompleteModel" (click)="AutoComplete_Client_ApplyFilter(defaultTaskType_AutoCompleteRequest, 'Search',
                                                      insertLenderConfigTaskType.value, 'Task Type', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultTaskType_AutoCompleteRequest, 'Search',
                                                      insertLenderConfigTaskType.value, 'Task Type', true)" type="text" matInput
                          [matAutocomplete]="defaultTaskType_MatAutoComplete">

                        <mat-autocomplete class="" #defaultTaskType_MatAutoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                          (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultTaskType_AutoCompleteModel)"
                          [panelWidth]="500">
                          <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultTaskType_AutoCompleteRequest, 'Search',
                                                          insertLenderConfigTaskType.value, 'Task Type')">
                            <mat-option class="glb_Font" [value]=control>
                              {{control.ControlValue}}
                            </mat-option>
                          </div>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
                    <div class="">
                      <div class="text-white glb_inputFieldLabel glb_customFlexRow">Default Assignee</div>
                      <mat-form-field appearance="outline"
                        class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                        <input #insertLenderConfigTaskAssigneeName tabindex="11" class="glb_formInputBox glb_skinLightOutline text-dark glb_Font"
                          type="text" placeholder="Default Assignee" [(ngModel)]="defaultTaskTypeAssignee_AutoCompleteModel" (click)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAssigneeName.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAssigneeName.value, 'Employee', true)" type="text" matInput
                          [matAutocomplete]="defaultTaskTypeAssignee_MatAutoComplete">

                        <mat-autocomplete class="" #defaultTaskTypeAssignee_MatAutoComplete="matAutocomplete"
                          [displayWith]="AutoComplete_GetPrettyName"
                          (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultTaskTypeAssignee_AutoCompleteModel)"
                          [panelWidth]="500">
                          <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultTaskTypeAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAssigneeName.value, 'Employee')">
                            <mat-option class="glb_Font" [value]=control>
                              {{control.ControlValue}}
                            </mat-option>
                          </div>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
                    <div class="">
                      <div class="text-white glb_inputFieldLabel glb_customFlexRow">Alternate Assignee</div>
                      <mat-form-field appearance="outline"
                        class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                        <input #insertLenderConfigTaskAlternateAssigneeName tabindex="11"
                          class="glb_formInputBox glb_skinLightOutline text-dark glb_Font" type="text" placeholder="Alternate Assignee"
                          [(ngModel)]="defaultTaskTypeAlternateAssignee_AutoCompleteModel" (click)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAlternateAssigneeName.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAlternateAssigneeName.value, 'Employee', true)" type="text" matInput
                          [matAutocomplete]="defaultTaskTypeAlternateAssignee_MatAutoComplete">

                        <mat-autocomplete class="" #defaultTaskTypeAlternateAssignee_MatAutoComplete="matAutocomplete"
                          [displayWith]="AutoComplete_GetPrettyName"
                          (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultTaskTypeAlternateAssignee_AutoCompleteModel)"
                          [panelWidth]="500">
                          <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssignee_AutoCompleteRequest, 'Search',
                                insertLenderConfigTaskAlternateAssigneeName.value, 'Employee')">
                            <mat-option class="glb_Font" [value]=control>
                              {{control.ControlValue}}
                            </mat-option>
                          </div>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
                    <div class="glb_paddingBottomSM">
                      <div class="text-white glb_inputFieldLabel glb_customFlexRow">SLA Days</div>
                      <input class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="SLA Days"
                        [(ngModel)]="TaskTypeSLACreate">
                    </div>
                  </div>

                  <!-- Blank is the last column -->
                  <div class="glb_customFlexRow justify-content-end align-items-center col-12">
                    <!-- Save button -->
                    <div class="row glb_customFlexRow justify-content-center align-items-center">
                      <!-- //put this span inside -->
                      <span *ngIf="!isCreatingLenderConfig" style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                          (click)="LenderConfigTaskAssignee_Create()">
                          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']"
                            matTooltip="Click to Insert a New Task Type Assignee for this Lender">
                          </fa-icon>
                        </button>
                      </span>
                      <!-- Lets stick a little spinner here, and switch it out when the button is pressed -->
                      <span *ngIf="isCreatingLenderConfig" style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right">
                          <fa-icon class="fa-fw glb_fastSpin glb_fixedSizeIcons" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                          </fa-icon>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Now lets have a div that shows all the task type assignee items. -->
            <div class="glb_customFlexRow col-12 row-cols-1">
              <!-- Stick a border around it -->
              <div class="glb_allBordersLight glb_PaddingHorizontalSM">

                <!--We need a header row -->
                <div class="glb_customFlexRow glb_paddingTopSM row-cols-6">
                  <div class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin">
                    Task Type
                  </div>
                  <div class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin">
                    Default Assignee
                  </div>
                  <div class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin">
                    Alternate Assignee
                  </div>
                  <div class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin">
                    Alternate Mode
                  </div>
                  <div class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin">
                    SLA Days
                  </div>
                  <div class="glb_customFlexRow glb_paddingBottomSM">
                    <!-- Empty row for buttons -->
                  </div>
                </div>

                <div *ngIf="isRetrievingConfigTasks">
                  <div class="col">
                    <div class="text-white glb_customFlexRow justify-content-center glb_paddingTopMD glb_paddingBottomMD">
                      <div class="spinner-border glb_spinnerMD"></div>
                    </div>
                  </div>
                </div>

                <!-- Loop for the values here -->
                <div class="glb_customFlexRow align-items-center row-cols-6" *ngFor="let taskTypeAssignee of lenderConfigTaskTypeAssignee">
                  <div class="glb_customFlexRow align-items-center glb_paddingTopSM glb_paddingBottomSM">
                    {{taskTypeAssignee.DefaultTaskTypeName}}
                  </div>

                  <div class="align-items-center">
                    <!-- We have a choice here. do we show the label, or the input box for editing? -->
                    <div *ngIf="!taskTypeAssignee.AllowEditing">
                      {{taskTypeAssignee.DefaultAssigneeName}}
                    </div>
                    <div *ngIf="taskTypeAssignee.AllowEditing">
                      <div class="text-white glb_customFlexRow align-items-center row-cols-1 justify-content-left col-12 glb_paddingZeroOffset">
                        <div class="">
                          <!-- <div class="text-white glb_inputFieldLabel glb_customFlexRow">Assignee</div> -->
                          <mat-form-field appearance="outline"
                            class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                            <input #updateLenderConfigTaskAssigneeName tabindex="11" class="glb_formInputBox glb_skinLightOutline text-dark glb_Font"
                              type="text" placeholder="Default Assignee" [(ngModel)]="defaultTaskTypeAssigneeUpdate_AutoCompleteModel" (click)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAssigneeName.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAssigneeName.value, 'Employee', true)" type="text" matInput
                              [matAutocomplete]="defaultTaskTypeAssignee_MatAutoComplete">

                            <mat-autocomplete class="" #defaultTaskTypeAssignee_MatAutoComplete="matAutocomplete"
                              [displayWith]="AutoComplete_GetPrettyName"
                              (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultTaskTypeAssigneeUpdate_AutoCompleteModel)"
                              [panelWidth]="500">
                              <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultTaskTypeAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAssigneeName.value, 'Employee')">
                                <mat-option class="glb_Font" [value]=control>
                                  {{control.ControlValue}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="align-items-center">
                    <!-- We have a choice here. do we show the label, or the input box for editing? -->
                    <div *ngIf="!taskTypeAssignee.AllowEditing">
                      {{taskTypeAssignee.AlternateAssigneeName}}
                    </div>
                    <div *ngIf="taskTypeAssignee.AllowEditing">
                      <div class="text-white glb_customFlexRow align-items-center row-cols-1 justify-content-left col-12 glb_paddingZeroOffset">
                        <div class="">
                          <!-- <div class="text-white glb_inputFieldLabel glb_customFlexRow">Assignee</div> -->
                          <mat-form-field appearance="outline"
                            class="glb_Font glb_paddingZeroOffset mat-autocomplete-dark-custom mat-autocomplete-custom">
                            <input #updateLenderConfigTaskAlternateAssigneeName tabindex="11"
                              class="glb_formInputBox glb_skinLightOutline text-dark glb_Font" type="text" placeholder="Default Assignee"
                              [(ngModel)]="defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel" (click)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAlternateAssigneeName.value, 'Employee', true)" (keydown)="AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAlternateAssigneeName.value, 'Employee', true)" type="text" matInput
                              [matAutocomplete]="defaultTaskTypeAssignee_MatAutoComplete">

                            <mat-autocomplete class="" #defaultTaskTypeAssignee_MatAutoComplete="matAutocomplete"
                              [displayWith]="AutoComplete_GetPrettyName"
                              (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteModel)"
                              [panelWidth]="500">
                              <div *ngFor="let control of AutoComplete_Client_ApplyFilter(defaultTaskTypeAlternateAssigneeUpdate_AutoCompleteRequest, 'Search',
                                    updateLenderConfigTaskAlternateAssigneeName.value, 'Employee')">
                                <mat-option class="glb_Font" [value]=control>
                                  {{control.ControlValue}}
                                </mat-option>
                              </div>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="glb_boxedPaddingNarrow lenderConfiguration">
                    <mat-checkbox [(ngModel)]="taskTypeAssignee.AlternateMode" class="glb_checkBoxMargin mat-checkbox-dark-custom"
                      (change)="LenderConfigTaskAssignee_AlternateModeSwitch(taskTypeAssignee)">
                    </mat-checkbox>
                    <mat-label class="glb_Font"></mat-label>
                  </div>

                  <div class="glb_boxedPaddingNarrow lenderConfiguration">
                    <input tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="SLA Days"
                      [(ngModel)]="taskTypeAssignee.SLADays" [disabled]="!taskTypeAssignee.AllowEditing"
                      [ngClass]="{ 'glb_inactiveInputBox': !taskTypeAssignee.AllowEditing }">
                  </div>

                  <!-- Buttons div for editing the individual row -->
                  <div class="glb_customFlexRow align-items-center justify-content-end">
                    <div *ngIf="isTaskTypeAssigneeBeingEdited && taskTypeAssignee.AllowEditing" class="buttonPadding">
                      <span style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                          (click)="LenderConfigTaskAssignee_Edit(taskTypeAssignee)">
                          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save this Assignee">
                          </fa-icon>
                        </button>
                      </span>
                    </div>
                    <div *ngIf="isTaskTypeAssigneeBeingEdited && taskTypeAssignee.AllowEditing" class="buttonPadding">
                      <span style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                          (click)="LenderConfigTaskAssignee_Delete(taskTypeAssignee)">
                          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
                          </fa-icon>
                        </button>
                      </span>
                    </div>
                    <div *ngIf="isTaskTypeAssigneeBeingEdited && taskTypeAssignee.AllowEditing" class="buttonPadding">
                      <span style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                          (click)="LenderConfigTaskAssignee_Cancel(taskTypeAssignee)">
                          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'ban']" matTooltip="Click to Discard your changes">
                          </fa-icon>
                        </button>
                      </span>
                    </div>
                    <div *ngIf="!isTaskTypeAssigneeBeingEdited" class="buttonPadding">
                      <span style="padding-left:0.375em;">
                        <button
                          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                          (click)="LenderConfigTaskAssignee_Modify(taskTypeAssignee)">
                          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this Assignee">
                          </fa-icon>
                        </button>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Email Config tab -->
      <div [ngClass]="CurrentNavItem_ForceRemove('BTN_EmailConfig')" class="col-12">
        <div [ngClass]="CurrentNavItem_Show('BTN_EmailConfig')" class="col-12">
          <div class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12">
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">From Email Display Name</div>
                <input tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="From Email Display Name" [(ngModel)]="chosenLenderConfigClone.FromEmailDisplay" [disabled]="!editEnabled"
                  [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Email Reply To</div>
                <input tabindex="12" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Reply To Email"
                  [(ngModel)]="chosenLenderConfigClone.EmailReplyTo" [disabled]="!editEnabled" [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Email CC</div>
                <input tabindex="13" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="CC Emails separated by semicolon (;)" [(ngModel)]="chosenLenderConfigClone.EmailCCTo" [disabled]="!editEnabled"
                  [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <!-- Condition Subsequent Reminder Email Subject-->
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Condition Subsequent Reminder Email Subject</div>
                <input tabindex="14" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="Condition Subsequent Reminder Email Subject" [(ngModel)]="chosenLenderConfigClone.EmailSubject_CS_Reminder"
                  [disabled]="!editEnabled" [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>

            <!-- Condition Subsequent Followup Email Template-->
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Condition Subsequent Reminder Template</div>
                <div id="toolbar_CSReminder" class="glb_quillInputBackgroundColor">
                  <span class="ql-formats">
                    <select class="ql-header"></select>
                    <select class="ql-size"></select>
                    <select class="ql-font"></select>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color ql-picker ql-color-picker"></select>
                    <select class="ql-background ql-picker ql-color-picker"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                      <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                      <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                      <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                      <button class="ql-align ql-align-justify q1-box" title="justify" value="justify"></button>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link" aria-label="Link" title="Link"></button>
                    <button class="ql-image" aria-label="Insert Image" title="Insert Image"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" title="Remove Styles"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="glb_quillVariablesTextColor" aria-label="Variables" title="Add variables"
                      (click)="ShowVariables_Toggle('Reminder')" [disabled]="!editEnabled">Variables</button>
                  </span>
                  <div *ngIf="ShowReminderVariables" class="text-black glb_customFlexRow col-12">
                    <div *ngFor="let emailVariable of EmailVariables" class="glb_boxedPaddingNarrow"
                      (click)="Variable_Click(QuillCSReminder,emailVariable.Name)">
                      <div class="glb_hoverHand glb_allBorders glb_clickableIconButtonStyleGrey glb_boxedPaddingNarrow glb_quillVariablesBG">
                        <div class="glb_boxedPaddingNarrow glb_quillVariablesTextColor" matTooltip="{{emailVariable.DisplayName}}
                                            {{emailVariable.Description}}
                                            Example: {{emailVariable.Example}}">
                          {{emailVariable.Name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div #QuillEditor_CSReminder class="glb_customFlexRow glb_quillInputBackgroundColor glb_inputFieldLabel glb_quillContent"
                  id="quill-editor_CSReminder">
                </div>
              </div>
            </div>

            <!-- Condition Subsequent Followup Email Subject-->
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Condition Subsequent Followup Email Subject</div>
                <input tabindex="15" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                  placeholder="Condition Subsequent Followup Email Subject" [(ngModel)]="chosenLenderConfigClone.EmailSubject_CS_Followup"
                  [disabled]="!editEnabled" [ngClass]="{ 'glb_inactiveInputBox': !editEnabled }">
              </div>
            </div>
            <!-- Condition Subsequent Followup Email Template-->
            <div class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
              <div class="">
                <div class="text-white glb_inputFieldLabel glb_customFlexRow">Condition Subsequent Followup Template</div>
                <div id="toolbar_CSFollowup" class="glb_quillInputBackgroundColor">
                  <span class="ql-formats">
                    <select class="ql-header"></select>
                    <select class="ql-size"></select>
                    <select class="ql-font"></select>
                  </span>
                  <span class="ql-formats">
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline" aria-label="Underline"></button>
                  </span>
                  <span class="ql-formats">
                    <select class="ql-color ql-picker ql-color-picker"></select>
                    <select class="ql-background ql-picker ql-color-picker"></select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-list" value="ordered"></button>
                    <button class="ql-list" value="bullet"></button>
                    <select class="ql-align">
                      <button class="ql-align ql-align-left q1-box" title="left" value=""></button>
                      <button class="ql-align ql-align-center q1-box" title="center" value="center"></button>
                      <button class="ql-align ql-align-right q1-box" title="right" value="right"></button>
                      <button class="ql-align ql-align-justify q1-box" title="justify" value="justify"></button>
                    </select>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-link" aria-label="Link" title="Link"></button>
                    <button class="ql-image" aria-label="Insert Image" title="Insert Image"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" title="Remove Styles"></button>
                  </span>
                  <span class="ql-formats">
                    <button class="glb_quillVariablesTextColor" aria-label="Variables" title="Add variables"
                      (click)="ShowVariables_Toggle('Followup')" [disabled]="!editEnabled">Variables</button>
                  </span>
                  <div *ngIf="ShowFollowupVariables" class="text-black glb_customFlexRow col-12">
                    <div *ngFor="let emailVariable of EmailVariables" class="glb_boxedPaddingNarrow"
                      (click)="Variable_Click(QuillCSFollowup,emailVariable.Name)">
                      <div class="glb_hoverHand glb_allBorders glb_clickableIconButtonStyleGrey glb_boxedPaddingNarrow glb_quillVariablesBG">
                        <div class="glb_boxedPaddingNarrow glb_quillVariablesTextColor" matTooltip="{{emailVariable.DisplayName}}
                                                            {{emailVariable.Description}}
                                                            Example: {{emailVariable.Example}}">
                          {{emailVariable.Name}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div #QuillEditor_CSFollowup class="glb_customFlexRow glb_quillInputBackgroundColor glb_inputFieldLabel glb_quillContent"
                  id="quill-editor_CSFollowup">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Task Notifier Config tab -->
      <div [ngClass]="CurrentNavItem_ForceRemove('BTN_TaskNotifierConfig')" class="col-12">
        <div [ngClass]="CurrentNavItem_Show('BTN_TaskNotifierConfig')" class="col-12">

          <div class="glb_PaddingHorizontalSM glb_paddingTopSM text-white glb_Font col-12">
            A Task Notifier will get triggered when a journal note is added to a User Task, based on the selected Task Types.
            <br />
            <div class="glb_customFlexRow glb_hoverHand" (click)="TemplateID_Toggle('Notes')">
              Notes &nbsp;
              <fa-icon [icon]="['fal', 'chevron-up']" class="glb_fixedSizeIcons taskNotifierIcon_Create" [ngClass]="TemplateID_GetCSS('Notes',false)"
                matTooltip="Hide">
              </fa-icon>
              <fa-icon [icon]="['fal', 'chevron-down']" class="glb_fixedSizeIcons taskNotifierIcon_Create" [ngClass]="TemplateID_GetCSS('Notes',true)"
                matTooltip="Show">
              </fa-icon>
            </div>
            <div id="Notes" [ngClass]="TemplateID_GetCSS('Notes')">
              <li>All members of the selected roles will be notified. Additionally, all email recipients will also receive an email.</li>
              <li>No email will be sent if the email template is blank</li>
              <li>No email will be sent if no roles are selected and no recipients added</li>
              <li>If the subject is missing, it will default to "xChange Task Notifier"</li>
            </div>
          </div>

          <!--Insert button-->
          <div class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12">
            <div class="glb_customFlexRow justify-content-end">
              <a (click)="TaskNotifier.Row_Create()" class="glb_hoverHand justify-content-end glb_customFlexRow col-2"
                [ngClass]="TaskNotifier.CreateCSS_Disable()">
                <fa-icon [icon]="['fal', 'plus']" class="glb_fixedSizeIcons taskNotifierIcon_Create" matTooltip="Click to insert a new Task Notifier">
                </fa-icon>
              </a>
            </div>

            <!-- Now lets have a div that shows all the task notifiers. -->
            <div class="glb_customFlexRow col-12 row-cols-1">

              <!-- Stick a border around it -->
              <div class="glb_allBordersLight glb_PaddingHorizontalSM">

                <!--We need a header row -->
                <div class="glb_customFlexRow glb_paddingTopSM row-cols-8">
                  <div *ngFor="let header of TaskNotifier.Headers"
                    class="glb_customFlexRow glb_paddingBottomSM glb_fontColorSkin glb_boxedPaddingNarrow">

                    <!-- Left align all string headers-->
                    <div class="glb_customFlexRow col-12" [ngClass]="TaskNotifier.ColumnCSS_Get(header)" [matTooltip]="header.Tooltip"
                      [matTooltipDisabled]="header.TooltipDisabled" matTooltipPosition="above">
                      {{header.DisplayName}}
                      <div *ngIf="!header.TooltipDisabled">
                        <fa-icon [icon]="['fal', 'circle-info']" class="glb_PaddingHorizontalSM glb_fixedSizeIcons taskNotifierIcon_Create">
                        </fa-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="TaskNotifier.IsProcessing">
                  <div class="col">
                    <div class="text-white glb_customFlexRow justify-content-center glb_paddingTopMD glb_paddingBottomMD">
                      <div class="spinner-border glb_spinnerMD"></div>
                    </div>
                  </div>
                </div>

                <!--Task Notifier Contents, loop through the task notifiers here -->
                <div class="glb_customFlexRow align-items-center row-cols-8" *ngFor="let row of TaskNotifier.Data;index as rowIndex">
                  <div *ngFor="let header of TaskNotifier.Headers"
                    class="glb_customFlexRow glb_paddingTopSM glb_paddingBottomSM glb_boxedPaddingNarrow">
                    <!-- Regular Readonly display-->
                    <div *ngIf="header.InputType === 'RichTextEditor' && header.Name !== 'Enabled' &&!row[TaskNotifier.SuffixEditFlag]"
                      class="glb_customFlexRow col-12" [ngClass]="TaskNotifier.ColumnCSS_Get(header)">
                      <div [matTooltip]="row[header.Name]" matTooltipPosition="left"
                        [matTooltipDisabled]="row[header.Name + TaskNotifier.SuffixDisableTooltip]">
                        {{row[header.Name + TaskNotifier.SuffixDisplay]}}
                      </div>
                    </div>
                    <!--String display-->
                    <div *ngIf="header.InputType === 'String'" class="col-12" [ngClass]="TaskNotifier.ColumnCSS_Get(header)">
                      <div class="glb_customFlexRow align-self-center">
                        <textarea class="glb_formInputBox glb_skinLightOutline text-black glb_Font taskNotifier_TextArea"
                          [(ngModel)]="row[header.Name]" type="text" [placeholder]="header.DisplayName" [disabled]="!row[TaskNotifier.SuffixEditFlag]"
                          [ngClass]="TaskNotifier.StringColCSS_Get(row)" [matTooltip]="row[header.Name]" matTooltipPosition="left"
                          [matTooltipDisabled]="row[header.Name + TaskNotifier.SuffixDisableTooltip]"></textarea>
                      </div>
                    </div>

                    <!--Multiselect display-->
                    <div *ngIf="header.InputType === 'Multiselect'" class="glb_customFlexRow col-12 text-dark glb_Font"
                      [ngClass]="TaskNotifier.ColumnCSS_Get(header)">

                      <!--This is a checkbox column (only applies for first column)-->
                      <div *ngIf="header.Name === 'TaskType'" class="col-2 glb_customFlexRow align-items-center">
                        <div class="glb_customFlexRow align-items-center" [matTooltip]="row.TooltipText" matTooltipPosition="left">
                          <mat-checkbox (change)="TaskNotifier.RowEnabled_Toggle(row)" [(ngModel)]="row.Enabled"
                            [disabled]="!row[TaskNotifier.SuffixEditFlag]" class="glb_checkBoxMargin mat-checkbox-dark-custom glb_paddingBottomXS">
                          </mat-checkbox>
                          <mat-label class="glb_Font"></mat-label>
                        </div>
                      </div>

                      <div class="text-dark glb_Font" [ngClass]="TaskNotifier.ColumnCSSChild_Get(header)">
                        <p-multiSelect class="glb_customFlexRow
                         taskNotifier_Multiselect" [id]="'INP_' + header.Name + rowIndex" [options]="TaskNotifier[header.Name + 'Options']"
                          optionLabel="ControlDisplay" [appendTo]="'body'" [virtualScroll]="true" [virtualScrollItemSize]="40" [filter]="true"
                          [resetFilterOnHide]="true" [showToggleAll]="false" [showClear]="false" [showHeader]="true"
                          [selectedItemsLabel]="'{0} options'" [placeholder]="header.DisplayName"
                          [(ngModel)]="row[header.Name + TaskNotifier.Multiselect]" [disabled]="!row.EditFlag" [maxSelectedLabels]="1"
                          [matTooltip]="row[header.Name + TaskNotifier.Multiselect + TaskNotifier.Tooltip]" matTooltipPosition="left"
                          [matTooltipDisabled]="row[header.Name + TaskNotifier.Multiselect + TaskNotifier.SuffixDisableTooltip]"
                          (onPanelShow)="TaskNotifier.RowMultiselect_Group(TaskNotifier[header.Name + 'Options'],row[header.Name + TaskNotifier.Multiselect])">
                        </p-multiSelect>
                      </div>
                    </div>

                    <!--Email Template-->
                    <div *ngIf="header.Name === 'EmailTemplate'" [ngClass]="TaskNotifier.ColumnCSS_Get(header)"
                      class="col-12 glb_customFlexRow align-items-center">
                      <div *ngIf="TaskNotifier.EditMode === false || (TaskNotifier.EditMode === true && row[TaskNotifier.SuffixEditFlag])"
                        class="justify-content-center">
                        <button id="BTN_EmailTemplate" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                          (click)="TaskNotifier.RowEmail_View(row)">
                          <span class="glb_Font glb_smallerText">{{TaskNotifier.RTEButtonText}}</span></button>
                      </div>
                    </div>

                    <!--Action section-->
                    <div *ngIf="header.Name === 'Action'" class="glb_customFlexRow col-12" [ngClass]="TaskNotifier.ColumnCSS_Get(header)">
                      <!--Edit button-->
                      <div *ngIf="TaskNotifier.EditMode === false" class="glb_customFlexRow">
                        <div *ngIf="!row[TaskNotifier.SuffixEditFlag]" class="buttonPadding">
                          <button
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="TaskNotifier.Row_Edit(row)">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit">
                            </fa-icon>
                          </button>
                        </div>
                      </div>
                      <!--Other buttons-->
                      <!--Save button-->
                      <div *ngIf="TaskNotifier.EditMode === true" class="glb_customFlexRow">
                        <div *ngIf="row[TaskNotifier.SuffixEditFlag]" class="buttonPadding">
                          <button
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="TaskNotifier.Row_Save(row, rowIndex)">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save">
                            </fa-icon>
                          </button>
                        </div>
                      </div>
                      <!--Delete button-->
                      <div *ngIf="TaskNotifier.EditMode === true && TaskNotifier.InsertMode === false" class="glb_customFlexRow">
                        <div *ngIf="row[TaskNotifier.SuffixEditFlag]" class="buttonPadding">
                          <button
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="TaskNotifier.Row_Delete(row)">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
                            </fa-icon>
                          </button>
                        </div>
                      </div>
                      <!--Cancel button-->
                      <div *ngIf="TaskNotifier.EditMode === true" class="glb_customFlexRow">
                        <div *ngIf="row[TaskNotifier.SuffixEditFlag]" class="buttonPadding">
                          <button
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="TaskNotifier.RowEditCancel_Click(rowIndex)">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'ban']" matTooltip="Click to Discard">
                            </fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>