import { Input, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { of, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { HostListener, OnDestroy } from '@angular/core';
import { map, debounceTime, distinctUntilChanged, delay, mergeMap } from 'rxjs/operators';
import { DataRow } from 'src/app/Global/Models/EntityModels';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ConfirmModal } from '@app/Components/Loan//ConfirmModal/ConfirmModal';

@Component({
  selector: 'LoanEntity',
  templateUrl: './LoanEntity.html',
  styleUrls: ['./LoanEntity.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})

export class LoanEntity implements OnInit, OnDestroy {

  public filterargs = { DisplaySection: 'header' };

  //Used by the HostListener (window resize event) to adjust some text alignment when screen size changes. default it to -1 so that it doesn't get used, until its needed.
  public ColumnWrapping = -1;

  //Used for the filter search.
  public filterSearchValue = "";
  public filterSearchValueKeyUp = new Subject<any>();

  //Used to show and hide the 'hide ghosted' tickbox
  public ShowHideGhostedTransactionTickbox = true;

  //Used to show and hide the Statement Generator button
  public ShowStatementGenerator = false;

  //Used to show and hide the Document Generator button
  public ShowDocumentGenerator = false;

  //Used to show and hide the Residential Payout Calculator button 
  public ShowPayoutCalculator = false;

  //Used to show and hide the Lease Payout Calculator button 
  public ShowLeasePayoutCalculator = false;

  //Claim to view the Lease Payout Calculator button 
  public HasLeasePayoutClaim = false;

  //Claim to view the Residential Payout Calculator button 
  public HasResiPayoutClaim = false;

  //Used to show and hide the reset borrower password button
  public ShowBorrowerPasswordReset = false;

  //Array to store the identifier GUIDs
  public TemplateIdentifiers: any[] = [];

  //To track and unsubscribe from any subscriptions that we create
  public Subscription: Subscription;

  @Input() loanIndex: LoanIndex;
  //A ref to the current modal screen. this is used for passing update request data down
  @Input() DataRowModal: DataRow;
  //Entity Name
  @Input() EntityName = "Loading";
  //Should we show the header section of entities
  @Input() ShowHeader = true;
  //Should we wrap odd rows (e.g. for dates)
  @Input() WrapOddRows = false;
  @Input() WrapOddRowsHeader = false;
  //Allow the number of columns in the header to be passed in
  @Input() ColumnsInHeader = -1;
  //Allow the number of columns in the body to be passed in
  @Input() ColumnsInBody = 2;
  //I may want to combine data label and value into a single row, at times. lets cater for that.
  @Input() CombineLabelAndData = false;
  //Contains data about all entities, their display names, and even the linked array.
  //@Input() EntityDict: any[] = new Array();
  @Input() EntityDict: { [key: string]: any } = { Loading: { DisplayName: "Loading", Count: -1, Entity: [], InitialLoad: false, InitialLoadCompleted: false, Spinner: 0 } };
  //The max number of columns in the tab
  @Input() ColumnsInTab = 2;
  //This contains the table headers
  @Input() TableHeaders: any[] = null;
  //Should we show the table headers?
  @Input() ShowTableHeaders = false;
  //Should we show the search/filter box?
  @Input() ShowSearchFilterBox = true;
  //Show the create/insert new entity button?
  @Input() ShowCreateNewEntityButton = true;
  //Should we show the download csv button?
  @Input() ShowDownloadButton = true;
  //Add bottom padding? used for pivot style entities, generally. but caller can decide
  @Input() AddBottomPadding = false;

  //A few more conditionals, we want to allow Headless mode to customize it
  @Input() public ShowRefreshButton = true;
  //Headless mode will want to modify this to true, so that users can click to close the 'popped up' entity (its in a modal)
  @Input() public ShowModalCloseButton = false;
  //Try a local version for the entity insert button.
  @Input() public ShowInsertNewEntityButton = false;

  //Default this to a high value, so that pages that don't need it will never show it (autoHide is set to true on the paginator config)
  @Input() PaginatorItemsPerPage = 100;

  @Input() SingleEntityModalView = false;

  @ViewChild('appenHere', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  public paginatorConfig = {
    id: "tbd", itemsPerPage: 10, currentPage: 0, maxSize: 10, previousLabel: "", nextLabel: ""
  }

  //for controlling the autoHide. we need it to stay ON for the items that have paging, because the user may be on a page that no longer has items on it.
  public autoHide = true;

  public onPageChange(page: number, scrollType = "resetScroll") {
    this.paginatorConfig.currentPage = page;
    //window.scrollTo(0, 0);
    //scrolling to the bottom of the screen might be better! need a small delay though, or the page is moved too quickly (before the DOM renders the changed content). Why do i like this? because it keeps the page buttons in the same place, so that its easier to keep clicking to scroll forward pages. If we scroll to the top, it becomes annoying for the user - they have to scroll down, look for the arrow or page numbers, reposition mouse and then click. By having a sufficient page size (items per page of 50), we ensure that the scroll to bottom always leaves the user on the same relative space on the screen. The only time this won't happen, is when they hit a final page which has few items. this is an ok tradeoff.

    if (scrollType === "resetScroll")
      this.globalFunctions.delay(50).then(any => {
        window.scrollTo(0, document.body.scrollHeight);
      });
  }

  //we can use this to catch any window changes, and then update styles in the html.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //check if event is null, if so, use current screen width.
    let windowLength = 768;
    if (event === null) {
      windowLength = window.innerWidth;
    }
    else {
      windowLength = event.target.innerWidth
    }

    //let's check the window width, and then flip some display switches as needed. This is a more performant than calling ngDoCheck,  which fires consistently!

    //here are the current bootstrap responsize sizes. we could chuck this in a global static class, if we like.
    // xs (for phones - screens less than 768px wide)
    // sm (for tablets - screens equal to or greater than 768px wide)
    // md (for small laptops - screens equal to or greater than 992px wide)
    // lg (for laptops and desktops - screens equal to or greater than 1200px wide)

    //now, for this componenent, we want to trigger column wrapping when the screen gets too small.
    //we only want to do this for tabular style views. so when the column count inside the body is a sufficiently large number
    //(currently 6 columns for transactions)
    if (this.ShowTableHeaders) {
      if (this.TableHeaders.length >= 3) {
        //let's target anything sm or below.    
        if (windowLength <= 768) {
          //assume double rows - wrap odd rows, align them to the right! even stays left.
          this.ColumnWrapping = 2;
        }
        else {
          //regular non wrapping treatment. the function will know what to do!
          this.ColumnWrapping = 0;
        }
      }
    }
  }

  constructor(public globalFunctions: GlobalFunctions, public apiService: ApiService, public dialog: MatDialog, public clientDataStore: ClientDataStore, private notifyService: NotifyService) {
  }

  //Get the identifier. Initialize if it doesn't exist and return the identifier object
  public TemplateID_Get(identifierID: string, childEntityNavTabName = "") {
    const templateIdentifierIDArray = this.TemplateIdentifiers.filter(x => x.TemplateIdentifierGUID == identifierID);
    if (this.globalFunctions.isEmpty(templateIdentifierIDArray)) {
      let templateIsEnabled = false;

      //This is here to help default the state of the nested child entity content, based on the entity type. we want to default addresses, contacts and employments in an uncollapsed state. All others can start collapsed. 
      if (!this.globalFunctions.isEmpty(childEntityNavTabName)) {
        if (childEntityNavTabName === "ClientAddress" || childEntityNavTabName === 'ClientContact' || childEntityNavTabName === 'IndividualEmployments' || childEntityNavTabName === 'IndividualSelfEmployed') {
          templateIsEnabled = true;
        }
      }
      const newTemplateID = { TemplateIdentifierGUID: identifierID, IsEnabled: templateIsEnabled }
      this.TemplateIdentifiers.push(newTemplateID);
      return newTemplateID;
    }
    return templateIdentifierIDArray[0];
  }

  //Find and Toggle the IsEnabled Flag on the identifier
  public TemplateID_Toggle(identifierID: string) {
    const templateIdentifierID = this.TemplateID_Get(identifierID);
    templateIdentifierID.IsEnabled = !templateIdentifierID.IsEnabled;
  }

  //Check the state of the Identifier and returrn the IsEnabled flag
  public CheckTemplateID_CheckForDisplay(identifierID: string, childEntityNavTabName = "") {
    return this.TemplateID_Get(identifierID, childEntityNavTabName).IsEnabled;
  }

  //Get the class based on identifier state
  public TemplateID_GetCSS(identifierID: string, inverted = false, childEntityNavTabName = ""): string {
    let returnClass = "";
    if (!this.globalFunctions.isEmpty(identifierID)) {

      const templateEnabled = this.CheckTemplateID_CheckForDisplay(identifierID, childEntityNavTabName);

      //Inverted flag allows us to bind the active or inactive state. Eg. Chevron Down arrow binds to the inverted state
      if (!inverted) {
        if (!templateEnabled) {
          returnClass = returnClass + "glb_hiddenObjectImmediate ";
        }
      }
      else {
        if (templateEnabled) {
          returnClass = returnClass + "glb_hiddenObjectImmediate ";
        }
      }
    }

    //Always return a string
    return returnClass;
  }

  //Array to store the active child nav tab items
  public CurrentChildNavTabItems: any[] = [];

  //Get the current active child nab tab item
  public GetActiveNavTabItem(parentGUID: any, entity: any, entityIndex: number) {
    //Get the child entity for that parent
    const childNavTabItem = this.CurrentChildNavTabItems.filter(x => x.ParentGUID === parentGUID);

    //Check if it is not in the list
    if (this.globalFunctions.isEmpty(childNavTabItem)) {
      let entityName = "";
      //If it is the first entity on the list, set it as active child nav tab
      if (entityIndex === 0) {
        entityName = entity;
      }
      const newChildNavTabItem = { ParentGUID: parentGUID, EntityName: entityName }
      this.CurrentChildNavTabItems.push(newChildNavTabItem);

      return newChildNavTabItem;
    }
    return childNavTabItem[0];
  }

  //Get and highlight the currently active nav bar item
  public GetCurrentNavBarItem(parentGUID: any, entity: any, entityIndex: number) {

    const currentNavTabItem = this.GetActiveNavTabItem(parentGUID, entity, entityIndex);

    //Highlight if the current child entity is in the list of active child entities
    if (currentNavTabItem.EntityName === entity) {
      return 'active'
    }

    return ''
  }

  //When a nav bar item is clicked, set the current child entity as active child entity nav tab
  public NavBarItemClicked(parentGUID: any, entity: any, entityIndex: number) {
    const currentNavTabItem = this.GetActiveNavTabItem(parentGUID, entity, entityIndex);
    currentNavTabItem.EntityName = entity;
  }

  //Checks the name of the supplied entity, and supplies the css to fade IN the content (make it visible with a short fade in animation using css keyframes)
  public ShowCurrentNavItem(parentGUID: any, entity: any, entityIndex: number): string {
    const currentNavTabItem = this.GetActiveNavTabItem(parentGUID, entity, entityIndex);
    if (entity === currentNavTabItem.EntityName) {
      //If we are showing the content, supply the css keyframes that fade it in
      return 'glb_keyFrameFadeIn';
    }
    return 'glb_hiddenObjectImmediate';
  }

  ngOnInit(): void {
    //manually fire a window event on startup to sync the alignments. or just get the current window target length.
    this.onResize(null);
    //give the paginator the id of this entity name
    this.paginatorConfig.id = this.EntityName;
    //and the number of items per page. this could potentially be made configurable by the user themselves (let them decide from a set of options, e.g. 10, 30, 50, etc)
    this.paginatorConfig.itemsPerPage = this.PaginatorItemsPerPage;

    this.GetEntityFriendlyNames();

    //Work out if the insert button for this entity should be displayed, based on the supplied override
    if (this.SingleEntityModalView) {
      this.ShowInsertNewEntityButton = false;
    }
    else {
      if (!this.globalFunctions.isEmpty(this.loanIndex)) {
        //work out if the insert button for this entity should be displayed, based on the users claims. get the matching entity claim
        const matchingClaim = this.loanIndex.GetEntityClaim(this.EntityFriendlyName);
        //console.log('matchingClaim on entity', matchingClaim);

        //we can also inspect the SupportsCloning property of the entity. this should contain the correct info on whether or not we can insert data to this. use the EntityType that was looked up earlier
        //console.log('this.EntityType', this.EntityType);

        //now see if we should show it, based on both the SupportsInsert of the entity type, and the users claim
        if (this.EntityType.SupportsInsert && matchingClaim.Insert) {
          this.ShowInsertNewEntityButton = true;
        }
        else {
          this.ShowInsertNewEntityButton = false;
        }
      }
      else {
        //there is no loan entity. hmm. just default it to false?
        this.ShowInsertNewEntityButton = false;
      }
    }

    //Check if we are in the Association entity
    if (this.EntityName === 'LoanAssociations') {
      //Should we show the borrower reset password button
      if (this.clientDataStore.ClientClaims.filter(x => x.Name === "ResetBorrowerPassword").length > 0) {

        //Claim exists! let's grab it and inspect!
        const resetBorrowerPasswordClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "ResetBorrowerPassword")[0];
        //Check if the READ property of this claim is NOT ticked.
        if (resetBorrowerPasswordClaim.Read) {

          this.ShowBorrowerPasswordReset = true;
        }
      }
    }

    //check if we are in the Transaction entity
    if (this.EntityName === 'LoanTransactions') {

      //so we are in the Transaction entity! now lets check for any claims that are specific to Transactions.
      //check if we want to hide the 'Hide Ghosted' checkbox
      if (this.clientDataStore.ClientClaims.filter(x => x.Name === "ShowTransactionGhostedTickbox").length > 0) {

        //claim exists! let's grab it and inspect!
        const showTransactionGhostedTickboxClaim = this.clientDataStore.ClientClaims.filter(x => x.Name == "ShowTransactionGhostedTickbox")[0];

        //check if the READ property of this claim is NOT ticked.
        if (!showTransactionGhostedTickboxClaim.Read) {

          //then hide the 'hide ghosted' tickbox
          this.ShowHideGhostedTransactionTickbox = false;
        }

      }

      //Check if the logged in user has access to statement generator
      if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "StatementGenerator", "Read") === true) {
        this.ShowStatementGenerator = true;
      }

      //Check if the logged in user has access to Residential Payout Calculator
      if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "PayoutCalculator", "Read") === true) {
        this.HasResiPayoutClaim = true;
      }

      //Check if the logged in user has access to Lease Payout Calculator
      if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "LeasePayoutCalculator", "Read") === true) {
        this.HasLeasePayoutClaim = true;
      }
    }

    //Check if we are in the Document entity
    if (this.EntityName === 'LoanDocuments') {

      //Check if the logged in user has access to the document generator
      if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "DocumentGenerator", "Read") === true) {
        this.ShowDocumentGenerator = true;
      }
    }

    //Subscription for the entity search filter
    this.Subscription = this.filterSearchValueKeyUp.pipe(
      map((event: any) => (<HTMLInputElement>event.target).value),
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(delay(100)))
    ).subscribe(value => {
      //lets show the spinner when we start filtering
      if (!this.globalFunctions.isEmpty(value)) {
        this.Entity_ToggleSpinner(this.EntityName, 0);
      }
      else {
        //call it directly with a blank string
        this.loanIndex.Entity_SearchFilter(this.EntityName, "");
      }
      //small delay so that the spinner has a chance to show.
      //this is useful for the user experience, as they get a visual indication that the filtering has completed.
      this.globalFunctions.delay(300).then(any => {
        //run after a small delay.
        if (!this.globalFunctions.isEmpty(value)) {
          this.loanIndex.Entity_SearchFilter(this.EntityName, value);
        }
      });
    });
  }

  //Show overlay panel
  public OverlayDataUnits_Show(event, overlay, item): void {

    //Dispaly only if there are any Overlay DUs
    if (item.ShowOverlayDataUnits === true) {
      overlay.show(event);
    }
  }

  //Hide overlay panel
  public OverlayDataUnits_Hide(overlay, item): void {
    if (item.ShowOverlayDataUnits === true) {
      overlay.hide();
    }
  }

  //Launch create email
  public CreateEmail_Launch(overlayPanel, dataRow, emailTemplate = "Reminder"): void {

    //Hide the overlay panel
    overlayPanel.hide();

    //Launch Email
    this.loanIndex.CreateEmail_Launch(dataRow, emailTemplate);
  }

  //Show/Hide the Lease Payout Launch button
  public PayoutCalculatorButton_Show(): void {

    //Must have a claim and the product type must be lease
    if (this.HasLeasePayoutClaim === true && this.loanIndex.AccountProductType === "Lease") {
      this.ShowLeasePayoutCalculator = true;
    }

    //Must have a claim and the product type must not be empty and not of Lease type
    if (this.HasResiPayoutClaim === true && !this.globalFunctions.isEmpty(this.loanIndex.AccountProductType) && this.loanIndex.AccountProductType != "Lease" && !this.globalFunctions.isEmpty(this.loanIndex.AccountProductClass) && this.loanIndex.AccountProductClass === "Residential") {
      this.ShowPayoutCalculator = true;
    }
  }

  ngOnDestroy(): void {
    //Unsub from rxjs subscriptions on destroy
    if (!this.globalFunctions.isEmpty(this.Subscription)) {
      this.Subscription.unsubscribe();
    }
  }

  //when using GUIDS to bind to elements in html, we can't have the curly braces { and }. use this method to strip them out, as needed
  public StripBraces(GUID: string) {
    //use the globalFunction method
    return this.globalFunctions.StripBracesAndSpaces(GUID);
  }

  //gets the class based on entity setup. this is needed as direct binding inside the html template seems to run into some runtime bugs (might be a limit related to the length of the conditions)
  public getClass(Type: string, ColumnsInBody: number, WrapOddRows: boolean = null, isOdd: boolean = null, isEven: boolean = null, CombineLabelAndData: boolean = null, itemIndex: number = null, arrayCount: number = null, rowStyle: string = null, dataUnitArray: any = null, childEntityNavTabName: string = null, identifierID = "") {
    //If we were asked to display a tabular style view (checkTableHeaders), then instead of using the passed in column count, dynamically render from the table headers length
    if (this.ShowTableHeaders) {
      ColumnsInBody = this.TableHeaders.length;
    }

    //For data unit arrays, we dont want to count hidden columns. so count it again, ignoring hidden ones
    if (!this.globalFunctions.isEmpty(dataUnitArray)) {
      arrayCount = dataUnitArray.filter(x => !x.HideDisplay).length;
    }

    let returnClass = "";

    //Append the class based on identifier state.
    returnClass = returnClass + this.TemplateID_GetCSS(identifierID, false, childEntityNavTabName);

    //For data pairs (label + value)
    if (Type == 'DataPair') {

      //check if any row specific style need to be applied to the data.
      if (rowStyle === "ghosted") {
        returnClass = returnClass + "ghosted ";
      }
      if (rowStyle === "rowBold") {
        returnClass = returnClass + "rowBold ";
      }
      if (rowStyle === "addToBase") {
        returnClass = returnClass + "addToBase ";
      }

      if (ColumnsInBody === 1) {
        return returnClass + "row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
      }
      if (!WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2';
      }
      if (WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2';
      }

      if (ColumnsInBody === 3) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3';
      }
      if (ColumnsInBody === 4) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4';
      }
      if (ColumnsInBody === 5) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-5';
      }
      if (ColumnsInBody === 6) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-6 row-cols-lg-6';
      }
      if (ColumnsInBody === 7) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-7';
      }
      if (ColumnsInBody === 8) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
      if (ColumnsInBody === 9) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-9';
      }
      if (ColumnsInBody === 10) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-10';
      }
    }

    //for the actual value display
    else if (Type == 'ValueDisplay') {

      //table headers have to deal with the table header length
      if (this.ShowTableHeaders) {
        //even columns, or columns in the first half of the table should be left aligned
        if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping <= 0 && itemIndex < (Math.ceil(arrayCount / 2.0))))) {
          return 'dataClass text-start';
        }
        //odd columns, or columns in the second half of the table should be right aligned
        if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping <= 0 && itemIndex >= (Math.ceil(arrayCount / 2.0))))) {
          return 'dataClass text-end';
        }
      }

      //standard processing for non tabular style views
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'dataClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      //when we are wrapping odd rows, that means this is a pivoted style entity, where every first data unit is a label!
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
    }

    //for table header values
    else if (Type == 'TableHeaderValues') {
      //even columns, or columns in the first half of the table should be left aligned
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping <= 0 && itemIndex < (Math.ceil(arrayCount / 2.0))))) {
        return 'labelClassTableHeader text-start';
      }
      //odd columns, or columns in the second half of the table should be right aligned
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping <= 0 && itemIndex >= (Math.ceil(arrayCount / 2.0))))) {
        return 'labelClassTableHeader text-end';
      }
    }
  }

  //for determining the child level entity (e.g. valuation or asset location) number of columns.
  public getChildEntityClass(ShowLabel: boolean = null) {

    const returnClass = "";

    if (ShowLabel) {
      return returnClass + "row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2";
    }
    else {
      return returnClass + 'row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1';
    }
  }

  //work out if border padding classes need to apply, used for pivot card entities (dates, balances, identifiers)
  public GetBorderPaddingClass() {
    let returnClass = "";
    if (!this.ShowTableHeaders && this.WrapOddRows) {
      returnClass = returnClass + " glb_rightPaddingNarrow glb_customFlexRow";
    }
    return returnClass;
  }

  //work out if a top border is needed
  public GetTopBorderClass() {
    let returnClass = "glb_customFlexRow";

    if (this.ColumnsInTab === 1) {
      returnClass = returnClass + " row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
    }

    if (this.ColumnsInTab === 2) {
      returnClass = returnClass + " row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2";
    }

    //now add the bottom border, if the entity type demands it. e.g pivot style card based.    
    //but we don't want it for any card based entities that have header rows. how do we know that? count the ColumnsInHeader of course!
    if (!this.ShowTableHeaders && this.ColumnsInHeader <= 0) {
      returnClass = returnClass + " headerCardBorder";
    }

    return returnClass;
  }

  //work out if a a padding is needed (card based entities with no WrapDataRows)
  public GetPaddingClassForCards() {
    let returnClass = "";

    //target card based only, and NOT the pivot (balance/dates) ones
    if ((!this.ShowTableHeaders && !this.WrapOddRows)
      //EXCEPT when the pivot data has a header. then it needs some padding! e.g. arrears breakdown
      //|| (!this.ShowTableHeaders && this.WrapOddRows && this.ColumnsInHeader >= 1)
    ) {
      returnClass = returnClass + " paddingClassForCards";
    }

    return returnClass;
  }

  //work out if a a padding is needed for card based entities that has a header, AND wrapping rows (e.g. Arrears Breakdown)
  public GetPaddingForCardHeader() {
    let returnClass = "";

    //target card based only, has a header, AND wrapping rows (e.g. Arrears Breakdown).
    //balances and dates dont have a header, and therefore don't need the header padding.
    if ((this.ShowHeader && !this.WrapOddRows)
    ) {
      returnClass = returnClass + " paddingClassForCardHeader";
    }

    return returnClass;
  }

  //toggles the loading spinner for a passed in entity name
  private Entity_ToggleSpinner(EntityName: string, value: number) {
    this.loanIndex.EntityDict[EntityName].Spinner = value;
  }

  //lookup the nav display name of this entity from the client data store
  public NavDisplayName: string;

  //This can be used and supplied to force a different nav display name
  @Input() public NavDisplayNameCustom: string = null;

  //and the friendly name too
  public EntityFriendlyName: string;

  //this is the entity type from the enum
  public EntityType: any;

  public GetEntityFriendlyNames() {
    //console.log('this.loanIndex.store.EntityTypes', this.loanIndex.store.EntityTypes);
    //console.log('this.loanIndex', this.loanIndex);

    if (!this.globalFunctions.isEmpty(this.loanIndex)) {
      //This is the entity type data from the server enum. lets store this now, as it can be very useful later.
      this.EntityType = this.loanIndex.store.EntityTypes.filter(x => x.Name === this.EntityName)[0];

      if (!this.globalFunctions.isEmpty(this.EntityType)) {

        //Get both nav and friendly display name. Check if a custom name was supplied
        if (!this.globalFunctions.isEmpty(this.NavDisplayNameCustom)) {
          //Use the custom name
          this.NavDisplayName = this.NavDisplayNameCustom;
        }
        else {
          //Use the regular entity name
          this.NavDisplayName = this.EntityType.NavDisplayName;
        }

        //Friendly name is anlways the entity friendly
        this.EntityFriendlyName = this.EntityType.FriendlyName;
      }
    }
  }

  //method to remove all mark tags from a value (useful when copying for clipboard)
  public StripAllHighlightTags(inputValue: any): string {
    return this.globalFunctions.StripAllHighlightTags(inputValue, this.globalFunctions.GetLoanEntityFilterHighlightColor());
  }

  public AddBorderPadding = false
  //do we need to show the border padding
  public CheckIfBorderPaddingRequired() {
    if (this.AddBorderPadding) {
      return 'glb_boxedPaddingLG'
    }

    //let's also check if bottom padding was requested
    if (this.AddBottomPadding) {
      return 'glb_paddingBottomMD'
    }

    return '';
  }

  //do we hide ghosted transaction
  public hideGhostedTransactions = false;
  //switch to hide or show ghosted transactions when the checkbox is clicked
  public HideGhosted(flag: any, event: any) {
    this.hideGhostedTransactions = event.checked;
    //console.log("Hide ghosted:" + this.hideGhostedTransactions);

    //whenever we click this flag, we have to remove search filters. (they can't work together like this)
    this.loanIndex.Entity_ResetSearchFilter(this.EntityName);

    //now let's hide ghosted transactions! i think we have to get loanindex to do it for us.
    this.loanIndex.Entity_HideGhostedTransactions(this.hideGhostedTransactions);
  }

  //This is used to show or hide add new button
  public InsertNewEntityButton_Sync() {

    //Check if it's the Bank Account Entity
    if (this.EntityName === 'BankDetails') {

      //Check if entity is not null
      if (!this.globalFunctions.isEmpty(this.EntityDict[this.EntityName]) && !this.globalFunctions.isEmpty(this.EntityDict[this.EntityName].Entity[0])) {

        //Bank details already exists on this account, hide the insert button
        this.ShowInsertNewEntityButton = false;
      }
      else {
        this.ShowInsertNewEntityButton = true;
      }
    }
  }

  //check if we should ghost on init
  public CheckGhostedTransactionsOnInit(clientDataStore: any) {
    //custom behaviour for certain entities. e.g. LoanTransaction - after this loads, we can check the claims, see if there is an AMALEmployee one.
    //if so, we can leave the transactions as is. If not, then assume its a lender, and default the ghosted transactions to hidden
    //console.log(this.EntityName, 'this.EntityName');

    //check that its the LoanTransaction Entity
    if (this.EntityName === 'LoanTransactions') {
      if (!this.globalFunctions.isEmpty(clientDataStore.ClientClaims)) {
        if (clientDataStore.ClientClaims.filter(x => x.Name == "AMALEmployee").length === 0) {
          this.hideGhostedTransactions = true;
          //fake event
          const dummyEvent = { checked: true };
          this.HideGhosted(true, dummyEvent);
        }
      }
    }

    if (this.EntityName === 'LoanNotes') {
      if (!this.globalFunctions.isEmpty(clientDataStore.ClientClaims)) {
        //let's just always default the system notes as hidden for all users
        //if (clientDataStore.ClientClaims.filter(x => x.Name == "AMALEmployee").length === 0) {
        this.hideSystemNotes = true;
        //fake event
        const dummyEvent = { checked: true };
        this.HideSystemNotes(true, dummyEvent);
        //}
      }
    }
  }

  //do we hide ghosted transaction
  public hideSystemNotes = false;
  //switch to hide or show system notes when the checkbox is clicked
  public HideSystemNotes(flag: any, event: any) {
    this.hideSystemNotes = event.checked;
    //console.log("Hide ghosted:" + this.hideGhostedTransactions);

    //whenever we click this flag, we have to remove search filters. (they can't work together like this)
    this.loanIndex.Entity_ResetSearchFilter(this.EntityName);

    //now let's hide ghosted transactions! i think we have to get loanindex to do it for us.
    this.loanIndex.Entity_HideSystemNotes(this.hideSystemNotes);
  }

  //method to help default the state of the  nested child entity content, based on the entity type. we want to default addresses, contacts and employments in an uncollapsed state. All others can start collapsed. Note that this works in tandem with code in getClass() above, which contains same logic but sets the aria-expanded state of the parent item.
  public GetChildEntityAriaExpandedState(entityName: string) {

    if (entityName === 'ClientAddress' || entityName === 'ClientContact' || entityName === 'IndividualEmployments' || entityName === 'IndividualSelfEmployed') {
      //these child nav tab entities would return a true to the aria-expanded state, so that the chevron up arrow appears
      return 'true'
    }
    //default aria-expanded state to false, so that the chevron down arrow appears
    return 'false';
  }

  //Display password reset button for V6 clients
  public PasswordReset_Show(childEntity, parentEntity): boolean {
    //Check if the user has claim to view this button
    if (!this.ShowBorrowerPasswordReset) {
      return false;
    }

    if (this.globalFunctions.isEmpty(childEntity)) {
      return false;
    }

    //Check if the current child entity is V6 login control guid
    if (childEntity.DataUnits[0].Value === '{f4f0fd71-84ad-4285-a4d6-1eec1ab5f09a}') {

      if (!this.globalFunctions.isEmpty(parentEntity.ChildEntity[0])) {
        if (!this.globalFunctions.isEmpty(parentEntity.ChildEntity[0].ChildEntity[0])) {
          //Only display the reset button for individual type
          if (parentEntity.ChildEntity[0].ChildEntity[0].EntityName === "Individual") {
            return true;
          }
        }
      }
    }

    return false;
  }

  public IsResettingPassword = false;

  public ResetBorrowerPassword_Click(entity, parentEntity): void {
    //Let's do some basic client side validation
    if (this.globalFunctions.isEmpty(entity)) {
      this.notifyService.Error_Show("No client selected.", "Reset Password");
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());

    //Use html content so that we can style it
    dialogRef.componentInstance.htmlContent = "Are you sure that you want to reset the password for \"" + parentEntity.DataUnits.filter(x => x.Name == "Client")[0].ValueDisplay.trim() + "\"?"

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Track state of this request, so that we can show the spinner, if necessary.
        this.IsResettingPassword = true;
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Construct the request and send it to the server
        const apiRequest = { AccountID: this.loanIndex.AccountID, UserNumber: entity.DataUnits[1].Value };

        this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.XV6Password_Reset], apiRequest)
          .subscribe(apiResponse => {
            if (this.globalFunctions.isEmpty(apiResponse)) {
              //There was no response, or an error.
              this.IsResettingPassword = false;
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {
              //Get the response, and try to display it in the document viewer
              //var response = JSON.parse(JSON.stringify(apiResponse));
              //console.log('response', response);

              this.notifyService.Success_Show("The password for the client has been reset.", "Password Reset");

              //Turn off the fullscreen loading
              this.clientDataStore.SetShowFullscreenLoading(false);
              this.IsResettingPassword = false;
              return;
            }
          });
      }
    }
    );
  }

}

