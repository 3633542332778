import { Component, OnInit, Input, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { DashboardCalendar, ILabelColor, DateValue, DataSet } from '@app/Components/Dashboard/DashBoardCalendar';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { groupBy, sumBy, map } from 'lodash-es';
import Chart from 'chart.js';
import { animate, style, transition, trigger } from '@angular/animations';
import { ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartDrillThrough } from '@app/Components/Dashboard/Charts/ChartDrillThrough/ChartDrillThrough';

//Custom chart class that will construct and render Chart.js elements for us
@Component({
  selector: 'CustomChart',
  templateUrl: './CustomChart.html',
  styleUrls: ['./CustomChart.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class ChartComponent implements OnInit, AfterViewInit {

  constructor(public elementRef: ElementRef, private globalFunctions: GlobalFunctions, private dialog: MatDialog) {
    //let's have the spinner chart render so it can have the right size. this will get overridden later when the ChartConstructor is called.
    this.ChartData = this.SampleData;
    this.CreateChart(null, null, "Loading", "Loading");
    this.ShowSpinner = true;
  }

  ngOnInit(): void {
    Chart.defaults.global.defaultFontFamily = "Roboto";
  }

  //Construct the chart after the first view has initialized
  ngAfterViewInit() {
    //console.log('CustomChart ngAfterViewInit');
    //Need a small delay to let the html render the canvas in, otherwise the ChartJS html property will be missing. Needs to be slightly higher than the delay on Dashboard.ts, otherwise it won't find the html element
    this.globalFunctions.delay(this.ChartDrawDelay + 10).then(any => {
      this.DisplayCharts();
    });
  }

  //Inputs required
  @Input() LenderName: string;
  @Input() ColumnName: string;
  @Input() ChartName: string;
  @Input() ChartType: string;
  @Input() FormatCurrency = true;
  @Input() ChildChartProps: any[] = [];
  @Input() LabelColor: Array<ILabelColor> = new Array<ILabelColor>();
  @Input() ChartDataStyle: string;
  @Input() ChartDrawDelay: number;
  @Input() IsStacked: boolean;
  @Input() ShowAllDataLabels = false;
  @Input() GroupByPortfolio: boolean;
  //Our version of the dashboard data that we want this chart to display. This will get supplied by the parent component (dashboard)
  @Input() public DashboardArray: DashboardCalendar[] = new Array<DashboardCalendar>();
  //Historical data
  @Input() public DashboardArrayHistorical: DashboardCalendar[] = new Array<DashboardCalendar>();
  //Future dated data
  @Input() public DashboardArrayFuture: DashboardCalendar[] = new Array<DashboardCalendar>();

  //The html chart canvas
  @ViewChild('ChartID') ChartJS: any;

  //The constructed Chartjs instance that we can destroy on close
  public ChartJSConstructed: any;

  //Used by the generic CreateChart method to store and display Chart Data into the page
  public ChartData: any;

  //Used for grouping
  public GroupedChartData: any;

  //An array of colors that can be used for portfolios. shared across charts for consistency (same color for each portfolio in each chart)
  public BackgroundDefaultColors: Array<string> = ["#007099", "#0096cc", "#00adee", "#1ac2ff", "#4dcfff", "#460716", "#740b24", "#990f2f", "#ba1239", "#e81748", "#464207", "#746f0b", "#99910F", "#bab112", "#e8de17", "orange", "brown", "yellow", "green", "blue"];

  //ChartJS related plugins
  public plugins: any = [
    ChartDataLabels,
    {
      //This lets us resize the chart after it has been intialized, so that we can make some room for the legend at the top
      beforeInit: function (chart) {
        chart.legend.afterFit = function () {
          this.height = this.height + 50;
        }
      }
    }];

  //ChartJS options
  public options: any;

  //ChartJS Name
  public name: any;

  //Whether or not to show the loading spinner
  public ShowSpinner = true;

  //Used for CalendarDate display
  private Months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //Static readonly properties, could be moved to globals. is used by the label splitter (GetLabelName) method
  private static readonly LabelSeparator = "_lbl_";

  //Used to replace a minus
  private static readonly MinusToken = "_MIN_";

  //Some sample data for the spinner version to use
  public SampleData = {
    //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    labels: [],
    datasets: [
      {
        label: 'Loading',
        backgroundColor: '#42A5F5',
        borderColor: '#1E88E5',
        //data: [0, 0, 0, 0, 0, 0, 0]
        data: []
      }
    ]
  }

  //When a data point is clicked, will call the server for drill through data.
  public ShowDrillThrough(event: any) {
    const clickedElement = this.ChartJSConstructed.getElementAtEvent(event)[0];

    //If we didn't find an element, don't continue
    if (this.globalFunctions.isEmpty(clickedElement)) {
      return;
    }

    //We need to keep a ref to this modal, so that we can close it later
    const chartDrillThroughModal = this.dialog.open(ChartDrillThrough, this.globalFunctions.GetFeatureModalConfig("70%"));

    //Pass a copy of the data required to the child component
    chartDrillThroughModal.componentInstance.ChartName = this.ChartName;
    chartDrillThroughModal.componentInstance.LenderName = this.LenderName;
    chartDrillThroughModal.componentInstance.ClickedElement = clickedElement;
    chartDrillThroughModal.componentInstance.ChartData = this.ChartData;
    chartDrillThroughModal.componentInstance.FirstCalendarDate = this.DashboardArray[0].CalendarDate;
  }

  public DisplayCharts() {
    //Put the spinner on while we load
    this.ShowSpinner = true;

    //Construct chart using data from the parent dashboard array
    this.ChartConstructor();

    //Build and display the chartjs locally
    this.BuildChartJS();

    //Turn spinner off, chart is ready for viewing
    this.ShowSpinner = false;
  }

  //Clean up chartjs instances otherwise they will leave detached DOM elements!
  ngOnDestroy() {
    if (!this.globalFunctions.isEmpty(this.ChartJSConstructed)) {
      this.ChartJSConstructed.destroy();
    }
  }

  //Format currency style values
  public FormatCash(n: number): any {
    if (isNaN(n)) return n;

    //ABS just for formatting, the negative sign will be reinstated at the end
    const N = Math.abs(n)

    //Declare the return value
    let returnValue = "";

    if (N < 1e3 && N > 0) { returnValue = N.toFixed(2) }
    if (N >= 1e3 && N < 1e6) returnValue = (N / 1e3).toFixed(2) + "K";
    if (N >= 1e6 && N < 1e9) returnValue = (N / 1e6).toFixed(2) + "M";
    if (N >= 1e9 && N < 1e12) returnValue = (N / 1e9).toFixed(2) + "B";
    if (N >= 1e12) returnValue = (N / 1e12).toFixed(2) + "T";

    //Check and display the negative sign before the currency symbol
    if (n < 0) {
      return "- " + "$" + returnValue;
    }
    else if (n > 0) {
      return "$" + returnValue;
    }
    else if (n == 0) {
      return "$0.00";
    }
    else {
      return "$" + returnValue;
    }
  }

  public RefreshMyself() {
    this.ChartConstructor();
  }

  //Used to assign colours in order, where we don't have a fixed values (e.g. portfolio names)
  public GetColorByIndex(index: string) {
    return this.BackgroundDefaultColors[index];
  }

  //Get the color based on the supplied labelname
  public GetColorByLabel(label: string) {
    //look for the matching color
    const foundColor = this.LabelColor.filter(x => x.label === label);

    //If found
    if (foundColor.length > 0) {
      //Check what type do we want to return. hover color is identical at the moment, not sure if we need the demarcation.
      return foundColor[0].color;
    }
    else {
      //No color found, return grey
      return 'grey';
    }
  }

  //Does all the boilerplate stuff for us in chart generation, and creates it
  public ChartConstructor(): void {
    //Feed the future array for the maturity chart.
    if (this.ChartName === "Maturities") {
      //Maturity is forward looking.
      this.DashboardArray = this.DashboardArrayFuture;
    }
    //Pie charts don't have time series, data, they are based on a single point in time
    else if (this.ChartType === "pie") {
      this.DashboardArray = (this.DashboardArrayHistorical.filter(x =>
        (new Date(x.CalendarDate).getMonth().toString() === (new Date()).getMonth().toString())));
    }
    else {
      //All others are historical
      this.DashboardArray = this.DashboardArrayHistorical;
    }

    if (this.DashboardArray != null) {
      //Set the dashboard array to the value that was passed in from the parent, if its not null. don't forget to filter it for the lender name
      this.DashboardArray = this.DashboardArray.filter(x => x.LenderName_lbl_Lender_Name == this.LenderName);
    }

    //Create a child property that the parent can access
    this.ChildChartProps[this.LenderName + this.ChartName] = { isHidden: false };
    //Grab the y axis columns we want for this chart. we could loop through all columns that match a convention based prefix.
    const columnArray: any[] = [];
    //Check if we have any data
    if (this.DashboardArray.length == 0) {
      return;
    }

    //We now have an array of all the columns we want to map. create a dataset for each column that we want to put into the chart. use our generic method to do it
    //Just grab the first row to work out the properties. if we have any data, that is.
    for (const property of Object.keys(this.DashboardArray[0])) {
      //Starts with lets us grab any convention based stuff, e.g. status columns all start with "Status_"
      if (property.startsWith(this.ColumnName)) {
        columnArray.push(property);
      }
    }

    //Declare a dataset array we can pass down by reference
    const myDatasetsArray: DataSet[] = new Array<DataSet>();
    let groupedxAxisArray: any[] = [];

    //We also want to group the x axis, since we may have mutliple values per portfolio
    groupedxAxisArray = [...new Set(this.DashboardArray.map(item => item.CalendarDate))];

    if (this.GroupByPortfolio) {
      //Generate a single data set on the aggregate of portfolios
      this.GenerateDataSets(columnArray, myDatasetsArray as DataSet[], '', '', this.GroupByPortfolio);
    }
    else {
      //NOT grouping by portfolio. loop through each portfolio, and generate a dataset for each one
      const portfolioArray = [...new Set(this.DashboardArray.map(item => item.PortfolioName_lbl_Portfolio_Name))];
      for (const portfolioArrayItem in portfolioArray) {
        this.GenerateDataSets(columnArray, myDatasetsArray as DataSet[], portfolioArray[portfolioArrayItem], portfolioArrayItem);
      }
    }

    //Check if the datasets is empty (no data, or sum to zero) to determine if we hide the chart
    //Start/default as hidden
    this.ChildChartProps[this.LenderName + this.ChartName].isHidden = true;

    //Now loop through each dataset and check
    for (const key in myDatasetsArray) {
      const sum1 = myDatasetsArray[key]?.data?.reduce((x, y) => x + y, 0) ?? 0;
      if (sum1 !== 0) {
        //If there is any value, the chart is unhidden.
        this.ChildChartProps[this.LenderName + this.ChartName].isHidden = false;
      }
    }

    //Call the create method. check if we need to pivot results
    if (this.ChartDataStyle === "pivot") {
      //Just use the label from the first dataset as the x axis for pivoted charts (pie charts really)
      this.CreateChart(myDatasetsArray[0].label, myDatasetsArray, this.ChartName, this.ChartType);
    }
    else if (this.ChartDataStyle === "regular") {
      //Usual timeseries based method, where the x Axis is the Cal Date. send the aggregated x axis
      this.CreateChart(groupedxAxisArray.map(a => (this.Months[(new Date(a)).getMonth()] + ' ' + (new Date(a)).getFullYear())), myDatasetsArray, this.ChartName, this.ChartType);
    }

    //this.ShowSpinner = false;
  }

  //Generates datasets for ChartConstructor, 
  public GenerateDataSets(columnArray: any[], myDatasets: DataSet[], portfolioName: string, portfolioIndex: string, groupByPortfolio = false): DataSet[] {
    //Grab the data specific for this column and portfolioName
    let dashArrayFiltered: any[] = [];

    //If we are grouping, filter the array
    if (groupByPortfolio) {
      dashArrayFiltered = this.DashboardArray;
    }
    else {
      dashArrayFiltered = this.DashboardArray.filter(x => x.PortfolioName_lbl_Portfolio_Name === portfolioName);
    }

    //If we are pivoting the columnArray, then lets approach this a little differently.
    if (this.ChartDataStyle === "pivot") {
      //Here we dont want multiple datasets, just one. used for the pie chart only, at this stage. declare an array to store the single results 
      const dataArray = [];
      //A result of label names
      const labelArray = [];
      const colorArray = [];
      const hovercolorArray = [];

      //Now loop through and fill this single array with the values and label names
      for (const key in columnArray) {
        const value = columnArray[key];

        //Now grab the data specific for this column
        const yData = dashArrayFiltered.map(a => (a[value]));

        //Get the sum so that we can work out if we want to exclude this data set.
        var sum = yData.reduce((sum, current) => sum + current, 0);

        //If a status has a total sum of ZERO data, then let's ignore it. so it wont show on the chart! just break this iteration of the loop.
        if (sum == 0) {
          continue;
        }

        //Work out the label name by getting the string value of the property, and sending it to our label parser
        const label = this.GetLabelName(value);
        var labelColor_BG = null;
        var labelColor_Hover = null;
        var chosenChartyData: any;
        var chosenLabel: any;

        if (groupByPortfolio) {
          this.GroupedChartData = dashArrayFiltered.map(a => { return new DateValue(a['CalendarDate'], a[value]) });

          //Using lodash to group the values by months
          const formatteddata = map(groupBy(this.GroupedChartData, 'dateVal'), (v, k) => ({
            dateVal: k,
            value: sumBy(v, 'value')
          }));

          //Use the grouped version
          chosenChartyData = formatteddata.map(a => (a['value']));
          chosenLabel = label

          //Get label colors
          labelColor_BG = this.GetColorByLabel(label);
          labelColor_Hover = labelColor_BG;
        }
        else {
          //Non grouped version
          chosenChartyData = yData;
          chosenLabel = portfolioName;
          labelColor_BG = this.GetColorByIndex(portfolioIndex);
          labelColor_Hover = labelColor_BG;
        }

        dataArray.push(chosenChartyData);
        labelArray.push(chosenLabel);
        const labelName = this.GetLabelName(columnArray[key]);
        const labelColor = this.GetColorByLabel(labelName);
        colorArray.push(labelColor);
        hovercolorArray.push(labelColor);
      }

      //now declare the single data set we want to pass back
      const myDataSet: DataSet =
      {
        data: dataArray,
        borderColor: colorArray,
        backgroundColor: hovercolorArray,
        hoverBackgroundColor: colorArray,
        label: labelArray,
        showAllDataLabels: this.ShowAllDataLabels
      };

      //Push this into the array of datasets that we want the chart to render
      myDatasets.push(myDataSet);
    }
    else if (this.ChartDataStyle === "regular") {

      //Time series based method. Loop for each data point, we want a dataset for each
      for (const key in columnArray) {
        const value = columnArray[key];

        //Now grab the data specific for this column
        const yData = dashArrayFiltered.map(a => (a[value]));

        //Get the sum so that we can work out if we want to exclude this data set.
        var sum = yData.reduce((sum, current) => sum + current, 0);

        //If a status has a total sum of ZERO data, then let's ignore it. so it wont show on the chart! just break this iteration of the loop.
        if (sum == 0) {
          continue;
        }

        //Work out the label name by getting the string value of the property, and sending it to our label parser
        const label = this.GetLabelName(value);

        var labelColor_BG = null;
        var labelColor_Hover = null;
        var chosenChartyData: any;
        var chosenLabel: any;

        if (groupByPortfolio) {
          this.GroupedChartData = dashArrayFiltered.map(a => { return new DateValue(a['CalendarDate'], a[value]) });

          //Using lodash to group the values by months
          const formatteddata = map(groupBy(this.GroupedChartData, 'dateVal'), (v, k) => ({
            dateVal: k,
            value: sumBy(v, 'value')
          }));

          //Use the grouped version
          chosenChartyData = formatteddata.map(a => (a['value']));
          chosenLabel = label

          //Get label colors
          labelColor_BG = this.GetColorByLabel(label);
          labelColor_Hover = labelColor_BG;
        }
        else {
          //Non grouped version
          chosenChartyData = yData;

          //This modifies the label name of the value that comes from the server
          chosenLabel = portfolioName;
          labelColor_BG = this.GetColorByIndex(portfolioIndex);
          labelColor_Hover = labelColor_BG;
        }

        //Construct the dataset for this yaxis column
        const myDataSet =
          {
            //Inject the label name
            label: chosenLabel,
            //Inject data for the y axis
            data: chosenChartyData,
            stack: this.IsStacked,
            //All the colors can be adjusted above based on the label name, or otherwise.
            borderColor: labelColor_BG,
            backgroundColor: labelColor_BG,
            hoverBackgroundColor: labelColor_Hover,
            labelArray: {} as string[],
            showAllDataLabels: this.ShowAllDataLabels
          } as DataSet;

        //Push this into the array of datasets that we want the chart to render
        myDatasets.push(myDataSet);
      }
    }

    //Return the filled dataset array
    return myDatasets;
  }

  //Returns colors based on index position of labelNames, put empty color value '' in case ILabelColor not defined by that name
  public GetPieColorArray(labelNameArray: Array<string> | string, labelColor: Array<ILabelColor>): Array<string> {
    const colors = new Array<string>();
    for (let i = 0; i < labelNameArray.length; i++) {
      if (labelColor.find(x => x.label === labelNameArray[i]) != undefined) {
        colors.push(labelColor.find(x => x.label == labelNameArray[i]).color);
      }
      else
        //Push white if not found
        colors.push('white');
    }

    return colors;
  }

  //Generic method that creates a chart based on passed in options. can add more params if we like too, e.g. the options object - font styles, color, chart type (pie vs bar) etc.
  public CreateChart(xAxisArray: string | string[], dataSets: DataSet[], chartName: string, chartType: string): void {

    //Set the chart title
    this.name = chartName;
    this.ChartType = chartType;

    //For pie charts, colors are in the first dataset
    if (chartType === 'pie') {
      dataSets[0]["backgroundColor"] = dataSets[0].borderColor;
    }

    //The callback in the function below doesn't have access to the class variable. create a local copy of it here.
    const format = this.FormatCurrency;

    this.ChartData = {
      //Inject data for the x axis
      labels: xAxisArray,
      //Add all the datasets that we need
      datasets: dataSets
    };

    this.plugins = [
      ChartDataLabels,
      {
        beforeInit: function (chart) {
          chart.legend.afterFit = function () {
            this.height = this.height + 20;
          }
        }
      }];

    this.options = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        position: 'top',
        align: 'end',
        //This makes the icon change to a pointer when the legend is hovered on by the mouse pointer
        onHover: function (e) {
          e.target.style.cursor = 'pointer';
        },
        labels: {
          usePointStyle: true,
          fontColor: '#8E8E93',
        }
      }
      ,
      tooltips: {
        callbacks: {
          label: (item, data) => {
            //Tried injecting html to rendering (e.g. a bold tag) but it didnt work.
            if (chartType === 'pie') {
              return data.datasets[0].label[item.index] + ':' + data.datasets[0].data[item.index];
            }
            return data.datasets[item.datasetIndex].label + ": " + (this.FormatCurrency ? this.FormatCash(item.yLabel) : item.yLabel);
          },
        }
      },
      //This makes the icon change to a pointer when the chart content is hovered on by the mouse pointer
      hover: {
        onHover: function (e) {
          const point = this.getElementAtEvent(e);
          //Supressing ESLint for this line, I can't figure out how to make it work.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (point.length) {
            e.target.style.cursor = 'pointer';
          }
          else {
            e.target.style.cursor = 'default';
          }
        }
      },
      scales: {
        yAxes: [{
          stacked: this.IsStacked,
          padding: 10,
          display: chartType === 'pie' ? false : true,
          scaleLabel: {
            show: true,
            labelString: 'Value'
          },
          gridLines: {
            color: '#D1D0D0',
            zeroLineColor: '#fff',
          },
          ticks: {
            fontColor: "#8E8E93",
            beginAtZero: true,
            padding: 2,
            display: chartType === 'pie' ? false : true,
            callback: (value) => {
              //Using the local variable (as class variable is not in scope in the callback)
              let result = value;

              if (format) {
                //This is a currency style formatting
                result = value < 0 ? '(' + format ? this.FormatCash(value) : value + ')' : format ? this.FormatCash(value) : value;
              }
              return result;
            }
          }
        }],
        xAxes: [{
          stacked: this.IsStacked,
          padding: 10,
          display: chartType === 'pie' ? false : true
          , gridLines: {
            display: false,
          }
          , ticks: {
            fontColor: "#8E8E93",
          }
        }]
      },
      plugins: {
        datalabels: {
          color: (chartType === 'pie' ? 'white' : 'white'),
          font: chartType === 'pie' ? {} : {},
          anchor: chartType === 'pie' ? 'center' : 'end',
          display: function (context) {
            //Force any zero data labels to not be rendered in the chart, they don't actually contribute anything useful, and check if ShowAllDataLabels claim exists, otherwise fallback to auto
            return context.dataset.data[context.dataIndex] === 0 ? false : context.dataset.showAllDataLabels === true ? true : 'auto';
          },
          align: chartType === 'pie' ? 'center' : 'end',
          formatter: (value) => {
            return format ? this.FormatCash(value) : value;
          }
        }
      }
    }

    //Can't build the chart here as the angular view is still not ready. Rely on ngAfterViewInit instead for first initialization
  }

  //Build Charts using native Chartjs
  public BuildChartJS() {
    //console.log('BuildChartJS for', this.LenderName);

    //If it doesn't exist, the chart might be empty. Don't try to construct it
    if (this.globalFunctions.isEmpty(this.ChartJS)) {
      //console.log('BuildChartJS empty!');
      return;
    }

    //Make a chartjs version
    const chartItem = this.ChartJS.nativeElement;

    //console.log('this.data', this.data);
    //console.log('this.options', this.options);

    this.ChartJSConstructed = new Chart(chartItem, {
      type: this.ChartType
      , data: this.ChartData
      , options: this.options
      //Might need to give this plugins as well
      , plugins: this.plugins
    });
  }

  //If the chart is still loading, adjust its height so the loading spinner takes precedence
  public ShowChart() {
    if (!this.ShowSpinner) {
      return 'chartHeight'

    }
    else return 'chartNoHeight'
  }

  //Below are helper methods
  //This checks a string, if it finds the separator, will split and return the label name. otherwise, return the original
  public GetLabelName(label: string): string {
    let labelResult = label;
    if (labelResult.includes(ChartComponent.LabelSeparator)) {
      labelResult = label.split(ChartComponent.LabelSeparator)[1];
    }

    //Replace MIN with minus
    labelResult = this.ReplaceAll(labelResult, ChartComponent.MinusToken, "-")
    //Replace all remaining underscore with space
    labelResult = this.ReplaceAll(labelResult, "_", " ")
    return labelResult.trim();
  }

  //Gets all instances of a string and replaces it with the other, helper method for the label
  public ReplaceAll(str, find, replace): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }
}