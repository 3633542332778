<div #SLAReportingComponent id="PG_SLAReporting" class="glb_formBGColor glb_boxedPadding glb_Font text-white glb_Font">
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
      <div class="col-12 glb_customFlexRow glb_paddingBottomMD align-items-center glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-start glb_fontColorSkin glb_breakLongWords glb_paddingZeroOffset col-6">
          SLA Reporting
        </div>
      </div>
    </div>
  </div>
  <div class="glb_Font header-padding glb_customFlexRow col-12">
    <div class="glb_customFlexRow glb_paddingZeroOffset">
      <div class="glb_customFlexRow glb_paddingBottomMD align-items-center glb_paddingZeroOffset">
        <div
          class="glb_customFlexRow align-items-center glb_TitleSM text-start glb_fontColorSkin text-white glb_breakLongWords glb_paddingZeroOffset">
          SLA Calculations as of: {{SLACalcDateDisplay}}
          <div *ngIf="ShowSLACacheRefreshButton"
            class="glb_customFlexRow align-items-center glb_paddingLeft justify-content-start glb_paddingZeroOffset">
            <button id="BTN_RefreshCache"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right"
              (click)="SLACacheData_Refresh()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'gears']" matTooltip="Click to Refresh SLA Categories">
              </fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow justify-content-start col-12" style="background-color: white; padding: 10px;">
    <!-- Lender-->
    <div class="col-12">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="glb_inputFieldLabel glb_customFlexRow text-dark" style="padding-left: 5px;">Choose Lenders</div>
        <div class="glb_customFlexRow col-12">
          <div class="glb_customFlexRow justify-content-left align-items-left left-padding text-dark col-9" style="padding: 5px;">
            <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom" appearance="outline">
              <!-- <mat-label class="glb_Font">Choose a Lender</mat-label> -->

              <input #LenderConfig_FocusCreateLenderName id="INP_Lenders" (click)="AutoComplete_Client_ApplyFilter(LenderAutoComplete, 'Search',
                  insertLenderName.value, 'Lender', true)" (keydown)="AutoComplete_Client_ApplyFilter(LenderAutoComplete, 'Search',
                  insertLenderName.value, 'Lender', true)" #insertLenderName type="text" matInput [matAutocomplete]="insertLenderName_autoComplete"
                placeholder="Enter a value to search" [disabled]="!AreInputsActive || AllLendersChecked"
                class="mat-form-field-autocompleteplaceholder">
              <mat-autocomplete #insertLenderName_autoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, SLAReportingLender, 'Lender')" [panelWidth]="500">
                <div *ngFor="let control of AutoComplete_Client_ApplyFilter(LenderAutoComplete, 'Search',
                    insertLenderName.value, 'Lender')">
                  <mat-option class="glb_Font" [value]=control>
                    {{control.ControlValue}}
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="glb_customFlexRow justify-content-left align-items-left left-padding text-dark col-3">
            <span class="glb_customFlexRow justify-content-end align-items-center">
              <mat-checkbox id="CB_AllLenders" class="glb_Font glb_PaddingHorizontalSM mat-checkbox-light-custom" [checked]="AllLendersChecked"
                [disabled]="!AreInputsActive" (change)="AllLendersCheckBox_Click($event)">All Lenders</mat-checkbox>
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-10 col-md-12 col-sm-12 col-12">
        <div class="glb_inputFieldLabel glb_customFlexRow text-dark" style="padding-left: 5px;" *ngIf="!AllLendersChecked">Lenders Selected:</div>
        <div class="glb_customFlexRow justify-content-start row-cols-6">
          <div class="glb_customFlexRow justify-content-left align-items-left text-dark col" *ngFor=" let chosenLender of ChosenLenders"
            style="padding: 3px;">
            <div class="glb_boxedPaddingNarrow glb_roundedBorderSM glb_customFlexRow align-items-center col-12 chosen-items-border"
              [ngClass]="SelectedClientBG_GetCSS(chosenLender)">
              <div class="col-10" matTooltip="{{chosenLender.ControlDisplay}}" matTooltipPosition="below">
                {{TextSize_Limit(chosenLender.ControlDisplay,18)}}
              </div>
              <div class="col-2">
                <span class="glb_customFlexRow justify-content-end align-items-center">
                  <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]=chosenLender.IsChecked
                    (change)="LenderCheckBox_Click(chosenLender, $event)" [disabled]="!AreInputsActive"></mat-checkbox>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Assigned To-->
    <div class="col-12">
      <div class="col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="glb_inputFieldLabel glb_customFlexRow text-dark" style="padding-left: 5px;">Choose Assignees</div>
        <div class="glb_customFlexRow col-12">
          <div class="glb_customFlexRow justify-content-left align-items-left left-padding text-dark col-9" style="padding: 5px;">
            <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom" appearance="outline">
              <input id="INP_Assignees" (click)="AutoComplete_Client_ApplyFilter(AssigneeAutoComplete, 'Search',
                      insertAssignee.value, 'Assigned To', true)" (keydown)="AutoComplete_Client_ApplyFilter(AssigneeAutoComplete, 'Search',
                      insertAssignee.value, 'Assigned To', true)" #insertAssignee type="text" matInput
                [matAutocomplete]="insertAssignee_autoComplete" placeholder="Enter a value to search"
                [disabled]="!AreInputsActive || AllAssigneesChecked" class="mat-form-field-autocompleteplaceholder">
              <mat-autocomplete #insertAssignee_autoComplete="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                (optionSelected)="AutoComplete_SaveSelectedControlRecord($event.option, SLAAssignee, 'Assigned To')" [panelWidth]="500">
                <div *ngFor="let control of AutoComplete_Client_ApplyFilter(AssigneeAutoComplete, 'Search',
                        insertAssignee.value, 'Assigned To')">
                  <mat-option class="glb_Font" [value]=control>
                    {{control.ControlValue}}
                  </mat-option>
                </div>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="glb_customFlexRow justify-content-left align-items-left left-padding text-dark col-3">
            <span class="glb_customFlexRow justify-content-end align-items-center">
              <mat-checkbox id="CB_AllAssignees" class="glb_Font glb_FontSize glb_PaddingHorizontalSM mat-checkbox-light-custom"
                [checked]="AllAssigneesChecked" [disabled]="!AreInputsActive" (change)="AllAssigneesCheckBox_Click($event)">All
                Assignees</mat-checkbox>
            </span>
          </div>
        </div>
      </div>
      <div class="col-lg-10 col-md-12 col-sm-12 col-12">
        <div class="glb_inputFieldLabel glb_customFlexRow text-dark" style="padding-left: 5px;" *ngIf="!AllAssigneesChecked">Assignees Selected:</div>
        <div class="glb_customFlexRow justify-content-start row-cols-6">
          <div class="glb_customFlexRow justify-content-left align-items-left text-dark col" *ngFor=" let assignee of Assignees"
            style="padding: 3px;">
            <div class="glb_boxedPaddingNarrow glb_roundedBorderSM glb_customFlexRow align-items-center col-12 chosen-items-border"
              [ngClass]="SelectedClientBG_GetCSS(assignee)">
              <div class="col-10 glb_FontSize" matTooltip="{{assignee.ControlDisplay}}" matTooltipPosition="below">
                {{TextSize_Limit(assignee.ControlDisplay,18)}}
              </div>
              <div class="col-2">
                <span class="glb_customFlexRow justify-content-end align-items-center">
                  <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]=assignee.IsChecked
                    (change)="AssigneeCheckBox_Click(assignee, $event)" [disabled]="!AreInputsActive"></mat-checkbox>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="glb_inputFieldLabel glb_customFlexRow text-dark col-12" style="padding-left: 5px;">Source:</div>
      <div class="col-lg-2 col-md-6 col-sm-6 col-12 glb_customFlexRow" style="padding-left:5px;">
        <mat-form-field appearance="outline">
          <mat-select #matSelect id="DU_MatSelect" class="glb_Font" [(value)]="Source" placeholder="Choose source" [disabled]="!AreInputsActive"
            (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
            <div *ngFor="let control of ControlData_Filter('Task Source')">
              <mat-option class="glb_Font" value={{control.ControlGUID}}>
                {{control.ControlDisplay}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="col-12 glb_customFlexRow glb_paddingTopSM">
      <div class="glb_inputFieldLabel glb_customFlexRow text-dark col-12" style="padding-left: 5px;">Choose From and To Dates:</div>
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 glb_customFlexRow">
        <div class="glb_customFlexRow col-6 justify-content-center align-items-center" style="padding: 5px;">
          <div class="col-10 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:0px;">
            <!-- <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: 1.0em;">From Date</div> -->
            <p-calendar id="INP_FromDate" #calendarFromDate [(ngModel)]="DTPFromDate.JSDate" class="DTPInputGrid" [showTime]="true"
              [showSeconds]="false" [maxDate]="DTPMaxDate" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true" [inline]="false"
              [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false" [dateFormat]="DTPDateFormat" [hourFormat]="12"
              [appendTo]="'body'" baseZIndex="15000" inline="false" inputStyleClass="DTPInputStyle glb_Font" placeholder="From Date"
              [disabled]="!AreInputsActive">
              <ng-template pTemplate="footer">
                <div class="glb_customFlexRow justify-content-center">
                  <button id="BTN_CloseCalendar"
                    class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white"
                    (click)="DTP_Close(calendarFromDate, DTPFromDate)">OK</button>
                </div>
              </ng-template>
            </p-calendar>
            <div class="floatTopRightCalendarSLA">
              <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="calendarFromDate.toggle()">
                <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
              </span>
            </div>
          </div>

          <!-- button to clear content inside the input box, spans a div of its own -->
          <div class="col-2 glb_PaddingHorizontalSM" style="padding-bottom:0px; padding-top:0px;">
            <!-- <button tabindex="-1"
              class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
              matTooltip="Click to Clear" (click)="DTPFromDate_ClearData()">
              <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
            </button> -->
          </div>
        </div>
        <div class="glb_customFlexRow col-6 justify-content-center align-items-center" style="padding: 5px;">
          <div class="col-10 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:0px;">
            <!-- <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: 1.0em;">To Date</div> -->
            <p-calendar id="INP_ToDate" #calendarToDate [(ngModel)]="DTPToDate.JSDate" class="DTPInputGrid" [showTime]="true" [showSeconds]="false"
              [maxDate]="DTPMaxDate" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true" [inline]="false" [showOtherMonths]="false"
              [hideOnDateTimeSelect]="false" [touchUI]="false" [dateFormat]="DTPDateFormat" [hourFormat]="12" [appendTo]="'body'" baseZIndex="15000"
              inline="false" inputStyleClass="DTPInputStyle glb_Font" placeholder="To Date" [disabled]="!AreInputsActive">
              <ng-template pTemplate="footer">
                <div class="glb_customFlexRow justify-content-center">
                  <button id="BTN_CloseCalendar"
                    class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white"
                    (click)="DTP_Close(calendarToDate, DTPToDate)">OK</button>
                </div>
              </ng-template>
            </p-calendar>
            <div class="floatTopRightCalendarSLA">
              <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="calendarToDate.toggle()">
                <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
              </span>
            </div>
          </div>

          <!-- button to clear content inside the input box, spans a div of its own -->
          <div class="col-2 glb_PaddingHorizontalSM" style="padding-bottom:0px; padding-top:0px;">
            <!-- <button tabindex="-1"
              class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
              matTooltip="Click to Clear" (click)="DTPToDate_ClearData()">
              <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12 glb_customFlexRow">
        <!-- Generate button-->
        <div *ngIf="AreInputsActive" class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="glb_paddingBottomSM glb_paddingTopSM d-grid">
            <button id="BTN_GenerateSLAReporting" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
              (click)="SLAData_Get()">
              <span class="glb_Font glb_smallerText">Generate</span>
            </button>
          </div>
        </div>

        <!-- Modify button-->
        <div *ngIf="!AreInputsActive" class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="glb_paddingBottomSM glb_paddingTopSM d-grid">
            <button id="BTN_ModifySLAReporting" tabindex="3" class="glb_clearButton glb_skinLightOutline btn glb_BGColorClear text-white"
              (click)="SLADataInputs_Toggle()" [disabled]="ShowSpinner">
              <span class="glb_Font glb_smallerText">Modify</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Nav Tabs for SLA Type reports-->
  <div id="SLANavBarContent" class="glb_Font col-12" style="padding-top: 10px;">
    <div class="nav nav-tabs glb_customFlexRow row-cols-2 row-cols-sm-4 row-cols-md-6 row-cols-lg-9 col-lg-12" style="padding-right:0px;"
      (click)="NavBarItem_Clicked($event)">
      <button *ngFor="let navTabSLA of NavTabsSLATypes; index as itemIndex" [ngClass]="CurrentNavBarItem_Get(navTabSLA.Key)"
        class="glb_customFlexRow align-self-stretch navItemBorder nav-item nav-link" id="{{navTabSLA.Key}}">
        <!-- Text -->
        <div class="glb_customFlexRow justify-content-center align-self-center" id="{{navTabSLA.Key}}" style="width:100%;">
          <div class="glb_TitleSmall glb_TitleSmallMobile" id="{{navTabSLA.Key}}">
            {{SLANavTabDisplay_Get(navTabSLA.Key, NavTabsSLATypes)}}
          </div>
        </div>
      </button>
    </div>
  </div>

  <!-- Active tab -->
  <div [ngClass]="CurrentNavItem_ForceRemove('LBL_Active')">
    <div [ngClass]="CurrentNavItem_Show('LBL_Active')">
      <SLAReportingSummary #ActiveTab [SLAReporting]="this" [AggregateData]="this.SLAReportingActive" [SLAColors]="this.SLAColors"
        TabName="LBL_Active" [OverrideDueDateFlag]=0 [TaskTypeLengthLimit]=25 [IsActiveNavTab]=true>
      </SLAReportingSummary>
    </div>
  </div>

  <!-- Active - Override tab -->
  <div [ngClass]="CurrentNavItem_ForceRemove('LBL_ActiveOverride')">
    <div [ngClass]="CurrentNavItem_Show('LBL_ActiveOverride')">
      <SLAReportingSummary #ActiveOverrideTab [SLAReporting]="this" [AggregateData]="this.SLAReportingActiveOverride" [SLAColors]="this.SLAColors"
        [OverrideDueDateFlag]=1 TabName="LBL_ActiveOverride" [TaskTypeLengthLimit]=25 [IsActiveNavTab]=true>
      </SLAReportingSummary>
    </div>
  </div>

  <!-- Completed tab -->
  <div [ngClass]="CurrentNavItem_ForceRemove('LBL_Completed')">
    <div [ngClass]="CurrentNavItem_Show('LBL_Completed')">
      <SLAReportingSummary #CompletedTab [SLAReporting]="this" [AggregateData]="this.SLAReportingCompleted" [SLAColors]="this.SLAColors"
        TabName="LBL_Completed" [OverrideDueDateFlag]=0 [TaskTypeLengthLimit]=13 [IsActiveNavTab]=false>
      </SLAReportingSummary>
    </div>
  </div>

  <!-- Completed - Override tab -->
  <div [ngClass]="CurrentNavItem_ForceRemove('LBL_CompletedOverride')">
    <div [ngClass]="CurrentNavItem_Show('LBL_CompletedOverride')">
      <SLAReportingSummary #CompletedOverrideTab [SLAReporting]="this" [AggregateData]="this.SLAReportingCompletedOverride"
        [SLAColors]="this.SLAColors" TabName="LBL_CompletedOverride" [OverrideDueDateFlag]=1 [TaskTypeLengthLimit]=13 [IsActiveNavTab]=false>
      </SLAReportingSummary>
    </div>
  </div>
</div>