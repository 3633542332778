import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { StaticDataControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'UserProfile',
  templateUrl: './UserProfile.html',
  styleUrls: ['./UserProfile.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class UserProfile implements OnInit {

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog) {

    //This is called even when we hit this after a reroute. So grab and update the router state here. Will be used used later in onInit to determine whether or not to reuse cache, or grab fresh data.
    this.RouterState_Set();

  }

  @ViewChild('CurrentPasswordInput') CurrentPasswordInput;
  @ViewChild('NewPasswordInput') NewPasswordInput;
  @ViewChild('ConfirmPasswordInput') ConfirmPasswordInput;
  @ViewChild('TwoFactorCodeInput') TwoFactorCodeInput;
  @ViewChild('ChangePasswordTemplate', { read: TemplateRef }) ChangePasswordTemplate: TemplateRef<any>;

  public AllowClose = true;
  public IsChangingPassword = false;
  public CurrentPassword: string;
  public NewPassword: string;
  public ConfirmPassword: string;
  public TwoFactorCode;
  public DisableTwoFactorInput = true;
  public AppName: string = this.globalFunctions.GetApplicationName();

  //For hiding/revealing the password
  public InputTypePassword = "password";
  public HidePassword = true;

  //For storing and refreshing the router state on this component
  private NavState: string;

  //For storing the clicked on user, their claims and roles
  public ChosenUser = {
    UserGUID: "",
    ClientGUID: "",
    UserNumber: "",
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    MobileNumber: "",
    UserName: "",
    CreaterName: "",
    IsLocked: "",
    IsRegistered: ""
  };

  public ChosenUserRoles: any[];

  //These headers are for the Role section
  public ColumnsInBodyRole = 2;

  public CombineLabelAndData = true;
  public WrapOddRows = false;

  //Used by the HostListener (window resize event) to adjust some text alignment when screen size changes. default it to -1 so that it doesn't get used, until its needed.
  public ColumnWrapping = -1;
  public ColumnsInTab = 1;
  public ShowSpinner = false;

  //Do we allow error buttons to be visible?
  public EnableErrorGenerator = false;

  //The user list from the server
  private UserList;

  private DialogRef;
  private ChangePasswordLaunched = false;

  ngOnInit() {
    this.Users_Get();

    //Check if the user has a claim to see the throw error buttons.
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {
      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "ErrorGenerator").length > 0) {
        this.EnableErrorGenerator = true;
      }
      else {
        this.EnableErrorGenerator = false;
      }
    }
  }

  //Removes this dialog
  public Dialog_Close() {
    if (this.AllowClose) {

      //Don't forget to reset the state of the modal, without this it won't be launchable again!
      this.ChangePasswordLaunched = false;

      //Now close this dialog, but with a false result (dont tell GlobalFunctions to refresh pages, or we'll land at an empty dashboard!)
      this.DialogRef.close(false);
    }
  }

  //Used for clearing local cached content
  public Cache_Clear() {
    sessionStorage.clear();
    localStorage.clear();
    this.notifyService.Success_Show("Cached data has been cleared", "Clear cache successful");
  }

  //Validate password inputs and enable 2FA input
  public PasswordInputs_Verify() {
    this.DisableTwoFactorInput = !this.Passwords_Validate();
    if (this.DisableTwoFactorInput == false) {
      this.globalFunctions.delay(1).then(() => { this.TwoFactorCodeInput.nativeElement.focus(); });
    }
  }

  //Client side password validation
  public Passwords_Validate() {

    //Are the password inputs valid?
    if (!this.globalFunctions.isEmpty(this.CurrentPassword) && !this.globalFunctions.isEmpty(this.NewPassword) && !this.globalFunctions.isEmpty(this.ConfirmPassword) && (this.NewPassword == this.ConfirmPassword) && (this.NewPassword.length >= GlobalFunctions.PasswordMinLength) && (this.NewPassword.length <= GlobalFunctions.PasswordMaxLength)) {
      return true;
    }

    return false;
  }

  //Changing password
  public Password_Change() {

    //Are the inputs valid?
    if (this.globalFunctions.isEmpty(this.CurrentPassword)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Current password is missing");
      this.globalFunctions.delay(1).then(() => { this.CurrentPasswordInput.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.NewPassword)) {
      this.notifyService.Error_Show("Please check and re-enter.", "New password is missing");
      this.globalFunctions.delay(1).then(() => { this.NewPasswordInput.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.ConfirmPassword)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Confirm password is missing");
      this.globalFunctions.delay(1).then(() => { this.ConfirmPasswordInput.nativeElement.focus(); });
      return;
    }

    if (this.NewPassword != this.ConfirmPassword) {
      this.notifyService.Error_Show("Please check and re-enter.", "Passwords are not identical");
      this.globalFunctions.delay(1).then(() => { this.NewPasswordInput.nativeElement.focus(); });
      return;
    }

    if (this.NewPassword.length < GlobalFunctions.PasswordMinLength) {
      this.notifyService.Error_Show("This password is too short, please try again.", "Too short");
      this.globalFunctions.delay(1).then(() => { this.NewPasswordInput.nativeElement.focus(); });
      return;
    }

    if (this.NewPassword.length > GlobalFunctions.PasswordMaxLength) {
      this.notifyService.Error_Show("This password is too long, please try again.", "Too long");
      this.globalFunctions.delay(1).then(() => { this.NewPasswordInput.nativeElement.focus(); });
      return;
    }

    //Validate 2FA input
    if (this.globalFunctions.isEmpty(this.TwoFactorCode)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Two Factor Code is missing");
      this.globalFunctions.delay(1).then(() => { this.TwoFactorCodeInput.nativeElement.focus(); });
      return;
    }

    this.IsChangingPassword = true;
    const apiRequest = { CurrentPassword: this.CurrentPassword, NewPassword: this.NewPassword, ConfirmPassword: this.ConfirmPassword, TwoFactorCode: this.TwoFactorCode };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.ChangePassword], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsChangingPassword = false;
          this.TwoFactorCode = "";
          this.globalFunctions.delay(1).then(() => { this.CurrentPasswordInput.nativeElement.focus(); });
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          //const response = JSON.parse(JSON.stringify(apiResponse));

          //Not much to do here. just a message to let the user know that they can login now. a notification might be a nice touch here
          this.notifyService.Success_Show("Password has been changed", "Password change successful");
          this.IsChangingPassword = false;

          //Going home will trigger a login
          this.Dialog_Close();
        }
      });

  }

  //Throw a client error
  public ClientError_Throw() {

    //Explicitly throw an error
    //throw new Error("Something went catastrophically wrong");

    //Or run a method that doesn't exist
    this.DialogRef.Throwface();
  }

  //Throw a server error
  public ServerError_Throw() {

    //Call the throw method on the server
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.ThrowError])
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          return;
        }
        else {

          //Not much to do here.
        }
      });
  }

  //Change password modal
  public ChangePassword_Launch(template: TemplateRef<any>) {

    //Now flip it on
    this.ChangePasswordLaunched = true;

    //Initialise the inputs
    this.CurrentPassword = "";
    this.NewPassword = "";
    this.ConfirmPassword = "";
    this.TwoFactorCode = "";
    this.DisableTwoFactorInput = true;

    //Construct the modal
    this.DialogRef = this.dialog.open(template, this.globalFunctions.GetFeatureModalConfig('40%'));
  }

  //Reaveal/Hide the password on click and hold
  public PasswordReveal_Toggle(hidePassword: boolean): void {
    if (hidePassword == true) {
      this.InputTypePassword = "password";
      this.HidePassword = true;
    }
    else {
      this.InputTypePassword = "text";
      this.HidePassword = false;
    }
  }

  //Gets the class based on entity setup. this is needed as direct binding inside the html template seems to run into some runtime bugs (might be a limit related to the length of the conditions)
  public Class_Get(Type: string, ColumnsInBody: number, WrapOddRows: boolean = null, isOdd: boolean = null, isEven: boolean = null, CombineLabelAndData: boolean = null
    , itemIndex: number = null, arrayCount: number = null, rowStyle: string = null
  ) {

    //Testing performance, how often is this firing.
    //console.log("getClass firing for:", Type);

    //For data pairs (label + value)
    if (Type == 'DataPair') {
      let returnClass = "";

      //Check if any row specific style need to be applied to the data.
      if (rowStyle === "ghosted") {
        returnClass = returnClass + "ghosted ";
      }
      if (rowStyle === "rowBold") {
        returnClass = returnClass + "rowBold ";
      }
      if (rowStyle === "addToBase") {
        returnClass = returnClass + "addToBase ";
      }
      if (ColumnsInBody === 1) {
        return returnClass + "row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
      }
      if (!WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2';
      }
      if (WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-md-2 row-cols-sm-2 row-cols-lg-2';
      }
      if (ColumnsInBody === 7) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
      if (ColumnsInBody === 6) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-6 row-cols-lg-6';
      }
      if (ColumnsInBody === 5) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-5';
      }
      if (ColumnsInBody === 4) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4';
      }
      if (ColumnsInBody === 8) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
    }

    //For the actual value display
    else if (Type == 'ValueDisplay') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'dataClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-end';
      }
    }

    //For table header values
    else if (Type == 'TableHeaderValues') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'labelClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-end';
      }
    }
  }

  //Gets the state from the router, so that we can use it to modify our load process.
  private RouterState_Set() {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras?.state as {
      Link: string;
    };

    if (state != null) {
      this.NavState = state.Link;
    }
  }

  //Gets all users from the server, used on entry to this component
  private Users_Get() {
    this.ShowSpinner = true;
    const apiRequest = { TargetUserGUID: this.clientDataStore.loginDataDirect.LoggedInUserGUID };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetUsers], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.ShowSpinner = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Store it client side
          this.UserList = response.UserList;

          //We need to unescape HTML encoding on firstname, lastname, email and mobile.
          this.UserList.forEach(e => {
            //console.log(e);
            e.LastName = this.globalFunctions.HTMLUnescape(e.LastName);
            e.FirstName = this.globalFunctions.HTMLUnescape(e.FirstName);
            e.UserName = this.globalFunctions.HTMLUnescape(e.UserName);
            e.EmailAddress = this.globalFunctions.HTMLUnescape(e.EmailAddress);
            e.MobileNumber = this.globalFunctions.HTMLUnescape(e.MobileNumber);
            e.CreaterName = this.globalFunctions.HTMLUnescape(e.CreaterName);
          });

          //And the only user in this should be the chosen one
          this.ChosenUser = this.UserList[0];
          this.ShowSpinner = false;

          //Launch the password change modal with a small delay, based on the nav state
          if (this.NavState == "LaunchPasswordChange") {
            this.globalFunctions.delay(30).then(() => {
              //Launch password change modal
              this.ChangePassword_Launch(this.ChangePasswordTemplate);
            });
          }
        }
      });
  }
}