<div #LoanEntityModifyComponent id="PG_LoanEntityModify" style="padding: 0.25em;padding-left: 30px;">
  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
      <button id="BTN_CloseLoanEntityModify" [disabled]="GetLoanIndex_IsUpdatingEntityRequest()" tabindex="15"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="EntityEditModal_Close()">
        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
      </button>
    </div>
  </div>

  <!-- the overflow: hidden stops the scrollbar for this section appearing (its not needed) -->
  <div class="glb_customFlexRow glb_Font updateEntityModalTitle" style="padding-bottom:1.25em;">
    <div>{{EntityTitleName_Get()}}</div>
  </div>

  <div class="">
    <div *ngIf="DataRowModal.DataUnits !== null">
      <div *ngFor="let dataUnit of DataRowModal.DataUnits | searchfilter">

        <!-- for control type fields (dropdown boxes) -->
        <div *ngIf="dataUnit.Type==='control'">
          <!-- The input box or selectors should be in a row, and span one column -->
          <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center">
            <div class="col-10" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
              <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">{{dataUnit.Name}}</div>
              <mat-form-field appearance="outline">
                <!-- <mat-label class="glb_Font">{{dataUnit.Name}}</mat-label> -->
                <mat-select #matSelect id="DU_MatSelect" class="glb_Font glb_FontSize" [(value)]="dataUnit.SelectedControl"
                  placeholder="Choose a value from the dropdown list" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                  <!-- <div *ngFor="let control of LoanIndex.ControlData"> -->
                  <div *ngFor="let control of LoanIndex.FilterControlData(dataUnit.Name, dataUnit.CustomFilter)">
                    <mat-option class="glb_Font" *ngIf="control.ControlType===dataUnit.Name" value={{control.ControlGUID}}>
                      {{control.ControlValue}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- button to clear content inside the input box, spans a div of its own -->
            <div class="col-2 glb_PaddingHorizontalMD" style="padding-bottom:0px; padding-top:11px;">
              <button tabindex="-1"
                class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
                matTooltip="Click to Clear" (click)="Control_ClearSelected(dataUnit)">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- for autocomplete type fields -->
        <div *ngIf="dataUnit.Type==='autocomplete'">
          <!-- The input box or selectors should be in a row, and span one column -->
          <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center">
            <div class="col-10" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
              <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">{{dataUnit.Name}}</div>
              <mat-form-field class="glb_Font mat-autocomplete-light-custom glb_paddingZeroOffset mat-autocomplete-custom" appearance="outline">
                <input id="DU_MatAutocomplete" (click)="AutoComplete_ApplyFilter(dataUnit, 'Search', inputVal.value, true)"
                  (keydown)="AutoComplete_ApplyFilter(dataUnit, 'Search', inputVal.value, true)" class="glb_Font" #inputVal type="text" matInput
                  [matAutocomplete]="auto" placeholder="Enter a value to search" [(value)]="dataUnit.AutoCompleteControlDisplayValue.value">
                <mat-icon matSuffix>search</mat-icon>
                <mat-autocomplete class="glb_Font" #auto="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                  (optionSelected)='AutoComplete_SaveSelected(dataUnit, $event.option)'>
                  <div *ngFor="let control of AutoComplete_ApplyFilter(dataUnit, 'Search', inputVal.value)">
                    <mat-option class="glb_Font" [value]=control>
                      {{control.ControlValue}}
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <!-- button to clear content inside the autocomplete input box, spans a div of its own -->
            <div class="col-2 glb_PaddingHorizontalMD" style="padding-bottom:0px; padding-top:11px;">
              <button tabindex="-1"
                class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
                matTooltip="Click to Clear" (click)="AutoComplete_ClearSelected(dataUnit, inputVal)">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
              </button>
            </div>
          </div>

        </div>

        <!-- material datepicker type fields -->
        <div *ngIf="(dataUnit.Type==='datetime' || dataUnit.Type==='shortdatetime')">
          <!-- The input box or selectors should be in a row, and span one column -->
          <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center">
            <div class="col-10" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
              <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">{{dataUnit.Name}}</div>
              <mat-form-field class="glb_Font" appearance="outline" style="display: grid; padding-right:0px; padding-left:0px; padding-bottom:0px;">
                <!-- <mat-label class="glb_Font">{{dataUnit.Name}}</mat-label> -->
                <input id="DU_MatDate" matInput class="glb_Font" [matDatepicker]="picker" [(value)]="dataUnit.Value"
                  (dateChange)="DatePicker_Changed(dataUnit, $event)" placeholder="Enter a date (dd/mm/yyyy) or choose a date">
                <mat-datepicker-toggle class="mat-datepicker-light-custom" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- button to clear content inside the input box, spans a div of its own -->
            <div class="col-2 glb_PaddingHorizontalMD" style="padding-bottom:0px; padding-top:11px;">
              <button tabindex="-1"
                class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
                matTooltip="Click to Clear" (click)="TextArea_ClearData(dataUnit)">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- Primeng datetime with time support fields -->
        <div *ngIf="dataUnit.Type==='longdatetime' && dataUnit.ClientDisplay">
          <!-- The input box or selectors should be in a row, and span one column -->
          <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center">
            <div class="col-10 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
              <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">{{dataUnit.Name}}</div>
              <p-calendar id="DU_DTPDate_{{dataUnit.Name}}" #calendar class="DTPInputGrid" [(ngModel)]="dataUnit.ValueDate" [showTime]="true"
                [showSeconds]="false" [maxDate]="DTPMaxDate" [showIcon]="false" [showButtonPanel]="false" [showButtonBar]="true" [inline]="false"
                [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false" [dateFormat]="DTPDateFormat" [hourFormat]="12"
                [appendTo]="'body'" baseZIndex="15000" inputStyleClass="DTPInputStyle glb_Font" placeholder="Choose a date and time"
                [(disabled)]="dataUnit.Disabled" [minDate]="dataUnit.MinDate">
                <ng-template pTemplate="footer">
                  <div id="BTN_CloseCalendar" class="glb_customFlexRow justify-content-center">
                    <button class="glb_standardButton glb_skinLightOutline glb_clickableIconButtonStyle glb_BGColorSkin text-white"
                      (click)="DTP_Close(calendar)">OK</button>
                  </div>
                </ng-template>
              </p-calendar>
              <div class="glb_floatTopRightCalendar">
                <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="calendar.toggle()">
                  <fa-icon [icon]="['fal', 'calendar-clock']" class="fa-md" style="color:black"></fa-icon>
                </span>
              </div>
            </div>

            <!-- button to clear content inside the input box, spans a div of its own -->
            <div class="col-2 glb_PaddingHorizontalMD" style="padding-bottom:0px; padding-top:12px;">
              <button *ngIf="!dataUnit.Disabled" tabindex="-1"
                class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear" type="button"
                matTooltip="Click to Clear" (click)="TextArea_ClearData(dataUnit)" [(disabled)]="dataUnit.Disabled">
                <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- boolean type fields -->
        <div *ngIf="dataUnit.Type.includes('boolean')">
          <div class="col-10" style="padding-left:0px; padding-right:0px;">
            <!-- for boolean this can span the entire row, so left align it. (there is no clear button) -->
            <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 6px;">
              <mat-checkbox class="glb_Font mat-checkbox-light-custom mat-checkbox-font-custom" (change)="CheckBox_Click(dataUnit, $event)"
                [checked]="(dataUnit.Value === 'True' || dataUnit.Value === 'true')" #checkBox>
                {{dataUnit.Name}}</mat-checkbox>
            </div>
          </div>
        </div>

        <!-- Override Due Date Check Box -->
        <div *ngIf="dataUnit.Name === 'Due Date' && ShowOverrideDueDateCheckBox">
          <div class="col-10" style="padding-left:0px; padding-right:0px;">
            <div class="glb_customFlexRow justify-content-left align-items-center" style="padding-bottom: 6px;">
              <mat-checkbox class="glb_Font glb_inputFieldLabel glb_customFlexRow mat-checkbox-light-custom mat-checkbox-font-custom"
                (change)="OverrideDueDateCheckBox_Toggle()">
                Override Due Date (Approved by Manager as an exceptional
                circumstance)</mat-checkbox>
            </div>
          </div>
        </div>

        <!-- most of the other remaining field types will use a text area, e.g. string, currency, decimal, etc. -->
        <div *ngIf="dataUnit.Type==='string' || dataUnit.Type==='percent' || dataUnit.Type.includes('currency') || dataUnit.Type==='integer' ||
          dataUnit.Type.includes('decimal')">
          <!-- The input box or selectors should be in a row, and span one column -->
          <!-- the 1.2 style padding here is to match what angular material does with autocomplete and selectors, so the spacing between fields looks more consistent and easier for eyes to follow -->
          <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center" style="padding-bottom: 6px;">
            <div class="col-10" style="padding-left:0px; padding-right:0px;">
              <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
              <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">{{dataUnit.Name}}</div>
              <textarea id="DU_TextArea" class="textAreaCustom glb_Font" style="width: 100%; white-space: pre-wrap;" placeholder="Enter a value"
                [(value)]="dataUnit.Value" (keydown)="TextArea_OnKeyPress($event, 'keydown', dataUnit)"
                (keyup)="TextArea_OnKeyPress($event, 'keyup', dataUnit)" [ngClass]="{'textAreaHeightRegular' : dataUnit.EditBoxSize === 'Regular' ,'textAreaHeightLarge' : dataUnit.EditBoxSize ===
                'Large','textAreaHeightMedium' : dataUnit.EditBoxSize === 'Medium'}">
              </textarea>
              <fa-icon *ngIf="dataUnit.Type==='string'" [icon]="['fal', 'text']"
                class="fa-fw fa-lg floatingIcons floatingIconsLeft floatingIconsLeftRegular"> </fa-icon>
              <fa-icon
                *ngIf="dataUnit.Type==='percent' || dataUnit.Type==='currency' || dataUnit.Type==='integer' || dataUnit.Type.includes('decimal')"
                [icon]="['fal', 'calculator']" class="fa-fw fa-lg floatingIcons floatingIconsLeft floatingIconsLeftRegular">
              </fa-icon>
            </div>

            <!-- button to clear content inside the input box, spans a div of its own -->
            <div class="col-2 glb_PaddingHorizontalMD align-self-end" style="padding-bottom: 8px;">
              <span>
                <button tabindex="-1"
                  class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                  matTooltip="Click to Clear" (click)="TextArea_ClearData(dataUnit)">
                  <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
              </span>
            </div>

          </div>
        </div>
      </div>

      <!-- This is the upload file control -->
      <div *ngIf="DataRowModal.ShowFilePicker" class="glb_customFlexRow row-cols-2 justify-content-left align-items-center">
        <div class="col-10 glb_paddingZeroOffset">
          <div class="glb_Font glb_inputFieldLabel glb_customFlexRow" style="font-size: .80em;">Choose a file</div>
          <p-fileUpload #fileUpload id="DataUnit_FileUpload" chooseIcon="pi pi-folder" [multiple]="false" maxFileSize="15728640"
            (onSelect)="LoanIndex.FileSelected_Process($event, fileUpload)" (onRemove)="LoanIndex.File_Unselect()" [showCancelButton]="false"
            [showUploadButton]="false" chooseLabel=" " class="p-customFileUpload">
            <ng-template pTemplate="content">
            </ng-template>
          </p-fileUpload>
        </div>
      </div>

      <!-- Save button -->
      <div class="glb_customFlexRow col-11 justify-content-left glb_paddingZeroOffset" style="padding-bottom:30px; padding-top:10px;">
        <div *ngIf="!LoanIndex.isUpdatingEntityRequest" class="col-11 glb_paddingZeroOffset d-grid">
          <button id="BTN_SaveDataRow" (click)="LoanIndex.Entity_UpdateEntityRequest(ShowOverrideDueDateCheckBox, this.CustomConfirmMessage)"
            class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" [disabled]="!EnableSubmitButton" [ngClass]="CSS_Disable()">
            <span class="glb_Font glb_smallerText">{{SaveButtonText}}</span>
          </button>
        </div>

        <div *ngIf="LoanIndex.isUpdatingEntityRequest" class="col-11 glb_paddingZeroOffset d-grid">
          <button class="glb_standardButton btn glb_BGColorSkin text-white">
            <div style="position: relative;" class="spinner-border glb_floatingSpinner"></div>
          </button>
        </div>

      </div>
    </div>
  </div>