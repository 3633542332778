<div id="PG_LeasePayout" class="glb_formBGColor glb_paddingBottomMD glb_Font text-white glb_Font" [ngClass]="ModalIdentifier">

  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow">
    <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start glb_boxedPaddingNarrow align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button id="BTN_MinimizeLeasePayout" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="LeasePayout_MinimizeModal()">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseLeasePayout" tabindex="3"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button" (click)="Modal_Close()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12">
        <div class="glb_customFlexRow col-8 glb_paddingZeroOffset">
          <div class="col-8 glb_paddingZeroOffset">
            <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
              Lease Payout Calculator
            </div>
          </div>
        </div>
        <div class="col-4 glb_boxedPaddingNarrow justify-content-end align-items-center glb_customFlexRow">
          <span>
            <button id="BTN_DownloadLeasePayout"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right"
              (click)="CSV_Download()">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
              </fa-icon>
            </button>
          </span>
        </div>
      </div>
      <br>
      <br>
      <div class="glb_boxedPaddingNarrow col-12">
        <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
          <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM">
            <div class="glb_thinBottomWhiteBorder glb_customFlexRow col-12 glb_paddingZeroOffset row-cols-13">
              <div class="glb_customFlexRow align-items-center">
                <div>Account ID</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Interest Rate</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Discount Rate</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Payout Rate</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Account Type</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Payment Timing</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Uncleared Funds</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Instalment Amt (Ex GST)</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Next Instalment Date</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Residual Amt (Ex GST)</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Loan Balance (Scheduled)</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Remaining Term (Months)</div>
              </div>
              <div class="glb_customFlexRow align-items-center justify-content-end text-end">
                <div>Total Arrears</div>
              </div>
            </div>
          </div>
          <div *ngFor="let item of PayoutCalculations" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
            <div class="glb_customFlexRow glb_paddingZeroOffset glb_paddingBottomSM col-12 row-cols-13">

              <div class="glb_customFlexRow align-items-center">
                <div>{{item.AccountID}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.InterestRate}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.DiscountRate}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.PayoutRate}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.AccountType}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.PaymentTiming}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.UnclearedFunds}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.RepaymentAmount}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.NextInstalmentDueDate}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.ResidualBalance}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.LoanAmount}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{item.RemainingInstalments}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center justify-content-end text-end">
                <div>{{item.ArrearsAmount}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="glb_boxedPaddingNarrow glb_customFlexRow justify-content-end col-12">
        <div class="glb_allBordersSkin glb_Font col-12 col-lg-3 glb_customFlexRow glb_customFlexRow glb_PaddingHorizontalMD">
          <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM glb_paddingTopSM row-cols-2">
            <div class="glb_customFlexRow align-items-center col-7 glb_paddingZeroOffset justify-content-start glb_TitleMD text-end">
              <div>Payout Figure (Incl GST)</div>
            </div>
            <div class="glb_customFlexRow align-items-center col-5 glb_paddingZeroOffset justify-content-end glb_bold">
              <div>{{LeasePayout}}</div>
            </div>
          </div>
          <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM row-cols-2">
            <div class="glb_customFlexRow align-items-center col-7 glb_paddingZeroOffset justify-content-start glb_TitleMD text-end">
              <div>GST</div>
            </div>
            <div class="glb_customFlexRow align-items-center col-5 glb_paddingZeroOffset justify-content-end glb_bold">
              <div>{{GST}}</div>
            </div>
          </div>
          <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM row-cols-2">
            <div class="glb_customFlexRow align-items-center col-7 glb_paddingZeroOffset justify-content-start glb_TitleMD text-end">
              <div>Termination Income</div>
            </div>
            <div class="glb_customFlexRow align-items-center col-5 glb_paddingZeroOffset justify-content-end glb_bold">
              <div>{{TerminationIncome}}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="glb_boxedPaddingNarrow glb_paddingZeroOffset align-items-start glb_customFlexRow row-cols-2 justify-content-left glb_paddingBottomLG col-12">
        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left">
          <div>
            <div class="glb_boxedPaddingNarrow">
              <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left glb_TitleMD glb_importantTextSM">
                {{PayoutMessage}}
              </div>
            </div>
            <div *ngIf="ShowUnclearedFundsNote" class="glb_boxedPaddingNarrow">
              <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left glb_TitleMD glb_importantTextSM">
                {{UnclearedFundsNote}}
              </div>
            </div>
          </div>
        </div>
        <div class="glb_paddingZeroOffset justify-content-end glb_customFlexRow">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_PaddingHorizontalSM">
            <div *ngIf="!IsGenerateInProgress" class="d-grid">
              <button id="BTN_GeneratePayoutLetter" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PayoutLetter_Generate()" [disabled]="PayoutLetterGenerated" matTooltip="Click to generate a payout letter">
                <span class="glb_Font glb_smallerText">Generate Letter</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Generate Letter</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="d-grid">
              <button id="BTN_SaveLeasePayout" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PayoutNote_Save()" [disabled]="PayoutNoteSaved" matTooltip="Click to save as a journal note">
                <span class="glb_Font glb_smallerText">Save Payout Note</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Save Payout Note</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="PayoutLetterGenerated" class="col-12">
          <div class="justify-content-end glb_customFlexRow" style="padding-top: 5px;">
            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left glb_TitleMD glb_importantTextSM">
              The generated letter is available on the document tab.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>