import { Component } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import moment from 'moment';

@Component({
  selector: 'SLAReportingDrillthrough',
  templateUrl: './SLAReportingDrillthrough.html',
  styleUrls: ['./SLAReportingDrillthrough.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class SLAReportingDrillthrough {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SLAReportingDrillthrough>,
    private csvDataService: CsvDataService = null) {

  }

  //Declare the variables
  public SLADrillthroughData;
  public SLACategory;
  public SLADrillthroughDataCSV;
  public TabName;
  public SortingKeysArray = [];
  public ShowColumns = [
    { HeaderName: "LoanID", HeaderDisplay: "Account ID" }
    , { HeaderName: "TaskID", HeaderDisplay: "Task ID" }
    , { HeaderName: "LenderName", HeaderDisplay: "Lender" }
    , { HeaderName: "TaskType", HeaderDisplay: "Task Type" }
    , { HeaderName: "TaskStatus", HeaderDisplay: "Task Status" }
    , { HeaderName: "SLACategory", HeaderDisplay: "SLA Category" }
    , { HeaderName: "Source", HeaderDisplay: "Source" }
    , { HeaderName: "DueDate", HeaderDisplay: "Due Date" }
    , { HeaderName: "OverrideDueDate", HeaderDisplay: "Override Due Date" }
    , { HeaderName: "CompletedDate", HeaderDisplay: "Completed Date" }
    , { HeaderName: "AssignedToName", HeaderDisplay: "Assignee" }
    , { HeaderName: "TaskNote", HeaderDisplay: "Task Note" }
  ];

  //Text display limit
  public TextDisplayLimit = 16;

  //Limit text size on the column
  public TextSize_Limit(input, length: number): number {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //This closes the modal
  public SLADrillthrough_Close(): void {

    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //Launch the task details
  public TaskView_Launch(item): void {

    //It's possible to create a 'Headless' LoanIndex that we can reuse
    const loanIndex = new LoanIndex(this.apiService, this.globalFunctions, this.notifyService, this.dialog, this.clientDataStore);

    //Try to get its basic stuff initialized. send the first selected task from the array. we can split the key into its 2 parts for this request (as we need LoanID and TaskGUID both)
    const loanID = item.LoanID
    const taskGUID = item.TaskGUID

    //The entity in this case is always LoanTasks
    const entityName = "LoanTasks";

    //Set the fullscreen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Now call the loanIndex initializer, called 'HeadlessMode'. we can pass the loanID and taskGUID to specify that it should load this single records from the server, and instantly show the Edit modal for us. it will remove the fullscreen loading spinner when that is done. as a script so that callbacks. i want some way to detect when save is clicked, and then refresh myself. how to do it? pass myself as a script to the Headless Mode
    //Instead of going straight to the LoanEntityModify template, let's try to construct an entity and navigate to that instead. let's try getting loanindex to do it for us.
    loanIndex.HeadlessModeInit(loanID, entityName, taskGUID, this);

    //TODO some destruction or removal of these constructed headless LoanIndex and its related classes may be in order (after the modal is closed, ie. loanIndex.CloseSingleEntityModal)
  }

  //Downloads the selected entity into a csv file
  public Tasks_DownloadCSV(): void {

    const csvArray = [];

    //Loop through the results and perform formatting on data 
    this.SLADrillthroughDataCSV.forEach(element => {

      //Remove TaskGUID from the export
      delete element.TaskGUID;

      //Unescape the TaskNote elements, if they exist.
      if (!this.globalFunctions.isEmpty(element.TaskNote)) {
        element.TaskNote = this.globalFunctions.HTMLUnescape(element.TaskNote);
      }

      //Remove T from the timestamps on download so that excel can read it a datetime
      if (!this.globalFunctions.isEmpty(element.DueDate)) {
        element.DueDate = element.DueDate.replace("T", " ");
      }

      if (!this.globalFunctions.isEmpty(element.OverrideDueDate)) {
        element.OverrideDueDate = element.OverrideDueDate.replace("T", " ");
      }

      if (!this.globalFunctions.isEmpty(element.CompletedDate)) {
        element.CompletedDate = element.CompletedDate.replace("T", " ");
      }

      if (!this.globalFunctions.isEmpty(element.StartDate)) {
        element.StartDate = element.StartDate.replace("T", " ");
      }

      //Push into the array to be used for download
      csvArray.push(element);
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("SLADrillthrough", csvArray);
  }

  //For re-sorting the list of detail data, when a header is clicked
  public Click_Sort(itemKey) {

    //Let's try flipping the sortKeyDirection
    let sortingKeyDirection = 1;

    //Let's find the current sort direction for this header. need to use the primary key (combine key + subkey and header name)
    const sortingKeyDirectionEntry = this.SortingKeysArray.filter(x => x.HeaderName === itemKey);
    if (!this.globalFunctions.isEmpty(sortingKeyDirectionEntry)) {

      //Deep clone as we don't want to touch the original
      sortingKeyDirection = JSON.parse(JSON.stringify(sortingKeyDirectionEntry[0].HeaderSortDirection));

      //Now flip the original value in the array
      sortingKeyDirectionEntry[0].HeaderSortDirection = sortingKeyDirectionEntry[0].HeaderSortDirection * -1;
    }

    //Now that its an object array, we can sort it by the itemKey column
    this.SLADrillthroughData.sort((n1, n2) => {
      let sortVal1 = n1[itemKey];
      let sortVal2 = n2[itemKey];

      //Dates needs to be converted. can't be compared as strings
      if (itemKey.includes('Date') === true) {

        //Convert it to a date using moment, and then to milliseconds from epoch
        sortVal1 = moment(sortVal1, "YYYY-MM-DDTHH-mm-ss").format("x");
        sortVal2 = moment(sortVal2, "YYYY-MM-DDTHH-mm-ss").format("x");
      }

      //Now sort
      if (sortVal1 >= sortVal2) {
        return sortingKeyDirection;
      }
      if (sortVal1 < sortVal2) {
        return -1 * sortingKeyDirection;
      }
      return 0;
    });
  }

  //Calls the parent to refresh all Task based components
  public ParentTaskData_Refresh(): void {

    //TODO: Please holder if anything needs to refreshed on the parent
  }
}