import { Component, Input, OnInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { AccountsControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocViewer } from '../DocViewer/DocViewer';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import moment from 'moment';

@Component({
  selector: 'StatementGenerator',
  templateUrl: './StatementGenerator.html',
  styleUrls: ['./StatementGenerator.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class StatementGenerator implements OnInit {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<StatementGenerator>) {
  }

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  public AccountID: string;
  public FromDate: Date;
  public ToDate: Date;
  public NowDate: Date;
  public ThresholdDays = -3;
  public MaxToDate: Date;
  public AllowBorrowerAccess: boolean = false;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Angular startup method
  ngOnInit(): void {
    this.Page_Init();
  }

  //Send the request to Generate the statement on the server
  public Statement_Generate(): void {
    //Let's do some basic client side validation
    //From and To Date must be selected. They must be a valid date
    if (this.globalFunctions.isEmpty(this.FromDate) || !this.globalFunctions.IsValidDate(this.FromDate)) {
      this.notifyService.Error_Show("Please select a valid From Date", "Error - From Date");
      return;
    }

    //To Date
    if (this.globalFunctions.isEmpty(this.ToDate) || !this.globalFunctions.IsValidDate(this.ToDate)) {
      this.notifyService.Error_Show("Please select a valid To Date", "Error - To Date");
      return;
    }

    //From date must be less than or equal to the To Date
    if (this.ToDate < this.FromDate) {
      this.notifyService.Error_Show("Please select a valid To Date", "Error - To Date must be after From Date");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);
    //console.log('this.FromDate', this.FromDate);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.AccountID, FromDate: moment(this.FromDate).format('YYYY-MM-DD'), ToDate: moment(this.ToDate).format('YYYY-MM-DD'), AllowBorrowerAccess: this.AllowBorrowerAccess };
    //console.log('apiRequest', apiRequest);

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GenerateStatement], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response', response);

          if (response.ProcessResult === true) {
            //Create a blob that can be used to represent the binary version of the file
            const blob = this.globalFunctions.base64toBlob(response.Base64Document);

            //Open DocViewer
            const docViewer = this.dialog.open(DocViewer, this.globalFunctions.GetFeatureModalConfig());
            docViewer.componentInstance.FileBlob = blob;
            docViewer.componentInstance.FileName = response.Name + "." + response.Extension;
            docViewer.componentInstance.FileType = response.Extension;
            docViewer.componentInstance.OriginalFileType = response.Extension;

            this.globalFunctions.delay(1000).then(() => {

              //A notification would be nice
              this.notifyService.Success_Show("Statement Generated", "Success");
            });

          }
          else {
            //We didn't recieve the document. Its going to take too long to generate. Display a message using the confirmer
            const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());

            //Use html content so that we can style it
            dialogRef.componentInstance.htmlContent = response.ProcessMessage;

            //Hide the No Button
            dialogRef.componentInstance.ShowNoButton = false;

            //Change the Yes button text to "OK"
            dialogRef.componentInstance.YesButtonText = "Ok";

            //After the OK button is pressed, close this Statement Generator Modal too
            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {

                //Close this
                this.StatementGenerator_CloseModal();

                //TODO we could also trigger navigation to the Document entity, if it exists. Can do this later
              }
            });
          }

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
        }
      });
  }

  //This closes the modal
  public StatementGenerator_CloseModal(): void {
    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {

      //Remove the modal if it is in the minimized windows
      this.globalFunctions.MinimizedDialog_Remove(this.dialogRef, Number(this.AccountID));

      this.dialogRef.close();
    }
  }

  //Minimize the modal
  public StatementGenerator_MinimizeModal(): void {
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {

      //Minimize the modal
      this.globalFunctions.MinimizedDialog_Add("Statement Generator", this.dialogRef, Number(this.AccountID), this.ModalIdentifier);
    }
  }

  //Send the request to get the max to date
  public Statement_GetMaxEndDate(): void {

    //Construct the request and send it to the server
    const apiRequest = { Date: moment(this.NowDate).format('YYYY-MM-DD'), NumberOfDays: this.ThresholdDays };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetBusinessDate], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          return;
        }
        else {

          //Get and save the response date
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.MaxToDate = response.ResponseDate;

          //Default To date to the response date and convert it to JS date
          this.ToDate = new Date(JSON.parse(JSON.stringify(this.MaxToDate)));

          //Set FromDate as the first day of the ToDate month, to prevent default FromDate being set after ToDate at the start of the new month
          this.FromDate = new Date(this.ToDate.getFullYear(), this.ToDate.getMonth(), 1);
        }
      });
  }

  //Input Date validation on change
  public Date_OnChange() {

    //Convert the MaxToDate to JS date for comparison
    const maxToDate = new Date(this.MaxToDate);

    //Check if the from date selected is after the max allowed
    if (this.FromDate > maxToDate) {
      this.notifyService.Error_Show("Please choose a date prior to 3 business days", "From Date Error");
      this.FromDate = maxToDate;
      return;
    }

    //Check if the to date selected is after the max allowed
    if (this.ToDate > maxToDate) {
      this.notifyService.Error_Show("Please choose a date prior to 3 business days", "To Date Error");
      this.ToDate = maxToDate;
      return;
    }
  }

  //Entry point to initialize the start and end dates
  private Page_Init(): void {
    //Set defaults for the From and To Date
    const date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();

    //First day of the month
    const firstDay = new Date(y, m, 1);
    //Last day of the month
    //var lastDay = new Date(y, m + 1, 0);
    //Now (no time)
    this.NowDate = new Date(y, m, d);

    //Default From date to the start of the current month
    this.FromDate = firstDay;

    //Default To date to today (current date)
    this.ToDate = new Date(JSON.parse(JSON.stringify(this.NowDate)));

    //Determine if the user is an AMAL Employee 
    if (this.globalFunctions.Claim_VerifyAMALEmployee(this.clientDataStore) === true) {

      //Set max allowed date to today's date for AMAL Employees
      this.MaxToDate = this.NowDate;
    }
    else {

      //Get the Max allowed to date for non AMAL Employees
      this.Statement_GetMaxEndDate();
    }
  }
}