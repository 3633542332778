import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';
import { Editor } from 'primeng/editor';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import Quill from 'quill';

@Component({
  selector: 'RichTextEditor',
  templateUrl: './RichTextEditor.html',
  styleUrls: ['./RichTextEditor.scss']
})

export class RichTextEditor implements OnInit, AfterViewInit {

  //Caller component for calling back
  public ParentComponent;

  //Title
  public PageHeader;

  //Class variables
  public Variables: any;
  public ShowVariables = false;
  public Content;
  public ContentCache;

  //Do we want to allow editing on this component fields
  public EditEnabled = false;

  //Quill Rich Text Editor
  public QuillRichTextEditor: any;

  constructor(
    public dialogRef: MatDialogRef<RichTextEditor>,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog,
    private globalFunctions: GlobalFunctions) {
  }

  //Angular constructor
  ngOnInit() {
    this.Page_Init();
  }

  //Initialise
  Page_Init() {

    //TODO: Set variables from the caller
    //Set the variables
    this.Variables = this.clientDataStore.EmailVariables;

    //Cache the content
    this.ContentCache = this.Content;
  }

  //This will be called after the view has been loaded
  ngAfterViewInit() {

    //Initialise Quill Rich Text Editor
    this.QuillRichTextEditor = new Quill('#quill-editor_RichTextEditor', {
      modules: {
        toolbar: '#toolbar'
      },
      placeholder: 'Compose an email template...',
      theme: 'snow',
    });

    //Validate uploaded image size in quill editor
    this.globalFunctions.QuillImage_Validate(this.QuillRichTextEditor, this.notifyService);

    //Sync the quill content
    this.QuillContent_Sync();

    //Sync the editing of Quill editor
    this.QuillEditorEdit_Toggle();
  }

  //Whether to enable or disable the template for editting
  public QuillEditorEdit_Toggle(): void {
    this.QuillRichTextEditor.enable(this.EditEnabled);
  }

  //Close this dialog and sync the content to its parent
  public Modal_Close() {

    if (!this.globalFunctions.isEmpty(this.ParentComponent)) {

      //Get the quill content first
      this.Content = this.QuillRichTextEditor.getSemanticHTML();

      //Sync the content now
      this.ParentComponent.RichTextContent_Sync(this.Content);
    }

    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //To insert the clicked variable into the quill editor
  public Variable_Click(quillEditor, variable): void {

    const selection = quillEditor.getSelection(true);

    //The last parameter, the Source = "user", is required for ngModel to update after the text has been inserted (https://github.com/quilljs/quill/issues/2509)
    quillEditor.insertText(selection.index, variable, 'user');
  }

  //To toggle the display variables
  public ShowVariables_Toggle(): void {

    this.ShowVariables = !this.ShowVariables;
  }

  //Save the content (client side only)
  public Content_Save(): void {

    this.Modal_Close();
  }

  //Discard the changes
  public Content_Cancel(): void {

    //Display a confirmer message
    const confirmDialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());
    confirmDialogRef.componentInstance.htmlContent = "Are you sure you want to discard your changes and close?";

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Set the original content from the cache
        this.Content = this.ContentCache;

        //Sync the quill content
        this.QuillContent_Sync();

        //Close the modal
        this.Modal_Close();
      }
    });
  }

  //Sync the quill content
  public QuillContent_Sync(): void {

    //If content is null or empty, set it to blank string
    if (this.globalFunctions.isEmpty(this.Content)) {
      this.Content = "";
    }

    //Set the quill editor content
    this.QuillRichTextEditor.setContents(this.QuillRichTextEditor.clipboard.convert({ html: this.Content }));
  }

  //Reset the changes
  public Content_Reset(): void {

    //Display a confirmer message
    const confirmDialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());
    confirmDialogRef.componentInstance.htmlContent = "Are you sure you want to reset all your changes?";

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Set the original content from the cache
        this.Content = this.ContentCache;

        //Sync the quill content
        this.QuillContent_Sync();

        //Display a success toast
        this.notifyService.Info_Show("Contents have been reset", "Reset");
      }
    });
  }
}