import { Component, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NavigationUrls } from '@app/Global/EnumManager';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { SearchBar } from '@app/Components/Loan/SearchBar/SearchBar';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = this.globalFunctions.GetApplicationName();
  public showFullscreenLoading = false;
  public loadingScreenSubscription: Subscription;
  @ViewChild('SearchBar') SearchBar: SearchBar;

  constructor(private clientDataStore: ClientDataStore, public globalFunctions: GlobalFunctions, private router: Router) {
  }

  ngOnInit() {
    //subscribe to changes on the showFullscreenLoading in the client data store
    this.loadingScreenSubscription = this.clientDataStore.GetShowFullscreenLoading.subscribe((result: any) => {
      this.showFullscreenLoading = result;
    });

    //this prevents the end users from landing on the error page after pressing refresh. leave it off for debug - we want devs to see errors!
    if (environment.buildVersion !== "debug") {
      //but this also blocks the unprotected pages from being visited. leave those alone (don't reroute)
      //don't put home here, as it is blank and will resolve to true!! we dont want to capture that here
      if (location.pathname.toUpperCase().includes(NavigationUrls.PasswordReset.toString().toUpperCase())
        || location.pathname.toUpperCase().includes(NavigationUrls.UserInvite.toString().toUpperCase())
        || location.pathname.toUpperCase().includes(NavigationUrls.TwoFactorReset.toString().toUpperCase())
      ) {
        //these are public pages that shouldn't reroute to the root home page. e.g. a user clicking an Invite link from their email.
      }
      else {
        //any other non protected page, if refreshed, this will send the user back to the root.
        this.router.navigate(['']);
      }
    }
  }

  //Pass data/component to router outlet child components
  ChildComponent_OnLoad(component: any) {

    //Pass SearchBar Component to LoanIndex when a LoanIndex component is loaded
    if (component instanceof LoanIndex) {
      component.SearchBarComponent = this.SearchBar;
    }
  }

  //Toggle the minimized dialog/windows based on the click
  public MinimizedWindows_Toggle(dialog) {

    //Get the clicked dialog
    const dialogClicked = this.globalFunctions.MinimizedDialogs.filter(x => x.GUID === dialog.GUID)[0];

    //If clicked on minimized one, lets maximize it
    if (dialogClicked.IsMinimized === true) {

      //Maximize the dialog
      this.globalFunctions.Dialog_Maximize(dialogClicked);
    }

    //Or vice-versa
    else {

      //Minimize the dialog
      this.globalFunctions.Dialog_Minimize(dialogClicked);
    }
  }

  //Close the minimized dialog/windows based on the click
  public MinimizedWindows_Close(dialog) {

    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(dialog) && !this.globalFunctions.isEmpty(dialog.DialogRef)) {

      //Remove the modal if it is in the minimized windows
      this.globalFunctions.MinimizedDialog_Remove(dialog.DialogRef, dialog.RefAccountID);

      dialog.DialogRef.close();
    }
  }
}