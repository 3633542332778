import { Component, TemplateRef, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationUrls, UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'UserInvite',
  templateUrl: './UserInvite.html',
  styleUrls: ['./UserInvite.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})
export class UserInvite {
  isUpdatingPassword = false
  userRegisterData = { ResetToken: "", Password1: "", Password2: "", UserNumber: "" };
  registrationSuccessfulModalRef: any;
  responseMessageHTML: any;
  acceptTermsAndConditions = false;
  public appName = this.globalFunctions.GetApplicationName();

  //the other child components
  @ViewChild('focusField1') focusField1;
  @ViewChild('focusField2') focusField2;
  @ViewChild('focusField3') focusField3;

  //For hiding/revealing the password
  public InputTypePassword = "password";
  public HidePassword = true;

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private store: ClientDataStore) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      //console.log(params);
      //write this into the Reset Token
      if (!this.globalFunctions.isEmpty(params.ResetToken)) {
        this.userRegisterData.ResetToken = params.ResetToken
      }
      else {
        this.apiService.Error_Go('Invalid or expired link', 'Invalid or expired link, please double check your email, or request a new password link.', 'Invalid Token');
      }
    })
  }

  //clears data
  public ClearDataPassword(): void {
    this.userRegisterData.Password1 = "";
    this.userRegisterData.Password2 = "";
  }

  public RegisterUser(registrationSuccessTemplate: TemplateRef<any>): void {

    //is it valid?
    if (this.globalFunctions.isEmpty(this.userRegisterData.UserNumber)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Login ID is missing");
      this.globalFunctions.delay(1).then(any => { this.focusField1.nativeElement.focus(); });
      return;
    }

    if (this.userRegisterData.Password1 != this.userRegisterData.Password2) {
      this.notifyService.Error_Show("Please check and re-enter.", "Passwords are not identical");
      this.globalFunctions.delay(1).then(any => { this.focusField2.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.userRegisterData.Password1) || this.globalFunctions.isEmpty(this.userRegisterData.Password2)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Passwords are missing");
      this.globalFunctions.delay(1).then(any => { this.focusField2.nativeElement.focus(); });
      return;
    }

    if (this.userRegisterData.Password1.length < GlobalFunctions.PasswordMinLength) {
      this.notifyService.Error_Show("This password is too short, please try again.", "Too short");
      this.globalFunctions.delay(1).then(any => { this.focusField2.nativeElement.focus(); });
      return;
    }

    if (this.userRegisterData.Password1.length > GlobalFunctions.PasswordMaxLength) {
      this.notifyService.Error_Show("This password is too long, please try again.", "Too long");
      this.globalFunctions.delay(1).then(any => { this.focusField2.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.userRegisterData.ResetToken) || this.userRegisterData.ResetToken.length < 35) {
      this.notifyService.Error_Show("Please check your email or request a new password reset.", "Invalid Token");
      return;
    }

    //check that the terms and conditions checkbox is selected
    if (!this.acceptTermsAndConditions) {
      this.notifyService.Error_Show("Please read and provide your acceptance of the Terms and Conditions by ticking the checkbox.", "Terms and Conditions");
      return;
    }

    const apiRequest = this.userRegisterData;
    this.isUpdatingPassword = true;
    this.store.SetShowFullscreenLoading(true);
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UserRegistration], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUpdatingPassword = false;
          this.store.SetShowFullscreenLoading(false);

          return;
        }
        else {

          //deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          if (response.ResponseMessage === "Registration Successful") {

            this.responseMessageHTML = response.ResponseMessageHTML;

            //we need to keep a ref to this registration modal, so that we can close it later
            this.registrationSuccessfulModalRef = this.matDialog.open(registrationSuccessTemplate, this.globalFunctions.GetFeatureModalConfig("50%"));

            //not much to do here. just a message to let the user know that they can login now. a notification might be a nice touch here
            this.notifyService.Success_Show("User has been registered.", "Registration Successful");
            this.store.SetShowFullscreenLoading(false);
          }
          this.isUpdatingPassword = false;
        }
      });
  }

  public HideRegistrationModal() {
    this.registrationSuccessfulModalRef.close();
  }

  //Go to the home page
  public GoHome() {
    //Tell router to reload the component, even if the URL is the same
    this.router.navigate([NavigationUrls.Home], { onSameUrlNavigation: 'reload' });

    //Wait a small amount, and then reload the page
    this.globalFunctions.delay(500).then(any => {
      window.location.reload();
    });
  }

  public LauchLoginModal() {
    if (!this.globalFunctions.isEmpty(this.registrationSuccessfulModalRef)) {
      this.registrationSuccessfulModalRef.close();
    }
    this.globalFunctions.LaunchLoginModal(true, "Registration successful, please log in", true, true);
  }

  //Reaveal/Hide the password on click and hold
  public PasswordReveal_Toggle(hidePassword: boolean): void {
    if (hidePassword == true) {
      this.InputTypePassword = "password";
      this.HidePassword = true;
    }
    else {
      this.InputTypePassword = "text";
      this.HidePassword = false;
    }
  }
}