import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocViewer } from '../DocViewer/DocViewer';
import { LoanIndex } from '../LoanIndex/LoanIndex';

@Component({
  selector: 'LeasePayoutCalculator',
  templateUrl: './LeasePayoutCalculator.html',
  styleUrls: ['./LeasePayoutCalculator.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class LeasePayoutCalculator implements OnInit, AfterViewInit {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<LeasePayoutCalculator>,
    private csvDataService: CsvDataService = null) {
  }

  @Input() LoanIndex: LoanIndex;

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  //Declare the variables
  public PayoutCalculations;
  public PayoutCalculationsOriginal;
  public TotalFacilityPayoutDisplay;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Payout Date
  public PayoutDate: Date;

  //For payout details display
  public LeasePayout: number;
  public GST: number;
  public TerminationIncome: number;
  public PayoutMessage: string;
  public UnclearedFundsNote = "";
  public ShowUnclearedFundsNote = false;
  public PayoutNoteSaved = false;
  public PayoutLetterGenerated = false;

  //To check if it is the initial load
  private InitialPageLoad = true;

  //Angular startup method
  ngOnInit() {

    //Set the payout date to current date on initial load within this method: InitPage
    this.Page_Init();
  }

  ngAfterViewInit() {

    //Run it on initial load only
    if (this.InitialPageLoad) {
      //We still need a short delay even this method is added on ngAfterViewInit
      this.globalFunctions.delay(50).then(() => {
        this.PayoutCalculation_Generate();

        //Set class variable initialPageLoad to false so that it won't force the payout date to current date
        this.InitialPageLoad = false;
      });
    }
  }

  //Send the request to generate the payout calculation on the server
  public PayoutCalculation_Generate() {

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetLeasePayoutCalculation], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          this.PayoutCalculations = response.LeasePayoutDetailsResult;
          this.PayoutCalculationsOriginal = JSON.parse(JSON.stringify(this.PayoutCalculations));

          this.PayoutCalculations.forEach(element => {

            //Payout message
            this.PayoutMessage = element.PayoutFigureNote;

            //Uncleared funds
            const UnclearedFunds = element.UnclearedFunds;

            if (UnclearedFunds > 0) {

              //Set reminder if there is uncleared funds
              this.UnclearedFundsNote = "Uncleared funds may require post clearance.";
              this.ShowUnclearedFundsNote = true;
            }

            //Convert date to DD MMM YYYY
            element.PayoutValidTillDate = this.globalFunctions.getCustomDateFormat(element.PayoutValidTillDate, "shortdate", "custom", "YYYY-MM-DD");
            element.NextInstalmentDueDate = this.globalFunctions.getCustomDateFormat(element.NextInstalmentDueDate, "shortdate", "custom", "YYYY-MM-DD");
            element.PayoutCalculationDate = this.globalFunctions.getCustomDateFormat(element.PayoutCalculationDate, "shortdate", "custom", "YYYY-MM-DD");

            //Convert the amounts to currency format
            element.UnclearedFunds = this.globalFunctions.customDataTypeParser(UnclearedFunds, "currency.2", "aus");
            element.ResidualBalance = this.globalFunctions.customDataTypeParser(element.ResidualBalance, "currency.2", "aus");
            element.LoanAmount = this.globalFunctions.customDataTypeParser(element.LoanAmount, "currency.2", "aus");
            element.ArrearsAmount = this.globalFunctions.customDataTypeParser(element.ArrearsAmount, "currency.2", "aus");
            element.PayoutFigure = this.globalFunctions.customDataTypeParser(element.PayoutFigure, "currency.2", "aus");
            element.GSTAmount = this.globalFunctions.customDataTypeParser(element.GSTAmount, "currency.2", "aus");
            element.TerminationIncome = this.globalFunctions.customDataTypeParser(element.TerminationIncome, "currency.2", "aus");

            //Instalment Due Amount
            element.RepaymentAmount = this.globalFunctions.customDataTypeParser(element.RepaymentAmount, "currency.2", "aus");

            //These are displayed as summary below the details
            this.LeasePayout = element.PayoutFigure;
            this.GST = element.GSTAmount;
            this.TerminationIncome = element.TerminationIncome;

            //Interest Rate to percentage
            element.InterestRate = this.globalFunctions.customDataTypeParser(element.InterestRate * 100, "percent", "aus");
            element.DiscountRate = this.globalFunctions.customDataTypeParser(element.DiscountRate * 100, "percent", "aus");
            element.PayoutRate = this.globalFunctions.customDataTypeParser(element.PayoutRate * 100, "percent", "aus");
          });

          this.notifyService.Success_Show("The payout calculation has been generated successfully.", "Payout Calculation");

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //Generate Payout Letter
  public PayoutLetter_Generate(): void {

    //Let's do some basic client side validation
    if (this.PayoutCalculations.length === 0) {
      this.notifyService.Error_Show("No payout records detected.", "Save Payout");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, SaveNote: !this.PayoutNoteSaved };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GenerateLeasePayoutLetter], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response', response);

          //Flag to indicate the letter has been generated already
          this.PayoutLetterGenerated = true;

          //Disable save note button as we will be saving the note as part of letter generation
          if (this.PayoutNoteSaved === false) {
            this.PayoutNoteSaved = true;
          }

          //Create a blob that can be used to represent the binary version of the file
          const blob = this.globalFunctions.base64toBlob(response.Base64Document);

          //Open DocViewer
          const dovViewer = this.dialog.open(DocViewer, this.globalFunctions.GetFeatureModalConfig());
          dovViewer.componentInstance.FileBlob = blob;
          dovViewer.componentInstance.FileName = response.Name + "." + response.Extension;
          dovViewer.componentInstance.FileType = response.Extension;
          dovViewer.componentInstance.OriginalFileType = response.OriginalFileType;

          //These 3 datapoints are required for downloading a document from the DocViewer
          dovViewer.componentInstance.DocumentGUID = response.GUID;
          dovViewer.componentInstance.EntityType = "LoanDocuments";
          dovViewer.componentInstance.LoanIndex = this.LoanIndex;

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;

          this.globalFunctions.delay(1000).then(() => {

            //A notification would be nice
            this.notifyService.Success_Show("Payout Letter Generated", "Success");
          });

        }
      });
  }

  //Save Payout Calculations
  public PayoutNote_Save() {

    //Let's do some basic client side validation
    if (this.PayoutCalculations.length === 0) {
      this.notifyService.Error_Show("No payout records detected.", "Save Payout Failed");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.SaveLeasePayoutCalculation], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Flag to indicate that the current payout has been saved
          this.PayoutNoteSaved = true;

          //Show toast message on success
          this.notifyService.Success_Show("The lease payout calculation has been saved successfully in the Notes screen.", "Save Payout");

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //This closes the modal
  public Modal_Close(): void {
    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {

      //Remove the modal if it is in the minimized windows
      this.globalFunctions.MinimizedDialog_Remove(this.dialogRef, Number(this.LoanIndex.AccountID));

      this.dialogRef.close();
    }
  }

  //Minimize the modal
  public LeasePayout_MinimizeModal(): void {
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {

      //Minimize the modal
      this.globalFunctions.MinimizedDialog_Add("Lease Payout", this.dialogRef, Number(this.LoanIndex.AccountID), this.ModalIdentifier);
    }
  }

  //Downloads the selected entity into a csv file
  public CSV_Download(): void {

    const csvArray = [];

    //Loop through the results and remove unneeded columns
    this.PayoutCalculations.forEach(element => {

      //Remove AccountGUID and IsLoanInAdvance flag (Repayment Timing) from the export
      delete element.AccountGUID;
      delete element.IsLoanInAdvance;

      //Push into the array to be used for download
      csvArray.push(element);
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("LeasePayout", csvArray);
  }

  //Get current date for initial load
  private CurrentDate_Get(): Date {
    //Set defaults for the Payout Date
    const date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();

    //Now return (no time)
    return new Date(y, m, d);
  }

  //Setting default payout date on initial load
  private Page_Init() {

    if (this.InitialPageLoad) {
      //Default Payout Date to today (current date)
      this.PayoutDate = this.CurrentDate_Get();
    }
  }
}