import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { MatDialog } from '@angular/material/dialog';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ReassignTask } from '@app/Components/User/WorkflowDelegator/ReassignTask/ReassignTask';
import { Dashboard } from '@app/Components/Dashboard/Dashboard';
import moment from "moment";
import { Header, TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'WorkflowDelegator',
  templateUrl: './WorkflowDelegator.html',
  styleUrls: ['./WorkflowDelegator.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})

export class WorkflowDelegator implements OnInit {

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    public dialog: MatDialog) {
  }

  //Copies of other parent, in case we want to trigger updates on them later
  @Input() dashboard: Dashboard;

  //Status Multiselect Input
  @ViewChild('INP_StatusColumn') INPStatusColumn;

  //Task Type Multiselect Input
  @ViewChild('INP_TaskType') INPTaskType;

  //Store how many columns it has, dynamic as the number of status can change, with new ones added or removed.
  public aggregateDataColumnCount = 0;

  //Store the detail data here (first level detail, contains Task Guids, Lender name, Due date and status). this is an dictionary and will contain entries for all elements from the aggregate in delegatorDashboardDataAggregated
  public delegatorDashboardDataDetailDict: { [key: string]: any };

  //Local spinner
  public showSpinner = false;

  //ReassignTask Spinner
  public showReassignTaskButtonSpinner = false;

  //Used to separate keys when combined into a single identifier. looks like we don't need this anymore. just going to leave it blank.
  public keySeparator = "";

  //Used for the button (icon) spinner on the refresh button at the top right (the RefreshDashboardDataAggregated method)
  public refreshingAggregatedSpinner = true;

  //Used for tracking the refreshing of Task Type in the Multiselect filter
  public RefreshingTaskTypeSpinner = true;

  //Store a list of all clicked items that are used to send the request to the server. rename this to selectedTasks
  public selectedTasks = [];

  //Track all clicked Items, in order. to allow shift click support. (these are not necessarily all the selected tasks, as shift click allows us to select more that what was clicked)
  public lastClickedTaskItemsArray = [];

  //Used to track if we should show the context action bar
  public showContextBar = false;

  //Should we show the Edit Task button (only should be allowed when a single task is highlighted)
  public showTaskEditButton = false;

  //I want to store the sort direction for each header. let's use a client side array to help us remember them
  public sortingKeysArray: Header[] = [];

  //This is now supplied and filled by the child ReassignTask component
  public reassignTask_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //This is now supplied and filled by the child ReassignTask component
  public reassignTask_Client: string;

  //The delegator dashboard data from the server (aggregated) for display
  public DashboardAggregatedDisplay;

  //The delegator dashboard data from the server (aggregated) for storing the original dictionary
  private DashboardAggregatedResponse;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Status selection options, used by the primeng multiselect
  public StatusOptions: any[];

  //Selected status options as per the primeng multiselect
  public SelectedStatus: any[] = [];

  //Cached selected status options as per the primeng multiselect
  public CachedSelectedStatus: any[] = [];

  //Flag to track the status of selectAll checkbox on the status primeng multiselect
  public StatusSelectAll = { Value: true };

  //TaskType selection options, used by the primeng multiselect
  public TaskTypeOptions: any[] = [];

  //Selected task types options as per the primeng multiselect
  public SelectedTaskTypes: any[] = [];

  //Flag to track the status of selectAll checkbox on the task type primeng multiselect
  public TaskTypeSelectAll = { Value: false };

  //Flag to check whether we have received the detail data from the server
  private DetailDataReceived = false;

  //Flag to check whether the chevron has been clicked to load detail data
  private IsDetailLoading = false;

  ngOnInit() {

    //Get Workflow Delegator (WD) data Task Types from the server
    this.DelegatorDashboardTaskTypes_Get();

    //Get the WD Aggregated data
    this.DashboardAggregated_Refresh();

    //Init the status multiselect options
    this.StatusOptions = [
      { name: 'Active' },
      { name: 'Awaiting Information' },
      { name: 'Reminder Issued' },
      { name: 'Quality Checking' },
      { name: 'QC Complete' },
      { name: 'Complete' }
    ];

    //To achieve the inversion - default all status displays to true
    for (const item of this.StatusOptions) {
      this.SelectedStatus.push(item);
    }
  }

  //Toggle all for Status Multiselect
  public StatusMultiSelectAll_Toggle(e) {

    this.globalFunctions.MultiSelectAll_Toggle(e, this.StatusSelectAll, this.StatusOptions, this.SelectedStatus, this.INPStatusColumn);

    //Reset status multiselect
    this.StatusMultiSelect_Reset();

    //Refresh display
    this.DashboardAggregatedDisplay_Refresh();
  }

  //Toggle when we select/unselect individual status checkboxes in the list
  public StatusMultiSelect_Toggle(multiSelectToggle = true) {

    //To tick/untick the select all checkbox
    this.globalFunctions.MultiSelect_Toggle(this.StatusSelectAll, this.StatusOptions, this.SelectedStatus);

    //Check if it is being invoked as part of Chevron click (multiselectToggle = false)
    if (multiSelectToggle) {

      //Reset status multiselect
      this.StatusMultiSelect_Reset();
    }

    //Refresh display
    this.DashboardAggregatedDisplay_Refresh();
  }

  //Toggle all for Task Type Multiselect
  public TaskTypeMultiSelectAll_Toggle(e) {

    this.globalFunctions.MultiSelectAll_Toggle(e, this.TaskTypeSelectAll, this.TaskTypeOptions, this.SelectedTaskTypes, this.INPTaskType);
  }

  //Toggle when we select/unselect individual checkboxes in the list
  public TaskTypeMultiSelect_Toggle() {

    //To tick/untick the select all checkbox
    this.globalFunctions.MultiSelect_Toggle(this.TaskTypeSelectAll, this.TaskTypeOptions, this.SelectedTaskTypes);
  }


  //Populate the aggregate data for display and show/hide the status columns based on the flags
  public DashboardAggregatedDisplay_Refresh() {

    //Unselect any highlighted items as they may be hidden after hiding the column
    this.AllTasks_Deselect(true);

    //Re-initialise display array on any status checkbox click
    this.DashboardAggregatedDisplay = {};

    //Get keys from the original dictionary (response from the server)
    const aggregateDataKeys = Object.keys(this.DashboardAggregatedResponse);

    let columnCounterIndex = 0;

    //Loop through the aggregate data and hide the columns as needed
    aggregateDataKeys.forEach(key => {

      //Deep clone object for each key into our display dictionary
      this.DashboardAggregatedDisplay[key] = JSON.parse(JSON.stringify(this.DashboardAggregatedResponse[key]));

      const delegatorDashboardData = this.DashboardAggregatedDisplay[key];

      //Remove the status columns based on the selected status checkboxes
      this.SelectedStatus_Check("Active", delegatorDashboardData);
      this.SelectedStatus_Check("Complete", delegatorDashboardData);
      this.SelectedStatus_Check("Awaiting Information", delegatorDashboardData);
      this.SelectedStatus_Check("Quality Checking", delegatorDashboardData);
      this.SelectedStatus_Check("QC Complete", delegatorDashboardData);
      this.SelectedStatus_Check("Reminder Issued", delegatorDashboardData);

      //Run only once
      if (columnCounterIndex === 0) {

        //Update the number of columns for the display. 
        this.aggregateDataColumnCount = Object.keys(this.DashboardAggregatedDisplay[key]).length;
        columnCounterIndex++;
      }
    });
  }

  //Check if the status is in the selected list. Remove the status column otherwise
  public SelectedStatus_Check(name, delegatorDashboardData): void {
    const targetStatusChecked = this.SelectedStatus.filter(x => x.name === name)[0];
    if (this.globalFunctions.isEmpty(targetStatusChecked)) {
      delete delegatorDashboardData[name];
    }
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Refresh the detail from the server when a chevron is clicked, this is a wrapper and performs some cleansing and putting things back together for the next API call
  public DelegatorDashboardDetailDataWrapper_Get(i_key: string, i_subKey: string, identifier: string) {

    //This is to prevent user from clicking other chevron or hiding the same detail before detail data is loaded
    if (this.IsDetailLoading) {

      //Detail data is still loading from previous click, return
      return;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(identifier);

    //Set detail loading to true
    this.IsDetailLoading = true;

    //Strip braces and spaces, otherwise data toggling in the html will not work (ID's wont match)
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);

    //Cache current selected status
    if (this.CachedSelectedStatus.length <= 0) {
      this.SelectedStatus.forEach(statusOption => {
        this.CachedSelectedStatus.push(statusOption);
      });
    }

    //Init the entry inside the dictionary first
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey] = { Entity: [], DisplayName: "Detail", Spinner: 0, Count: -1, InitialLoad: false, InitialLoadCompleted: false };
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity = [];

    //Now let's try calling out to the server for some data. turn the loading spinner on for this one
    this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Spinner = true;
    //Pass it the two keys from the template the we recieved - key = assigned User GUID, and the subKey = status. Status can have spaced, which need to be preserved when sending the request to the server. so supply the raw value here as well. (braces are easy to add, space not so much)
    this.DelegatorDashboardDetailData_Get(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity, key, subKey, i_subKey);
  }

  //For re-sorting the list of detail data, when a header is clicked
  public Click_Sort(itemKey, itemSubkey, itemSortKey) {

    //Strip braces and spaces
    const itemKeyStripped = this.BracesAndSpaces_Strip(itemKey);
    const itemSubKeyStripped = this.BracesAndSpaces_Strip(itemSubkey);
    //console.log('itemSortKey', itemSortKey);

    //Let's try flipping the sortKeyDirection
    let sortingKeyDirection = 1;

    //console.log('this.sortingKeysArray', this.sortingKeysArray);
    //console.log('itemKey + this.keySeparator + itemSubkey + itemSortKey', itemKeyStripped + this.keySeparator + itemSubKeyStripped + itemSortKey);

    //Let's find the current sort direction for this header. need to use the primary key (combine key + subkey and header name)
    const sortingKeyDirectionEntry = this.sortingKeysArray.filter(x => x.HeaderName === itemKeyStripped + this.keySeparator + itemSubKeyStripped + itemSortKey);
    if (!this.globalFunctions.isEmpty(sortingKeyDirectionEntry)) {

      //Deep clone as we don't want to touch the original
      sortingKeyDirection = JSON.parse(JSON.stringify(sortingKeyDirectionEntry[0].HeaderSortDirection));

      //Now flip the original value in the array
      sortingKeyDirectionEntry[0].HeaderSortDirection = sortingKeyDirectionEntry[0].HeaderSortDirection * -1;
    }
    //console.log('sortingKeyDirection', sortingKeyDirection);
    //console.log('sortingKeyDirectionEntry[0]', sortingKeyDirectionEntry[0]);

    //The detail data is actually a dictionary, which cannot be sorted in Typescript. it needs to be converted to an array using Object.entries
    const detailData = Object.entries(this.delegatorDashboardDataDetailDict[itemKeyStripped + this.keySeparator + itemSubKeyStripped].Entity);
    //console.log('detailData', detailData);

    //Now that its an object array, we can sort it by the itemSortKey column
    const detailDataSorted = detailData.sort((n1, n2) => {

      let sortVal1 = n1[1][itemSortKey];
      let sortVal2 = n2[1][itemSortKey];

      //Dates needs to be converted. can't be compared as strings
      if (itemSortKey.includes('Date') === true) {
        //Convert it to a date using moment, and then to milliseconds from epoch
        sortVal1 = moment(sortVal1, "DD MMMM YYYY").format("x");
        sortVal2 = moment(sortVal2, "DD MMMM YYYY").format("x");
        //console.log('sortVal1', sortVal1);
        //console.log('sortVal2', sortVal2);
      }

      //Now sort
      if (sortVal1 >= sortVal2) {
        return sortingKeyDirection;
      }
      if (sortVal1 < sortVal2) {
        return -1 * sortingKeyDirection;
      }
      return 0;
    });

    //Convert it back to a dictionary
    const sortedDictionary = Object.fromEntries(detailDataSorted);

    //Now a deep copy and replace of the original dictionary should do the trick
    this.delegatorDashboardDataDetailDict[itemKeyStripped + this.keySeparator + itemSubKeyStripped].Entity = JSON.parse(JSON.stringify(sortedDictionary));
  }

  //Shows detail from the local dictionary value inside delegatorDashboardDataDetailDict
  public DetailData_Show(i_key: string, i_subKey: string) {
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);

    if (this.globalFunctions.isEmpty(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey])) {
      //console.log('ShowDetailData no data');
      return "none";
    }
    //console.log('ShowDetailData some data');
    //console.log('key + . + subKey]', key + this.keySeparator + subKey);
    //console.log('this.delegatorDashboardDataDetailDict[key + . + subKey]', this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity);
    return this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Entity;
  }

  //Shows detail from the local dictionary inside delegatorDashboardDataDetailDict regarding the spinner status
  public DetailDataSpinner_Check(i_key: string, i_subKey: string) {
    const key = this.globalFunctions.StripBracesAndSpaces(i_key);
    const subKey = this.globalFunctions.StripBracesAndSpaces(i_subKey);
    if (this.globalFunctions.isEmpty(this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey])) {
      //This entry has not been initialized. probably never been loaded. just return false for the spinner.
      return false;
    }
    //Return the spinner value
    return this.delegatorDashboardDataDetailDict[key + this.keySeparator + subKey].Spinner;
  }

  //When using GUIDS to bind to elements in html, we can't have the curly braces { and } or even spaces! use this method to strip them out, as needed
  public BracesAndSpaces_Strip(GUID: string) {
    //Moved to the globalFuctions library
    return this.globalFunctions.StripBracesAndSpaces(GUID);
  }

  //This preserves the order of key values coming in from server json dictionaries, used in the pipe on the html template. actually no. it just gets the value of the object, instead of the key (because its a dictionary)
  public Order_Keep = (a,) => {
    return a;
  }

  //Used to track clicks on each of the expanded task items, whether or not to remove highlights and also shift click support
  public TaskItem_Clicked(event, item) {

    //Check and unselect action bars on all others
    this.dashboard.DisableContextActionBars('WorkflowDelegator');

    //console.log('event.currentTarget: ', event.currentTarget);
    //use a temp class to indicate that we are selecting this.
    event.currentTarget.classList.add('newSelection');

    //Now, only add the highlight if this was NOT selected before.
    if (event.currentTarget.classList.contains('glb_highlightSelectedTask') === false) {
      //console.log('clicked item', item);

      //Update the last clicked item. (needed for shift click support). we need a separate list to track all clicked items, so that we can properly support shift click, when items are unclicked. its currently hard to roll back and get the prior clicked item, when something is unclicked (mutliple unclicks are even harder).
      this.lastClickedTaskItemsArray.push(item);

      //Enable shift click support - if the shift key was held, let's select all tasks up to this one.
      //First, we should make sure that something was selected before (this.lastClickedTaskItemsArray)
      if (!this.globalFunctions.isEmpty(this.lastClickedTaskItemsArray) || this.lastClickedTaskItemsArray.length === 0) {
        //Now check if the shift key was held
        if (event.shiftKey === true) {
          //console.log('shift key was held');
          //Let's find the last clicked item, and highlight everything from that to this.
          //console.log('this.lastClickedTaskItem.key', this.lastClickedTaskItem.key);
          //Since we pushed an entry already into lastClickedTaskItemsArray, its the Second last object (-2 instead of 1). that second last object may not exist yet, so null check it.
          if (!this.globalFunctions.isEmpty(this.lastClickedTaskItemsArray[this.lastClickedTaskItemsArray.length - 2])) {
            const targetLastClickedDiv = document.getElementById(this.lastClickedTaskItemsArray[this.lastClickedTaskItemsArray.length - 2].key);
            //console.log('targetLastClickedDiv', targetLastClickedDiv);

            const nodes = Array.prototype.slice.call(targetLastClickedDiv.parentNode.children);
            const lastClickedIndex = nodes.indexOf(targetLastClickedDiv);
            //console.log('lastClickedIndex', lastClickedIndex);

            //Let's get the index of the currently clicked div
            const targetThisClickedDiv = document.getElementById(item.key);
            //console.log('targetThisClickedDiv', targetThisClickedDiv);
            const thistClickedIndex = nodes.indexOf(targetThisClickedDiv);
            //console.log('thisClickedIndex', thistClickedIndex);

            //What direction are we going?
            let direction = 'downward'
            if (lastClickedIndex < thistClickedIndex) {
              direction = 'downward'
            }
            else {
              direction = 'upward'
            }

            //Loop through the nodes with matching elements
            nodes.forEach(element => {
              if (!this.globalFunctions.isEmpty(element.getAttribute('data-parentguid'))) {
                //console.log('nodes.indexOf(element)', nodes.indexOf(element));
                const elementIndex = nodes.indexOf(element);

                //Let's support both downward and upward direction too
                if (direction === 'downward' && (elementIndex > lastClickedIndex && elementIndex <= thistClickedIndex)
                  || direction === 'upward' && (elementIndex < lastClickedIndex && elementIndex >= thistClickedIndex)) {
                  //console.log('matching index element', element);
                  //console.log('matching index element classList', element.classList);

                  //Check if highlight is already applied
                  if (element.classList.contains('glb_highlightSelectedTask') === false) {
                    //This item isn't already highlighted. add the highlight
                    element.classList.add('glb_highlightSelectedTask');
                    //We now have the parentid available. lets get it
                    const parentGUID = this.globalFunctions.StripBracesAndSpaces(element.getAttribute('data-parentguid'));
                    //console.log('parentguid', parentGUID);
                    //Choose it and add it to the clickedTasks array. lookup this item from the full delegatorDashboardDataDetailDict array, using the parentGUID
                    const lookupItemClient = this.delegatorDashboardDataDetailDict[parentGUID];
                    //console.log('lookupItemClient', lookupItemClient)
                    if (!this.globalFunctions.isEmpty(lookupItemClient)) {
                      //Now find this element inside it
                      //console.log('element.id', element.id);
                      const lookupItem = lookupItemClient.Entity[element.id];
                      //console.log('lookupItem', lookupItem)
                      if (!this.globalFunctions.isEmpty(lookupItem)) {
                        //We want to push this with its key, so that it looks just like the other rows (a dictionary entry with key and value).
                        this.selectedTasks.push({ key: element.id, value: lookupItem });
                      }
                    }
                  }
                }
              }

            });
          }
        } else {
          if (event.ctrlKey === false) {
            //Clean out the clicked stuff, including detailed arrays
            this.AllTasks_Deselect(true);
          }
          //Regular non shift click, just push the selected item in
          //console.log('shift key was not held');
          event.currentTarget.classList.add('glb_highlightSelectedTask');
          this.selectedTasks.push(item);
        }
      }
      else {
        //There is nothing clicked before (lastClickedItem is empty). just push the selected item in        
        event.currentTarget.classList.add('glb_highlightSelectedTask');
        this.selectedTasks.push(item);
      }
    }
    else {
      //It already contains it. the same data unit was clicked! remove it!
      event.currentTarget.classList.remove('glb_highlightSelectedTask');
      //Remove this from the list
      this.selectedTasks.splice(this.selectedTasks.findIndex(x => x.key == item.key), 1);

      //And we should remove the last clicked item, and go to the one that was clicked just before.
      const indexOfLastClickedTask = this.lastClickedTaskItemsArray.findIndex(x => x.key == item.key);
      //console.log('indexOfLastClickedTask', indexOfLastClickedTask);
      //If the item is not found (perhaps was shift selected), then don't remove it (index will be -1)
      if (indexOfLastClickedTask >= 0) {
        this.lastClickedTaskItemsArray.splice(this.lastClickedTaskItemsArray.findIndex(x => x.key == item.key), 1);
      }
    }

    //Remove the temporary newSelection class. not sure if this is needed here at this very moment, but might be handy later, when doing copy/paste style operations. leaving it for now.
    event.currentTarget.classList.remove('newSelection');

    //Track what is now left in the lists, useful for debugging
    //console.log('clickedTasks', this.clickedTasks);
    //console.log('this.lastClickedTaskItemsArray', this.lastClickedTaskItemsArray);

    //Now check items and flip the context action bar
    if (this.selectedTasks.length > 0) {
      this.showContextBar = true;
      //If its exactly 1, then we can show the edit task button
      if (this.selectedTasks.length === 1) {
        this.showTaskEditButton = true;
      }
      else {
        this.showTaskEditButton = false;
      }
    }
    else {
      this.showContextBar = false;
    }
  }

  //For loading the Entity Update component
  private ReassignModalMatDialog;

  //Launches the Reassign Template
  public ReassignTemplate_Open() {
    //Clear existing selections first
    this.ReassignTask_ClearUIData();

    //Create modal using Mat dialog service
    this.ReassignModalMatDialog = this.dialog.open(ReassignTask, this.globalFunctions.GetFeatureModalConfig('40%'));

    //Set the component inputs
    this.ReassignModalMatDialog.componentInstance.WorkflowDelegator = this;
  }

  //Resets all the UI controls for the create new claim section
  public ReassignTask_ClearUIData() {
    this.reassignTask_Client = "";
    //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable
    this.reassignTask_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  }

  //Prepares all entries in the selectedTasks Array, and then sends them to the server for processing the update.
  public Task_Reassign() {
    //Some basic client side validation
    if (this.globalFunctions.isEmpty(this.reassignTask_ClientBind) || this.globalFunctions.isEmpty(this.reassignTask_Client)) {
      this.notifyService.Error_Show("Please choose a client to reassign tasks to", "Client not chosen")
      return;
    }

    if (this.globalFunctions.isEmpty(this.selectedTasks) || this.selectedTasks.length == 0) {
      this.notifyService.Error_Show("Please choose one or more tasks to reassign", "Task not chosen")
      return;
    }

    const dialogRef = this.dialog.open(ConfirmModal, this.globalFunctions.GetConfirmModalConfig());

    //Use html content so that we can style it
    dialogRef.componentInstance.htmlContent = "Are you sure that you want to reassign tasks to: <b>" + this.reassignTask_ClientBind.ControlValue + "</b>?<br>"
    if (this.selectedTasks.length > 0) {
      dialogRef.componentInstance.htmlContent = dialogRef.componentInstance.htmlContent + "Number of tasks to reassign: <b>" + this.selectedTasks.length + "</b>";
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.clientDataStore.SetShowFullscreenLoading(true);
        this.showReassignTaskButtonSpinner = true;
        //Construct a request body that we can post to the server. we need to convert the taskList to a simple array of strings.
        const taskListArray: Array<string> = [];

        //Loop through and create a simple array of strings, we don't need to send the full dictionary of data to the server for this request
        Object.entries(this.selectedTasks).forEach(
          ([, value]) => {
            //the primary key is specially encoded with a LoanID_TaskGUID. split it out.
            //console.log('value key', value['key']);
            //taskGUID is the second part of the identifier
            const taskGUID = value['key'].split('_')[1];
            taskListArray.push(taskGUID);
          });

        //Task Type GUIDs
        let taskTypeGUIDs = "";
        if (this.SelectedTaskTypes.length > 0) {
          this.SelectedTaskTypes.forEach(taskType => {
            taskTypeGUIDs += taskType.guid + ",";
          });

          //Strip out the last comma delimiter
          taskTypeGUIDs = taskTypeGUIDs.substring(0, taskTypeGUIDs.length - 1);
        }


        const apiRequest = { TaskList: taskListArray, TargetUserGUID: this.reassignTask_ClientBind.ControlGUID, TaskTypeGUIDs: taskTypeGUIDs };
        //Call the server, if we have some value to update
        if (!this.globalFunctions.isEmpty(this.reassignTask_ClientBind) && !this.globalFunctions.isEmpty(this.selectedTasks) && this.selectedTasks.length > 0) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.ReassignTask], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) {
                this.clientDataStore.SetShowFullscreenLoading(false);
                this.showReassignTaskButtonSpinner = false;
                return;
              }
              else {
                //Deserialize it into an class that we can understand
                const response = JSON.parse(JSON.stringify(apiResponse));
                this.notifyService.Success_Show(response.ResponseMessage, "Success");

                //Turn on the loading spinner
                this.showSpinner = true;

                //Process dashboard response and populate the aggregate data for display
                this.DashboardAggregated_Process(response);

                //Turn off the loading spinner
                this.showSpinner = false;

                //Disable the fullscreen loading screen
                this.clientDataStore.SetShowFullscreenLoading(false);
                this.showReassignTaskButtonSpinner = false;

                //Now other task based components may need refreshing. call the parent method to do it, but no need to refresh ourself.
                this.dashboard.RefreshTaskData(false);

                //Close the modal
                this.Modal_Close();
              }
            });
        }
      }
    });

  }

  //Process dashboard data and populate the dictionary for display
  public DashboardAggregated_Process(dashboardData): void {

    //Sync the SelectedStatus with the CachedSelectedStatus
    this.MultiselectCache_Sync(this.CachedSelectedStatus, this.SelectedStatus, this.INPStatusColumn);

    //Dictionary needs to be reset to a blank object
    this.DashboardAggregatedResponse = {};

    for (const key in dashboardData) {

      //Need to unescape the assigned to column
      if (!this.globalFunctions.isEmpty(dashboardData[key]) && !this.globalFunctions.isEmpty(dashboardData[key]['Assigned To'])) {
        dashboardData[key]['Assigned To'] = this.globalFunctions.HTMLUnescape(dashboardData[key]['Assigned To']);
      }

      //And fill it
      this.DashboardAggregatedResponse[key] = dashboardData[key];
    }

    //Populate the aggregate dictionary for display and show/hide status columns if any of those are checked
    this.DashboardAggregatedDisplay_Refresh();

    //Clean out the clicked stuff, including detailed arrays
    this.UIArrays_Clear();
  }

  //Unhighlight all selected task items on the screen.
  public AllTasks_Deselect(silently = false) {
    if (this.selectedTasks.length === 0) {
      if (!silently) {
        this.notifyService.Warning_Show("There are no highlighted tasks", "No Tasks Highlighted!")
      }
      return;
    }

    Object.entries(this.selectedTasks).forEach(
      ([, value]) => {
        //Target the div, remove the highlight class
        const targetDiv = document.getElementById(value['key']);
        //console.log('targetDiv', targetDiv);
        if (targetDiv != null) {
          if (targetDiv.classList.contains('glb_highlightSelectedTask')) {
            targetDiv.classList.remove('glb_highlightSelectedTask');
          }
        }
      });

    this.UIArrays_Clear(false);

    if (!silently) {
      this.notifyService.Info_Show("All highlighted tasks have been unselected", "Tasks Unselected")
    }
  }

  //Launch the task view
  public TaskView_Launch() {
    //It's possible to create a 'Headless' LoanIndex that we can reuse
    const loanIndex = new LoanIndex(this.apiService, this.globalFunctions, this.notifyService, this.dialog, this.clientDataStore);
    //console.log('loanIndex', loanIndex);

    //Try to get its basic stuff initialized. send the first selected task from the array. we can split the key into its 2 parts for this request (as we need LoanID and TaskGUID both)
    const loanID = this.selectedTasks[0].key.split('_')[0]
    const taskGUID = this.selectedTasks[0].key.split('_')[1]
    //The entity in this case is always LoanTasks
    const entityName = "LoanTasks";

    //Set the fullscreen loading spinner
    this.clientDataStore.SetShowFullscreenLoading(true);
    //Now call the loanIndex initializer, called 'HeadlessMode'. we can pass the loanID and taskGUID to specify that it should load this single records from the server, and instantly show the Edit modal for us. it will remove the fullscreen loading spinner when that is done. as a script so that callbacks. i want some way to detect when save is clicked, and then refresh myself. how to do it? pass myself as a script to the Headless Mode
    //instead of going straight to the LoanEntityModify template, let's try to construct an entity and navigate to that instead. let's try getting loanindex to do it for us.
    loanIndex.HeadlessModeInit(loanID, entityName, taskGUID, this);

    //TODO some destruction or removal of these constructed headless LoanIndex and its related classes may be in order (after the modal is closed, ie. loanIndex.CloseSingleEntityModal)
  }

  //Align the columns for the aggregate table based on how much data is coming in.
  public CssClass_Get() {
    //console.log('getclass item', item);
    //we could get the length here like this, but its expensive
    //console.log('getclass Object.keys(item).length', Object.keys(item).length);

    //Instead, let's get it once when the Aggregate data is requested, and refer to the class variable that its stored in (aggregateDataColumnCount)
    let classText = 'glb_customFlexRow col-12 ';

    //Check how many columns inside the object
    if (this.aggregateDataColumnCount === 1) {
      classText += 'row-cols-1'
    }
    else if (this.aggregateDataColumnCount === 2) {
      classText += 'row-cols-2'
    }
    else if (this.aggregateDataColumnCount === 3) {
      classText += 'row-cols-3'
    }
    else if (this.aggregateDataColumnCount === 4) {
      classText += 'row-cols-4'
    }
    else if (this.aggregateDataColumnCount === 5) {
      classText += 'row-cols-5'
    }
    else if (this.aggregateDataColumnCount === 6) {
      classText += 'row-cols-6'
    }
    else if (this.aggregateDataColumnCount === 7) {
      classText += 'row-cols-7'
    }
    else if (this.aggregateDataColumnCount === 8) {
      classText += 'row-cols-8'
    }

    return classText;
  }

  public TextSize_Limit(input, length: number) {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //To set the col width when a detail data is displayed
  public ColumnCSS_Get(subItem): string {

    //Check if there is any chevron being expanded. If yes, wait until we received the detail data from the server before assigning the col width
    if (!this.globalFunctions.isEmpty(this.TemplateIdentifiers.filter(x => x.IsEnabled)[0]) && this.DetailDataReceived) {

      //First column, set to col-2
      if (subItem.key === "Assigned To") {
        return "col-2"
      }

      //Detail data, col-10
      return "col-10"
    }

    //If there are no expanded chevrons or no detail data received yet, do not apply any changes
    return "";
  }

  //When a user collapses the detail data
  public DetailData_Collapse(identifer): void {

    //Let's not collapse while the detail is still loading
    if (this.IsDetailLoading) {
      return;
    }

    //Toggle the chevron
    this.TemplateID_Toggle(identifer);

    //Bit of delay for the template identifer to get updated based on the click (ngClass)
    this.globalFunctions.delay(10).then(any => {

      //Check if there is any expanded chevron
      if (this.globalFunctions.isEmpty(this.TemplateIdentifiers.filter(x => x.IsEnabled)[0])) {

        //This is the last expanded chevron being collapsed. Reset the associated class variables
        this.DetailDataReceived = false;

        //Sync the SelectedStatus with the CachedSelectedStatus
        this.MultiselectCache_Sync(this.CachedSelectedStatus, this.SelectedStatus, this.INPStatusColumn, true, true);
      }
    });
  }

  //Calls the parent to refresh all Task based components
  public ParentTaskData_Refresh() {
    this.dashboard.RefreshTaskData();
  }

  //Used to clear all client side cached items. useful when refreshing.
  private UIArrays_Clear(clearDetailData = true) {
    //Clear the entire clickedTasks array
    this.selectedTasks.length = 0;
    this.selectedTasks = [];
    this.lastClickedTaskItemsArray.length = 0;
    this.lastClickedTaskItemsArray = [];
    //Clear out the detailed data dictionary as well. if we don't do this, then we leave data that may not be needed anymore (e.g. tasks that were reassigned after a save). will cause UI issues when trying to click items afterwards, since they now exist twice.
    //Unless we DONT want to clear UI data, e.g. when the DeselectAllTasks button is clicked.
    if (clearDetailData) {
      this.delegatorDashboardDataDetailDict = {};
      //Collapse the chevron
      this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);
    }

    //Disable the context bar
    this.showContextBar = false;
  }

  private Modal_Close() {
    this.ReassignModalMatDialog.componentInstance.ReassignTask_CloseModal();
  }

  //Apply Task Type Filter
  public TaskTypeFilter_Apply() {

    if (!this.globalFunctions.isEmpty(this.INPTaskType)) {
      this.INPTaskType.hide();
    }

    //Loop through all the selected items and add those on the top of task type options
    this.SelectedTaskTypes.forEach(taskType => {

      //Remove the selected item from the list
      this.TaskTypeOptions.splice(this.TaskTypeOptions.indexOf(taskType), 1);

      //Now add the same selected item on the top 
      this.TaskTypeOptions.unshift(taskType);
    })

    //Refresh the dashboard data
    this.DashboardAggregated_Refresh();
  }

  //Refresh the aggregated data from the server
  public DashboardAggregated_Refresh(assignedToGUID = "", status = "") {

    //Turn on the spinners
    this.showSpinner = true;
    this.refreshingAggregatedSpinner = true;

    //Task Type GUIDs
    let taskTypeGUIDs = "";
    if (this.SelectedTaskTypes.length > 0) {
      this.SelectedTaskTypes.forEach(taskType => {
        taskTypeGUIDs += taskType.guid + ",";
      });

      //Strip out the last comma delimiter
      taskTypeGUIDs = taskTypeGUIDs.substring(0, taskTypeGUIDs.length - 1);
    }

    //TaskAssignedToGUID will ensure that the column is used as the primary key instead of in the results.
    const apiRequest = { ReturnType: "Aggregate", AssignedToGUID: assignedToGUID, Status: status, KeyColumn: "TaskAssignedToGUID", TaskTypeGUIDs: taskTypeGUIDs };

    //Invoke server request
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetDelegatorDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.showSpinner = false;
          this.refreshingAggregatedSpinner = false;
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Process dashboard data reponse
          this.DashboardAggregated_Process(response);

          //Turn the spinners off
          this.showSpinner = false;
          this.refreshingAggregatedSpinner = false;
          return;
        }
      });

    //Init a new dictionary to store server data (detail). but will be populated later, when the user clicks on the chevrons
    this.delegatorDashboardDataDetailDict = {};
  }

  //Show/hide tool tip based on the size and the max limit
  public ValueWithinLimit_Check(value, limit): boolean {
    if (!this.globalFunctions.isEmpty(value)) {
      if (value.length > limit) {
        return false;
      }
    }

    return true;
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  private TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Re-sync the cached multiselect items if exists
  private MultiselectCache_Sync(cachedItems, targetItems, targetInput, resetCache = false, refreshAggregate = false): void {

    //Sync the target multiselect list with the Cached item list
    if (cachedItems.length > 0) {

      //Initialise the target list
      targetItems = [];

      //Loop through the cached items and add it to the target list
      cachedItems.forEach(item => {
        targetItems.push(item);
      });

      //Force the target input to update the UI based on selected items
      targetInput.updateModel(targetItems);

      //Reset cache items based on the parameter
      if (resetCache) {
        cachedItems.length = 0;
      }

      //Refresh dashboard based on the parameter
      if (refreshAggregate) {
        this.DashboardAggregatedDisplay_Refresh();
      }

    }
  }

  //Reset chevron and multiselect variables
  private StatusMultiSelect_Reset(): void {

    //Collapse the chevron if there are any expanded ones. We now expand detail data per status.
    this.globalFunctions.TemplateID_CollapseAll(this.TemplateIdentifiers);

    //Initialise the class variables associated with the detail data display
    this.CachedSelectedStatus.length = 0;
    this.DetailDataReceived = false;
  }

  //Gets the relevant detailed data from the server
  private DelegatorDashboardDetailData_Get(fillThis, key_AssignedTo: string, subKey_status = "", subKey_statusRaw = "") {

    //Don't forget to reinstate the braces for any keys we send back to the server. in this call, we also know that the primary key (KeyColumn) for the request is TaskGUID. We want this to be placed into the root of each returned entry in the dictionary. Easier for the html template to find the key and use it for future requests.

    //Task Type GUIDs
    let taskTypeGUIDs = "";
    if (this.SelectedTaskTypes.length > 0) {
      this.SelectedTaskTypes.forEach(taskType => {
        taskTypeGUIDs += taskType.guid + ",";
      });

      //Strip out the last comma delimiter
      taskTypeGUIDs = taskTypeGUIDs.substring(0, taskTypeGUIDs.length - 1);
    }

    const apiRequest = { ReturnType: "Detail", AssignedToGUID: this.globalFunctions.ReinstateBraces(key_AssignedTo), Status: subKey_statusRaw, KeyColumn: "TaskGUID", TaskTypeGUIDs: taskTypeGUIDs };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetDelegatorDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //Turn spinner off
          this.delegatorDashboardDataDetailDict[key_AssignedTo + this.keySeparator + subKey_status].Spinner = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('GetDelegatorDashboardDetailData response:', response);
          //Reset the dictionary
          fillThis.length = 0;

          //I want to get all headers, and fill the sortingKeysArray with it.
          let headerRowIndex = 0;

          //Now loop through and fill all properties
          for (const key in response) {
            //console.log('response[key]', response[key]);
            //Due Date: "08/07/2021"
            //Lender: "Mortgageport Home Loans Pty Ltd &amp; &gt;"
            //Type: "Conversion from I/O to P&amp;I"

            if (headerRowIndex == 0) {
              //Increment so that we only do this once
              headerRowIndex = headerRowIndex + 1;

              //Grab this first entry
              if (!this.globalFunctions.isEmpty(response[key])) {

                //And get all its keys (they are the headers)
                const headers = Object.entries(response[key]);
                //console.log('headers', headers);

                //Clear the array. no we can't. since we may launch detail of multiple sections
                //this.sortingKeysArray = [];

                //And fill the headers array for this
                headers.forEach(element => {
                  //When filling the header array, use the primary key of the section. as we may sort multiple sections at the same time!
                  //console.log('primaryKey', key_AssignedTo + this.keySeparator + subKey_status);
                  this.sortingKeysArray.push({ HeaderName: key_AssignedTo + this.keySeparator + subKey_status + element[0], HeaderSortDirection: 1 })
                });
              }
            }

            //Unescape columns
            if (!this.globalFunctions.isEmpty(response[key])) {
              if (!this.globalFunctions.isEmpty(response[key]['Lender'])) {
                response[key]['Lender'] = this.globalFunctions.HTMLUnescape(response[key]['Lender']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['Type'])) {
                response[key]['Type'] = this.globalFunctions.HTMLUnescape(response[key]['Type']);
              }
              if (!this.globalFunctions.isEmpty(response[key]['Task Note'])) {
                response[key]['Task Note'] = this.globalFunctions.HTMLUnescape(response[key]['Task Note']);
              }

              if (!this.globalFunctions.isEmpty(response[key]['Principal Borrower'])) {
                response[key]['Principal Borrower'] = this.globalFunctions.customDataTypeParser(response[key]['Principal Borrower'], "string");
              }
            }

            let value = response[key];
            //We can reparse the dictionary as JSON so that we can adjust any values, as needed. deal with date formats here instead of a html template pipe
            const JSONparsed = JSON.parse(JSON.stringify(value));

            //Format the Start Date. It is included in both active and completed tasks
            JSONparsed['Start Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Start Date'], 'shortdate', 'aus');

            if (subKey_status === 'Complete') {
              //For Complete, its a different column name
              JSONparsed['Complete Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Complete Date'], 'shortdate', 'aus');
            }
            else {
              JSONparsed['Due Date'] = this.globalFunctions.customDataTypeParser(JSONparsed['Due Date'], 'shortdate', 'aus');
            }

            //Put the nicely formatted value back into the dictionary
            value = JSONparsed;
            //And assign it to the local dictionary that was passed by reference.
            fillThis[key] = value;
          }

          //Turn spinner off
          this.delegatorDashboardDataDetailDict[key_AssignedTo + this.keySeparator + subKey_status].Spinner = false;

          //Detail loading is complete
          this.IsDetailLoading = false;

          //We have now received the detail data
          this.DetailDataReceived = true;

          //Push the status of the clicked chevron
          this.SelectedStatus = [];
          this.SelectedStatus.push({ name: subKey_statusRaw });

          //Toggle the primeng multiselect to only display the target status
          this.StatusMultiSelect_Toggle(false);

          //Force the multiselect to update the model. The select all wasn't syncing
          this.INPStatusColumn.updateModel(this.SelectedStatus);

          //Just checking the header array
          //console.log('this.sortingKeysArray', this.sortingKeysArray);

          //No need to return anything, as the html template has a function call to retrieve it from the referenced dictionary
          return;
        }
      });
  }

  //Gets the WD Task Types from the server
  private DelegatorDashboardTaskTypes_Get() {

    //Turn on the spinner on task type multiselect
    this.RefreshingTaskTypeSpinner = true;

    //Invoke API with return type = Control
    const apiRequest = { ReturnType: "Control", AssignedToGUID: "", Status: "", KeyColumn: "", TaskTypeGUIDs: "" };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetDelegatorDashboard], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //Turn off the spinner on task type multiselect
          this.RefreshingTaskTypeSpinner = false;

          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('GetDelegatorDashboardDetailData response:', response);

          //Initilise TaskTypeOptions
          this.TaskTypeOptions.length = 0;

          //Now loop through the response and fill the TaskTypeOptions
          for (const key in response) {

            //Populate Multiselect task type options with the data from the server
            this.TaskTypeOptions.push({ name: response[key]['TaskType'], guid: response[key]['TaskTypeGUID'] });

          }

          //Turn off the spinner on task type multiselect
          this.RefreshingTaskTypeSpinner = false;

          return;
        }
      });
  }

  //Colors the alternate row differently
  public RowColor_Alternate(index: number) {
    if (index % 2 === 0) {
      return ''
    }
    else {
      return 'glb_alternateRowColor'
    }
  }
}