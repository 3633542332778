import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { NotifyService } from '@app/Services/NotifyService';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountFeeData, InputDataUnit, TemplateID } from '@app/Global/Models/ClientModels';


@Component({
  selector: 'AccountFee',
  templateUrl: './AccountFee.html',
  styleUrls: ['./AccountFee.scss'],
  animations: [
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class AccountFee implements OnInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private notifyService: NotifyService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<AccountFee>) {
  }

  //To call the child component method
  @ViewChild('INP_TransactionType') INPTransactionType;
  @ViewChild('INP_FeeAmount') INPFeeAmount;
  @ViewChild('INP_FeeNote') INPFeeNote;

  public AccountPayoutCalculation;
  public AccountFees;
  public PayoutCalculator;
  public EnableAddButton = false;

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Add delay for showing mat tool tip (in ms)
  public ToolTipDelay = 300;

  //Total fees
  public TotalFees = 0;
  public TotalFeesDisplay = 0;

  //To cache the fee and note if editing cancelled
  public AccountFeeCached = 0;
  public AccountFeeNoteCached = "";
  public FeeBeingEdited = false;

  //Checkbox tool tip display
  public IncludeToolTip = "Click to include";
  public ExcludeToolTip = "Click to exclude";

  //New ModelData that captures it inside an array, which lets us use a generic function to retrieve and update
  public ModelData: InputDataUnit[] = [];

  //Angular startup method
  ngOnInit() {

    //Set the payout date to current date on initial load within this method: InitPage
    this.Page_Init();

    //Fill static data for the input data model here, base it on the input names. Demonstrating the use of the partial constructor here, only mapping the properties we want to use
    this.ModelData.push(
      new InputDataUnit({ Name: "INP_TransactionType", DisplayName: "Transaction Type", Placeholder: "Choose transaction type", HTMLInputType: "autocomplete", Type: "string" })
      , new InputDataUnit({ Name: "INP_FeeAmount", DisplayName: "Amount", Placeholder: "Fee Amount", HTMLInputType: "textarea", Type: "currency", MinValue: this.globalFunctions.TextAreaValidMinNumericGreaterThanZero, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric })
      , new InputDataUnit({ Name: "INP_FeeNote", DisplayName: "Notes", Placeholder: "Notes", HTMLInputType: "textarea", Type: "string", MinLength: this.globalFunctions.TextAreaValidLengthOptional, MaxLength: this.globalFunctions.TextAreaValidMaxLengthMD, IsResizable: true })
    );
  }

  //Refresh/sync account fees
  public AccountFee_Refresh(): void {
    this.AccountFees.forEach(element => {
      element.Fees_FMT = this.globalFunctions.customDataTypeParser(element.Fees, "currency.2", "aus");

      //Client side property to indicate whether the fee is being edited or not. It's not a server side property, therefore initialised here
      if (this.globalFunctions.isEmpty(element.ModifyFeeClicked)) {
        element.ModifyFeeClicked = false;
      }
    });

    //Sync the totals
    this.FeeTotals_Sync();
  }

  //Sync Totals
  public FeeTotals_Sync(): void {

    //Initialise totals
    this.TotalFees = 0;

    //Loop through all the included fees and calculate totals
    this.AccountFees.filter(x => x.IsIncluded === true).forEach(element => {
      this.TotalFees = this.TotalFees + element.Fees;
    });

    //Format totals for display
    this.TotalFeesDisplay = this.globalFunctions.customDataTypeParser(this.TotalFees, "currency.2", "aus");
  }

  //When a fee is included or excluded
  public IncludeCheckbox_Click(item): void {

    //Flip the flag and set the tooltip
    if (item.IsIncluded === true) {
      item.IsIncluded = false;
      item.ToolTipText = this.IncludeToolTip;
    }
    else {
      item.IsIncluded = true;
      item.ToolTipText = this.ExcludeToolTip;
    }

    //Sync the total
    this.FeeTotals_Sync();
  }

  //Validate the inputs
  public Inputs_Validated(notifyUser = true): boolean {

    let errorMessage = "";
    let inputsValidated = true;

    //Get the Transaction Type DU first
    const transactionTypeDU = this.ModelDataUnit_Get({ id: "INP_TransactionType" });

    //Validate transaction type selected, autocomplete value validation
    if (typeof (transactionTypeDU.AutoCompleteControlData["ControlGUID"]) != "string" || this.globalFunctions.isEmpty(transactionTypeDU.AutoCompleteControlData) || this.globalFunctions.isEmpty(transactionTypeDU.AutoCompleteControlData.ControlGUID)) {

      //User didn't clicked from the autocomplete list
      errorMessage = "Please select a valid transaction type.";
      inputsValidated = false;
    }

    //Validate FeeAmount if transactionType is validated, otherwise return with the error message
    if (inputsValidated) {

      //Fee amount
      const amountDU = this.ModelDataUnit_Get({ id: "INP_FeeAmount" });

      if (this.globalFunctions.isEmpty(amountDU) || this.globalFunctions.isEmpty(amountDU.Value) || Number(amountDU.Value) <= 0) {
        errorMessage = "Please input a valid fee amount.";
        inputsValidated = false;
      }
    }

    //Notify user. Not required for validation to enable the buttons
    if (notifyUser && !inputsValidated) {
      this.notifyService.Error_Show(errorMessage, "Add Fee");
    }

    return inputsValidated;
  }

  //Method to add the new fee into the array
  public Fee_Add(): void {

    //Lets validate the inputs here first
    if (!this.Inputs_Validated()) {

      //The validation methods pushes the validation error notification. No additional notifications required here
      return;
    }

    //Get the input DUs. TransactionType, FeeAmount and Fee Note
    const transactionTypeDU = this.ModelDataUnit_Get({ id: "INP_TransactionType" });
    const amountDU = this.ModelDataUnit_Get({ id: "INP_FeeAmount" });
    const noteDU = this.ModelDataUnit_Get({ id: "INP_FeeNote" });

    //Formatting the fee amount for display
    const newFeeAmount_FMT = this.globalFunctions.customDataTypeParser(Number(amountDU.Value), "currency.2", "aus");

    //Push the fee to the fees array
    this.AccountFees.push(
      new AccountFeeData({ AccountGUID: this.AccountPayoutCalculation.AccountGUID, AccountID: this.AccountPayoutCalculation.AccountID, Fees: Number(amountDU.Value), Fees_FMT: newFeeAmount_FMT, IsIncluded: true, TransactionType: transactionTypeDU.AutoCompleteControlData.ControlValue, TransactionTypeGUID: transactionTypeDU.AutoCompleteControlData.ControlGUID, TransactionTypeID: 0, Notes: noteDU.Value ?? "", FeeGUID: "{NewGUID}" })
    );

    //Sync the fee total
    this.FeeTotals_Sync();

    //Display success toast message
    this.notifyService.Success_Show("Fee added.", "Success");

    //Add fee successful, now lets clear the input values now
    this.INPTransactionType.InputData_Clear({ id: "INP_TransactionType" });
    this.INPFeeAmount.InputData_Clear({ id: "INP_FeeAmount" });
    this.INPFeeNote.InputData_Clear({ id: "INP_FeeNote" });
  }

  //Account row CSS
  public AccountRowCSS_Get(item): string {

    let cssStr = "";

    //When a fee is excluded
    if (item.IsIncluded === false) {
      cssStr = "ExcludedCSS";
    }

    return cssStr;
  }

  //This closes the modal
  public AccountFee_CloseModal(): void {

    //Check if any fee is being edited, show warning message
    if (this.FeeBeingEdited) {
      this.notifyService.Warning_Show("Please save or discard your changes before closing.", "Modify Fee");
      return;
    }

    //Call the sync method on the parent component to sync the fees
    this.PayoutCalculator.AccountFee_Sync(this.AccountPayoutCalculation.AccountID, this.AccountFees);

    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }

    //Display the minimized menu dialog if applicable
    this.globalFunctions.ShowMinimizedDialog_Toggle(true);
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Sync the display of the UI input elements
  public InputView_Sync(): void {

    //Enable Add button only after the mandatory inputs are provided
    this.EnableAddButton = false;

    //Validate the mandatory inputs
    if (this.Inputs_Validated(false)) {
      this.EnableAddButton = true;
    }
  }

  //Get the matching model data input
  public ModelDataUnit_Get(input) {
    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelDataItem)) {
      return modelDataItem;
    }
  }

  //When a fee edit button is clicked
  public Fee_Modify(item) {

    //Cache the current value
    this.AccountFeeCached = JSON.parse(JSON.stringify(item.Fees));
    this.AccountFeeNoteCached = JSON.parse(JSON.stringify(item.Notes));

    //Set the flag to indicate a fee is being edited
    this.FeeBeingEdited = true;

    //Set the flag to indicate that the account fee is being modified
    item.ModifyFeeClicked = true;
  }

  //Save modify fee changes
  public FeeModify_Save(item): void {

    //Validate the fee amount
    if (this.globalFunctions.isEmpty(item.Fees) || this.globalFunctions.isEmpty(item.Fees) || Number(item.Fees) <= 0 || item.Fees === "-") {
      this.notifyService.Error_Show("Please input a valid fee amount.", "Modify Fee");
      return;
    }

    //Check if the values have been modified before saving
    if (!this.globalFunctions.isEmpty(this.AccountFeeCached) && Number(item.Fees) === Number(this.AccountFeeCached) && item.Notes === this.AccountFeeNoteCached) {
      this.notifyService.Warning_Show("The target values match the requested values. Please change values and try again, or cancel.", "Modify Fee");
      return;
    }

    //Convert the value to number. Two way binding sets the value as string. If the user doesn't make any changes on the Fees input, the item.Fees seems to have retained its numeric format
    item.Fees = Number(item.Fees.toString().replaceAll("$", ""));

    //Adjusted the fee from user input, now refresh to update the display value
    this.AccountFee_Refresh();

    //Set the flag to indicate a fee editing is completed
    this.FeeBeingEdited = false;

    //Set editing fee flag on the fee item to false
    item.ModifyFeeClicked = false;
  }

  //When fee editing is cancelled
  public FeeModify_Cancel(item): void {

    //Set the changes back to the cached value
    item.Fees = JSON.parse(JSON.stringify(this.AccountFeeCached));
    item.Notes = JSON.parse(JSON.stringify(this.AccountFeeNoteCached));

    //Set the flag to indicate a fee editing is cancelled
    this.FeeBeingEdited = false;

    //Set editing fee flag on the fee item to false
    item.ModifyFeeClicked = false;
  }

  //Input validation for two way binded properties. Can't revert to last valid value when invalid values pasted from clipboard (Can only strip last character and check)
  public TextArea_Validate(item, event, inputType = "currency", propertyName = "Fees") {

    //Where was this character inserted? at event.target.selectionStart
    const characterIndexInserted = event.target.selectionStart;

    //Check the data type, and perform the necessary removal of invalid characters using regex. except string, that has no regex
    if (inputType.includes('string') === false) {

      //Get the regex expression for the input data type
      const regExType = this.globalFunctions.RegExp_Get(inputType);

      //Now perform the regex test
      if (!this.globalFunctions.isEmpty(regExType) && regExType.test(item[propertyName]) === false) {

        //Didn't pass regex, remove the last character
        this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted, item, propertyName);

        //Let's parse it into a pretty text that we can display to the user
        const prettyType = JSON.parse(JSON.stringify(inputType));
        let prettyTypeDescription = '';

        if (prettyType.includes('.') === true) {
          const splitted = prettyType.split('.');
          prettyTypeDescription = splitted[0] + " with a maximum of " + splitted[1] + " decimal places";
        }
        else {
          prettyTypeDescription = prettyType;
        }

        //What if it was pasted in? and has more issues than just the last entered character? regex it once more, if it doesn't pass, remove the entire thing
        if (regExType.test(item[propertyName]) === false) {

          //Set it to empty
          item[propertyName] = "";
        }

        //Now give the user this error message
        this.notifyService.Error_Show("An invalid value was entered. Please review and try again", "Not a valid " + prettyTypeDescription)
      }
      else {
        //We passed regex. now lets do max and min processing for numeric values

        //Check if its too big
        if (parseFloat(item[propertyName]) > this.globalFunctions.TextAreaValidMaxNumeric) {

          //Don't update it, its too large. now give the user this error message
          this.notifyService.Error_Show("This value is larger than the maximum of " + this.globalFunctions.customDataTypeParser(this.globalFunctions.TextAreaValidMaxNumeric, "decimal.0") + ". Please make it smaller and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted, item, propertyName);

          //If its still too large, set it to empty
          if (parseFloat(item[propertyName]) > this.globalFunctions.TextAreaValidMaxNumeric) {
            item[propertyName] = "";
          }
        }

        //Check if its too small
        else if (parseFloat(item[propertyName]) < this.globalFunctions.TextAreaValidMinNumericGreaterThanZero) {

          //Don't update it, its too small. now give the user this error message
          this.notifyService.Error_Show("This value is smaller than the minimum of " + this.globalFunctions.TextAreaValidMinNumericGreaterThanZero + ". Please make it larger and try again", "Invalid value")

          //Didn't pass, remove the last character
          this.LastCharacter_ResetAndRemove(event.target, characterIndexInserted, item, propertyName);

          //If its still too small, set it to empty
          if (parseFloat(item[propertyName]) < this.globalFunctions.TextAreaValidMinNumericGreaterThanZero) {
            item[propertyName] = "";
          }
        }
      }
    }
  }

  //Removes a character from the event target string, and resets the location of the selection index
  private LastCharacter_ResetAndRemove(target, characterIndexInserted, item, propertyName = ""): void {

    //Didn't pass, remove the last character
    target.value = this.globalFunctions.removeStringByIndex(target.value, characterIndexInserted);

    //Validation didn't pass, remove the last character
    item[propertyName] = target.value;

    //Try to set the cursor back to where it was
    target.selectionStart = characterIndexInserted - 1;
    target.selectionEnd = characterIndexInserted - 1;
  }

  //Setting defaults on initial load
  private Page_Init(): void {

    //Get the account fees
    this.AccountFees = this.AccountPayoutCalculation.DischargeFeesResults;

    //Sync/refresh the account fees
    this.AccountFee_Refresh();
  }
}