<div id="PG_LoanEntity" [ngClass]="CheckIfBorderPaddingRequired()">

  <!-- lets wrap the title and entity buttons into a flex -->
  <div class="glb_Font glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 align-items-end">
    <!-- show the entity name when the spinner is off -->
    <div *ngIf="EntityDict[EntityName].Spinner === 1" class="titleClass">{{NavDisplayName}}</div>
    <!-- loading text name when the spinner is on -->
    <div *ngIf="EntityDict[EntityName].Spinner === 0" class="titleClass">Loading...</div>
    <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-center">


      <div class="input-group input-group-sm col-8 col-xs-8 col-sm-8 col-md-8 col-lg-6 justify-content-end">
        <!-- this is the hide ghosted transaction checkbox -->
        <span *ngIf="EntityName === 'LoanTransactions' && ShowHideGhostedTransactionTickbox"
          class="noLeftPadding hasPadding input-group-text glb_paddingZeroOffsetRight glb_RemoveBGandBorder">
          <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="hideGhostedTransactions"
            (change)="HideGhosted(hideGhostedTransactions, $event)"> Hide Ghosted
          </mat-checkbox>
        </span>

        <!-- this is the hide system notes checkbox -->
        <span *ngIf="EntityName === 'LoanNotes'" class="noLeftPadding hasPadding input-group-text  glb_paddingZeroOffsetRight glb_RemoveBGandBorder">
          <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="hideSystemNotes" (change)="HideSystemNotes(hideSystemNotes, $event)">
            Hide System Notes
          </mat-checkbox>
        </span>

        <div *ngIf="ShowSearchFilterBox" class="input-group-text glb_paddingZeroOffsetRight glb_RemoveBGandBorder">
          <div class="d-none d-md-block d-lg-block">
            <span class="glb_Font input-group-text glb_ZeroRadiusRight glb_PaddingVerticalSM" id="filter">Filter</span>
          </div>
          <input type="text" class="glb_Font form-control form-control-custom glb_ZeroRadiusLeft glb_PaddingVerticalSM"
            placeholder="Enter Search Term" (keyup)='filterSearchValueKeyUp.next($event)' [(ngModel)]='filterSearchValue' aria-label="Filter"
            aria-describedby="filter">
        </div>

        <div class="glb_rightAlignedContentPadding input-group-text glb_paddingZeroOffsetRight glb_RemoveBGandBorder">

          <span *ngIf="ShowRefreshButton && EntityDict[EntityName].Spinner === 1" class="noLeftPadding hasPadding">
            <button (click)="loanIndex.Entity_FullRefresh(EntityName)"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'sync-alt']" matTooltip="Click to Refresh">
              </fa-icon>
            </button>
          </span>
          <span *ngIf="ShowRefreshButton && EntityDict[EntityName].Spinner === 0" class="noLeftPadding hasPadding">
            <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons glb_fastSpin" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="EntityName === 'LoanTransactions' && ShowPayoutCalculator" class="noLeftPadding hasPadding">
            <button id="BTN_LaunchResiPayout" (click)="loanIndex.ResiPayout_Launch()"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'sack-dollar']" matTooltip="Click to Launch Residential Payout Calculator">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="EntityName === 'LoanTransactions' && ShowLeasePayoutCalculator" class="noLeftPadding hasPadding">
            <button id="BTN_LaunchLeasePayout" (click)="loanIndex.LeasePayout_Launch()"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'garage-car']" matTooltip="Click to Launch Lease Payout Calculator">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="EntityName === 'LoanTransactions' && ShowStatementGenerator" class="noLeftPadding hasPadding">
            <button id="BTN_LaunchGenerateStatement" (click)="loanIndex.StatementGenerator_Launch()"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'book']" matTooltip="Click to Generate a Statement">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="EntityName === 'LoanDocuments' && ShowDocumentGenerator" class="noLeftPadding hasPadding">
            <button id="BTN_LaunchGenerateDocument" (click)="loanIndex.DocumentGenerator_Launch()"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'file-plus']" matTooltip="Click to Generate a Document">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="ShowInsertNewEntityButton" class="noLeftPadding hasPadding">
            <button id="BTN_AddNewEntity" (click)="loanIndex.Entity_OpenModifyTemplate(null, '', EntityName)"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'plus-square']" matTooltip="Click to Add">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="ShowDownloadButton" class="noLeftPadding hasPadding">
            <button (click)="loanIndex.DownloadCSV(EntityName)"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
              </fa-icon>
            </button>
          </span>

          <span *ngIf="ShowModalCloseButton" class="noLeftPadding hasPadding">
            <button id="BTN_CloseLoanEntity" (click)="loanIndex.CloseSingleEntityModal()"
              class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right">
              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'times']" matTooltip="Click to Close">
              </fa-icon>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <!-- this is the loading spinner, lets show it while the entity is loading -->
    <div class="glb_customFlexRow headerCardBorder" *ngIf="EntityDict[EntityName].Spinner === 0">
      <div class="col-12" style="padding-top: 12px;">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" style="width: 2rem; height: 2rem;"></div>
        </div>
      </div>
    </div>

    <!-- lets have a no results section. -->
    <div *ngIf="EntityDict[EntityName].Count === 0" class="glb_customFlexRow headerCardBorder row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
      <!-- <div class="d-flex justify-content-center">
      <fa-icon class="glb_clickableIconButtonPadding" [icon]="['fal', 'ban']" size="lg" class="noResultsColor btn btn-failure align-center"
        style="color:grey;" matTooltip="No results"></fa-icon>
    </div> -->
      <div class="d-flex justify-content-center" style="padding-top: 8px;">
        <div class="noResultslabelClass">(No Records)</div>
      </div>
    </div>

    <!-- check spinner array before showing content -->
    <div id="ParentEntityContainerId" *ngIf="EntityDict[EntityName].Spinner === 1 && EntityDict[EntityName].Count > 0"
      [ngClass]="GetTopBorderClass()">
      <!-- check if we want to show a tabular style header. this is in the TableHeaders variable -->
      <div *ngIf="ShowTableHeaders && EntityDict[EntityName].Count> 0" style="background: white;" class="d-none d-md-block d-lg-block sticky-top"
        [ngClass]="{'card-body-bg-grey' : ColumnsInBody > 3, 'card-body-bg' : ColumnsInBody === 2 || ColumnsInBody === 0 || ColumnsInBody === 1}">
        <div class="glb_customFlexRow headerTableBottomBorder" [ngClass]="getClass('DataPair', TableHeaders.length, WrapOddRows)">
          <div *ngFor="let tableheaderitem of TableHeaders; odd as isOdd; even as isEven; index as itemIndex">
            <div class="text-style">
              <!-- make the inner value pairs wrap when the view is too small -->
              <div [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : !WrapOddRows && ColumnsInBody === 1
              ,'row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : !WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0) ||
              CombineLabelAndData
              }">
                <div [ngClass]="getClass('TableHeaderValues', ColumnsInBody, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                TableHeaders.length)">
                  {{tableheaderitem.PropertyName}}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- start looping through the entity here -->
      <div id="DR_Entity_{{rootParentItemIndex}}" *ngFor="let item of EntityDict[EntityName].Entity | searchfilter | paginate: paginatorConfig;
      index as rootParentItemIndex" @fadeIn [ngClass]="GetBorderPaddingClass()">
        <!-- adds border padding to pivoted card type content -->
        <div class="card cardCustom ParentDataRow"
          [ngClass]="{'card-body-bgwhite card-tabular' : ColumnsInBody > 3,'card-body-bgwhite justify-content-center cardCustomPadding2' : WrapOddRows,'card-body-nobg cardCustomPadding' : !WrapOddRows}"
          (mouseenter)="OverlayDataUnits_Show($event,overlayPanel_OverlayDU, item)"
          (mouseleave)="OverlayDataUnits_Hide(overlayPanel_OverlayDU, item)">

          <!-- check if we were asked to show the card header section -->
          <div *ngIf="ShowHeader === true" [ngClass]="{'paddingClassForCardHeader' : ShowHeader === true && !WrapOddRows}">
            <div class="header-title headerDataClassRow" [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInHeader === 1
          ,'glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : ColumnsInHeader === 2 || ColumnsInHeader === 0}">
              <!-- loop through all data units and show any marked as headers -->
              <div *ngFor="let subitemheader of item.DataUnits | header; odd as isOdd; even as isEven; index as itemIndex"
                class="align-self-center text-style" (click)="loanIndex.DataUnit_Clicked($event, item, subitemheader.Name);"
                style="padding-right:0px;">
                <!-- make the inner value pairs wrap when the view is too small -->
                <div [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : !WrapOddRowsHeader && ColumnsInHeader === 1
              ,'glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : !WrapOddRowsHeader && (ColumnsInHeader === 2 || ColumnsInHeader === 0)
              }">
                  <div *ngIf="subitemheader.ShowLabel===true" class="labelClass text-lg-start text-md-start text-sm-start"
                    [innerHTML]="subitemheader.Name | sanitizeHtml"></div>
                  <!-- right align all odd rows. with headers, we generally never show labels. so its best to left align it -->
                  <div [ngClass]="{
                'headerDataClass text-lg-end text-md-end text-sm-end text-xs-start' : (isOdd || !WrapOddRowsHeader),
                'headerDataClass text-lg-start text-md-start text-sm-start' : isEven && (WrapOddRowsHeader && ColumnsInHeader> 1)
                }" [innerHTML]="subitemheader.ValueDisplay | sanitizeHtml"></div>
                </div>
              </div>


              <!-- What if we want to support the insertion of first level child entities? -->
              <div class="glb_customFlexRow justify-content-end">

                <!--Finalise Task Button to be made visible on LoanTasks-->
                <div *ngIf="loanIndex.CSActionButton_IsVisible(item,'FinaliseCSTask')" style="padding: 0.2em;">
                  <button id="BTN_LaunchFinaliseTask" class="glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                    <fa-icon [icon]="['fal', 'flag-checkered']" class="fa-fw glb_fixedSizeIcons" style="color:black;"
                      matTooltip="Click to Finalise Task" (click)="loanIndex.TaskFinalise_Launch(item)">
                    </fa-icon>
                  </button>
                </div>

                <!--Follow Up Task Button to be made visible on LoanTasks-->
                <div *ngIf="loanIndex.CSActionButton_IsVisible(item,'ConditionSubsequentTasking')" style="padding: 0.2em;">
                  <button id="BTN_LaunchFollowUpTask" class="glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                    <fa-icon [icon]="['fal', 'calendar-lines']" class="fa-fw glb_fixedSizeIcons" style="color:black;"
                      matTooltip="Click to set Follow Up Date" (click)="loanIndex.TaskFollowup_Launch(item)">
                    </fa-icon>
                  </button>
                </div>

                <!--Create Reminder Button to be made visible on LoanTasks-->
                <div *ngIf="loanIndex.CSActionButton_IsVisible(item,'ConditionSubsequentTasking')" style="padding: 0.2em;">
                  <button id="BTN_LaunchCreateEmail" class="glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right"
                    (click)="overlayPanel_Email.toggle($event)">
                    <fa-icon [icon]="['fal', 'envelope']" class="fa-fw glb_fixedSizeIcons" style="color:black;"
                      matTooltip="Click to choose email template">
                    </fa-icon>
                  </button>
                </div>

                <!-- This is the overlay panel for the create email-->
                <p-overlayPanel #overlayPanel_Email id="LBL_OPCreateEmail" styleClass="glb_primeng_overlayPanel_sm glb_primeng_overlayPanel_default">
                  <div class="glb_customFlexRow">
                    <div class="col-12 glb_boxedPaddingXS">
                      <button id="BTN_LaunchReminderEmail"
                        class="glb_standardButton glb_skinLightOutline glb_standardButtonTight glb_BGColorSkin text-white"
                        (click)="CreateEmail_Launch(overlayPanel_Email, item, 'Reminder')">Reminder Template</button>
                    </div>
                    <div class="col-12 glb_boxedPaddingXS">
                      <button id="BTN_LaunchFollowupEmail"
                        class="glb_standardButton glb_skinLightOutline glb_standardButtonTight glb_BGColorSkin text-white"
                        (click)="CreateEmail_Launch(overlayPanel_Email, item, 'Followup')">Followup
                        Template</button>
                    </div>
                  </div>
                </p-overlayPanel>

                <!--QC Feedback Button to be made visible on LoanTasks with the TaskQualityReview Claim-->
                <div *ngIf="loanIndex.TaskFeedbackButton_IsVisible(item)" style="padding: 0.2em;">
                  <button id="BTN_LaunchTaskQualityReview" class="glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                    <fa-icon [icon]="['fal', 'user-check']" class="fa-fw glb_fixedSizeIcons" style="color:black;"
                      matTooltip="Click to add Quality Checking feedback" (click)="loanIndex.TaskFeedback_Launch(item)">
                    </fa-icon>
                  </button>
                </div>

                <div *ngFor="let validChildEntity of loanIndex.Entity_GetValidChildren(item.Entity)">
                  <div *ngIf="loanIndex.ChildEntity_IsInsertable(validChildEntity)" style="padding: 0.2em;">
                    <button class="glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                      <!-- here we pass an empty value for the label name, so that the modal will populate an entry for all dataunits inside the datarow-->
                      <fa-icon [icon]="['fal', loanIndex.Entity_GetFontAwesomeIcon(validChildEntity)]" class="fa-fw glb_fixedSizeIcons"
                        style="color:black;" matTooltip="Click to Create a {{loanIndex.Entity_GetFriendlyName(validChildEntity)}}"
                        (click)="loanIndex.Entity_OpenModifyTemplate(null, '', validChildEntity, false, item.GUID)">
                      </fa-icon>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- card body goes here. make the inner content flex -->
          <!-- looks like there is a bootstrap bug. had to pull this one out into a function to get it working! -->
          <div class="glb_customFlexRow" [ngClass]="getClass('DataPair', ColumnsInBody, WrapOddRows, null, null, null, null, null, item.RowStyle)"
            style="height:100%">

            <div *ngFor="let subitembody of item.DataUnits | body; odd as isOdd; even as isEven; index as itemIndex;"
              (click)="loanIndex.DataUnit_Clicked($event, item, subitembody.Name);" class="glb_customFlexRow align-items-stretch"
              [ngClass]="GetPaddingClassForCards()">
              <div class="text-style glb_customFlexRow align-self-stretch" style="width:100%;">
                <!-- make the inner value pairs wrap when the view is too small -->
                <div
                  [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : !WrapOddRows && ColumnsInBody === 1,'bottomBorder align-items-center align-self-stretch glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : !WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0) || CombineLabelAndData, 'align-self-center' : 1 === 1}"
                  style="width:100%;">
                  <div *ngIf="subitembody.ShowLabel===true" class="labelClass text-lg-start text-md-start text-sm-start">{{subitembody.Name}}</div>
                  <!-- we only want to make this right align (WrapOddRows) for certain entity types, e.g. dates. -->
                  <!-- TODO - item.DataUnits.length includes hidden columns. this messes up for some entities, e.g. Loan Documents. Need to pass in the number of UNHIDDEN columns only -->
                  <!-- same principle applies to headers as well -->
                  <div [ngClass]="getClass('ValueDisplay', ColumnsInBody, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                item.DataUnits.length, '', item.DataUnits)">
                    <div *ngIf="subitembody.Type !== 'boolean'"
                      [ngClass]="{'glb_emptyStyle' : subitembody.CSSClass === 'glb_emptyStyle', 'glb_activeHyperlink glb_hoverHand' : subitembody.CSSClass === 'glb_activeHyperlink glb_hoverHand'}"
                      [innerHTML]="subitembody.ValueDisplay | sanitizeHtml"></div>
                    <!-- show boolean values using material checkbox, disabled -->
                    <div *ngIf="subitembody.Type === 'boolean'">
                      <mat-checkbox class="mat-checkbox-light-custom" [disabled]="true"
                        [checked]="(subitembody.ValueDisplay === 'True' || subitembody.ValueDisplay === 'true')" #checkBox>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- let's loop through and show any child entities here -->
            <!-- the first child root usually defines an entity-->
            <div *ngFor="let childItemRoot1 of item.ChildEntity" class="ParentDataRow" style="padding-right:12px;">
              <!-- make sure that there is at least one data unit before we show the collapsible button -->
              <div *ngIf="childItemRoot1.DataUnits.length> 0" class="bottomBorder glb_customFlexRow row-cols-2">
                <div class="childEntityTitle2 align-self-center">
                  {{childItemRoot1.EntityName}}
                </div>
                <div class="glb_customFlexRow justify-content-end">
                  <a id="ParentChevronId" (click)="TemplateID_Toggle(childItemRoot1.GUID+rootParentItemIndex)"
                    class="align-self-center glb_hoverHand">
                    <fa-icon [icon]="['fal', 'chevron-down']" class="fa-fw glb_fixedSizeIcons childEntityChevronSlide2"
                      [ngClass]="TemplateID_GetCSS(childItemRoot1.GUID+rootParentItemIndex,true)" matTooltip="Expand" matTooltipPosition="left">
                    </fa-icon>
                    <fa-icon [icon]="['fal', 'chevron-up']" class="fa-fw glb_fixedSizeIcons childEntityChevronSlide2"
                      [ngClass]="TemplateID_GetCSS(childItemRoot1.GUID+rootParentItemIndex,false)" matTooltip="Hide" matTooltipPosition="left">
                    </fa-icon>
                  </a>
                </div>
              </div>

              <!-- actually we should also loop and showdataunits from the child entity itself, e.g. for valuations -->
              <div class="glb_customFlexRow"
                [ngClass]="getClass('DataPair', ColumnsInBody, WrapOddRows, null, null, null, null, null, childItemRoot1.RowStyle,null,null,childItemRoot1.GUID+rootParentItemIndex)">
                <div *ngFor="let childItemBody of childItemRoot1.DataUnits | body; odd as childItemisOdd; even as childItemisEven; index as
              childItemIndex" (click)="loanIndex.DataUnit_Clicked($event, childItemRoot1, childItemBody.Name, item.GUID);"
                  class="glb_customFlexRow align-self-stretch align-items-center">
                  <div class="text-style glb_customFlexRow align-self-stretch" style="width:100%;">
                    <!-- make the inner value pairs wrap when the view is too small -->
                    <!-- <div [ngClass]="{'row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : !WrapOddRows && ColumnsInBody === 1
                  ,'bottomBorder glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : !WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody
                  === 0) || CombineLabelAndData }"> -->
                    <div class="bottomBorder align-items-center align-self-stretch glb_customFlexRow" style="width:100%;"
                      [ngClass]="getChildEntityClass(childItemBody.ShowLabel)">
                      <div *ngIf="childItemBody.ShowLabel===true" class="labelClass align-self-center text-lg-start text-md-start text-sm-start">
                        {{childItemBody.Name}}
                      </div>
                      <!-- TODO - item.DataUnits.length includes hidden columns. this messes up for some entities, e.g. Loan Documents. Need to pass in the number of UNHIDDEN columns only -->
                      <!-- same principle applies to headers as well -->
                      <div [ngClass]="getClass('ValueDisplay', ColumnsInBody, WrapOddRows, childItemisOdd, childItemisEven, CombineLabelAndData,
                    childItemIndex, childItemRoot1.DataUnits.length)" class="align-self-center">
                        <div *ngIf="childItemBody.Type !== 'boolean'" [ngClass]="{'glb_emptyStyle' : childItemBody.CSSClass === 'glb_emptyStyle'}">
                          {{childItemBody.ValueDisplay}}
                        </div>
                        <!-- show boolean values using material checkbox, disabled -->
                        <div *ngIf="childItemBody.Type === 'boolean'">
                          <mat-checkbox class="mat-checkbox-light-custom" [disabled]="true"
                            [checked]="(childItemBody.ValueDisplay === 'True' || childItemBody.ValueDisplay === 'true')" #checkBox>
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- this is for child entities inside each child. e.g. the client individual of client -->
              <div *ngFor="let childItem of childItemRoot1.ChildEntity" class="card cardCustom bottomBorder ParentDataRow"
                [ngClass]="{'card-body-bgwhite card-tabular' : ColumnsInBody> 3, 'card-body-bg' : WrapOddRows, 'card-body-nobg' : !WrapOddRows}">
                <!-- make sure that there is at least one data unit before we show the collapsible button -->
                <div *ngIf="childItem.DataUnits.length> 0" class="glb_customFlexRow row-cols-2">
                  <div class="childEntityTitle">
                    {{childItem.EntityName}}
                  </div>

                  <div class="d-flex justify-content-end">

                    <!-- what if we want to support the insertion of second level child entities? e.g. addresses and contacts -->
                    <div class="glb_customFlexRow d-flex justify-content-end align-items-center" style="padding-right: 15px;">
                      <div *ngFor="let validChildEntity of loanIndex.Entity_GetValidChildren(childItem.Entity)">

                        <div>
                          <span *ngIf="loanIndex.ChildEntity_IsInsertable(validChildEntity)" style="padding-left:0.375em;">
                            <button (click)="loanIndex.Entity_OpenModifyTemplate(null, '', validChildEntity, false, item.GUID,
                      childItem)" class="btn glb_BGColorGreyCircular glb_clickableIconButtonMD align-self-right">
                              <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', loanIndex.Entity_GetFontAwesomeIcon(validChildEntity)]"
                                matTooltip="Click to Create a {{loanIndex.Entity_GetFriendlyName(validChildEntity)}}">
                              </fa-icon>
                            </button>
                          </span>
                        </div>

                      </div>
                    </div>

                    <a (click)="TemplateID_Toggle(childItem.GUID+rootParentItemIndex)" class="align-self-center glb_hoverHand">
                      <fa-icon [icon]="['fal', 'chevron-down']" class="glb_chevronStyle"
                        [ngClass]="TemplateID_GetCSS(childItem.GUID+rootParentItemIndex,true)" matTooltip="Expand" matTooltipPosition="left">
                      </fa-icon>
                      <fa-icon [icon]="['fal', 'chevron-up']" class="glb_chevronStyle"
                        [ngClass]="TemplateID_GetCSS(childItem.GUID+rootParentItemIndex,false)" matTooltip="Hide" matTooltipPosition="left">
                      </fa-icon>
                    </a>
                  </div>
                </div>

                <div class="glb_customFlexRow" [ngClass]="getClass('DataPair', ColumnsInBody, WrapOddRows, null, null,
              null, null, null, childItem.RowStyle,null, null, childItem.GUID+rootParentItemIndex)">

                  <div *ngFor="let childItemBody of childItem.DataUnits | body; odd as childItemisOdd; even as childItemisEven; index as
                childItemBodyIndex; let last= last" [ngClass]="{'last-child': last, 'bottomBorder': !last}"
                    (click)="loanIndex.DataUnit_Clicked($event, childItem, childItemBody.Name, item.GUID);"
                    class="glb_customFlexRow align-self-stretch align-items-center">
                    <div class="text-style glb_customFlexRow align-self-stretch" style="width:100%;">
                      <!-- make the inner value pairs wrap when the view is too small -->
                      <!-- <div [ngClass]="{'row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : !WrapOddRows && ColumnsInBody === 1
                    ,'bottomBorder glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2' : !WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody
                    ===  0) || CombineLabelAndData }"> -->
                      <div class="align-items-center align-self-stretch glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2"
                        style="width:100%;">
                        <div *ngIf="childItemBody.ShowLabel===true" class="labelClass align-self-center text-lg-start text-md-start text-sm-start">
                          {{childItemBody.Name}}
                        </div>
                        <!-- TODO - item.DataUnits.length includes hidden columns. this messes up for some entities, e.g. Loan Documents. Need to pass in the number of UNHIDDEN columns only -->
                        <!-- same principle applies to headers as well -->
                        <div [ngClass]="getClass('ValueDisplay', ColumnsInBody, WrapOddRows, childItemisOdd, childItemisEven, CombineLabelAndData,
                      childItemBodyIndex, childItem.DataUnits.length)" class="align-self-center">
                          <div *ngIf="childItemBody.Type !== 'boolean'" [ngClass]="{'glb_emptyStyle' : childItemBody.CSSClass === 'glb_emptyStyle'}">
                            {{childItemBody.ValueDisplay}}
                          </div>
                          <!-- show boolean values using material checkbox, disabled -->
                          <div *ngIf="childItemBody.Type === 'boolean'">
                            <mat-checkbox class="mat-checkbox-light-custom" [disabled]="true"
                              [checked]="(childItemBody.ValueDisplay === 'True' || childItemBody.ValueDisplay === 'true')" #checkBox>
                            </mat-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- Child Nav Tabs - Titles/Pills -->
            <div *ngIf="item.ChildNavTabs.length > 0">
              <nav style="padding-right: 12px;">
                <div class="nav nav-tabs nav-pills glb_customFlexRow align-items-center align-self-stretch" style="width:100%;">
                  <ng-container *ngFor="let childNavTab of item.ChildNavTabs; index as childNavTabIndex">
                    <!-- This is the child nav tab name that can be clicked on to display its contents -->
                    <button class="nav-link" id="BTN_ChildNavTabTitle_{{childNavTab.EntityName}}"
                      [ngClass]="GetCurrentNavBarItem(item.GUID,item.GUID+childNavTab.EntityName, childNavTabIndex)"
                      (click)="NavBarItemClicked(item.GUID,item.GUID+childNavTab.EntityName, childNavTabIndex)">{{childNavTab.FriendlyName}}
                      ({{childNavTab.ChildNavTabEntities.length}})
                    </button>
                  </ng-container>
                </div>
              </nav>
            </div>

            <!-- Child Nav Tabs - Content -->
            <div *ngIf="item.ChildNavTabs.length > 0" class="glb_customFlexRow align-items-center align-self-stretch" style="width:100%;">
              <div *ngFor="let childNavTab of item.ChildNavTabs; index as childNavTabIndex"
                [ngClass]="ShowCurrentNavItem(item.GUID,item.GUID+childNavTab.EntityName, childNavTabIndex)" class="col-12">

                <!-- Show a no record text if no data -->
                <div *ngIf="childNavTab.ChildNavTabEntities.length === 0" class="tab-pane col-12 glb_customFlexRow justify-content-center"
                  style="padding-top: 8px;">
                  <div class="noResultslabelClass">(No Records)</div>
                </div>

                <!-- Regular data view -->
                <div *ngIf="childNavTab.ChildNavTabEntities.length > 0" class="tab-pane col-12"
                  id="LBL_ChildNavTabContent_{{childNavTab.EntityName}}">
                  <div *ngFor="let childNavTabEntity of childNavTab.ChildNavTabEntities">
                    <!-- Code was copied from above and then replaced here -->
                    <div *ngFor="let processedChildNavTabEntity of childNavTabEntity.ProcessedChildNavTabEntity" class="ParentDataRow"
                      style="padding-right:12px;">
                      <!-- Make sure that there is at least one data unit before we show the collapsible button -->
                      <div *ngIf="processedChildNavTabEntity.DataUnits.length> 0" class="bottomBorder glb_customFlexRow row-cols-2">
                        <div class="childEntityTitle2 align-self-center">
                          {{processedChildNavTabEntity.EntityName}}
                          <span *ngIf="PasswordReset_Show(processedChildNavTabEntity, item)" class="childEntityTitle2 align-self-center">
                            <button id="BTN_BorrowerPasswordReset_{{item.GUID}}"
                              class="glb_standardButtonNarrow glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white glb_Font"
                              (click)="ResetBorrowerPassword_Click(processedChildNavTabEntity, item)">Reset
                              password</button>
                          </span>
                        </div>
                        <!-- These are the buttons for collpasing and uncollapsing the data units in child nav tabs -->
                        <div class="glb_customFlexRow justify-content-end">
                          <a id="CHV_FirstChildChevron" (click)="TemplateID_Toggle(processedChildNavTabEntity.GUID+rootParentItemIndex)"
                            class="align-self-center glb_hoverHand">
                            <fa-icon [icon]="['fal', 'chevron-down']"
                              [ngClass]="TemplateID_GetCSS(processedChildNavTabEntity.GUID+rootParentItemIndex,true,processedChildNavTabEntity.Entity)"
                              class="fa-fw glb_fixedSizeIcons childEntityChevronSlide2" matTooltip="Expand" matTooltipPosition="left">
                            </fa-icon>
                            <fa-icon [icon]="['fal', 'chevron-up']"
                              [ngClass]="TemplateID_GetCSS(processedChildNavTabEntity.GUID+rootParentItemIndex,false, processedChildNavTabEntity.Entity)"
                              class="fa-fw glb_fixedSizeIcons childEntityChevronSlide2" matTooltip="Hide" matTooltipPosition="left">
                            </fa-icon>
                          </a>
                        </div>
                      </div>

                      <!-- actually we should also loop and showdataunits from the child entity itself, e.g. for valuations (child version now only, like user task note and user task document) -->
                      <div class="glb_customFlexRow"
                        [ngClass]="getClass('DataPair', ColumnsInBody, WrapOddRows, null, null, null, null, null, processedChildNavTabEntity.RowStyle, null, processedChildNavTabEntity.Entity, processedChildNavTabEntity.GUID+rootParentItemIndex)">
                        <div
                          *ngFor="let childItemBody of processedChildNavTabEntity.DataUnits | body; odd as childItemisOdd; even as childItemisEven; index as childItemIndex"
                          (click)="loanIndex.DataUnit_Clicked($event, processedChildNavTabEntity, childItemBody.Name, item.GUID);"
                          class="glb_customFlexRow align-self-stretch align-items-center">
                          <div class="text-style glb_customFlexRow align-self-stretch" style="width:100%;" matTooltip="Click to Select">
                            <div
                              class="bottomBorder align-items-center align-self-stretch glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2"
                              style="width:100%;">
                              <div *ngIf="childItemBody.ShowLabel===true"
                                class="labelClass align-self-center text-lg-start text-md-start text-sm-start">
                                {{childItemBody.Name}}
                              </div>
                              <div
                                [ngClass]="getClass('ValueDisplay', ColumnsInBody, WrapOddRows, childItemisOdd, childItemisEven, CombineLabelAndData,childItemIndex, processedChildNavTabEntity.DataUnits.length)"
                                class="align-self-center">
                                <div *ngIf="childItemBody.Type !== 'boolean'"
                                  [ngClass]="{'glb_emptyStyle' : childItemBody.CSSClass === 'glb_emptyStyle'}">
                                  {{childItemBody.ValueDisplay}}
                                </div>
                                <!-- show boolean values using material checkbox, disabled -->
                                <div *ngIf="childItemBody.Type === 'boolean'">
                                  <mat-checkbox class="mat-checkbox-light-custom" [disabled]="true"
                                    [checked]="(childItemBody.ValueDisplay === 'True' || childItemBody.ValueDisplay === 'true')" #checkBox>
                                  </mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- This is the overlay panel for the overlay data units-->
        <p-overlayPanel #overlayPanel_OverlayDU id="LBL_OPOverlayDU" styleClass="glb_primeng_overlayPanel_md glb_primeng_overlayPanel_default">
          <div class="glb_customFlexRow" *ngFor="let supItem of item.OverlayDataUnits">
            <div class="col-12 glb_boxedPaddingXS">
              <div class="glb_standardButton glb_skinLightOutline glb_standardButtonTight glb_BGColorSkin text-white">
                {{supItem.Name}} </div>{{supItem.Value}}
            </div>
          </div>
        </p-overlayPanel>
      </div>


      <!-- paginator control buttons (next page, prior page etc) -->
      <div class="row">
        <div class="col">
          <div class="glb_Font d-flex justify-content-center">
            <pagination-controls [id]="EntityName" [autoHide]="autoHide" (pageChange)="onPageChange($event)" [maxSize]="paginatorConfig.maxSize"
              [responsive]="true" [previousLabel]="paginatorConfig.previousLabel" [nextLabel]="paginatorConfig.nextLabel">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>

    <!-- let's put the floating context action bar here. launched entity modal can't see this, and we want it to. so we have moved it to the Entity Template -->
    <div id="LBL_ContextActionBar" class="glb_ActionBarParent glb_ActionBarParentMobile d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center
        align-items-end" *ngIf="loanIndex != null && loanIndex.showContextBar == true" @fadeIn @fadeOut>
      <span style="padding-left:0.375em;">
        <button ngxClipboard [cbContent]="StripAllHighlightTags(loanIndex.dataUnitValue)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clipboard']" matTooltip="Copy to Clipboard">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showDocumentLaunchButton == true" style="padding-left:0.375em;">
        <button (click)="loanIndex.Document_Launcher(loanIndex.dataUnitItem)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download Document">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showDocumentViewButton == true" style="padding-left:0.375em;">
        <button id="BTN_ViewDocument" (click)="loanIndex.Document_Viewer(loanIndex.dataUnitItem)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eye']" matTooltip="Click to View Document">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showEditButton == true" style="padding-left:0.375em;">
        <button id="BTN_EditDataUnit" (click)="loanIndex.Entity_OpenModifyTemplate(loanIndex.dataUnitItem, loanIndex.dataUnitLabelName)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit Field">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showEditObjectButton == true" style="padding-left:0.375em;">
        <!-- here we pass an empty value for the label name, so that the modal will populate an entry for all dataunits inside the datarow -->
        <button id="BTN_EditDataRow" (click)="loanIndex.Entity_OpenModifyTemplate(loanIndex.dataUnitItem, '')"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorEdit glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'list-alt']" matTooltip="Click to Edit Object/Record">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showEditObjectButton == true && loanIndex.showCloneObjectButton == true"
        style="padding-left:0.375em;">
        <button (click)="loanIndex.Entity_OpenModifyTemplate(loanIndex.dataUnitItem, '', '', true)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClone glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone">
          </fa-icon>
        </button>
      </span>

      <span *ngIf="loanIndex != null && loanIndex.showDeleteButton == true" style="padding-left:0.375em;">
        <button id="BTN_DeleteDataRow" (click)="loanIndex.Entity_Delete(loanIndex.dataUnitItem)"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorDelete glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete">
          </fa-icon>
        </button>
      </span>

      <!-- a small separator/gap between other buttons and the close/cancel button -->
      <span style="padding-left:0.375em;">
        <div class="align-self-right">
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </span>

      <!-- a close/cancel button -->
      <span style="padding-left:0.375em;">
        <button id="BTN_CloseActionBar" (click)="loanIndex.DataUnit_RemoveSelected()"
          class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_BGColorClear glb_clickableIconButtonLG align-self-right">
          <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'times']" matTooltip="Click to Close">
          </fa-icon>
        </button>
      </span>
    </div>
  </div>