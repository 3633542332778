import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { ApiService } from '@app/Services/APIService';
import { AccountsControllerMethods, NavigationUrls } from '@app/Global/EnumManager';
import { animate, style, transition, trigger } from '@angular/animations';
import { ClientDataStore, StoreItemTypes } from '@app/Global/ClientDataStore';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ChartDrillThrough',
  templateUrl: './ChartDrillThrough.html',
  styleUrls: ['./ChartDrillThrough.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

//ChartDrillThrough is used to show the drill through, account level data for a clicked data point on a chart
export class ChartDrillThrough implements OnInit {

  constructor(public elementRef: ElementRef, private globalFunctions: GlobalFunctions, private apiService: ApiService, private clientDataStore: ClientDataStore, private router: Router, private dialogRef: MatDialogRef<ChartDrillThrough>) {
    //Grab the list of last searched loans from storage. as we may need to append to it, if the user clicks on an account
    this.LastClickedListName = this.clientDataStore.loginDataDirect.LoginID + StoreItemTypes[StoreItemTypes.LastClickedAccounts];

    //If its non empty, grab the list. 
    if (!globalFunctions.isEmpty(localStorage.getItem(this.LastClickedListName))) {
      //Then set it on the class variable here
      this.LastClickedAccounts = JSON.parse(localStorage.getItem(this.LastClickedListName));
    }

    //Now check if there are any entries, and we can flip the bool to show them on initialize
    if (!globalFunctions.isEmpty(this.LastClickedAccounts) && this.LastClickedAccounts.length > 0) {
      //Nothing to do here
    }
    else {
      //Empty, so lets init the variable ourself as empty. this is needed so that we don't get null errors
      this.LastClickedAccounts = [];
    }
  }

  ngOnInit(): void {
    //We can call ShowDrillThrough on init
    this.ShowDrillThrough(this.ClickedElement);
  }

  //Inputs required to be passed from a caller
  //Lender name is needed to call the server for the next set of data
  @Input() public LenderName: string;
  //Chart name is needed to call the server for the next set of data
  @Input() public ChartName: string;
  //We must know which element was clicked, so we can get some info out of it
  @Input() public ClickedElement: any;
  //And finally, the chart data, so we can get the Portfolio name, or anything else we might need
  @Input() public ChartData: any;
  //First Calendar Date, used for specific, non time series based charts
  @Input() public FirstCalendarDate: any;

  //Local public variables
  public ClickedElementData: any = [];
  public ChartLabel: string;
  public ShowSpinner = true
  //Store the drillthrough account level data here that we retrieve from the server
  public AccountResultData: any;

  //Storing the list of last clicked loans
  public LastClickedAccounts: any[];
  public LastClickedListName: string;

  //Used by the paginator, for initializing
  public PaginatorPaging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  //Used by the paginator, to store the current page that is shown
  public PaginatorCurrentPage: number;

  //Getting short month names
  private MonthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  //When this drill through is closed
  public CloseDrillThrough(): void {
    //Destroy this using angular material
    if (!this.globalFunctions.isEmpty(this.dialogRef)) {
      this.dialogRef.close();
    }
  }

  //When a data point is clicked, will call the server for drill through data.
  public ShowDrillThrough(clickedElement: any): void {
    this.ShowSpinner = true;
    //Empty the old data
    this.AccountResultData = [];

    //Set the dialogData
    this.ClickedElementData = [clickedElement._datasetIndex, clickedElement._index]

    //This is the CalendarDate, e.g. Dec 2020
    //console.log('data.labels[dialogData[1]]', this.data.labels[this.dialogData[1]]);
    let CalendarDate = this.ChartData.labels[this.ClickedElementData[1]];

    //This is the PortfolioName
    //console.log('this.data.datasets[this.dialogData[0]].label', this.data.datasets[this.dialogData[0]].label);
    this.ChartLabel = (JSON.parse(JSON.stringify(this.ChartData.datasets[this.ClickedElementData[0]].label)));
    let PortfolioName = this.ChartData.datasets[this.ClickedElementData[0]].label;

    //This is the value of the data point, e.g. 180000000. not needed for the next call
    //console.log('this.data.datasets[this.dialogData[0]].data[this.dialogData[1]]', this.data.datasets[this.dialogData[0]].data[this.dialogData[1]]);

    //Remove the chart name from the Portfolio, if it is there
    if (PortfolioName.includes("(" + this.ChartName + ")")) {
      PortfolioName = PortfolioName.replace("(" + this.ChartName + ")", "");
    }
    //console.log('PortfolioName', PortfolioName);

    //Send the current month instead for Status breakdown charts
    if (this.ChartName === 'Status Breakdown By Lender (Current Period)') {

      //Get the first CalendarDate from dashboard array. this should only contain data for a single period anyway (for this chart type)
      const firstDate = this.FirstCalendarDate;

      //Get the month and year from it
      const firstDateDT = new Date(firstDate);

      const monthName = this.MonthShortNames[firstDateDT.getMonth()];

      const year = firstDateDT.getFullYear();

      CalendarDate = monthName + ' ' + year;

      //The portfolio name, which will be treated as the status, should be the other column
      PortfolioName = this.ChartData.labels[this.ClickedElementData[1]];

      //Adjust the chart label name, make it blank since the status is already in the portfolio name
      this.ChartLabel = '';
    }

    //Let's send this to the server to get the data for the drillthrough
    this.GetLenderAccountDashboardData(CalendarDate, PortfolioName);
  }

  //Gets the server ODS dashboard account level info
  public GetLenderAccountDashboardData(CalendarDate: string, PortfolioName: string): void {
    const apiRequest = { CalendarDate: CalendarDate, PortfolioName: PortfolioName, LenderName: this.LenderName, ChartName: this.ChartName };
    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetLenderAccountDashboardData], apiRequest).subscribe(apiResponse => {
      //maybe we should check the response here? how do we stop execution otherwise? just check null and stop
      if (this.globalFunctions.isEmpty(apiResponse)) {
        //get rid of the spinner
        this.ShowSpinner = false;
        return;
      }

      const response = JSON.parse(JSON.stringify(apiResponse));
      this.AccountResultData = response;
      //scroll to its first page, in case it was previously sitting on high page number that doesn't exist for this new set of results
      this.OnPageChange(0);
      //get rid of the spinner
      this.ShowSpinner = false;
    });
  }

  //Used when clicking on an account after a drillthrough
  public SelectAccount(account: any): void {
    //after we have selected a loan to view, let's push it into a client side 'last viewed' loan list, that is cached.
    let clickedSearchAccount = null;
    //just use the data that was passed. Note that we will show the loan balance and status in the top header section from the history. oh well. lets see if anyone notices it.
    clickedSearchAccount = account;

    //check the loan that was clicked on. code copied from LoanSearch.ts
    if (!this.globalFunctions.isEmpty(clickedSearchAccount)) {
      //what was the last clicked loan? need to strip mark tags before checking the AccountID, or it won't match.
      clickedSearchAccount.AccountID = this.globalFunctions.StripAllHighlightTags(clickedSearchAccount.AccountID.toString(), this.globalFunctions.GetSearchHighlightColor());
      //now we can get all copies of this loan that already exist in the lastClickedAccounts array
      const allMatching = this.LastClickedAccounts.filter(x => x.AccountID === clickedSearchAccount.AccountID);

      //if its non empty
      if (!this.globalFunctions.isEmpty(allMatching)) {
        //check for all existing instances of this loan, and remove it. we don't want it in this list multiple times
        //console.log('deleting existing clickedSearchLoan', clickedSearchLoan);
        this.LastClickedAccounts.forEach((item, index) => {
          if (item.AccountID === clickedSearchAccount.AccountID) {
            this.LastClickedAccounts.splice(index, 1);
          }
        });
      }

      //before we stick it in, we need to remove all the higlights. (mark tags)
      Object.entries(clickedSearchAccount).forEach(
        ([rowKey, rowValue]) => {
          //console.log('rowKey', rowKey);
          //console.log('rowValue', rowValue);
          if (!this.globalFunctions.isEmpty(rowValue)) {
            //remove all mark tags
            rowValue = this.globalFunctions.StripAllHighlightTags(rowValue.toString(), this.globalFunctions.GetSearchHighlightColor());
            //update the value into the parent object so that the html is updated
            //console.log('post rowValue', rowValue);
            clickedSearchAccount[rowKey] = rowValue;
          }
        })

      //this inserts to the updated list to the local class variable
      this.LastClickedAccounts.unshift(clickedSearchAccount);

      //limit this array to 20 or so items. loop through and remove any over the limit
      const listMax = 20
      if (this.LastClickedAccounts.length > listMax) {
        //count how many we are over the limit by
        const deletionCount = this.LastClickedAccounts.length - listMax;

        //now we can splice this many items out of the array.
        if (deletionCount > 0) {
          this.LastClickedAccounts.splice(listMax, deletionCount);
        }
      }

      //set the selected loan, but we want to use the parsed copy where the highlights are removed
      this.clientDataStore.SetSelectedAccount(clickedSearchAccount);

      //disable the search bar input and button on the page header, after a loan is selected
      //this.clientDataStore.Update_HideSearchBar(true);

      //now we can save this updated list of clicked loans into the cache
      localStorage.setItem(this.LastClickedListName, JSON.stringify(this.LastClickedAccounts));

      //Now we are ready to route to the account (LoanIndex) page
      //Go to the account page
      const targetURL = [NavigationUrls.Account.replace(':AccountID', clickedSearchAccount.AccountID)];

      //Tell router to reload the component, even if the URL is the same
      this.router.navigate(targetURL, { onSameUrlNavigation: 'reload' });

      this.CloseDrillThrough();
    }
  }

  //Paginator page change method
  public OnPageChange(page: number): void {
    this.PaginatorCurrentPage = page;
  }
}